/* Angular */
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, Event, UrlTree, Params } from '@angular/router';
import { take, lastValueFrom, forkJoin, firstValueFrom, Subscription } from 'rxjs';

/* Libraries */
import { DateTime } from 'luxon'; // Used for all time related operations
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

/* Class */
import { AvailabilityParsing } from 'src/app/v2/classes/availability-parsing';

/* Models */
import { BookingPage } from 'src/app/models/storage/booking-page.model';
import { ProductLocation } from 'src/app/models/storage/product-location.model';
import { User } from 'src/app/models/storage/user.model';
import { Collection } from 'src/app/v2/models/collection-reference.model';
import { BookingFlowContentType, BookingTemplate, DateRangeContentCard } from 'src/app/v2/models/booking-flow.model';
import { AvailabilityInterface, AvailabilityObject, CartQuantities } from 'src/app/models/availability.model';
import { Cart } from 'src/app/models/storage/cart.model';
import { Company } from 'src/app/models/storage/company.model';
import { Product } from 'src/app/models/storage/product.model';
import { ProductGroup } from 'src/app/models/storage/product-group.model';
import { WidgetInterface } from 'src/app/models/widget.model';
import { InventoryPage } from 'src/app/models/storage/inventory-page.model';
import { DiscountCode } from 'src/app/v2/models/storage/discount-code.model';
import { ProductSizeType } from 'src/app/models/storage/product-size-type.model';
import { ProductSize } from 'src/app/v2/models/product-size.model';
import { Rental } from 'src/app/models/storage/rental.model';
import { DateRangeInputOnChange, DateRangeConfig } from 'src/app/v2/models/date-range.model';

/* Components */
import { Cart2Component } from 'src/app/partner/booking-suite/cart2/cart2.component';
import { BookingCatalogViewComponent } from '../../commons/booking-flows/catalog/booking-catalog-view/booking-catalog-view.component';

/* Services */
import { ProductsService } from 'src/app/services/products.service';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { AvailabilityService } from 'src/app/services/availability.service';
import { InventoryPageService } from 'src/app/services/inventory-page.service';
import { FirestoreService } from 'src/app/v2/services/firestore.service';
import { CookieInteractionService } from 'src/app/v2/services/cookie-interaction.service';
import { TimeService } from 'src/app/services/time.service';
import { CartComponent } from './cart/cart.component';
import { CartService } from 'src/app/services/cart.service';
import { PageBy } from 'src/app/v2/models/firestore-interaction.model';
import { DateRangeService } from 'src/app/v2/services/date-range.service';

@Component({
  selector: 'app-booking-flow-wrapper',
  templateUrl: './booking-flow-wrapper.component.html',
  styleUrls: ['./booking-flow-wrapper.component.scss', '../../../styles/booking-flow.scss']
})

export class BookingFlowWrapperComponent implements OnInit, OnDestroy {
  bookingFlowContentType = BookingFlowContentType; // Enum

  protected dateRangeConfig: DateRangeConfig = {
    showAdditionalInputs: {
      showTimeslots: false
    },
    filterByCurrentDay: true,
    showFormLabels: false,
    showAvailabilityOverrideToggle: false,
    runErrorValidationOnInit: true
  }

  algoMetadata: {} = {}; // Has a type in a later PR
  protected currentBookingFlowNavigationType: BookingFlowContentType;
  protected initContentType: BookingFlowContentType; // Determines the init state of the booking flow (catalog, search, cart, etc)
  private user: User; // Currently logged in user (if logged in)
  private timer: ReturnType<typeof setTimeout>;
  protected cartQuantities: CartQuantities;
  protected isMobile: boolean = false;
  protected dateRangeContentCards: DateRangeContentCard[] = [];
  protected queryParamsForInvPageParent: { startDate: DateTime, endDate: DateTime, location: string, productGroupID: string };
  protected customerInfoLoaded: boolean = false;
  protected paymentIntentInfoLoaded: boolean = false;
  protected checkoutSuccessLoaded: boolean = false;
  protected rentalSearchDaySpan: number | null = null;
  protected algoRes: AvailabilityObject;
  protected cartWidgetList: WidgetInterface[] = [];
  protected cartItemID: string;
  protected selectedLocationID: string; // holds chosen location
  protected selectedStartDate: DateTime | null = null;
  protected selectedEndDate: DateTime | null = null;
  protected cartObj: Cart = { items: [] };
  protected bookingFlowIsLoading: boolean = true; // Loader / spinner
  protected availabilityLoadingSpinner: boolean = true;
  protected currentContentID: string = "";
  protected customerBookingPagesMap: { [id: string]: BookingPage };
  protected templateObj: BookingTemplate;
  protected gridHeight: number = 550;
  protected companyObj: Company;
  protected availability: AvailabilityInterface;
  private subs: Subscription = new Subscription();
  protected templateID: string; // the ID of the template being viewed / edited (both Creator & Customer)
  protected isDev: boolean = false;
  protected isPartner: boolean = false;
  private userCompanyID: string | undefined;
  protected userBelongsToCompany: boolean | undefined; // Could possibly be removed with legacy custom cart
  protected showingInventoryPage: boolean = false; // shows and hides the inventory page component
  protected pmtLinkRentalDoc: Rental | null = null;
  protected initialComponentLoad: boolean = true;

  // Stripe
  protected isTestStripe: boolean = false;
  protected isSkipPayment: boolean = false;

  /* Collection Arrays */
  protected locationsArray: ProductLocation[] = []; // holds collection
  protected productsArray: Product[] = [];
  protected productGroupsArray: ProductGroup[] = [];
  protected widgetsArray: WidgetInterface[] = [];

  /* Collection Maps */
  protected widgetsMap: { [key: string]: WidgetInterface } = {};
  protected inventoryPageMap: { [key: string]: InventoryPage } = {};
  protected discountCodeMap: { [key: string]: DiscountCode } = {};
  protected locationsMap: { [key: string]: ProductLocation } = {};
  protected productSizeTypesMap: { [key: string]: ProductSizeType } = {};
  protected productSizesMap: { [key: string]: ProductSize } = {};
  protected productsMap: { [key: string]: Product } = {};
  protected productGroupsMap: { [key: string]: ProductGroup } = {};


  @ViewChild(CartComponent) cartComponent!: CartComponent;
  @ViewChild(BookingCatalogViewComponent) bookingCatalogViewComponent: BookingCatalogViewComponent;

  // Dependency Injections
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private bpo: BreakpointObserver,
    private productService: ProductsService,
    private availabilityService: AvailabilityService,
    private currentUserService: CurrentUserService,
    private firestoreService: FirestoreService,
    private cookieInteractionService: CookieInteractionService,
    private timeService: TimeService,
    private cdr: ChangeDetectorRef,
    private cartService: CartService,
    private dateRangeService: DateRangeService
  ) { }

  public async ngOnInit(): Promise<void> {
    await this.queryInitalUrlParams(); // Gets Template + pmtLink
    this.initializeBreakpointObserver();
    await this.setAccessLevelVariables() // determine user settings, etc (needs templateID)
    await this.processCollectionPromises();
  }

  private processInitalLocationFromURL(): string {
    /* Assign values based off of routes */
    let location: string = this.activatedRoute.snapshot.queryParamMap.get("location");

    // If no location provided in URL or Location can't be found amongst companies locations
    if (!location || !this.locationsMap[location]) {
      // Attempt to find a default location in locationMap and use it
      const defaultLocation = this.locationsArray.find((l) => l.isDefault)

      if (defaultLocation) { // If default found, assign and stop further processing
        location = defaultLocation.id;
      }
      else if (this.locationsArray.length >= 1) { // No default found, choose 1st available location
        location = this.locationsArray[0].id;
      }
      else { // If no available locations, throw err
        throw new Error($localize`No available location found`)
      }
    }

    return location;
  }

  private processInitalDatesFromURL(locationID): { dayStart: DateTime, dayEnd: DateTime } {
    /* Assign values based off of routes */
    let timezone: string
    try {
      timezone = this.locationsMap[locationID].timezone;
    }
    catch (err) {
      throw new Error($localize`Location is missing timezone`)
    }

    // Note: KeepLocalTime is used because we want the ISODate DateTime obj to be equal to the epoch seconds that
    // would equal that date in the correct timezone. Shifting it after the fast without keeping the local time
    // would maintain the epoch seconds equal to the amt of the system machine rather than actually changing it to equal the isodate in the shops timezone

    // Parse URL for Date and set to shops zone
    let dayStart: DateTime = DateTime.fromISO(this.activatedRoute.snapshot.queryParamMap.get("startDate")).setZone(timezone, { keepLocalTime: true }).startOf('day'),
      dayEnd: DateTime = DateTime.fromISO(this.activatedRoute.snapshot.queryParamMap.get("endDate")).setZone(timezone, { keepLocalTime: true }).startOf('day');

    // If the DateTime objects were created incorrectly for any reason, default to the shops current date
    if (!dayStart.isValid && !dayEnd.isValid) {
      dayStart = DateTime.now().setZone(timezone, { keepLocalTime: true }).startOf('day');
      dayEnd = DateTime.now().setZone(timezone, { keepLocalTime: true }).startOf('day');
    }

    // Validify that format of both dates are correct + dayEnd is greater than start date (if it's the same, it will be assigned to be the same)
    if (!dayStart.isValid || !dayEnd.isValid || dayEnd < dayStart) {
      if (!dayStart.isValid) {
        dayStart = dayEnd.isValid ? dayEnd : DateTime.now().setZone(timezone);
      }
      if (!dayEnd.isValid || dayEnd < dayStart) {
        dayEnd = dayStart;
      }
    }

    return { dayStart: dayStart, dayEnd: dayEnd };
  }

  private setStateFromInitalUrlParams(): void {
    if (this.isCartInputsLocked()) { // If cart lock in effect, assign search variable state based off of it
      this.setSearchInputByCartObj(this.cartObj);
    }
    else {
      /* Assign search variable state based off of routes */
      let locationIDFromURL: string = null,
        dayStart: DateTime = null,
        dayEnd: DateTime = null;

      try {
        locationIDFromURL = this.processInitalLocationFromURL();
        const dateTimesFromURL: { dayStart: DateTime, dayEnd: DateTime } = this.processInitalDatesFromURL(locationIDFromURL);
        dayStart = dateTimesFromURL.dayStart;
        dayEnd = dateTimesFromURL.dayEnd;

        this.setSearchInputs({ startDate: dayStart, endDate: dayEnd, location: locationIDFromURL });
      }

      catch (err) { // If an error occurs when attempting to set the inital search state, navigate to 404
        this.router.navigate(['404']);
      }
    }
  }

  private async queryInitalUrlParams(): Promise<void> {
    /* Request the templateObj from the templateID via the URL */
    this.templateID = this.activatedRoute.snapshot.paramMap.get("templateID")
    try {
      const guardResponse = await lastValueFrom(this.activatedRoute.data.pipe(take(1)));
      console.log('guardResponse', guardResponse);
      this.templateObj = guardResponse.templateID; // the dot notation represents the param name
    }
    catch {
      this.router.navigate(['/404']);
      return;
    }

    const rentalDoc = await this.getPmtLinkRentalDoc();
    console.log(rentalDoc)
    if (rentalDoc) {
      this.proccessPaymentLinkPreReqs(rentalDoc);
    }
  }

  private async proccessPaymentLinkPreReqs(rentalDoc) {
    const cartObjItems = rentalDoc.cartObj.items;
    if (!cartObjItems || cartObjItems.length <= 0) {
      throw new Error($localize`Invalid cart items provided from payment link`);
    }

    // Navigate to cart
    this.selectedStartDate = this.timeService.convertTimestampToLuxon(cartObjItems[0]?.dayStart).setZone(this.locationsMap[cartObjItems[0]?.locationID]?.timezone).startOf('day');
    this.selectedEndDate = this.timeService.convertTimestampToLuxon(cartObjItems[0]?.dayEnd).setZone(this.locationsMap[cartObjItems[0]?.locationID]?.timezone).startOf('day');
    this.selectedLocationID = rentalDoc.rentalLocationID ? rentalDoc.rentalLocationID : cartObjItems[0].locationID; // Can fail on older rental
    this.cartObj = Object.assign({...rentalDoc.cartObj, statusDate: rentalDoc.statusDate});
    this.navigateToCart();
  }

  private getUpdatedUrlParams(): void {
    /* Template Query Params */

    // ContentID
    this.currentContentID = this.activatedRoute.snapshot.queryParamMap.get("content") ? this.activatedRoute.snapshot.queryParamMap.get("content") : "";

    // Note: KeepLocalTime is used because we want the ISODate DateTime obj to be equal to the epoch seconds that
    // would equal that date in the correct timezone. Shifting it after the fast without keeping the local time
    // would maintain the epoch seconds equal to the amt of the system machine rather than actually changing it to equal the isodate in the shops timezone

    // Date Range
    let dayStart = DateTime.fromISO(this.activatedRoute.snapshot.queryParamMap.get("startDate")).setZone(this.locationsMap[this.selectedLocationID].timezone, { keepLocalTime: true });
    let dayEnd = DateTime.fromISO(this.activatedRoute.snapshot.queryParamMap.get("endDate")).setZone(this.locationsMap[this.selectedLocationID].timezone, { keepLocalTime: true });
    let location = this.activatedRoute.snapshot.queryParamMap.get("location");
    console.debug('getUpdatedUrlParams()', dayStart, dayEnd, location)
    if (this.isCartInputsLocked()) {
      this.setSearchInputByCartObj(this.cartObj);
    }
    else {
      if (dayStart && dayEnd && location) {
        this.setSearchInputs({ startDate: dayStart, endDate: dayEnd, location: location })
      }
    }
  }

  private setSearchInputByCartObj(cartObj: Cart): void {
    let cartObjItems = cartObj.items
    // Shouldn't keep local time since we have the timestamp / epoch stored in db
    this.selectedStartDate = this.timeService.convertTimestampToLuxon(cartObjItems[0]?.dayStart).setZone(this.locationsMap[cartObjItems[0]?.locationID]?.timezone);
    this.selectedEndDate = this.timeService.convertTimestampToLuxon(cartObjItems[0]?.dayEnd).setZone(this.locationsMap[cartObjItems[0]?.locationID]?.timezone);
    this.selectedLocationID = cartObjItems[0].locationID; // Can fail on older rental
  }

  private createRentalCookie(rentalDocID: string) {
    // Create cookie
    const date = new Date();
    date.setTime(date.getTime() + (60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    const name = `fm.R.${this.templateID}`
    const rentalID = rentalDocID;
    const secureAttributes = "secure;SameSite=Strict";
    document.cookie = `${name}=${rentalID};${expires};${secureAttributes};Path=/;`
  }

  private async getPmtLinkRentalDoc(): Promise<Rental | null> {
    // Attempt to get valid rentalID from query params
    let rentalDoc: Rental | null = await this.checkForRentalIDQueryParam();
    if (rentalDoc) {
      this.createRentalCookie(rentalDoc.id); // Create cookie
      this.pmtLinkRentalDoc = rentalDoc;
      return rentalDoc;
    }

    // If gets here, then no rentalID found in query params. Check if rentalID in cookies
    rentalDoc = await this.checkForRentalIDCookie();
    if (rentalDoc) {
      this.pmtLinkRentalDoc = rentalDoc;
      return rentalDoc;
    }

    return null;
  }

  private async checkForRentalIDQueryParam(): Promise<null | Rental> {
    // Attempt to get valid rentalID from query params
    const rentalIDFromURL = this.activatedRoute.snapshot.queryParamMap.get("rental");
    console.log(rentalIDFromURL)

    if (!rentalIDFromURL) { // If no param found
      return null;
    }

    const rentalDoc: Rental | null = await this.firestoreService.getDocument(Collection.Rentals, rentalIDFromURL);

    if (!rentalDoc) {
      return null
    }

    return rentalDoc;
  }

  private async checkForRentalIDCookie(): Promise<null | Rental> {
    // Attempt to get valid rentalID from cookie

    const cookieName: string = this.cookieInteractionService.getPaymentLinkCookie(this.templateID);
    const cookieValue: string | null = this.cookieInteractionService.getCookie(cookieName);

    if (!cookieValue) {
      return null
    }

    const rentalDoc: Rental | null = await this.firestoreService.getDocument(Collection.Rentals, cookieValue);

    if (!rentalDoc) {
      return null
    }
    return rentalDoc;
  }

  private initializeBreakpointObserver(): void {
    this.subs.add(this.bpo.observe(["(max-width: 589px)"]).subscribe((result: BreakpointState) => {
      result.matches ? this.isMobile = true : this.isMobile = false;
    }));
  }

  /**
   * @description This method is meant to subscribe to other neccessary collections after having recieved template data
   */
  protected async processCollectionPromises(): Promise<void> {

    const promises: Promise<any>[] = []; // A variety of types will be pushed onto this arr (so using any)
    const
      // Collections
      bookingPagesCollection$ = this.firestoreService.getCollection(Collection.BookingPages, [
        { field: 'companyID', operator: '==', value: this.templateObj.companyID },
        { field: 'isActive', operator: '==', value: true }
      ]),
      productGroupCollection$ = this.firestoreService.getCollection(Collection.ProductGroup, [
          { field: 'companyID', operator: '==', value: this.templateObj.companyID },
          { field: 'isActive', operator: '==', value: true }
        ], [], { limit: PageBy.MaximumSize }
      ),
      productsCollection$ = this.firestoreService.getCollection(Collection.Products, [
        { field: 'companyID', operator: '==', value: this.templateObj.companyID },
        { field: 'isActive', operator: '==', value: true }
        ], [], { limit: PageBy.MaximumSize }
      ),
      productLocationCollection$ = this.firestoreService.getCollection(Collection.ProductLocations, [
        { field: 'companyID', operator: '==', value: this.templateObj.companyID },
        { field: 'isActive', operator: '==', value: true }
      ]),
      widgetCollection$ = this.firestoreService.getCollection(Collection.Widgets, [
        { field: 'companyID', operator: '==', value: this.templateObj.companyID },
        { field: 'isActive', operator: '==', value: true }
      ]),
      productSizesCollection$ = this.firestoreService.getCollection(Collection.ProductSizes, [
        { field: 'companyId', operator: '==', value: this.templateObj.companyID },
        { field: 'isActive', operator: '==', value: true }
      ]),
      productSizeTypesCollection$ = this.firestoreService.getCollection(Collection.ProductSizeTypes, [
        { field: 'companyId', operator: '==', value: this.templateObj.companyID },
        { field: 'isActive', operator: '==', value: true }
      ]),
      discountCodeCollection$ = this.firestoreService.getCollection(Collection.DiscountCodes, [
        { field: 'companyID', operator: '==', value: this.templateObj.companyID },
        { field: 'isActive', operator: '==', value: true },
        { field: 'expiredDate', operator: '>', value: new Date() }
      ]),
      inventoryPageCollection$ = this.firestoreService.getCollection(Collection.InventoryPages, [
        { field: 'companyID', operator: '==', value: this.templateObj.companyID },
        { field: 'isActive', operator: '==', value: true }
      ]);

    // Cart Doc Promise
    if (localStorage.getItem(`FM-cart-uuid-${this.templateID}`) && !this.pmtLinkRentalDoc) { // If an ID was found in local storage, set up a promise to query for it
      const cartPromise: Promise<Cart> = this.firestoreService.getDocument(Collection.Cart, localStorage.getItem(`FM-cart-uuid-${this.templateID}`));
      if (cartPromise) {
        promises.push(cartPromise.then((res): Promise<void> => {
          return new Promise((resolve) => {
            this.cartObj = res;
            resolve();
          })
        }));
      }
    }

    // Company Doc Promise
    const getCompanyDoc: Promise<Company> = this.firestoreService.getDocument(Collection.Companies, this.templateObj?.companyID);
    promises.push(getCompanyDoc.then((res): Promise<void> => {
      return new Promise((resolve) => {
        this.companyObj = res;
        resolve();
      })
    }));

    promises.push(firstValueFrom(bookingPagesCollection$).then((res): Promise<void> => {
      return new Promise((resolve) => {
        this.customerBookingPagesMap = this.firestoreService.createCollectionMap(res);
        resolve();
      })
    }));

    promises.push(firstValueFrom(productGroupCollection$).then((res): Promise<void> => {
      return new Promise((resolve) => {
        this.productGroupsArray = res;
        this.productGroupsMap = this.firestoreService.createCollectionMap(res);
        resolve();
      })
    }));

    promises.push(firstValueFrom(productsCollection$).then((res): Promise<void> => {
      return new Promise((resolve) => {
        this.productsArray = res;
        this.productsMap = this.firestoreService.createCollectionMap(res);
        resolve();
      })
    }));

    promises.push(firstValueFrom(productLocationCollection$).then((res): Promise<void> => {
      return new Promise((resolve) => {
        this.locationsMap = this.firestoreService.createCollectionMap(res);
        this.locationsArray = res;
        resolve();
      })
    }));

    promises.push(firstValueFrom(widgetCollection$).then((res): Promise<void> => {
      return new Promise((resolve) => {
        this.widgetsMap = this.firestoreService.createCollectionMap(res);
        this.widgetsArray = res;
        resolve();
      })
    }));

    promises.push(firstValueFrom(productSizeTypesCollection$).then((res): Promise<void> => {
      return new Promise(async (resolve) => {
        this.productSizeTypesMap = this.firestoreService.createCollectionMap(res);
        await this.queryAndApplyDefaultSizeTypeAndSizes();
        resolve();
      })
    }));

    promises.push(firstValueFrom(productSizesCollection$).then((res): Promise<void> => {
      return new Promise((resolve) => {
        this.productSizesMap = this.firestoreService.createCollectionMap(res);
        resolve();
      })
    }));

    promises.push(firstValueFrom(discountCodeCollection$).then((res): Promise<void> => {
      return new Promise((resolve) => {

        res.forEach((discountCode) => {
          this.discountCodeMap[discountCode.discountTitle.toLowerCase()] = discountCode;
        })
        resolve();
      })
    }));

    promises.push(firstValueFrom(inventoryPageCollection$).then((res): Promise<void> => {
      return new Promise((resolve) => {
        this.inventoryPageMap = this.firestoreService.createCollectionMap(res);
        resolve();
      })
    }));

    // Wait for all promises to complete and processing to finish
    forkJoin(promises).pipe(take(1)).subscribe({
      next: () => {
        if (this.initialComponentLoad) {
          this.operationsAfterPromisesComplete();
          this.initialComponentLoad = false;
        }
        else {
          if (this.cartComponent) {
            this.cartComponent.checkOutProcessing();
          }
        }
      },
      error: (error) => {
        console.error("Error loading collections: ", error);
      }
    });
  }

  protected provideNewCart() {
    this.cartObj = {items: []}
    this.pmtLinkRentalDoc = null
  }

  private async queryAndApplyDefaultSizeTypeAndSizes(): Promise<void> {
    // Default SizeType
    const defaultSizeType = await this.productService.getCustomSizeTypesPromiseDefault(); // get default size type (1 doc for all companies)
    defaultSizeType.forEach(async (sizeType) => {
      this.productSizeTypesMap[sizeType.id] = sizeType

      // Default Sizes
      const defaultSizes = await this.productService.getCustomSizesPromise(sizeType.id);
      defaultSizes.forEach((size) => {
        this.productSizesMap[size.id] = size;
      })
    })
  }

  private async navigateAndCallAvailability(route: string[], applyDebounce?: boolean): Promise<void> {
    if (route[3] == 'search') {
      this.showDatedSearchPage();
      if (applyDebounce) {
        this.debounceTimer({ startDate: this.selectedStartDate, endDate: this.selectedEndDate, location: this.selectedLocationID })
      }
      return
    }

    if (route[3] == 'catalog') {
      this.navigateToFrontOfTemplateView();
      if (applyDebounce) {
        this.debounceTimer({ startDate: this.selectedStartDate, endDate: this.selectedEndDate, location: this.selectedLocationID })
      }
      return
    }
  }

  private handleViewTypePreference(): void {
    /* View Type */
    let path = this.router.url.split("/");
    let viewType: string[] = ['catalog']; // default view type if none are defined

    /* If we're visiting a component other than a search view type ex:(catalog, dated, etc), return early */
    if (path.length === 4 && ['cart', 'customer-info', 'payment-intent'].includes(path[3])) {
      return
    }

    /* If no viewtype is currently defined in the URL, Set the current Viewtype to the templates default */
    if (path.length === 3) {
      switch (this.templateObj.viewTypePreference) {
        case 'catalog': // for consistentcy
          viewType = ['catalog'];
          break;
        case 'date':
          viewType = ['search'];
          break;
      }
    }
    // if viewtype already specified in url, router should be relative to it's current route
    else {
      viewType = [];
    }

    /* ContentID */
    this.currentContentID = this.activatedRoute.snapshot.queryParamMap.get("content");
    this.router.navigate(viewType, { relativeTo: this.activatedRoute, queryParams: { location: this.selectedLocationID, startDate: this.selectedStartDate.toISODate(), endDate: this.selectedEndDate.toISODate(), content: this.currentContentID ? this.currentContentID : null } })
  }

  private async operationsAfterPromisesComplete(): Promise<void> {
    this.setStateFromInitalUrlParams(); // Sets inital search variable state based off init URL (navigates to 404 on err)
    this.subscribeToRouteChanges(); // Subscribe to route changes / updates
    this.handleViewTypePreference(); // Adjust URL by view type preference
    await this.determineInitComponentToShow(); // Determines inital component to load based off current URL
    this.bookingFlowIsLoading = false; // BookingFlowSpinner
  }

  protected navigateToInvPage(chosenProductGroupID: string): void {
    /* Navigating to inventory page from datedSearch view */
    this.navigateSearchView(['search', chosenProductGroupID]);
  }

  protected navigateToCustInfo(): void {
    let queryParams = {};
    if (this.pmtLinkRentalDoc) {
      queryParams['rental'] = this.pmtLinkRentalDoc.id
    }
    this.router.navigate(['customer-info'], { relativeTo: this.activatedRoute, queryParams: queryParams });
    this.viewComponentManager(BookingFlowContentType.CustomerInfo)
  }

  protected navigateToPaymentIntent(isTest?: boolean, isWOPayment?: boolean): void {
    let queryParams = {};
    if (isTest) {
      this.isTestStripe = true
    }
    if (isWOPayment) {
      this.isSkipPayment = true
    }
    if (this.pmtLinkRentalDoc) {
      queryParams['rental'] = this.pmtLinkRentalDoc.id
    }
    this.router.navigate(['payment-intent'], { relativeTo: this.activatedRoute, queryParams: queryParams })
    this.viewComponentManager(BookingFlowContentType.PaymentIntent)
  }

  protected navigateToSuccessPayment(): void {
    this.router.navigate(['checkout-success'], { relativeTo: this.activatedRoute })
    this.viewComponentManager(BookingFlowContentType.CheckoutSuccess);
  }

  private navigateToFrontOfTemplateView(): void {
    this.router.navigate(['catalog'], { relativeTo: this.activatedRoute, queryParams: { content: "", location: this.selectedLocationID, startDate: this.selectedStartDate.toISODate(), endDate: this.selectedEndDate.toISODate() } })
  }

  protected navigateToCart(): void {
    let queryParams = {};
    // If rentalID
    if (this.pmtLinkRentalDoc) {
      queryParams['rental'] = this.pmtLinkRentalDoc.id
    }
    this.router.navigate(['cart'], { relativeTo: this.activatedRoute, queryParams: queryParams });
  }

  protected navigateToCartEditView(e: any): void {
    this.cartItemID = e.cartItemID;
    this.selectedStartDate = e.startDate;
    this.selectedEndDate = e.endDate

    this.router.navigate(['item-edit', e.productGroupID], {
      queryParams: {
        location: e.location, startDate: e.startDate.toISODate(), endDate: e.endDate.toISODate(),
        cartItemID: e.cartItemID
      }, relativeTo: this.activatedRoute
    })
  }

  protected handleItemsBeingAddedToNewCart(cartDoc: Cart): void {
    this.cartObj = cartDoc; // set state of cart
  }

  protected async algoResUpdated(newAvailabilityParsing: AvailabilityInterface): Promise<void> {
    this.availability = newAvailabilityParsing;
    this.setAvailabilityProperties();
    await this.updateAvailabilityOnView();
  }

  private async updateAvailabilityOnView(): Promise<void> {
    await this.updateContentCards(); // Update dated search view
    this.findContentCardAvailability(); // Update catalog view
  }

  private viewComponentManager(showView: BookingFlowContentType, showingInventoryPage?: boolean): void {
    showingInventoryPage ? this.showingInventoryPage = true : this.showingInventoryPage = false;

    switch (showView) {
      case BookingFlowContentType.Catalog:
        this.currentBookingFlowNavigationType = BookingFlowContentType.Catalog;
        break;
      case BookingFlowContentType.DateRangeSearch:
        this.currentBookingFlowNavigationType = BookingFlowContentType.DateRangeSearch;
        break;
      case BookingFlowContentType.Cart:
        this.currentBookingFlowNavigationType = BookingFlowContentType.Cart;
        break;
      case BookingFlowContentType.CustomerInfo:
        this.currentBookingFlowNavigationType = BookingFlowContentType.CustomerInfo;
        break;
      case BookingFlowContentType.PaymentIntent:
        this.currentBookingFlowNavigationType = BookingFlowContentType.PaymentIntent;
        break;
      case BookingFlowContentType.CheckoutSuccess:
        this.currentBookingFlowNavigationType = BookingFlowContentType.CheckoutSuccess;
        break;
      case BookingFlowContentType.CartItemEdit:
        this.showingInventoryPage = true;
        this.currentBookingFlowNavigationType = BookingFlowContentType.CartItemEdit;
        break;
    }
    return
  }

  async navigateCatalogView(urlArray) {
    // If a location and date have already been searched for
    if (this.selectedStartDate !== null && this.selectedEndDate !== null && this.selectedLocationID !== null) { // user has been
      console.log("DO STUFF CATALOG FULL")
      await this.router.navigate(urlArray, { queryParams: { content: this.currentContentID ? this.currentContentID : null, location: this.selectedLocationID, startDate: this.selectedStartDate.toISODate(), endDate: this.selectedEndDate.toISODate() }, relativeTo: this.activatedRoute })
    }
    else { // if no prior search path history
      console.log("DO STUFF CATALOG EMPTY")
      console.log(urlArray)
      await this.router.navigate(urlArray, { queryParams: { content: this.currentContentID ? this.currentContentID : null }, relativeTo: this.activatedRoute })
    }
    return
  }

  selectedLocationChange($event: ProductLocation) {
    this.selectedLocationID = $event.id;
  }

  private navigateSearchView(urlArray: string[]): void {
    if (this.selectedStartDate !== null && this.selectedEndDate !== null && this.selectedLocationID !== null) { // user has been
      this.router.navigate(urlArray, {
        queryParams: {
          location: this.selectedLocationID, startDate: this.selectedStartDate.toISODate(),
          endDate: this.selectedEndDate.toISODate()
        }, relativeTo: this.activatedRoute
      })
    }
    else { // if no prior search path history
      this.router.navigate(urlArray, { relativeTo: this.activatedRoute })
    }
  }

  protected continueShopping($event: boolean): void {
    if ($event == true) { // If back to shopping
      let route = this.router.url.split('/');
      // We can set the second param to true if we want to ensure that the cache catches other peoples rentals / cancellations (reruns the availability)
      // With it as false, it will not re-run the availability if the user adds to their cart. It will simply take them back to the availability status from when they added to cart
      // Pro of false = faster UI / better UX.
      this.navigateAndCallAvailability(route, false)
    }
    else { // Otherwise -> cart
      this.router.navigate(['cart'], { relativeTo: this.activatedRoute });
    }
  }

  async determineIfDev(): Promise<boolean> {
    const user = await this.currentUserService.getCurrentUserOnce()
    return user ? user.isDeveloper : false
  }

  async determineIfPartner(): Promise<boolean> {
    const user = await this.currentUserService.getCurrentUserOnce()
    return user ? user.levelType == 'Partner' : false
  }

  private loadInventoryPage(): void {
    if (this.router.url.includes('catalog')) {
      if (this.cartObj?.items.length > 0) {
        this.templateObj?.content.forEach((contItem) => {
          if (contItem?.contentID === this.currentContentID && contItem.isItem) {
            this.queryParamsForInvPageParent = {
              productGroupID: contItem.productGroupID,
              startDate: this.selectedStartDate,
              endDate: this.selectedEndDate,
              location: this.selectedLocationID
            };
          }
        });
      }
      this.viewComponentManager(BookingFlowContentType.Catalog);
      this.showingInventoryPage = true;
    } else {
      this.viewComponentManager(BookingFlowContentType.DateRangeSearch);
      this.showingInventoryPage = true;
      this.queryParamsForInvPageParent = {
        productGroupID: this.activatedRoute.firstChild.snapshot.params.productGroupID,
        startDate: this.selectedStartDate,
        endDate: this.selectedEndDate,
        location: this.selectedLocationID
      };
    }
  }

  private handleCartRoutes(): void {
    this.viewComponentManager(BookingFlowContentType.Cart);
    this.initContentType = BookingFlowContentType.Cart;
  }

  private async determineInitComponentToShow() {
    const url = this.router.url;

    // Must query the availability + apply processing for views previous to showing them
    if (url || url.includes('search') || url.includes('catalog')) {
      await this.setAvailabilityState();
    }

    switch (true) {
      case url.includes('search'):
        this.handleSearchRoutes(url);
        break;

      case url.includes('catalog'):
        this.handleCatalogRoutes();
        break;

      case url.includes('item-edit'):
        this.viewComponentManager(BookingFlowContentType.CartItemEdit);
        break;

      case url.includes('cart'):
        this.handleCartRoutes();
        break;

      case url.includes('customer-info'):
        this.viewComponentManager(BookingFlowContentType.CustomerInfo);
        this.initContentType = BookingFlowContentType.CustomerInfo;
        break;

      case url.includes('payment-intent'):
        this.viewComponentManager(BookingFlowContentType.PaymentIntent);
        this.initContentType = BookingFlowContentType.PaymentIntent;
        break;

      case url.includes('checkout-success'):
        this.viewComponentManager(BookingFlowContentType.CheckoutSuccess);
        this.initContentType = BookingFlowContentType.CheckoutSuccess;
        break;
    }

    // Extra processing is done if showing an inventory page
    if (this.showingInventoryPage) {
      this.loadInventoryPage();
    }
  }

  private subscribeToRouteChanges(): void {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) { //  Updates the view based on the final URL after navigation has completed.
        this.handleNavigationEnd(event);
      } else if (event instanceof NavigationStart) { // Primarily concerned with handling browser navigation
        this.handleNavigationStart(event);
      }
    });
  }

  private handleNavigationStart(event: NavigationStart): void {
    if (event.navigationTrigger === 'popstate') {
      const difference = this.comparePopstateDifferences(event, true);
      if (difference) {
        this.setAvailabilityState();
      }
    }
  }

  private handleNavigationEnd(event: NavigationEnd): void {
    switch (true) {
      case event.url.includes("search"):
        this.handleSearchRoutes(event.url);
        break;
      case event.url.includes("catalog"):
        this.handleCatalogRoutes();
        break;
      case event.url.includes('item-edit'):
        this.viewComponentManager(BookingFlowContentType.CartItemEdit);
        break;
      case event.url.includes('cart'):
        this.handleCartRoutes();
        break;
      case event.url.includes('customer-info'):
        this.initContentType = BookingFlowContentType.CustomerInfo;
        break;
      case event.url.includes('payment-intent'):
        this.initContentType = BookingFlowContentType.PaymentIntent;
        break;
      case event.url.includes('checkout-success'):
        this.initContentType = BookingFlowContentType.CheckoutSuccess;
        break;
      default:
        console.error("Navigated to a route without it's configuration setup");
        break;
    }
  }

  private handleSearchRoutes(url: string): void {
    this.getUpdatedUrlParams();
    if (url.includes("search/")) {
      this.viewComponentManager(BookingFlowContentType.DateRangeSearch, true);
    }
    else {
      this.viewComponentManager(BookingFlowContentType.DateRangeSearch);
    }
  }

  private handleCatalogRoutes(): void {
    this.getUpdatedUrlParams();
    if (this.currentContentID != null) {
      let foundInvPage = false;
      this.templateObj?.content.forEach((contItem, index) => {
        if (contItem?.contentID === this.currentContentID && contItem.isItem) {
          foundInvPage = true;
          this.viewComponentManager(BookingFlowContentType.Catalog, true);
        }
        if (index === this.templateObj.content.length - 1 && !foundInvPage) {
          this.viewComponentManager(BookingFlowContentType.Catalog);
        }
      });
    }
  }

  private isCartInputsLocked(): boolean {
    if (this.cartObj && this.cartObj.items && this.cartObj.items.length >= 1) {
      return true;
    }
    return false;
  }

  private comparePopstateDifferences(event, assignValues?: boolean): boolean {
    // Compare differences between current and previous URL. If different, re-query availability
    const urlTree: UrlTree = this.router.parseUrl(event.url);
    const queryParams: Params = urlTree.queryParams;
    let isDifferent: boolean = false;

    // Do not allow the ability to have differing dates / locations if an item already exists in the cart
    if (this.isCartInputsLocked()) {
      return isDifferent;
    }

    /* Start Date */
    if (this.selectedStartDate.toISODate() !== queryParams['startDate']) {
      isDifferent = true;
      if (assignValues) {
        this.selectedStartDate = DateTime.fromISO(queryParams['startDate']);
      }
    }
    /* End Date */
    if (this.selectedEndDate.toISODate() !== queryParams['endDate']) {
      isDifferent = true;
      if (assignValues) {
        this.selectedEndDate = DateTime.fromISO(queryParams['endDate']);
      }
    }

    /* Location */
    if (this.selectedLocationID != queryParams['location']) {
      isDifferent = true;
      if (assignValues) {
        this.selectedLocationID = queryParams['location'];
      }
    }

    return isDifferent;
  }

  /* Utility ***********************************************************/
  protected showDatedSearchPage(): void {
    this.navigateSearchView(['search']);
    this.getUpdatedUrlParams(); // Used for hiding breadcrumbs on switching to dated view
    this.viewComponentManager(BookingFlowContentType.DateRangeSearch);
  }

  private debounceTimer(queryParams: { startDate: DateTime, endDate: DateTime, location: string }): void {
    this.availabilityLoadingSpinner = true;
    if (this.timer != null) { // Reset timer to max length if timer is currently still running
      clearInterval(this.timer)
    }
    this.timer = setTimeout(() => {
      this.searchForProductAvailability(queryParams)
    }, 1000 * 3)
  }

  private setSearchInputs(queryParams: { startDate: DateTime, endDate: DateTime, location: string }): void {

    // If a cart lock is currently applied, do not update search state
    if (this.isCartInputsLocked()) {
      return;
    }

    // Get form inputs
    this.selectedStartDate = queryParams.startDate;
    this.selectedEndDate = queryParams.endDate;
    this.selectedLocationID = queryParams.location;
  }

  protected searchForProductAvailability(queryParams: { startDate: DateTime, endDate: DateTime, location: string }): void {
    this.availabilityLoadingSpinner = true;
    this.setSearchInputs(queryParams);

    if (!this.selectedStartDate || !this.selectedEndDate || !this.selectedLocationID) {
      this.availabilityLoadingSpinner = false;
      return
    }

    // Routes have to be specified this way otherwise the inventory page routes will cause back-navigation issues
    if (this.router.url.includes('catalog') || this.router.url.includes('catalog?')) {
      console.log("LOOKING AT CATALOG")
      this.router.navigate([], {
        queryParams: {
          content: this.currentContentID ? this.currentContentID : null, location: queryParams.location, startDate: this.selectedStartDate.toISODate(),
          endDate: this.selectedEndDate.toISODate()
        }, relativeTo: this.activatedRoute
      })
    }

    // If you are looking at the search then update search link
    else if (this.router.url.includes('search') || this.router.url.includes('search?')) {
      this.router.navigate([], { queryParams: { location: queryParams.location, startDate: this.selectedStartDate.toISODate(), endDate: this.selectedEndDate.toISODate() }, relativeTo: this.activatedRoute })
    }
    this.setAvailabilityState();
  }

  protected dateRangeInputChanged($event: DateRangeInputOnChange ): void {
    let inputState = $event.componentInputState;
    this.searchForProductAvailability({startDate: inputState.selectedStartDate, endDate: inputState.selectedEndDate, location: inputState.selectedLocationID});
  }

  private async setAvailabilityState(): Promise<void> {
    this.availabilityLoadingSpinner = true;
    this.cdr.detectChanges(); // Detect that spinner is active before async

    this.availability = await this.availabilityService.runAvailabilityAlgorithm(
      this.selectedStartDate,
      this.selectedEndDate,
      this.selectedLocationID,
      this.templateObj?.companyID,
      this.locationsArray,
      this.productsArray,
      this.productGroupsMap,
      this.inventoryPageMap,
      this.widgetsMap,
      this.productsMap,
      this.cartObj
    );

    console.debug(`BookingFlowWrapperComponent.setAvailabilityState()`, this.availability)
    this.setAvailabilityProperties();

    await this.updateAvailabilityOnView();

    this.availabilityLoadingSpinner = false;
  }

  private setAvailabilityProperties() {
    this.algoRes = this.availability.algoResult;
    this.algoMetadata = this.availability.algoMetadata// passes algoMetaData (such as runtime, to be passed as child component)
    this.cartQuantities = this.availability.cartQuantities;
    this.rentalSearchDaySpan = this.availability.selectedDaySpan;
  }

  private async updateContentCards(): Promise<void> {
    // Reset
    this.dateRangeContentCards = [];

    let contentCards = [];
    let res = this.availability.resultSet;
    // Loops through the resultSet and determines which sizes are available & places them in an array of objects that are easy for the frontend to parse through and translate using ngFor
    Object.keys(res).forEach((PGID, index) => {
      res[PGID]['sizingAvail'] = this.availability.productsOrganizedFunction(PGID, this.productSizeTypesMap); // assign property
      let obj = { ...res[PGID], productGroupID: PGID };
      contentCards.push(obj); // push into arr for template ngFor
    })

    contentCards.sort((a, b) => {
      if (this.productGroupsMap[a['productGroupID']].groupName < this.productGroupsMap[b['productGroupID']].groupName) {
        return -1;
      }
      if (this.productGroupsMap[a['productGroupID']].groupName > this.productGroupsMap[b['productGroupID']].groupName) {
        return 1;
      }
      return 0;
    });

    this.dateRangeContentCards = contentCards;

    return
  }

  private findContentCardAvailability(): void {
    this.templateObj['content'].forEach(card => {
      // Erasing previous unavailable calculations
      // delete card['itemUnavailable'];
      card['itemUnavailable'] = true;

      const PGID = card.productGroupID || card.id
      console.debug(`findContentCardAvailability() for productGroupID=${PGID}`, {card})
      if (PGID) {
        try {
          const prdGrp = this.availability.resultSet[PGID] || null
          const avail = this.availability.checkGroupAvailability(PGID)
          console.debug(`Availability.checkGroupAvailability(${PGID}), content card and group details`, {PGID, avail, prdGrp})

          if (prdGrp) { // For some reason... all cards don't qwerqwer
            card['sizesAvail'] = JSON.parse(JSON.stringify(this.availability.productsOrganizedFunction(PGID, this.productSizeTypesMap)))

            // Splicing needed so that commas can be processed correctly on template (based off whether or not item is last in list)
            for (let i = card['sizesAvail'].length - 1; i >= 0; i--) { // reverse for loop needed to splice without index shifting
              if (card['sizesAvail'][i]['currentAvail'] > 0 || card['sizesAvail'][i]['isAvailable']) {
                delete card['itemUnavailable'];
              }
              else {
                // Splice size off
                card['sizesAvail'].splice(i, 1);
              }
            }
          }
        } catch (err) {
          console.warn(`failure checking content card availabilty for product group (${PGID}),`, err)
        }
      }
    })

    if (this.bookingCatalogViewComponent) { // If the component has already been loaded, rebuild the view to show the updated availability status
      this.bookingCatalogViewComponent.loadCurrentContent(); // Rebuilds catalog view
    }
  }

  async updateAvailabilityFromCart(availability?: AvailabilityInterface) {
    if (availability) { // If availability was processed in the cart
      this.availability = availability;
      this.setAvailabilityProperties();
      await this.updateAvailabilityOnView();
    }
    else {
      await this.setAvailabilityState();
    }
  }

  private async setAccessLevelVariables(): Promise<void> {
    this.user = await this.currentUserService.getCurrentUserOnce(); // Get and store user
    if (this.user) {
      this.isDev = (this.user.isDeveloper ? true : false); // If user exists & isDeveloper == true
      this.isPartner = (this.user.levelType ? true : false); // If user exists & levelType == 'Partner' == true
      this.userCompanyID = (this.user.companyId ? this.user.companyId : undefined) // If user exists, store companyID
      this.userBelongsToCompany = (this.userCompanyID === this.templateObj.companyID ? true : false); // If user's companyID == template's companyID
    }
  }

  protected processNavigationEvent($event: BookingFlowContentType): void {
    if (this.pmtLinkRentalDoc && $event != BookingFlowContentType.Cart) { // Allow a user to naviagte back to the cart but nowhere else if payment link
      this.cartService.hasCustomCartPermission(this.cartObj, true);
      return
    }

    switch ($event) {
      case BookingFlowContentType.DateRangeSearch:
        this.showDatedSearchPage();
        break;
      case BookingFlowContentType.Catalog:
        this.navigateToFrontOfTemplateView();
        break;
      case BookingFlowContentType.Cart:
        this.navigateToCart();
        break;
    }
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
