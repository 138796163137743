<div class="container-fluid">
  <div class="row justify-content-center mt-2">
    <div class="col-md-6 text-left">
      <h1 class="mt-2 mb-2" style="font-size: 32px">
        Shop Information
        <button
          type="button"
          class="btn btn-sm"
          [style.background]="themeColor"
          [style.color]="themeText"
          (click)="openAddModal()"
        >
          + Add Shop
        </button>
      </h1>
    </div>
    <div class="col-md-6 text-end"></div>
  </div>
</div>

<div class="container-fluid mt-3">
  <div class="row justify-content-center align-items-center">
    <div class="col-6"></div>
    <div class="col-6">
      <div class="float-end">
        <input
          class="form-control"
          type="text"
          (keyup)="applyFilter($event)"
          placeholder="Type to search"
          #input
        />
      </div>
    </div>
  </div>
  <hr />
  <div class="row justify-content-center align-items-center">
    <div class="col-12">
      <div *ngIf="dataSource || !isLoading; then completeLoad; else loading"></div>

      <!-- start spinner -->
      <ng-template #loading>
        <div class="position-absolute top-50 start-50">
          <mat-spinner></mat-spinner>
        </div>
      </ng-template>

      <!-- start table -->
      <ng-template #completeLoad>
        <div class="mat-elevation-z8">
          <div style="overflow-x: auto">
            <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="responsive-table">
              <ng-container matColumnDef="Name">
                <th mat-header-cell *matHeaderCellDef>
                  <div class="text-start">Product location name</div>
                </th>
                <td mat-cell class="custom-cell" justify="center" justify="center" *matCellDef="let row">
                  <div class="text-start location-name">
                    <ng-container *ngIf="currentUser?.isDeveloper; else nonDeveloper">
                      <a class="location-name-anchor" href="https://console.firebase.google.com/u/0/project/{{ projectId }}/firestore/data/~2FproductLocations/{{ row.id }}" target="_blank" style="text-decoration: none">
                        {{ row.name }}
                      </a>
                    </ng-container>
                    <ng-template #nonDeveloper>{{ row.name }}</ng-template>
                    <span *ngIf="row.isDefault" class="badge badge-secondary">
                      Default
                    </span>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="expand">
                <th mat-header-cell *matHeaderCellDef aria-label="row actions">
                  <div class="text-end actions">Actions</div>
                </th>
                <td mat-cell class="custom-cell" justify="center" *matCellDef="let row">
                  <div class="text-end">
                    <button
                      class="btn btn-success mr-2"
                      (click)="openEditModal(row); $event.stopPropagation()"
                    >
                      <i class="fa fa-pencil"></i>
                    </button>

                    <button
                      class="btn btn-success btn-warning mr-2"
                      (click)="
                        getProductsLocation(row); $event.stopPropagation()
                      "
                      data-bs-toggle="modal"
                      data-bs-target="#productModal"
                    >
                      <i class="fas fa-bezier-curve"></i>
                    </button>
                    <button
                      mat-icon-button
                      aria-label="expand row"
                      (click)="
                        expandedElement = expandedElement === row ? null : row;
                        $event.stopPropagation()
                      "
                    >
                      <mat-icon *ngIf="expandedElement !== row"
                        >keyboard_arrow_down</mat-icon
                      >
                      <mat-icon *ngIf="expandedElement === row"
                        >keyboard_arrow_up</mat-icon
                      >
                    </button>
                  </div>
                </td>
              </ng-container>
              <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
              <ng-container matColumnDef="expandedDetail">
                <td
                  mat-cell
                  class="custom-cell" justify="center"
                  *matCellDef="let row"
                  [attr.colspan]="columnsToDisplayWithExpand.length"
                >
                  <div
                    class="element-detail containerExpan"
                    [@detailExpand]="
                      row == expandedElement ? 'expanded' : 'collapsed'
                    "
                  >
                    <div class="w-25 p-1">
                      <div class="card">
                        <p class="text-start fw-bold card-header">Location</p>
                        <div class="card-body">
                          <p class="text-start">
                            Adress: {{ row.address.address1 }}
                          </p>
                          <p class="text-start">City: {{ row.address.city }}</p>
                          <p class="text-start">
                            Country: {{ row.address.country }}
                          </p>
                          <p class="text-start">
                            State: {{ row.address.state }}
                          </p>
                          <p class="text-start">Zip: {{ row.address.zip }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="w-25 p-1">
                      <div class="card">
                        <p class="text-start fw-bold card-header">
                          Schedule By Day
                        </p>
                        <div class="card-body">
                          <div *ngFor="let item of row.scheduleWeek">
                            <p class="text-start" *ngIf="item.available">
                              {{ item.day }} {{ formatTime( item.startHour ) }} -
                              {{ formatTime( item.endHour ) }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="w-25 p-1">
                      <div class="card">
                        <p class="text-start fw-bold card-header">
                          Unavailable Days
                        </p>
                        <div class="card-body">
                          <div *ngFor="let item of row.unavailableDays">
                            <p class="text-start" *ngIf="!item.date.seconds">
                              {{ item.name }} {{ item.date | date }}
                            </p>
                            <p class="text-start" *ngIf="item.date.seconds">
                              {{ item.name }}
                              {{ item.date.seconds * 1000 | date }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="w-25 p-1">
                      <div class="card">
                        <p class="text-start fw-bold card-header">
                          Unavailable Hours
                        </p>
                        <div class="card-body">
                          <div *ngFor="let item of row.unavailableHours">
                            <p class="text-start" *ngIf="!item.date.seconds">
                              {{ item.date | date }} {{ formatTime(item.startTime) }} -
                              {{ formatTime(item.endTime) }}
                            </p>
                            <p class="text-start" *ngIf="item.date.seconds">
                              {{ item.date.seconds * 1000 | date }}
                              {{ formatTime(item.startTime) }} -
                              {{ formatTime(item.endTime) }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <ng-container *ngIf="row.isLeadtime">
                      <div class="w-25 p-1">
                        <div class="card">
                          <p class="text-start fw-bold card-header">
                            Leadtime Between Locations
                          </p>
                          <div class="card-body">
                            <div *ngFor="let item of row.leadtime">
                              <p class="text-start">
                                {{ findNameLocation(item.locationB) }} :
                                {{ item.minutes }} m
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container> -->
                  </div>
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="columnsToDisplayWithExpand"
              ></tr>
              <tr
                mat-row
                *matRowDef="
                  let row;
                  columns: columnsToDisplayWithExpand;
                  let i = index
                "
                class="element-row"
                [class.expanded-row]="expandedElement === row"
                (click)="expandedElement = expandedElement === row ? null : row"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: ['expandedDetail']"
                class="detail-row"
              ></tr>

              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No locations found</td>
              </tr>
            </table>
            <mat-paginator
              [pageSize]="100"
              [pageSizeOptions]="[5, 10, 25, 100]"
              aria-label="Select page of users"
            ></mat-paginator>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<!-- start price and discount editing popup -->
<div
  class="modal fade"
  id="addNewProductLocation"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="addNewProductLocation"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="addNewProductLocation">
          <div *ngIf="isEdit; then edit; else add"></div>
          <ng-template #edit> Edit Shop Location </ng-template>
          <ng-template #add> Add new product location </ng-template>
        </h2>
        <button
          type="button"
          class="btn-close"
          (click)="closeModal(); stepper.reset()"
        ></button>
      </div>
      <div class="modal-body">
        <div class="container">
          <ng-container *ngIf="isEdit && location.isDefault === false">
            <div class="container-fluid text-end">
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="checkboxDefault"
                  (click)="setDefaultLocation(location.id, stepper)"
                />
                <label class="form-check-label" for="flexSwitchCheckDefault"
                  >Set as default localization</label
                >
              </div>
            </div>
          </ng-container>

          <!-- <div class="container-fluid text-end">
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="checkboxLeadtime"
                [checked]="isLeadtime"
                (click)="setLeadtime(location.id)"
              />
              <label class="form-check-label" for="flexSwitchCheckDefault">
                Sharing product availability between locations ?</label
              >
            </div>
          </div> -->

          <mat-stepper [linear]="isLinear" [orientation]="stepperOrientation" #stepper>
            <mat-step
              [stepControl]="myForm"
              label="Location"
              state="location_on"
            >
              <fieldset class="border rounded p-2">
                <legend class="float-none w-auto p-2 titlelegend">
                  Define a location
                </legend>
                <form autocomplete="off" [formGroup]="myForm">
                  <div class="row g-3">
                    <div class="col-12">
                      <label for="inputName" class="form-label"
                        >Name <b class="required">*</b></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': fieldInvalid('name') }"
                        name="Name"
                        formControlName="name"
                        required
                      />
                      <!-- [ngClass]="{'is-invalid': f.Name.errors && submitted}" -->
                      <span
                        class="invalid-feedback"
                        *ngIf="fieldInvalid('name')"
                        >Name is Required</span
                      >
                      <!-- *ngIf="f.Name.errors?.required" -->
                    </div>
                    <div class="col-12">
                      <label for="inputAddress" class="form-label"
                        >Address <b class="required">*</b></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': fieldInvalid('address1') }"
                        placeholder="1234 Main St"
                        name="address1"
                        formControlName="address1"
                      />
                      <!-- [ngClass]="{'is-invalid': f.address1.errors && submitted}" -->
                      <span
                        class="invalid-feedback"
                        *ngIf="fieldInvalid('address1')"
                        >Address is Required</span
                      >
                      <!-- *ngIf="f.address1.errors?.required" -->
                    </div>
                    <div class="col-12">
                      <label for="inputAddress2" class="form-label"
                        >Address 2</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Apartment, studio, or floor"
                        name="address2"
                        formControlName="address2"
                      />
                    </div>
                    <div class="col-md-3">
                      <label for="inputCity" class="form-label"
                        >City <b class="required">*</b></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': fieldInvalid('city') }"
                        name="city"
                        formControlName="city"
                      />
                      <!-- [ngClass]="{'is-invalid': f.city.errors && submitted}" -->
                      <span
                        class="invalid-feedback"
                        *ngIf="fieldInvalid('city')"
                        >City is Required</span
                      >
                      <!-- *ngIf="f.city.errors?.required" -->
                    </div>
                    <div class="col-md-3">
                      <label for="inputCountry" class="form-label"
                        >Country <b class="required">*</b></label
                      >
                      <ng-select [items]="countries"
                                bindLabel="name"
                                bindValue="shortName"
                                [searchable]="true"
                                [clearable]="true"
                                formControlName="country"
                                (change)="setStates()"
                                [class]="{'is-invalid': fieldInvalid('country') }"
                                >
                      </ng-select>
                      <span
                        class="invalid-feedback"
                        *ngIf="fieldInvalid('country')"
                        >Country is Required</span
                      >
                      <!-- *ngIf="f.country.errors?.required" -->
                    </div>
                    <div class="col-md-3">
                      <label for="inputState" class="form-label"
                        >State <b class="required">*</b></label
                      >
                      <ng-select [items]="states"
                                bindLabel="state"
                                bindValue="state"
                                [searchable]="true"
                                [clearable]="true"
                                formControlName="state"
                                [class]="{'is-invalid': fieldInvalid('state') }"
                                >
                      </ng-select>
                      <span
                        class="invalid-feedback"
                        *ngIf="fieldInvalid('state')"
                        >State is Required</span
                      >
                      <!-- *ngIf="f.state.errors?.required" -->
                    </div>
                    <div class="col-md-3">
                      <label for="inputZip" class="form-label"
                        >Zip <b class="required">*</b></label
                      >
                      <input
                        type="number"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': fieldInvalid('zip') }"
                        name="zip"
                        formControlName="zip"
                      />
                      <!-- [ngClass]="{'is-invalid': f.zip.errors && submitted}" -->
                      <span class="invalid-feedback" *ngIf="fieldInvalid('zip')"
                        >Zip is Required</span
                      >
                      <!-- *ngIf="f.zip.errors?.required" -->
                    </div>
                  </div>

                  <br />

                  <hr class="hr" />
                  <div class="row">
                    <div class="col-12 col-md order-md-2">
                      <div class="d-flex flex-column flex-md-row justify-content-center justify-content-md-end">
                        <button
                          *ngIf="isEdit"
                          (click)="editProductLocation(stepper)"
                          type="button"
                          class="btn btn-success mx-1 my-1 my-md-0"
                        >
                          Save changes
                        </button>
                        <button
                          class="btn btn-primary mx-1 my-1 my-md-0"
                          (click)="nextTimezone(stepper)"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                    <div *ngIf="isEdit" class="col-12 col-md-auto text-center text-md-start mt-3 mt-md-0 order-md-1">
                      <button
                        (click)="deleteProductLocation(stepper)"
                        type="button"
                        class="btn btn-outline-secondary w-100 w-md-auto"
                      >
                        Delete Shop Location
                      </button>
                    </div>
                  </div>
                </form>
              </fieldset>
            </mat-step>
            <mat-step
              [stepControl]="scheduleForm"
              label="Timezone"
              state="schedule"
            >
              <!---- start section by day -->
              <fieldset class="border rounded p-2">
                <legend class="float-none w-auto p-2 titlelegend">
                  Define a Schedule By Day
                </legend>
                <span class="ms-2 fs-6 fw-bold">Select location timezone</span>
                <form
                  class="row pe-1 mt-1 gy-2 gx-3 mb-2"
                  [formGroup]="scheduleForm"
                >
                  <div class="col-5">
                    <ng-select [items]="abbreviations"
                                bindLabel="abb"
                                bindValue="abb"
                                placeholder="Choose timezone Abbreviation..."
                                [searchable]="true"
                                [clearable]="true"
                                [(ngModel)]="timezoneName"
                                formControlName="timezoneName"
                                [class]="{'is-invalid': fieldInvalid('timezoneName') }"
                                (change)="changetimezone()"
                                >
                      </ng-select>
                    <span class="invalid-feedback"
                      >Timezone Abbreviation is Required</span
                    >
                  </div>
                  <div class="col-7">
                    <ng-select [items]="timezones"
                                bindLabel="timezone"
                                placeholder="Choose timezone..."
                                [disabled]="notimezone"
                                [searchable]="true"
                                [clearable]="true"
                                [(ngModel)]="timezone"
                                formControlName="timezone"
                                [class]="{'is-invalid': fieldInvalid('timezone') }"
                                >
                                <ng-template ng-option-tmp let-item="item">
                                  {{ item.timezone }} ({{ item.hour }})
                                </ng-template>
                      </ng-select>
                    <span class="invalid-feedback">Timezone is Required</span>
                  </div>
                </form>
                <form class="p-1">
                  <div class="row mb-3">
                    <div class="col-sm-4 text-start">
                      <span class="fw-bold">Day of Week</span>
                    </div>
                    <div class="col-sm-4 text-center">
                      <span class="fw-bold">Start Hour</span>
                    </div>
                    <div class="col-sm-4 text-center">
                      <span class="fw-bold">End Hour</span>
                    </div>
                  </div>
                  <div
                    class="row mb-3"
                    *ngFor="let day of scheduleWeek; let i = index"
                  >
                    <div class="col-sm-4 form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        [checked]="day.available"
                        (click)="setValueSchedule(i, 'available', $event)"
                      />
                      <label
                        class="form-check-label"
                        for="flexCheckIndeterminate"
                      >
                        {{ day.day }}
                      </label>
                    </div>
                    <div class="col-sm-4">

                      <div class="input-group">
                        <input
                        step="900"
                        min="00:00"
                        name="startHour"
                        [ngClass]="{
                          'is-invalid': day.lessth || day.equalz || day.negative
                        }"
                        [ngClass]="scheduleWeek[i].available ? 'available' : 'unavailable'"
                        type="text"
                        class="form-control"
                        name="startHour"
                        value="{{ formatTime(day.startHour) }}"
                        (click)="openModalSetTime(i, formatTime(day.startHour), formatTime(day.endHour))"
                        [disabled]="!scheduleWeek[i].available"
                        readonly
                        />
                        <!-- (change)="setValueSchedule(i, 'startHour', $event)" -->
                      </div>


                      <!-- <input
                        step="900"
                        min="00:00"
                        name="startHour"
                        [ngClass]="{
                          'is-invalid': day.lessth || day.equalz || day.negative
                        }"
                        type="time"
                        class="form-control"
                        name="startHour"
                        value="{{ day.startHour }}"
                        (change)="setValueSchedule(i, 'startHour', $event)"
                      /> -->
                    </div>
                    <div class="col-sm-4">

                      <div class="input-group">
                        <input
                        step="900"
                        min="00:00"
                        name="endHour"
                        [ngClass]="{
                          'is-invalid': day.lessth || day.equalz || day.negative
                        }"
                        [ngClass]="scheduleWeek[i].available ? 'available' : 'unavailable'"
                        type="text"
                        class="form-control"
                        name="endHour"
                        value="{{ formatTime(day.endHour) }}"
                        (click)="openModalSetTime(i, formatTime(day.startHour), formatTime(day.endHour))"
                        [disabled]="!scheduleWeek[i].available"
                        readonly
                      />
                      <!-- (change)="setValueSchedule(i, 'endHour', $event)" -->

                      </div>

                      <!-- <input
                        step="900"
                        min="00:00"
                        name="endHour"
                        [ngClass]="{
                          'is-invalid': day.lessth || day.equalz || day.negative
                        }"
                        type="time"
                        class="form-control"
                        name="endHour"
                        value="{{ day.endHour }}"
                        (change)="setValueSchedule(i, 'endHour', $event)"
                      /> -->
                    </div>
                    <div class="col-sm-12">
                      <span class="text-danger" *ngIf="day.lessth"
                        >There must be an interval greater than one hour.</span
                      >
                      <span class="text-danger" *ngIf="day.negative"
                        >The closing date cannot be less than the opening
                        date.</span
                      >
                      <span class="text-danger" *ngIf="day.equalz"
                        >You cannot put the same time in opening and
                        closing.</span
                      >
                    </div>
                  </div>
                  <hr class="hr" />
                  <div class="row">
                    <div class="col-12 col-md order-md-2">
                      <div class="d-flex flex-column flex-md-row justify-content-center justify-content-md-end">
                        <button
                          type="button"
                          class="btn btn-secondary mx-1 my-1 my-md-0"
                          matStepperPrevious
                        >
                          Back
                        </button>
                        <button
                          *ngIf="isEdit"
                          (click)="editProductLocation(stepper)"
                          type="button"
                          class="btn btn-success mx-1 my-1 my-md-0"
                        >
                          Save changes
                        </button>
                        <button
                          class="btn btn-primary mx-1 my-1 my-md-0"
                          (click)="nextUnavailableDays(stepper)"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                    <div *ngIf="isEdit" class="col-12 col-md-auto text-center text-md-start mt-3 mt-md-0 order-md-1">
                      <button
                        (click)="deleteProductLocation(stepper)"
                        type="button"
                        class="btn btn-outline-secondary w-100 w-md-auto"
                      >
                        Delete Shop Location
                      </button>
                    </div>
                  </div>
                </form>
              </fieldset>
              <!---ends section by day-->
            </mat-step>
            <mat-step
              [stepControl]="scheduleForm"
              label="Unavailable days"
              state="unavailable"
            >
              <!---starts section unavailable  days -->
              <fieldset class="border rounded p-2">
                <legend class="float-none w-auto p-2 titlelegend d-flex flex-column flex-md-row align-items-start align-items-md-center flex-wrap">
                  <span>Unavailable Days</span>
                  <button
                    type="button"
                    class="btn btn-success btn-add btn-sm mt-2 mt-md-0 ms-md-2"
                    data-bs-toggle="modal"
                    data-bs-target="#UnavailableDays"
                    (click)="editDay()"
                  >
                    Add Unavailable Day
                  </button>
                </legend>
                <table class="table-hover bg-light">
                  <thead>
                    <tr>
                      <th>
                        <h3><strong>Day</strong></h3>
                      </th>
                      <th>
                        <h3><strong>Date</strong></h3>
                      </th>
                      <th>
                        <h3><strong>Repeats</strong></h3>
                      </th>
                      <th>
                        <h3><strong>Actions</strong></h3>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let day of unavailableDays; let i = index">
                      <td name="Day">
                        <h3>{{ day.name }}</h3>
                      </td>
                      <td name="Date">
                        <h3 *ngIf="!day.date.seconds">{{ day.date | date }}</h3>
                        <h3 *ngIf="day.date.seconds">
                          {{ day.date.seconds * 1000 | date }}
                        </h3>
                      </td>
                      <td name="Repeats">
                        <h3>{{ day.periodicity }}</h3>
                      </td>
                      <td name="Actions">
                        <div class="row justify-content-center justify-content-md-start">
                          <div class="col-auto">
                            <button
                              type="button"
                              class="btn btn-primary btn-sm"
                              data-bs-toggle="modal"
                              data-bs-target="#UnavailableDays"
                              (click)="editDay(i, day, true)"
                            >
                              <mat-icon>edit</mat-icon>
                            </button>
                          </div>
                          <div class="col-auto">
                            <button
                              type="button"
                              class="btn btn-danger btn-sm"
                              (click)="deleteDay(i)"
                            >
                              <mat-icon>delete</mat-icon>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <hr class="hr" />
                <div class="row">
                  <div class="col-12 col-md order-md-2">
                    <div class="d-flex flex-column flex-md-row justify-content-center justify-content-md-end">
                      <button
                        type="button"
                        class="btn btn-secondary mx-1 my-1 my-md-0"
                        matStepperPrevious
                      >
                        Back
                      </button>
                      <button
                        *ngIf="isEdit"
                        (click)="editProductLocation(stepper)"
                        type="button"
                        class="btn btn-success mx-1 my-1 my-md-0"
                      >
                        Save changes
                      </button>
                      <button
                        class="btn btn-primary mx-1 my-1 my-md-0"
                        (click)="nextUnavailableHours(stepper)"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                  <div *ngIf="isEdit" class="col-12 col-md-auto text-center text-md-start mt-3 mt-md-0 order-md-1">
                    <button
                      (click)="deleteProductLocation(stepper)"
                      type="button"
                      class="btn btn-outline-secondary w-100 w-md-auto"
                    >
                      Delete Shop Location
                    </button>
                  </div>
                </div>
              </fieldset>
              <!--ends section unavailable days---->
            </mat-step>
            <mat-step
              [stepControl]="scheduleForm"
              label="Unavailable hours"
              state="unavailable-hours"
            >
              <!---starts section unavailable  hours -->
              <fieldset class="border rounded p-2">
                <legend class="float-none w-auto p-2 titlelegend d-flex flex-column flex-md-row align-items-start align-items-md-center flex-wrap">
                  Unavailable Hours
                  <button
                    type="button"
                    class="btn btn-success btn-add btn-sm mt-2 mt-md-0 ms-md-2"
                    data-bs-toggle="modal"
                    data-bs-target="#UnavailableHours"
                    (click)="addDayHours()"
                  >
                    Add Unavailable Hour
                  </button>
                </legend>
                <table class="table-hover bg-ligh">
                  <thead>
                    <tr>
                      <th>
                        <h3><strong>Day</strong></h3>
                      </th>
                      <th>
                        <h3><strong>Hours</strong></h3>
                      </th>
                      <th>
                        <h3><strong>Actions</strong></h3>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let hour of unavailableHours; let i = index"
                    >
                      <td name="Day">
                        <h3 *ngIf="!hour.date.seconds">
                          {{ hour.date | date }}
                        </h3>
                        <h3 *ngIf="hour.date.seconds">
                          {{ hour.date.seconds * 1000 | date }}
                        </h3>
                      </td>
                      <td name="Hours">
                        <h3>
                          {{ formatTime(hour.startTime) }} -
                          {{ formatTime(hour.endTime) }}
                        </h3>
                      </td>
                      <td name="Actions">
                        <div class="row justify-content-center justify-content-md-start">
                          <div class="col-auto">
                            <button
                              type="button"
                              class="btn btn-primary btn-sm"
                              data-bs-toggle="modal"
                              data-bs-target="#UnavailableHours"
                              (click)="editHour(i, unavailableHoursLS[i])"
                            >
                              <mat-icon>edit</mat-icon>
                            </button>
                          </div>
                          <div class="col-auto">
                            <button
                              type="button"
                              class="btn btn-danger btn-sm"
                              (click)="deleteHour(i)"
                            >
                              <mat-icon>delete</mat-icon>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <hr class="hr" />
                <div class="row">
                  <div class="col-12 col-md order-md-2">
                    <div class="d-flex flex-column flex-md-row justify-content-center justify-content-md-end">
                      <button
                        type="button"
                        class="btn btn-secondary mx-1 my-1 my-md-0"
                        matStepperPrevious
                      >
                        Back
                      </button>
                      <button
                        *ngIf="isEdit == false"
                        (click)="saveProductLocation(stepper)"
                        type="button"
                        class="btn btn-success mx-1 my-1 my-md-0"
                        [disabled]="isDisabled"
                      >
                        Submit
                      </button>
                      <button
                        *ngIf="isEdit"
                        (click)="editProductLocation(stepper)"
                        type="button"
                        class="btn btn-success mx-1 my-1 my-md-0"
                      >
                        Save changes
                      </button>
                      <!-- <button
                        *ngIf="isLeadtime == true"
                        class="btn btn-primary mx-1 my-1 my-md-0"
                        (click)="nextUnavailableHours(stepper)"
                      >
                        Next
                      </button> -->
                    </div>
                  </div>
                  <div *ngIf="isEdit" class="col-12 col-md-auto text-center text-md-start mt-3 mt-md-0 order-md-1">
                    <button
                      (click)="deleteProductLocation(stepper)"
                      type="button"
                      class="btn btn-outline-secondary w-100 w-md-auto"
                    >
                      Delete Shop Location
                    </button>
                  </div>
                </div>
              </fieldset>
              <!--ends section unavailable hours---->
            </mat-step>
            <!-- starts section Leadtime
            <mat-step
              [stepControl]="leadtimeForm"
              label="Leadtime Between Locations"
              state="leadtime"
              *ngIf="isLeadtime"
            >
              <fieldset class="border rounded p-2">
                <legend class="float-none w-auto p-2 titlelegend">
                  Define a Leadtime
                </legend>
                <form autocomplete="off" [formGroup]="leadtimeForm">
                  <table class="table-hover bg-ligh">
                    <thead class="text-center">
                      <tr>
                        <th>
                          <h3>
                            <strong>Locations </strong>
                          </h3>
                        </th>
                        <th>
                          <h3>
                            <strong>Leadtime <b class="required">*</b> </strong>
                          </h3>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container formArrayName="leadtimes">
                        <tr
                          *ngFor="
                            let l of leadtimesControl.controls;
                            let i = index
                          "
                          [formGroupName]="i"
                          class="text-center"
                        >
                          <td name="Locations">
                            <div class="row">
                              <div class="col-4">
                                <h3 *ngIf="location.name">
                                  {{ location.name }} A
                                </h3>
                                <h3 *ngIf="!location.name">
                                  {{ myForm.get("name").value }}
                                </h3>
                              </div>
                              <div class="col-4">
                                <mat-icon>east</mat-icon>
                              </div>
                              <div class="col-4">
                                {{ locationsLead[i].name }} B
                              </div>
                            </div>
                          </td>
                          <td name="Leadtime *">
                            <div class="row justify-content-center">
                              <div class="col col-md-5">
                                <div class="input-group mb-3">
                                  <input
                                    type="number"
                                    class="form-control"
                                    [ngClass]="{
                                      'is-invalid': invalidFieldLeadtime(i)
                                    }"
                                    placeholder="0 min."
                                    formControlName="minutes"
                                    name="minutes"
                                  />
                                  <span class="input-group-text" id="basic-addon2">
                                    minutes &nbsp;
                                    <mat-icon>departure_board</mat-icon>
                                  </span>
                                  <div class="invalid-feedback">
                                    {{ invalidTextLeadtime(i) }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="locationA"
                              name="locationA"
                              hidden
                            />
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              formControlName="locationB"
                              name="locationB"
                              hidden
                            />
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                  <ng-container *ngIf="leadtimesControl.controls.length === 0">
                    <p class="text-center fs-6">
                      There are no more locations configured to assign
                      leadtimes.
                    </p>
                  </ng-container>
                </form>
                <hr class="hr" />
                <div class="row">
                  <div class="col-12 col-md order-md-2">
                    <div class="d-flex flex-column flex-md-row justify-content-center justify-content-md-end">
                      <button
                        type="button"
                        class="btn btn-secondary mx-1 my-1 my-md-0"
                        matStepperPrevious
                      >
                        Back
                      </button>
                      <button
                        *ngIf="isEdit == false && isLeadtime == true"
                        (click)="saveProductLocation(stepper)"
                        type="button"
                        class="btn btn-success mx-1 my-1 my-md-0"
                        [disabled]="isDisabled"
                      >
                        Submit
                      </button>
                      <button
                        *ngIf="isEdit == true"
                        (click)="editProductLocation(stepper)"
                        type="button"
                        class="btn btn-success mx-1 my-1 my-md-0"
                      >
                        Save changes
                      </button>
                    </div>
                  </div>
                  <div *ngIf="isEdit" class="col-12 col-md-auto text-center text-md-start mt-3 mt-md-0 order-md-1">
                    <button
                      (click)="deleteProductLocation(stepper)"
                      type="button"
                      class="btn btn-outline-secondary w-100 w-md-auto"
                    >
                      Delete Shop Location
                    </button>
                  </div>
                </div>
              </fieldset>
            </mat-step>
            ends section Leadtime -->
            <!-- Icon overrides. -->
            <ng-template matStepperIcon="location_on">
              <mat-icon>location_on</mat-icon>
            </ng-template>
            <ng-template matStepperIcon="schedule">
              <mat-icon>schedule</mat-icon>
            </ng-template>
            <ng-template matStepperIcon="unavailable">
              <mat-icon>event_busy</mat-icon>
            </ng-template>
            <ng-template matStepperIcon="unavailable-hours">
              <mat-icon>av_timer</mat-icon>
            </ng-template>
            <!-- <ng-template matStepperIcon="leadtime">
              <mat-icon>departure_board</mat-icon>
            </ng-template> -->
          </mat-stepper>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal unavailable days -->
<div
  class="modal fade"
  id="UnavailableDays"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="UnavailableDays"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="modalTitleId">unavailable days</h2>
        <button
          type="button"
          class="btn-close"
          (click)="closeModalUnavailableDays()"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <!-- start form add unavailable days -->
          <br />
          <form novalidate [formGroup]="unavailableDayForm">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="nameDay">Name <small>(optional)</small></label>
                  <input
                    type="text"
                    formControlName="name"
                    class="form-control form-control-sm"
                    id="nameDay"
                    placeholder="Name"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="date">Date</label>
                  <input
                    id="date"
                    type="text"
                    formControlName="date"
                    class="form-control form-control-sm"
                    opens="center"
                    ngxDaterangepickerMd
                    [(ngModel)]="selected"
                    [closeOnAutoApply]="true"
                    [autoApply]="true"
                    [singleDatePicker]="true"
                    [locale]="locale"
                    [value]="date"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="periodicity">Repeats</label>
                  <ng-select [items]="repeats"
                                bindLabel="value"
                                bindValue="value"
                                [searchable]="true"
                                [clearable]="true"
                                formControlName="periodicity"
                                [class]="{'is-invalid': fieldInvalid('periodicity') }"
                                >
                      </ng-select>
                  <span class="invalid-feedback"
                    >Please select a periodicity</span
                  >
                </div>
              </div>
            </div>
          </form>
          <br />
          <!-- end form add unavailable days -->
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeModalUnavailableDays()"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="addUnavailableDay()"
        >
          {{ titleAddEdit }}
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Modal unavailable hours -->
<div
  class="modal fade"
  id="UnavailableHours"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="UnavailableHours"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="modalTitleId">Unavailable hours</h2>
        <button
          type="button"
          class="btn-close"
          (click)="closeModalUnavailableHours()"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body" *ngIf="isAddingUHours">
        <div class="container-fluid">
          <!-- start form add unavailable days -->
          <br />
          <form
            [formGroup]="unavailableHourForm"
            (ngSubmit)="submitHoursForm()"
            class="needs-validation"
            novalidate
          >
            <div class="form-group">
              <label for="date">Date:</label>
              <input
                type="text"
                class="form-control"
                [ngClass]="{
                  'is-invalid':
                   unavailableHourForm.controls.date.invalid && unavailableHourForm.controls.date.touched
                }"
                formControlName="date"
                placeholder="Choose date"
                ngxDaterangepickerMd
                (change)="setHours($event)"
                [isInvalidDate]="isInvalidDate"
                [isCustomDate]="isCustomDate"
                [locale]="locale"
                [singleDatePicker]="true"
                [autoApply]="true"
                [closeOnAutoApply]="true"
              />
              <div
                *ngIf="
                  unavailableHourForm.controls.date.invalid && unavailableHourForm.controls.date.touched
                "
                class="invalid-feedback"
              >
                Date is required.
              </div>
            </div>

            <div>
              <div class="row justify-content-center align-items-center g-2 py-3">
                  <div class="form-group">
                    <label for="startTime">Start Time:</label>
                    <ng-select [items]="hoursMinutes"
                              bindLabel="display"
                              bindValue="display"
                              [searchable]="true"
                              [clearable]="true"
                              [ngClass]="{
                                'is-invalid':
                                 unavailableHourForm.controls.startTime.invalid &&
                                 unavailableHourForm.controls.startTime.touched
                              }"
                              formControlName="startTime"
                              id="startTime"
                              >
                      <ng-template ng-option-tmp let-item="item">
                          {{ item.display }}
                      </ng-template>
                    </ng-select>
                    <div
                      *ngIf="
                        unavailableHourForm.controls.startTime.invalid &&
                        unavailableHourForm.controls.startTime.touched
                      "
                      class="invalid-feedback"
                    >
                      Start time is required.
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="endTime">End Time:</label>
                    <ng-select [items]="hoursMinutes"
                              bindLabel="display"
                              bindValue="display"
                              [searchable]="true"
                              [clearable]="true"
                              [ngClass]="{
                                'is-invalid':
                                 unavailableHourForm.controls.endTime.invalid &&
                                 unavailableHourForm.controls.endTime.touched
                              }"
                              formControlName="endTime"
                              id="endTime"
                              >
                      <ng-template ng-option-tmp let-item="item">
                          {{ item.display }}
                      </ng-template>
                    </ng-select>
                    <div
                    *ngIf="
                      unavailableHourForm.controls.endTime.errors &&
                      unavailableHourForm.controls.endTime.touched
                    "
                    class="invalid-feedback"
                    >
                      {{ getEndTimeErrorMessage() }}
                    </div>
                  </div>

              </div>

              <!-- <div class="form-group">
                <label for="startTime">Start Time:</label>
                <input
                  type="time"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      isHourSubmitted &&
                      unavailableHourForm.controls.startTime.invalid
                  }"
                  id="startTime"
                  (change)="checkHours(true, $event)"
                  formControlName="startTime"
                  required
                />
                <div
                  *ngIf="
                    isHourSubmitted &&
                    unavailableHourForm.controls.startTime.invalid
                  "
                  class="invalid-feedback"
                >
                  Start time is required.
                </div>
              </div>
              <div class="form-group">
                <label for="endTime">End Time:</label>
                <input
                  type="time"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      isHourSubmitted &&
                      unavailableHourForm.controls.endTime.invalid
                  }"
                  id="endTime"
                  (change)="checkHours(false, $event)"
                  formControlName="endTime"
                  required
                />
                <div
                  *ngIf="
                    isHourSubmitted &&
                    unavailableHourForm.controls.endTime.invalid
                  "
                  class="invalid-feedback"
                >
                  <span
                    *ngIf="unavailableHourForm.controls.endTime.errors.required"
                  >
                    End time is required
                  </span>
                  <span
                    *ngIf="
                      unavailableHourForm.controls.endTime.errors.endTimeInvalid
                    "
                  >
                    End time must be greater than the start time.
                  </span>
                </div>
              </div> -->
            </div>
          </form>
          <br />
          <!-- end form add unavailable days -->
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="closeModalUnavailableHours()"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="submitHoursForm()"
        >
          {{ titleAddEdit }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal link location to any/all products -->
<div
  class="modal fade"
  id="productModal"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="productModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title fs-5" id="productModalLabel">
          {{ location?.name ? location.name : "" }}
        </h5>
        <button
          type="button"
          class="btn-close"
          (click)="closeModalProduct()"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="container-fluid mb-3">
          <div class="mb-3">
            <div class="row">
              <div class="col-6 text-left">
                <label for="exampleFormControlInput1" class="form-label"
                  >Search product:</label
                >
              </div>
              <div class="col-6 text-right">
                <span class="badge bad bg-primary">
                  Total Products with this Location:
                </span>

                <span class="badge bad-2 bg-primary">
                  {{ totalcountproducts }}
                </span>

                <!-- <span *ngIf="ProductCountShow" class="badge bad-2 bg-primary">
                  {{ productCount }}
                </span> -->
              </div>
            </div>

            <input
              type="text"
              name="searchProductInput"
              [(ngModel)]="searchProductInput"
              class="form-control"
              placeholder="Search..."
              (keyup)="searchProduct()"
            />
            <div class="form-text">Select a product to assign the Location</div>
          </div>
        </div>

        <div class="container-fluid text-end">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              id="checkboxAll"
              (click)="selectAllProductLocation(location)"
            />
            <label class="form-check-label" for="flexSwitchCheckDefault"
              >Select all the products</label
            >
          </div>
        </div>

        <div class="container-fluid tableheight">
          <ng-container *ngIf="productsAll.length === 0">
            <div class="text-center mt-3">
              <h3>No products found. Products will be displayed once setup</h3>
            </div>
          </ng-container>
          <table
            id="productsTable"
            class="table text-center"
            *ngIf="productsAll.length > 0"
          >
            <thead class="bgtable">
              <tr id="tableHeader">
                <th scope="col">Product Number</th>
                <th scope="col">Product Name</th>
                <th scope="col">Category</th>
                <th scope="col">Location</th>
                <th scope="col">Select</th>
              </tr>
            </thead>
            <tbody class="text-left">
              <ng-container *ngFor="let product of productsAll; let i = index">
                <tr>
                  <td>#{{ product.productNumber }}</td>
                  <td>{{ product.productName }}</td>
                  <td>{{ product.productCategory }}</td>
                  <td>
                    {{
                      product.productLocationID
                        ? product.productLocationName
                        : "Unassigned"
                    }}
                  </td>
                  <td class="text-center">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        [disabled]="
                          location.isDefault &&
                          product.productLocationID == location.id
                        "
                        [checked]="
                          product.productLocationID == location.id
                            ? true
                            : false
                        "
                        id="flexCheckDefault"
                        (click)="selectProductLocation(product, i, location)"
                      />
                    </div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          (click)="closeModalProduct()"
          class="btn btn-secondary"
          data-bs-dismiss="modal"
        >
          Close
        </button>
        <button
          type="button"
          (click)="saveProducstLocation(location.id)"
          class="btn btn-primary"
        >
          Save Changes
        </button>
      </div>
    </div>
  </div>
</div>

<!-- * Start New modal Define a Schedule By Day -->

<div class="modal fade"
     id="modalSetTime"
     tabindex="-1"
     data-bs-backdrop="static"
     data-bs-keyboard="false"
     aria-labelledby="modalSetTimeLabel"
     aria-hidden="true">

  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="modalSetTimeLabel">Define a schedule</h1>
        <button type="button" class="btn-close" (click)="emptyingArrangement()" aria-label="Close"></button>
      </div>
      <div class="modal-body">


        <div class="container mt-1">
          <div class="row justify-content-center align-items-center g-2 py-3">
            <div class="col">
              <label for="schedule-open-time">Opening Time</label>
              <ng-select id="schedule-open-time" [items]="hoursMinutes"
                         bindLabel="display"
                         bindValue="display"
                         [(ngModel)]="timeSelected1"
                         [searchable]="true"
                         [clearable]="true"
                         >
                <ng-template ng-optgroup-tmp let-item="item">
                  <strong>{{ item }}</strong>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                    {{ item.display }}
                </ng-template>
              </ng-select>
            </div>
            <div class="col">
              <label for="schedule-close-time">Closing Time</label>
              <ng-select id="schedule-close-time" [items]="hoursMinutes"
                         bindLabel="display"
                         bindValue="display"
                         [(ngModel)]="timeSelected2"
                         [searchable]="true"
                         [clearable]="true"
                         >
                <ng-template ng-optgroup-tmp let-item="item">
                  <strong>{{ item }}</strong>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                    {{ item.display }}
                </ng-template>
              </ng-select>
            </div>
          </div>

          <div class="row justify-content-center align-items-center g-2">
            <div class="col">
              <label for="diasSemana">Apply to Days:</label>
                <div class="weekday">
                  <div *ngFor="let dia of daysWeek" class="checkbox">
                    <input type="checkbox" [id]="dia" [(ngModel)]="daySelected[dia]">
                    <label [for]="dia">{{ dia }}</label>
                  </div>
                </div>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="emptyingArrangement()">Close</button>
        <button type="button" class="btn btn-primary" (click)="getPositionsSelected()">Save changes</button>
      </div>
    </div>
  </div>

</div>
