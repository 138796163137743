<!--Title Dasboard-->
<div class="container-fluid">
  <div class="row justify-content-center mt-2">
    <div class="col-md-12 text-left">
      <h1 class="mt-2 mb-2" style="font-size: 32px">Dashboard</h1>
    </div>
  </div>
</div>
<!--Chart-->
<div class="container-fluid">
  <div class="flex">
    <div style="display: block">
      <canvas
        baseChart
        width="400"
        height="70"
        [data]="lineChartData"
        [options]="lineChartOptions"
        [type]="lineChartType"
        (chartHover)="chartHovered($event)"
        (chartClick)="chartClicked($event)"
      ></canvas>
    </div>
  </div>
</div>
<!--Cards-->
<div class="container-fluid mt-3 ps-5">
  <div class="row">
    <div class="col-xl-4 col-lg-6">
      <div
        class="card"
        style="
          background: linear-gradient(
            135deg,
            #23bdb8 0%,
            #af43e7 100%
          ) !important;
          color: white;
        "
      >
        <div class="card-statistic-3 p-4">
          <div class="card-icon card-icon-large">
            <i class="fas fa-shopping-cart"></i>
          </div>
          <div class="mb-4">
            <h3 class="card-title mb-0">
              <b>All Rentals in {{ month }}</b>
            </h3>
          </div>
          <div class="row align-items-center mb-2 d-flex">
            <div class="col-8">
              <h2 class="d-flex align-items-center mb-0">
                {{ rentalstotal }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-6">
      <div
        class="card"
        style="
          background: linear-gradient(to right, #59b948, #42baf4) !important;
          color: #fff;
        "
      >
        <div class="card-statistic-3 p-4">
          <div class="card-icon card-icon-large">
            <i class="fas fa-list"></i>
          </div>
          <div class="mb-4">
            <h3 class="card-title mb-0">
              <b>Total Pending Check Out Today</b>
            </h3>
          </div>
          <div class="row align-items-center mb-2 d-flex">
            <div class="col-8">
              <h2 class="d-flex align-items-center mb-0">
                {{ rentalsactive }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-6">
      <div class="card l-bg-orange">
        <div class="card-statistic-3 p-4">
          <div class="card-icon card-icon-large">
            <i class="fas fa-hammer"></i>
          </div>
          <div class="mb-4">
            <h3 class="card-title mb-0"><b>Total of repairs</b></h3>
          </div>
          <div class="row align-items-center mb-2 d-flex">
            <div class="col-8">
              <h2 class="d-flex align-items-center mb-0">
                {{ repairs }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid ps-5">
  <div class="row">
    <div class="col-md-6">
      <div class="accordion" id="accordionExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Pending Check Out Today
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <div class="table-wrapper-scroll-y my-custom-scrollbar cont">
                <table
                  class="table table-hover table-striped mb-0"
                  *ngIf="rentals.length > 0; else info2"
                >
                  <thead
                    [style.background]="colorsBackground"
                    [style.color]="colorsText"
                  >
                    <tr>
                      <th scope="col">Folio</th>
                      <th scope="col">Rental Date</th>
                      <th scope="col">Cost</th>
                      <th scope="col">Contact Phone</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let rental of rentals">
                      <td *ngIf="rental.rentalNumber == null">-</td>
                      <td *ngIf="rental.rentalNumber > 0">
                        {{ rental.rentalNumber }}
                      </td>
                      <td>{{ rental.dayStart }} {{ rental.dayEnd }}</td>
                      <td>$ {{ rental.cost }} USD</td>
                      <td>{{ rental.phone }}</td>
                      <ng-container
                        *ngIf="rental.isCancelled == true; else checkedout"
                      >
                        <td>
                          <div class="badge badge-danger">Cancelled</div>
                        </td>
                      </ng-container>
                      <ng-template #checkedout>
                        <ng-container *ngIf="rental.isCheckedOut == false">
                          <td>
                            <div class="badge badge-info">To Check Out</div>
                          </td>
                        </ng-container>
                      </ng-template>
                    </tr>
                    <tr style="display: none">
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>
                        <div class="badge badge-success">Active</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <ng-template #info2>
                  <tr colspan="5">
                    There is nothing to show today
                  </tr>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="accordion" id="tablesTwo">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              Pending Check In Today
            </button>
          </h2>
          <div
            id="collapseTwo"
            class="accordion-collapse collapse show"
            aria-labelledby="headingTwo"
            data-bs-parent="#tablesTwo"
          >
            <div class="accordion-body">
              <div class="col table-wrapper-scroll-y my-custom-scrollbar cont">
                <table
                  class="table table-hover table-striped mb-0"
                  *ngIf="rentalsUpcoming.length > 0; else info"
                >
                  <thead
                    [style.background]="colorsBackground"
                    [style.color]="colorsText"
                  >
                    <tr>
                      <th scope="col">Folio</th>
                      <th scope="col">Rental Date</th>
                      <th scope="col">Cost</th>
                      <th scope="col">Contact Phone</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let rentalsUp of rentalsUpcoming">
                      <td *ngIf="rentalsUp.rentalNumber == null">-</td>
                      <td>{{ rentalsUp.rentalNumber }}</td>
                      <td>{{ rentalsUp.dayStart }} {{ rentalsUp.dayEnd }}</td>
                      <td>$ {{ rentalsUp.cost }} USD</td>
                      <td>{{ rentalsUp.phone }}</td>
                      <ng-container *ngIf="rentalsUp.isCheckedIn == false">
                        <td>
                          <div class="badge badge-warning">
                            Pending Check In
                          </div>
                        </td>
                      </ng-container>
                    </tr>
                    <tr style="display: none">
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>
                        <div class="badge badge-success">Active</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <ng-template #info>
                  <tr colspan="5">
                    There is nothing to show today
                  </tr>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTree">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTree"
              aria-expanded="false"
              aria-controls="collapseTree"
            >
              Products in Maintenance
            </button>
          </h2>
          <div
            id="collapseTree"
            class="accordion-collapse collapse"
            aria-labelledby="headingTree"
            data-bs-parent="#tablesTwo"
          >
            <div class="accordion-body">
              <div class="col">
                <div
                  class="col table-wrapper-scroll-y"
                  style="height: 320px; overflow-y: scroll"
                >
                  <table
                    class="table table-hover table-striped mb-0"
                    *ngIf="products.length > 0"
                  >
                    <thead
                      [style.background]="colorsBackground"
                      [style.color]="colorsText"
                    >
                      <tr>
                        <th scope="col">Folio</th>
                        <th scope="col">Product Name</th>
                        <th scope="col">Category</th>
                        <th scope="col">Price</th>
                        <th scope="col" colspan="3">Maintenance</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let product of products">
                        <td>{{ product.productNumber }}</td>
                        <td>{{ product.productName }}</td>
                        <td>{{ product.productCategory }}</td>
                        <td>$ {{ product.price }} USD</td>
                        <td>
                          <div
                            *ngIf="product.isCleaning"
                            class="badge badge-danger"
                          >
                            Cleaning
                          </div>
                        </td>
                        <td>
                          <div
                            *ngIf="product.isDamaged"
                            class="badge badge-danger"
                          >
                            Damaged
                          </div>
                        </td>
                        <td>
                          <div
                            *ngIf="product.isTuneUp"
                            class="badge badge-danger"
                          >
                            Tune Up
                          </div>
                        </td>
                        <td *ngIf="product.isActive == true">
                          <div class="badge badge-success">Active</div>
                        </td>
                        <td *ngIf="product.isRetired == true">
                          <div class="badge badge-danger">Retired</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
