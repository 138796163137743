import { Component, Input, Output, EventEmitter, ViewChild, SimpleChanges, AfterContentInit, OnChanges, ChangeDetectorRef } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';

// Libraries
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateTime } from 'luxon';
import { FleetmaidDateAdapter } from '../../../v2/classes/fleetmaid-date-adapter';
import { MAT_LUXON_DATE_FORMATS, MAT_LUXON_DATE_ADAPTER_OPTIONS } from "@angular/material-luxon-adapter"
import { MatDatepicker } from '@angular/material/datepicker';

/* Models & Types*/
import { Cart } from 'src/app/models/storage/cart.model';
import { DateRangeConfig, DateRangeInputOnChange, DateRangeInputType } from 'src/app/v2/models/date-range.model';
import { ErrorCodes, ErrorHandlingObject } from 'src/app/v2/models/errors.model';
import { ProductLocation } from 'src/app/models/storage/product-location.model';
import { TimeslotGroupedByType, TimeslotOption } from 'src/app/v2/models/booking-flow.model';

/* Services */
import { AvailabilityOverrides } from 'src/app/models/availability-overrides.model';
import { DateRangeService } from 'src/app/v2/services/date-range.service';
import { ErrorService } from 'src/app/v2/services/errors.service';
import { TimeService } from 'src/app/services/time.service';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: FleetmaidDateAdapter, deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS },
  ]
})

export class DateRangeComponent implements AfterContentInit, OnChanges {
  /* Enum */
  protected dateRangeInputType = DateRangeInputType;

  private locationObj: ProductLocation // An object containing the selected location's data

  /* Form Controls */
  @ViewChild('dateRangeForm') private dateRangeForm!: NgForm;
  @ViewChild('endDateControl') private endDateControl!: NgModel;
  @ViewChild('locationControl') private locationControl!: NgModel;
  @ViewChild('startDateControl') private startDateControl!: NgModel;
  @ViewChild('timeslotControl') private timeslotControl!: NgModel;

  /* DayPicker */
  @ViewChild('rangePicker') private dPicker: MatDatepicker<DateTime>; // Reference to the Angular Material Datepicker

  /* Input variables */
  @Input() public selectedStartDate: DateTime = null; // selected start date
  @Input() public selectedEndDate: DateTime = null; // selected end date
  @Input() public selectedLocationID: string = null // ID of the selected location
  @Input() public selectedTimeslot: TimeslotOption = null;
  @Input() public isDev: boolean = false; // Currently used to display the availability algorthim run time to (only) developers
  @Input() public algoMetadata = null;
  @Input() public cartObj: Cart = null; //used for locking to specific location / date
  @Input() public locationMap: { [key: string]: ProductLocation };
  @Input() public locations: Array<ProductLocation>
  @Input() public isMobile: boolean // A variable that can be passed in to change whether or not mobile datepicker view is shown
  @Input() public isQuickBooking: boolean;
  @Input() public rentalEndDate = null;
  @Input() public rentalStartDate = null;
  @Input() public isMiniCartEdit: boolean;
  @Input() public availabilityOverrideConfig: AvailabilityOverrides; // Used for re-triggering form validation on override config change
  @Input() public isAvailabilityOverride: boolean = false;
  @Input() public showingViaBookingFlow: boolean = false; // Shows validation on init, updates err msgs
  @Input() public timeslotsGroupedByType: TimeslotGroupedByType[] = null;
  @Input() public dateRangeConfig: DateRangeConfig = { // Default
    showAdditionalInputs: {
      showTimeslots: false,
    },
    filterByCurrentDay: false,
    showAvailabilityOverrideToggle: false,
    showFormLabels: false,
    runErrorValidationOnInit: false
  };

  /* Events */
  @Output() private dateRangeInputChanged = new EventEmitter<DateRangeInputOnChange>();
  @Output() protected toggleAvailabilityOverride = new EventEmitter<boolean>();

  protected cartLockIsApplied: boolean = false;
  protected _errorHandlingObject: ErrorHandlingObject = null;

  /* Locations timezone data */
  protected timezonesCurrentDate: DateTime = null; // The current date based off the locations timezone upon load
  private selectedTimezone: string // Name of the locations timezone Ex: "America/Boise"

  /* Needed for locations dateFilter (DOTW, Unavailable Days, etc) */
  private dayOfTheWeekFormatedFromDbToLuxon: Set<number> = new Set();
  private unavailableDaysSingle: Set<string> = new Set();
  private unavailableDaysAnnually: Set<string> = new Set();

  constructor(private timeService: TimeService,
    private dateRangeService: DateRangeService,
    private errorService: ErrorService,
    private cdr: ChangeDetectorRef,
    private dateAdapter: DateAdapter<DateTime>,  // Inject the date adapter
  ) {
  }

  /**
 * @description If only one location exists, set location input = to it
 * Must use ngAfterViewInit in order to run after receiving the input variable value */
  public ngAfterContentInit(): void { // Happens once per component
    if (this.isMiniCartEdit) {
      if (!this.isQuickBooking) {
        this.selectedStartDate = this.timeService.convertTimestampToLuxon(this.rentalStartDate);
        this.selectedEndDate = this.timeService.convertTimestampToLuxon(this.rentalEndDate);
      }
    }
    // if only one location exists for the company, select it
    // if (this.locations?.length >= 1) {
    //   this.setLocationIfSingleInCompany();
    // } else {
    //   console.error("At least one location is required to use the date-range component")
    //   return
    // }

    // In order for errors to occur on init / have access to control validation, these inital operations need to be batched with the component generation
    setTimeout(() => {
      // this.showDateRangeOnInit = true
      this.updateDatepickerByLocation(this.selectedLocationID);
      if (this.dateRangeConfig?.runErrorValidationOnInit) { // Manually triggers the validation so that error messages can appear initally if needed (ex, booking flow)
        this.provideParamsOnInputChange(DateRangeInputType.Manual);
      }
    }, 0)

  }

  public ngOnChanges(changes: SimpleChanges): void {

    // Needed for Minicart
    if (changes.rentalStartDate || changes.rentalEndDate) {
      this.selectedStartDate = this.rentalStartDate;
      this.selectedEndDate = this.rentalEndDate;
    }

    if (changes.cartObj) { // Determine whether or not to apply a cartlock
      this.determineIfCartLockApplied();
    }

    // If Availability Override has been changed, re-validate inputs
    if (changes.availabilityOverrideConfig) {
      this.provideParamsOnInputChange(DateRangeInputType.AvailabilityOverride);
    }
  }

  public setErrorMessage(errHandlingObject: ErrorHandlingObject): void { // Allows outside components the ability to alter the date ranges error message component
    this._errorHandlingObject = errHandlingObject;
  }

  /**
   * @description Emits form's input values. Triggered on different defined inputType changes
   */
  public provideParamsOnInputChange(inputType: DateRangeInputType): void {
    if (!this.dateRangeForm) { // Prevent running this method if form has not yet been generated (ex: ngOnChanges inital call)
      return
    }

    /* Handle Specific Input Changes */
    if (inputType === DateRangeInputType.Location) {
      // Updates daypicker by current locations calendar
      this.updateDatepickerByLocation(this.selectedLocationID);

      // Keep local time is used because we want to keep the inputted dates relative data, but ensure that there timezone is changed to the new locations
      // can't have startOf because of current day processing
      // Adapt DateTime objects to the newly selected location - if possible
      const validDataType = this.dateRangeService.isDatePickerValid(this.selectedStartDate, this.selectedEndDate); // Ensure inputs are DateTime objects
      if (validDataType && this.locationMap[this.selectedLocationID]?.timezone) { // If valid DateTime Objects + Location exists and has a timezone
        this.selectedStartDate = this.selectedStartDate.setZone(this.locationMap[this.selectedLocationID].timezone, { keepLocalTime: true })
        this.selectedEndDate = this.selectedEndDate.setZone(this.locationMap[this.selectedLocationID].timezone, { keepLocalTime: true })
      }
    }

    if (inputType === DateRangeInputType.DatePicker) {
      // Mark controls as dirty (if user clicked search btn without selecting dates, must still mark as dirty)
      this.startDateControl?.control.markAsDirty();
      this.endDateControl?.control.markAsDirty();

      // If the user supplied a start date but not an end date, set the end date = to the start date
      if (!this.selectedEndDate && this.selectedStartDate) {
        this.selectedEndDate = this.selectedStartDate;
        this.endDateControl.control.setValue(this.selectedStartDate);
      }
    }

    /* Validate the form inputs */
    setTimeout(() => { // Timeout ensures that validation changes above (such as location update) have finished before checking form validity again here
      if (this.dateRangeForm.controls) {
        this.reloadFormControlValidation();
      }

      /* Check Form input validity */
      let isValid: boolean = this.checkAgainstFormControlValidation();

      /* Output */
      if (isValid) { // No error message is needed if the form is valid
        this._errorHandlingObject = null;
      }
      const dateRangeInputOnChange: DateRangeInputOnChange = {
        changedInput: inputType,
        isFormValid: isValid,
        componentInputState: {
          selectedStartDate: this.selectedStartDate,
          selectedEndDate: this.selectedEndDate,
          selectedLocationID: this.selectedLocationID,
          selectedTimeslot: this.selectedTimeslot
        }
      };
      this.dateRangeInputChanged.emit(dateRangeInputOnChange);
    }, 0)
    this.cdr.detectChanges(); // Needed in order to prevent ExpressionChangedAfterItHasBeenCheckedError
  }

  private checkAgainstFormControlValidation(): boolean {
    let isValid: boolean = true;

    /* Location Input Validation */
    if ((this.locationControl?.control?.invalid && this.locationControl?.dirty) || !this.locationMap[this.selectedLocationID]?.timezone) {
      isValid = false;
      this._errorHandlingObject = { isValid: false, errors: (this.errorService.getErrorByCode[ErrorCodes.INVALID_LOCATION]) };
      return isValid;
    }

    const startDateErrors = this.startDateControl?.control?.errors;
    const endDateErrors = this.endDateControl?.control?.errors;

    // If the date range picker has any errors
    if (startDateErrors || endDateErrors) {
      isValid = false;

      // If the controls have been modified
      if (this.startDateControl?.dirty || this.endDateControl?.dirty) {
        // Fails Required Validation
        if (startDateErrors?.required) {
          this._errorHandlingObject = { isValid: false, errors: [this.errorService.getErrorByCode(ErrorCodes.INVALID_DATE_RANGE)] };
          return isValid;
        }
      }

      // If showing via booking flow, inputs are considered dirty / to have already been modified (since inputs are pre-populated)
      if (startDateErrors?.matDatepickerFilter || endDateErrors?.matDatepickerFilter) {
        this._errorHandlingObject = { isValid: false, errors: [this.errorService.getErrorByCode(ErrorCodes.DATES_UNAVAILABLE)] };
        return isValid;
      }

      // If the errors are caused by the datepicker value being below the locations current day
      if ((startDateErrors?.matDatepickerMin) || endDateErrors?.matDatepickerMin) {
        if (this.showingViaBookingFlow && this.cartObj?.items.length > 0) { // Displays a slightly different message if the user is visiting via a booking flow
          this._errorHandlingObject = { isValid: false, errors: [this.errorService.getErrorByCode(ErrorCodes.DATES_PASSED_RANGE_IN_CART)] };
          return isValid;
        }
        else {
          this._errorHandlingObject = { isValid: false, errors: [this.errorService.getErrorByCode(ErrorCodes.DATES_PASSED)] };
          return isValid;
        }
      }
    }
    return isValid;
  }

  private reloadFormControlValidation(): void {
    // Note All of the relevant inputs need to be checked here in order for their validation to be updated on changes
    Object.keys(this.dateRangeForm.controls).forEach((controlName) => {
      const control = this.dateRangeForm.controls[controlName];
      control.updateValueAndValidity()
    })
  }

  private determineIfCartLockApplied(): void {
    if (this.cartObj && this.cartObj?.items && this.cartObj?.items?.length >= 1) { // If cartobj contains at least an item, apply cart lock
      this.cartLockIsApplied = true;
    }
    else {
      this.cartLockIsApplied = false;
    }
  }

  public updateDatepickerByLocation(locationID: string): void {
    // Close the datepicker if it's open
    if (this.dPicker) {
      this.dPicker.close();
    }

    // Step 1: Reset and update calendar availability
    this.dayOfTheWeekFormatedFromDbToLuxon.clear();
    this.unavailableDaysSingle.clear();
    this.unavailableDaysAnnually.clear();

    // Step 2: Find the location object based on the selected location ID
    this.locationObj = this.locations.find(loc => loc.id === locationID);
    if (!this.locationObj) {
      return; // If no valid location found, return early
    }

    // Step 3: Update timezone info
    this.selectedTimezone = this.locationObj.timezone;

    // Set the current date according to the selected timezone
    this.timezonesCurrentDate = DateTime.now().setZone(this.selectedTimezone);
    (this.dateAdapter as FleetmaidDateAdapter).setTimezone(this.selectedTimezone);

    // Step 4: Update available days based on the new location's schedule
    this.locationObj.scheduleWeek.forEach((schedule, index) => {
      if (schedule.available) {
        this.dayOfTheWeekFormatedFromDbToLuxon.add(index === 0 ? 7 : index); // Handle Luxon's weekday mapping (Sunday=7)
      }
    });

    // Step 5: Update unavailable days (single and annual)
    this.locationObj.unavailableDays.forEach((day) => {
      const dateTimeObj = this.timeService.convertTimestampToLuxon(day.date).setZone(this.selectedTimezone);
      if (day.periodicity === "One Time") {
        this.unavailableDaysSingle.add(dateTimeObj.toISODate()); // Store unavailable days as ISO dates for fast comparison
      } else if (day.periodicity === "Every year") {
        const { month, day: dayOfMonth } = dateTimeObj.toObject();
        this.unavailableDaysAnnually.add(`${month}-${dayOfMonth}`); // Store as month-day for annual comparison
      }
    });

    // Step 6: Revalidate the form controls and trigger change detection
    this.updateFormValidation(); // Ensures the form is revalidated with new rules

    // Optionally, force a UI update if Angular doesn't detect the changes automatically
    this.cdr.detectChanges();
  }

  private updateFormValidation(): void {
    // Trigger datepicker validators to update based on the new filter and min date
    if (this.startDateControl) {
      this.startDateControl.control.updateValueAndValidity(); // Trigger validation on start date control
    }

    if (this.endDateControl) {
      this.endDateControl.control.updateValueAndValidity(); // Trigger validation on end date control
    }

    // Manually trigger Angular's change detection to ensure that the UI reflects the changes
    this.cdr.detectChanges();
  }

  setFormDateInputsAsDirty() {
    this.startDateControl.control.markAsDirty();
    this.endDateControl.control.markAsDirty();
  }

  /**
   * @description Ran on each day being presented in the daypicker. Greys out unavailable days
   */
  protected validateEachCalendarDay = (calendarDate: DateTime | null): boolean => {
    if (!calendarDate) { return true; }

    let dayAvailable = false;

    // Check if the day of the week is available according to the location's schedule
    if (this.dayOfTheWeekFormatedFromDbToLuxon.has(calendarDate.weekday)) {
      dayAvailable = true;
    }

    // Check if the date is in the list of unavailable single-day dates
    if (this.unavailableDaysSingle.has(calendarDate.toISODate())) {
      dayAvailable = false;
    }

    // Check if the date is in the list of annually unavailable dates
    let { month, day: dayOfMonth } = calendarDate.toObject();
    if (this.unavailableDaysAnnually.has(`${month}-${dayOfMonth}`)) {
      dayAvailable = false;
    }

    return dayAvailable;
  };

  protected openDayPicker(): void {

    if (this.cartObj?.items?.length > 0 && !this.isMiniCartEdit) {
      return
    }

    if (!this.selectedLocationID) { // If no location is selected upon clicking on date range input, do not open picker - failsafe
      return
    }

    this.dPicker.open(); // Opens the datepicker element
  }

  /**
 * @description Set the selected location = the first (and should be only) element in the companies returned locations  */
  private setLocationIfSingleInCompany(): void {
    this.selectedLocationID = this.locations[0].id; // set the locationID to the first index in arr
    // this.updateDatepickerByLocation(this.locations[0].id) // must run this method to update the calendar dates
  }
}
