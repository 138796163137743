<div class="totalBody">
  <div class="container bgWhite pt-4 pb-5">
    <form
      [formGroup]="userForm"
      class="needs-validation"
      (ngSubmit)="checkForm()"
      novalidate
    >
      <ng-container *ngIf="!isCreating">
        <div class="row">
          <div class="col-lg-6">
            <h2>Account Information</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-6">
            <div class="form-group-sm mb-1 input-group-sm">
              <!-- Select -->
              <select
                class="form-select"
                id="accountType"
                [ngClass]="{ 'is-invalid': f.accountType.errors && submitted }"
                formControlName="accountType"
                (change)="changeCname()"
                [disabled]="isEmployee"
                [(ngModel)]="accountType"
                name="accountType"
                required
              >
                <option value="Employee">Employee</option>
                <option value="Partner">Partner</option>
                <option value="Company" *ngIf="isAdminOrDev">Company</option>
              </select>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div class="form-group-sm mb-1 input-group-sm">
              <input
                class="form-control"
                formControlName="email"
                type="text"
                [(ngModel)]="email"
                id="email"
                name="email"
                [ngClass]="{ 'is-invalid': f.email.errors && submitted }"
                placeholder="Email"
              />
              <!-- feedback -->
              <div *ngIf="f.email.errors && submitted" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Email is required</div>
                <div *ngIf="f.email.errors.email">Email is invalid</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-lg-6 col-md-6">
            <div class="form-group-sm mb-1 input-group-sm">
              <input
                type="password"
                formControlName="password"
                class="form-control"
                [(ngModel)]="password"
                id="password"
                name="password"
                placeholder="Password"
                autocomplete="new-password"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
              />
              <div
                *ngIf="submitted && f.password.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.password.errors.required">
                  Password is required
                </div>
                <div *ngIf="f.password.errors.minlength">
                  Password must be at least 6 characters
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div class="form-group-sm mb-1 input-group-sm">
              <input
                type="password"
                formControlName="confirmPassword"
                class="form-control"
                [(ngModel)]="repeatPassword"
                name="repeatPassword"
                id="repeatPassword"
                [ngClass]="{
                  'is-invalid': submitted && f.confirmPassword.errors
                }"
              />
              <div
                *ngIf="submitted && f.confirmPassword.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.confirmPassword.errors.required">
                  Confirm Password is required
                </div>
                <div *ngIf="f.confirmPassword.errors.mustMatch">
                  Password and confirm not match
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <!-- Phone -->
          <div class="col-lg-6 col-md-6">
            <div class="form-group-sm mb-1 input-group-sm">
              <input
                class="form-control"
                formControlName="phone"
                type="text"
                [(ngModel)]="phone"
                name="phone"
                [ngClass]="{ 'is-invalid': f.phone.errors && submitted }"
                placeholder="Phone"
              />
              <!-- feedback -->
              <div *ngIf="f.phone.errors && submitted" class="invalid-feedback">
                <div *ngIf="f.phone.errors.required">Phone is required</div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!isCreating && accountType == 'Company'">
        <div class="row mt-3">
          <h2>Company Information</h2>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="form-group-sm mb-1 input-group-sm">
              <input
                class="form-control"
                formControlName="cname"
                id="cname"
                type="text"
                [(ngModel)]="cname"
                name="fname"
                [ngClass]="{ 'is-invalid': f.cname.errors && submitted }"
                placeholder="Company Name"
              />

              <div *ngIf="f.cname.errors && submitted" class="invalid-feedback">
                <div *ngIf="f.cname.errors.required">
                  Company Name is required
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="currentStep === 'step1' && !isCreating">
        <div class="row mt-3">
          <h2>User Information</h2>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="form-group-sm mb-1 input-group-sm">
              <!--            <label for="fname" class="mb-2">First Name</label>-->
              <input
                class="form-control"
                formControlName="fname"
                type="text"
                [(ngModel)]="fname"
                name="fname"
                [ngClass]="{ 'is-invalid': f.fname.errors && submitted }"
                placeholder="First Name"
              />
              <div *ngIf="f.fname.errors && submitted" class="invalid-feedback">
                <div *ngIf="f.fname.errors.required">
                  First Name is required
                </div>
              </div>
            </div>
          </div>

          <div class="col-6">
            <div class="form-group-sm mb-1 input-group-sm">
              <!--            <label for="lname" class="mb-2">Last Name</label>-->
              <input
                class="form-control"
                formControlName="lname"
                type="text"
                [(ngModel)]="lname"
                name="lname"
                [ngClass]="{ 'is-invalid': f.lname.errors && submitted }"
                placeholder="Last Name"
              />
              <div *ngIf="f.lname.errors && submitted" class="invalid-feedback">
                <div *ngIf="f.lname.errors.required">Last Name is required</div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col">
            <button
              type="submit"
              id="sbtButton"
              name="button"
              class="btn login_btn"
              [style.background]="colorTheme"
              [style.color]="colorText"
            >
              Confirm
            </button>
          </div>
        </div>
      </ng-container>
    </form>
  </div>
</div>

<div
  class="modal fade"
  id="accountCreatedModal"
  tabindex="-1"
  role="dialog"
  style="z-index: 9999"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" style="width: 515px !important">
      <div class="modal-header"></div>
      <div class="modal-body">
        <ng-container *ngIf="!isCreated">
          <div class="d-flex justify-content-center textSize25">
            <b style="color: #1076c3"> Creating Account</b>
          </div>

          <div class="row d-flex justify-content-center textSize20 mt-2">
            This can take a few seconds....
          </div>
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </ng-container>

        <ng-container *ngIf="isCreated">
          <div class="row d-flex justify-content-center" style="padding: 30px">
            <img
              src="assets/images/icons/circle_check.png"
              class="checkIconSize"
            />
          </div>

          <div class="d-flex justify-content-center textSize25">
            <b style="color: #1076c3"> Account Succesfully Created!</b>
          </div>

          <div class="row d-flex justify-content-center textSize20 mt-2">
            Account has been created successfully.
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
