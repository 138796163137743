<div class="container-fluid">
  <div class="row justify-content-center mt-2">
    <div class="col-md-12 text-left">
      <h1 class="mt-2 mb-2" style="font-size: 32px">Company</h1>
    </div>
  </div>
</div>
<div>
  <form
    class="needs-validations"
    (ngSubmit)="checkForm()"
    [formGroup]="companyForm"
    novalidate
    class="row"
  >
    <div class="col-md-6">
      <fieldset class="container-fluid border rounded p-2 mt-2">
        <legend class="float-none fs-4 w-auto p-2 titletext">
          Company Address
        </legend>
        <div class="row g-3">
          <div class="col-12">
            <label for="inputAddress" class="form-label"
              >Address <b class="required">*</b></label
            >
            <input
              type="text"
              class="form-control"
              placeholder="1234 Main St"
              name="address1"
              formControlName="address1"
              [ngClass]="{ 'is-invalid': f.address1.errors && submitted }"
            />
            <span class="invalid-feedback" *ngIf="f.address1.errors?.required"
              >Address is Required</span
            >
          </div>
          <div class="col-12">
            <label for="inputAddress2" class="form-label">Address 2</label>
            <input
              type="text"
              class="form-control"
              placeholder="Apartment, studio, or floor"
              name="address2"
              formControlName="address2"
            />
          </div>
          <div class="col-md-3">
            <label for="inputCity" class="form-label"
              >City <b class="required">*</b></label
            >
            <input
              type="text"
              class="form-control"
              name="city"
              formControlName="city"
              [ngClass]="{ 'is-invalid': f.city.errors && submitted }"
            />
            <span class="invalid-feedback" *ngIf="f.city.errors?.required"
              >City is Required</span
            >
          </div>
          <div class="col-md-3">
            <label for="inputState" class="form-label"
              >State <b class="required">*</b></label
            >
            <select
              class="form-select"
              name="state"
              formControlName="state"
              [ngClass]="{ 'is-invalid': f.state.errors && submitted }"
            >
              <option value="">Choose...</option>
              <ng-container *ngFor="let state of states">
                <option [selected]="state == f.state.value" [value]="state">
                  {{ state }}
                </option>
              </ng-container>
            </select>
            <span class="invalid-feedback" *ngIf="f.state.errors?.required"
              >State is Required</span
            >
          </div>
          <div class="col-md-3">
            <label for="inputState" class="form-label"
              >Country <b class="required">*</b></label
            >
            <select
              (change)="setStates()"
              class="form-select"
              name="country"
              formControlName="country"
              [ngClass]="{ 'is-invalid': f.country.errors && submitted }"
            >
              <option value="">Choose...</option>
              <ng-container *ngFor="let country of countries">
                <option
                  [selected]="country.shortName == f.country.value"
                  [value]="country.shortName"
                >
                  {{ country.name }}
                </option>
              </ng-container>
            </select>
            <span class="invalid-feedback" *ngIf="f.country.errors?.required"
              >Country is Required</span
            >
          </div>
          <div class="col-md-3">
            <label for="inputZip" class="form-label"
              >Zip <b class="required">*</b></label
            >
            <input
              type="number"
              class="form-control"
              name="zip"
              formControlName="zip"
              [ngClass]="{ 'is-invalid': f.zip.errors && submitted }"
            />
            <span class="invalid-feedback" *ngIf="f.zip.errors?.required"
              >Zip is Required</span
            >
          </div>
        </div>
      </fieldset>
      <fieldset class="container-fluid border rounded p-2 mt-4">
        <legend class="float-none fs-4 w-auto p-2 titletext">URL</legend>
        <div class="row g-3">
          <div class="col-md-12">
            <label for="inputAddress" class="form-label"
              >Company Website Name <b class="required">*</b></label
            >
            <input
              type="text"
              class="form-control"
              name="companyWebsite"
              formControlName="companyWebsite"
              [ngClass]="{ 'is-invalid': f.companyWebsite.errors}"
            />
            <span
              class="invalid-feedback"
              *ngIf="f.companyWebsite.errors?.required"
              >Company Website Name is Required</span
            >
            <span
              class="invalid-feedback"
              *ngIf="f.companyWebsite.errors?.pattern"
              >Please enter a valid host</span
            >
          </div>
        </div>
      </fieldset>
    </div>
    <div class="col-md-6">
      <fieldset class="container-fluid border rounded p-2 mt-2">
        <legend class="float-none fs-4 w-auto p-2 titletext">
          Company Data
        </legend>
        <div class="row g-3">
          <div
            class="col-md-12 d-flex text-center justify-content-center align-items-center"
          >
            <div
              class="d-flex text-center justify-content-center align-items-center"
            >
              <!-- <img class="imglogo" alt="Image Logo"
                                src="{{this.company?.companyImg ? this.company?.companyImg : ''}}"> -->
              <!-- <button type="button" (click)="uploadCompanyImg()" for="companyimg"
                                class="btn btn-primary btnimg"><i class="fas fa-camera"></i></button> -->
              <img class="imglogo" alt="Image Logo" src="{{ companyLogo }}" />
              <button
                type="button"
                (click)="openImgGallery()"
                for="companyimg"
                class="btn btn-primary btnimg"
              >
                <i class="fas fa-camera"></i>
              </button>
            </div>
          </div>
          <div class="col-md-12">
            <label for="inputAddress" class="form-label"
              >Company Name <b class="required">*</b></label
            >
            <input
              type="text"
              class="form-control"
              name="companyName"
              formControlName="companyName"
              [ngClass]="{ 'is-invalid': f.companyName.errors && submitted }"
            />
            <span
              class="invalid-feedback"
              *ngIf="f.companyName.errors?.required"
              >Company Name is Required</span
            >
          </div>
          <div class="col-md-12">
            <label for="inputAddress2" class="form-label"
              >Company Email <b class="required">*</b></label
            >
            <input
              type="email"
              class="form-control"
              placeholder="example@fleetmaid.com"
              name="companyEmail"
              formControlName="companyEmail"
              [ngClass]="{ 'is-invalid': f.companyEmail.errors && submitted }"
            />
            <span
              class="invalid-feedback"
              *ngIf="f.companyEmail.errors?.required"
              >Company Email is Required</span
            >
          </div>
          <div class="col-md-6" *ngIf="!isAdmin && !isDeveloper">
            <label class="form-label"
              >Company Tax <b class="required">*</b></label
            >
            <div class="input-group">
              <span class="input-group-text" id="basic-addon1">%</span>
              <input
                type="number"
                step="0.01"
                class="form-control"
                name="companyTax"
                formControlName="companyTax"
                [ngClass]="{ 'is-invalid': f.companyTax.errors && submitted }"
              />
              <span
                class="invalid-feedback"
                *ngIf="f.companyTax.errors?.required"
                >Company Tax is Required</span
              >
            </div>
          </div>
          <div class="col-md-3" *ngIf="isAdmin || isDeveloper">
            <label class="form-label"
              >Company Tax <b class="required">*</b></label
            >
            <div class="input-group">
              <span class="input-group-text" id="basic-addon1">%</span>
              <input
                type="number"
                step="0.01"
                class="form-control"
                name="companyTax"
                formControlName="companyTax"
                [ngClass]="{ 'is-invalid': f.companyTax.errors && submitted }"
              />
              <span
                class="invalid-feedback"
                *ngIf="f.companyTax.errors?.required"
                >Company Tax is Required</span
              >
            </div>
          </div>
          <div class="col-md-3" *ngIf="isAdmin || isDeveloper">
            <label class="form-label"
              >FleetMaid Tax <b class="required">*</b></label
            >
            <div class="input-group">
              <span class="input-group-text adminfield" id="basic-addon1"
                >%</span
              >
              <input
                type="number"
                step="0.01"
                class="form-control admin"
                name="fleetTax"
                formControlName="fleetTax"
                [ngClass]="{ 'is-invalid': f.fleetTax.errors && submitted }"
              />
              <img
                class="imglock"
                src="../../../assets/images/icons/secure-blue.svg"
              />
              <span class="invalid-feedback" *ngIf="f.fleetTax.errors?.required"
                >FleetMaid Tax is Required</span
              >
            </div>
          </div>
          <div class="col-md-6">
            <label for="inputCity" class="form-label"
              >Phone <b class="required">*</b></label
            >
            <input
              type="text"
              class="form-control"
              name="companyPhone"
              formControlName="companyPhone"
              [ngClass]="{ 'is-invalid': f.companyPhone.errors && submitted }"
            />
            <span
              class="invalid-feedback"
              *ngIf="f.companyPhone.errors?.required"
              >Phone is Required</span
            >
          </div>
          <div class="col-mb-12">
            <label for="inputCity" class="form-label"
              >Ignore unavailable (closed) days when creating a booking
              in-house</label
            >
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                name="isIgnoreUDays"
                formControlName="isIgnoreUDays"
                (change)="setIgnoreUDays($event)"
              />
              <label
                class="form-check-label"
                *ngIf="f.isIgnoreUDays.value == true"
                >Enabled</label
              >
              <label
                class="form-check-label"
                *ngIf="f.isIgnoreUDays.value == false"
                >Disabled</label
              >
            </div>
          </div>
        </div>
      </fieldset>
    </div>
    <div class="col-md-6" *ngIf="isDeveloper">
      <fieldset class="container-fluid border rounded p-2 mt-2">
        <legend class="float-none fs-4 w-auto p-2 titletext">
          Email From
        </legend>
        <div class="row g-3">
          <div class="col-md-12">
            <p>Enter an alternate smtp email account to send mail from (default: fleetmaid1&#64;gmail.com).</p>
          </div>
          <div class="col-md-6">
            <label class="form-label">Host</label>
            <input type="text" class="form-control"
              [ngClass]="{'is-invalid': companyForm.controls.smtpHost.errors}" name="smtpHost"
              formControlName="smtpHost" />
            <div class="invalid-feedback">
              Please enter a valid host
            </div>
          </div>
          <div class="col-md-6">
            <label class="form-label"> Port </label>
            <select class="form-select" [ngClass]="{'is-invalid': companyForm.controls.smtpPort.errors}"
              name="smtpPort" formControlName="smtpPort">
              <option value="25">25</option>
              <option value="465">465</option>
              <option value="587">587</option>
            </select>
            <div class="invalid-feedback">
              Please enter a valid port
            </div>
          </div>
          <div class="col-md-6">
            <label class="form-label">SMTP User</label>
            <input type="text" class="form-control"
              [ngClass]="{'is-invalid': companyForm.controls.smtpUser.errors}" name="smtpUser"
              formControlName="smtpUser" />
            <div class="invalid-feedback">
              Please enter a valid email address
            </div>
          </div>
          <div class="col-md-6">
            <label class="form-label"> SMTP Password </label>
            <div class="input-group">
              <input [type]="hidePassword ? 'password' : 'text'" class="form-control" name="smtpPassword"
                formControlName="smtpPassword" />
              <button class="btn btn-outline-secondary" type="button" (click)="togglePassword()">
                <i class="fa" [ngClass]="{'fa-eye': hidePassword, 'fa-eye-slash': !hidePassword}"></i>
              </button>
            </div>
          </div>
          <div class="col-md-12">
            <p>SMTP credentials are not required for email notifications to be sent, but they are recommended. The following
              are the authentication method(s) which currently we support:</p>
            <ul>
              <li><code>PLAIN</code> - username (email) and password</li>
            </ul>
            <p>Our email notification setup has been successfully tested with the following providers:</p>
            <ul>
              <li><code>gmail.com</code></li>
              <li><code>smtp.office365.com</code></li>
            </ul>
            <p>Enter an email address below and press <strong>Test</strong> to send a message using your SMTP credentials.
            </p>
          </div>
          <div class="col-md-6">
            <label class="form-label">Mail To</label>
            <input type="text" class="form-control" [ngClass]="{'is-invalid': companyForm.controls.mailTo.errors}"
              name="mailTo" formControlName="mailTo" />
            <div class="invalid-feedback">
              Please enter a valid email address
            </div>
          </div>
          <div class="col-md-12">
            <button type="button" class="btn btn-primary" (click)="sendEmail()">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isSendingEmail"></span>
              Test
            </button>

          </div>
        </div>
      </fieldset>
    </div>
    <div class="col-md-6" *ngIf="isDeveloper">
      <fieldset class="container-fluid border rounded p-2 mt-2">
        <legend class="float-none fs-4 w-auto p-2 titletext">
          Email Notifications
        </legend>
        <div class="row g-3">
          <div class="col-md-6">
            <label class="form-label">Bcc</label>
            <input type="text" class="form-control"  [ngClass]="{'is-invalid': companyForm.controls.bcc.errors}"
              name="bcc" formControlName="bcc" />
          </div>
          <div class="col-md-12">
            <p>Blind carbon copy any email notifications to these users as well.</p>
          </div>
        </div>
      </fieldset>
      <fieldset class="container-fluid border rounded p-2 mt-2">
        <legend class="float-none fs-4 w-auto p-2 titletext">
          Stripe Setup
        </legend>
        <div class="row g-3">
          <div class="col-md-6">
            <label class="form-label">Stripe Live Account</label>
            <input
              type="text"
              class="form-control"
              name="stripeID"
              formControlName="stripeID"
            />
          </div>
          <div class="col-md-6">
            <label class="form-label">Stripe Test Account </label>
            <input
              type="text"
              class="form-control"
              name="stripeIDTest"
              formControlName="stripeIDTest"
            />
          </div>

          <!--<div class="col-md-6 d-grid gap-2">
                        <label class="form-label">Stripe Live Account <b class="required">*</b></label>
                        <a href="" type="button" class="btn btn-success" (click)="setStripeAccount()">Add Stripe Live Account</a>
                    </div>
                    <div class="col-md-6 d-grid gap-2">
                        <label class="form-label">Stripe Test Account </label>
                        <a target="_black" href="https://connect.stripe.com/oauth/v2/authorize?response_type=code&client_id=ca_Lk7bNWW8hCWFffRLMDvnWNTgcHnadnjF&scope=read_write" type="button" class="btn btn-warning" (click)="setStripeTestAccount()">Add Stripe Test Account</a>
                    </div>-->
          <div class="col-mb-12">
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                name="isTesting"
                formControlName="isTesting"
                (change)="setTesting()"
              />
              <label
                class="form-check-label"
                for="flexSwitchCheckDefault"
                *ngIf="f.isTesting.value == true"
                >Is a Live Account</label
              >
              <label
                class="form-check-label"
                for="flexSwitchCheckDefault"
                *ngIf="f.isTesting.value == false"
                >Is a Test Account</label
              >
            </div>
          </div>
        </div>
      </fieldset>
    </div>
    <div
      class="mt-2 mb-2 col-md-12 text-right align-items-right justify-content-right"
    >
      <button
        (click)="cancelChanges()"
        type="button"
        class="btn btn-secondary me-2"
      >
        Cancel
      </button>
      <button
        type="submit"
        [style.background]="colorBackground"
        [style.color]="colorText"
        class="btn btn"
      >
        Save Changes
      </button>
    </div>
  </form>
</div>

<!-- Image Gallery - Logo -->
<div class="modal fade" data-bs-backdrop="false" id="imgGallery">
  <div
    class="modal-dialog modal-dialog-centered"
    role="document"
    style="max-width: 90vw; max-height: 90vh"
  >
    <div class="modal-content">
      <div class="modal-body">
        <!-- Button used to close the modal -->
        <div style="position: relative; width: 100%">
          <button
            style="float: right"
            id="closeImgLibraryBtn"
            type="button"
            class="btn btn-danger rowBtn"
            data-bs-dismiss="modal"
          >
            <i class="fa fa-close"></i>
          </button>
        </div>

        <!-- The image library component -->
        <app-image-library
          [showSelect]="true"
          [defaultTabSelection]="'logo'"
        ></app-image-library>
      </div>
    </div>
  </div>
</div>
