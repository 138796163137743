<!--Tittle of Module-->
<div class="container-fluid">
  <div class="row justify-content-center mt-2">
    <div class="col-md-12 text-left">
      <h1 class="mt-2 mb-2" style="font-size: 32px">Rental Report</h1>
      <b>Today is {{ datenow }}</b>
    </div>
  </div>
</div>
<div class="container-fluid mt-2">
  <!--Main container-->
  <div class="row align-items-start">
    <!--Box with information-->
    <div id="cards" class="col-3 h-100">
      <div class="accordion" id="accordionClose">
      <!--Sales-->
      <div class="d-grid gap-2 col-12 mx-auto mb-3">
          <button class="btn btn-primary d-block d-md-none sales" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSales" aria-expanded="false" aria-controls="collapseSales">
            Sales
          </button>
      </div>
      <div class="accordion-collapse collapse d-md-block mb-3" id="collapseSales" data-bs-parent="#accordionClose">
        <div class="card text-center bg-light mt-4 shadow sales">
          <div class="card-body">
            <h3 class="card-title"><b>Sales</b></h3>
            <div class="container-fluid mt-2">
              <div class="row align-items-start">
                <div class="col-6 h-100">
                  <div class="wn-100 fs-4">
                    <b>$ {{ totalsalestm }}</b>
                  </div>
                  <div class="wn-100 mt-4">This Month</div>
                </div>
                <div class="col-6 h-100">
                  <div class="wn-100 fs-4">
                    <b>$ {{ totalsaleslm }}</b>
                  </div>
                  <div class="wn-100 mt-4">The Last Month</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Rentals-->
      <div class="d-grid gap-2 col-12 mx-auto mb-3">
        <button class="btn btn-primary d-block d-md-none rentals" type="button" data-bs-toggle="collapse" data-bs-target="#collapseRentals" aria-expanded="false" aria-controls="collapseRentals">
          Rentals
        </button>
      </div>
      <div class="accordion-collapse collapse d-md-block mb-3" id="collapseRentals" data-bs-parent="#accordionClose">
        <div class="card text-center bg-light mt-2 shadow rentals">
          <div class="card-body">
            <h3 class="card-title"><b>Rentals</b></h3>
            <div class="container-fluid mt-2">
              <div class="row align-items-start">
                <div class="col-4 h-100">
                  <div class="wn-100 fs-4">
                    <b>{{ totalrentalstm }}</b>
                  </div>
                  <div class="wn-100 mt-4">This Month</div>
                </div>
                <div class="col-4 h-100">
                  <div class="wn-100 fs-4">
                    <b>{{ totalrentalslm }}</b>
                  </div>
                  <div class="wn-100 mt-4">The Last Month</div>
                </div>
                <div class="col-4 h-100">
                  <div class="wn-100 fs-4">
                    <b>{{ totalrentals }}</b>
                  </div>
                  <div class="wn-100 mt-4">All Time</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Cancellations-->
      <div class="d-grid gap-2 col-12 mx-auto mb-3">
        <button class="btn btn-primary d-block d-md-none cancellations" type="button" data-bs-toggle="collapse" data-bs-target="#collapseCancellations" aria-expanded="false" aria-controls="collapseCancellations">
          Cancellations
        </button>
      </div>
      <div class="accordion-collapse collapse d-md-block mb-3" id="collapseCancellations" data-bs-parent="#accordionClose">
        <div class="card text-center bg-light mt-4 shadow cancellations">
          <div class="card-body">
            <h3 class="card-title"><b>Cancellations</b></h3>
            <div class="container-fluid mt-2">
              <div class="row align-items-start">
                <div class="col-6 h-100">
                  <div class="wn-100 fs-4">
                    <b>{{ tcancelledtm }}</b>
                  </div>
                  <div class="wn-100 mt-4">This Month</div>
                </div>
                <div class="col-6 h-100">
                  <div class="wn-100 fs-4">
                    <b>{{ tcancelledlm }}</b>
                  </div>
                  <div class="wn-100 mt-4">The Last Month</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Most Booked Product-->
      <div class="d-grid gap-2 col-12 mx-auto mb-3">
        <button class="btn btn-primary d-block d-md-none most" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMost" aria-expanded="false" aria-controls="collapseMost">
          Most Booked Product
        </button>
      </div>
      <div class="accordion-collapse collapse d-md-block mb-3" id="collapseMost" data-bs-parent="#accordionClose">
        <div class="card text-center bg-light mt-4 shadow most">
          <div class="card-body">
            <h3 class="card-title"><b>Most Booked Product</b></h3>
            <div class="container-fluid mt-2">
              <div class="row align-items-start">
                <div class="col-6 h-100">
                  <div class="wn-100 fs-4">
                    <b>{{ productthismonth[0][0] }}</b>
                  </div>
                  <div class="wn-100 mt-4">
                    <b>{{ productthismonth[0][1] }}</b> This Month
                  </div>
                </div>
                <div class="col-6 h-100">
                  <div class="wn-100 fs-4">
                    <b>{{ productlastmonth[0][0] }}</b>
                  </div>
                  <div class="wn-100 mt-4">
                    <b>{{ productlastmonth[0][1] }}</b> The Last Month
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Maintenances-->
      <div class="d-grid gap-2 col-12 mx-auto mb-3">
        <button class="btn btn-primary d-block d-md-none maintenances" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMaintenances" aria-expanded="false" aria-controls="collapseMaintenances">
          Maintenances
        </button>
      </div>
      <div class="accordion-collapse collapse d-md-block mb-3" id="collapseMaintenances" data-bs-parent="#accordionClose">
        <div class="card text-center bg-light mt-4 shadow maintenances">
          <div class="card-body">
            <h3 class="card-title"><b>Maintenances</b></h3>
            <div class="container-fluid mt-2">
              <div class="row align-items-start">
                <div class="col-12 h-100">
                  <div class="wn-100 fs-4">
                    <b>{{ trepairs }}</b>
                  </div>
                  <div class="wn-100 mt-4">Total of Maintenances</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <!--Tables & charts-->
    <div id="maincontainer" class="col-9 h-100">
      <!--Charts-->
      <div id="chartcontainter" class="wm-100">
        <!--Links to charts-->
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <!--Link to Sales Chart-->
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="saleschart-tab" data-bs-toggle="tab" data-bs-target="#saleschart"
              type="button" role="tab" aria-controls="saleschart" aria-selected="true">
              Sales Chart
            </button>
          </li>
          <!--Link to Charts-->
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="rentalschart-tab" data-bs-toggle="tab" data-bs-target="#rentalschart"
              type="button" role="tab" aria-controls="rentalschart" aria-selected="false">
              Chart
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="mostBooked-tab" data-bs-toggle="tab" data-bs-target="#mostBookedChart"
              type="button" role="tab" aria-controls="mostBooked" aria-selected="false">
              Most Booked
            </button>
          </li>
        </ul>
        <!--Charts-->
        <div class="tab-content" id="myTabContent">
          <!--Line Chart-->
          <div class="tab-pane fade show active" id="saleschart" role="tabpanel" aria-labelledby="saleschart-tab">
            <div class="chart-container">
              <canvas baseChart [data]="lineSalesData"
                [options]="lineChartOptions" [type]="lineChartType" (chartHover)="chartHovered($event)"
                (chartClick)="chartClicked($event)"></canvas>
            </div>
          </div>
          <!--Bar Chart-->
          <div class="tab-pane fade" id="rentalschart" role="tabpanel" aria-labelledby="rentalschart-tab">
            <div class="chart-container">
              <canvas baseChart [data]="lineRentalsData"
                [options]="barChartOptions" [type]="BarChartType" (chartHover)="chartHovered($event)"
                (chartClick)="chartClicked($event)"></canvas>
            </div>
          </div>
          <div class="tab-pane fade" id="mostBookedChart" role="tabpanel" aria-labelledby="mostBooked-tab">
            <div class="chart-container">
              <canvas baseChart [data]="linesBookedData"
                [options]="barChartOptions" [type]="BarChartType" (chartHover)="chartHovered($event)"
                (chartClick)="chartClicked($event)"></canvas>
            </div>
          </div>
        </div>
      </div>
      <!--Tables-->
      <div id="table-container" class="wn-100 mt-5 mb-2">
        <div class="align-items-start">
          <div>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <!--Link to Sales (Looks like its not saving data this collection)-->
              <!--<li class="nav-item" role="presentation">
                <button class="nav-link active" id="sales-tab" data-bs-toggle="tab" data-bs-target="#sales"
                  type="button" role="tab" aria-controls="sales" aria-selected="false">
                  Sales
                </button>
              </li>-->
              <!--Link to Rentals-->
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="rentals-tab" data-bs-toggle="tab" data-bs-target="#rentals"
                  type="button" role="tab" aria-controls="rentals" aria-selected="true">
                  Rentals
                </button>
              </li>
              <!--Link to Cancellations-->
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="cancellations-tab" data-bs-toggle="tab" data-bs-target="#cancellations"
                  type="button" role="tab" aria-controls="cancellations" aria-selected="false">
                  Cancellations
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="repairs-tab" data-bs-toggle="tab" data-bs-target="#repairs" type="button"
                  role="tab" aria-controls="repairs" aria-selected="false">
                  Maintenance
                </button>
              </li>
            </ul>
            <div class="tab-content mt-2" id="myTabContent">
              <!--Table Sales (Looks like its not saving data this collection)-->
              <!--<div class="tab-pane fade show active table-responsive-xxl" id="sales" role="tabpanel"
                aria-labelledby="sales-tab">
                <table class="table-hover bg-light" *ngIf="sales.length" datatable id="tablesales">
                  <thead [style.background]="backgrounColor" [style.color]="textColor">
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">Total</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Rental Type</th>
                      <th scope="col">PDF</th>
                    </tr>
                  </thead>
                  <tbody id="tablesales">
                    <tr *ngFor="let sale of sales">
                      <td>{{ sale.date }}</td>
                      <td>
                        <b>$ {{ sale.paidTotal }} USD</b>
                      </td>
                      <td>{{ sale.name }}</td>
                      <td>{{ sale.email }}</td>
                      <td>{{ sale.phone }}</td>
                      <td>
                        <div class="badge badge-success">
                          {{ sale.rentalType }}
                        </div>
                      </td>
                      <td>
                        <a href="{{ sale.url }}" class="text-danger fs-6"><i class="fas fa-file-pdf"></i></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>-->
              <!--Table Rentals-->
              <div class="tab-pane fade show active table-responsive-xxl" id="rentals" role="tabpanel"
                aria-labelledby="rentals-tab">
                <table class="table-hover bg-light" *ngIf="rentals.length" datatable id="tablerentals">
                  <thead class="sticky-top" [style.background]="backgroundColor" [style.color]="textColor">
                    <tr>
                      <th scope="col">Folio</th>
                      <th scope="col">Name</th>
                      <th scope="col">Day Start</th>
                      <th scope="col">Day End</th>
                      <th scope="col">Products</th>
                      <th scope="col">Cost</th>
                      <th scope="col">Contact Phone</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngIf="rentals.length == 0">
                      <tr>
                        <td colspan="8" class="text-center">
                          Theres not data to show
                        </td>
                      </tr>
                    </ng-container>
                    <ng-container *ngIf="rentals.length > 0">
                      <tr *ngFor="let rental of rentals">
                        <td name="Folio">{{ rental.rentalNumber ? rental.rentalNumber : "-" }}</td>
                        <td name="Name">{{ rental.userInfo.name + " " + rental.userInfo.lastName }}</td>
                        <td name="Day Start">{{ rental.dayStart }}</td>
                        <td name="Day End">{{ rental.dayEnd }}</td>
                        <td name="Products">
                          <span *ngFor="let r of rental.productsNames" class="badge badge-light text-dark">
                            {{ r }}
                          </span>
                        </td>
                        <td name="Cost">$ {{ rental.cost }} USD</td>
                        <td name="Contact Phone">{{ rental.userInfo.phone }}</td>
                        <td name="Status">
                          <span *ngIf="rental.isCancelled == true" class="badge badge-danger">Cancelled</span>
                          <span *ngIf="rental.isPrepared == false" class="badge badge-primary">To Prepare</span>
                          <span *ngIf="rental.isCheckedOut == false || rental.isChekedIn == false"
                            class="badge badge-info">To Checked Out</span>
                          <span *ngIf="rental.isCheckedOut == true || rental.isChekedIn == false"
                            class="badge badge-warning">To Checked In</span>
                          <span *ngIf="rental.isChekedIn == true" class="badge badge-success">Done</span>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
              <!--Table Cancellations-->
              <div class="tab-pane fade table-responsive-xxl" id="cancellations" role="tabpanel"
                aria-labelledby="cancellations-tab">
                  <table class="table-hover bg-light" id="tableCancelled">
                    <thead class="sticky-top" [style.background]="backgroundColor" [style.color]="textColor">
                      <tr>
                        <th scope="col">Folio</th>
                        <th scope="col">Name</th>
                        <th scope="col">Day Start</th>
                        <th scope="col">Day End</th>
                        <th scope="col">Products</th>
                        <th scope="col">Cost</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngIf="rentalsCancelled.length == 0">
                        <tr>
                          <td colspan="8" class="text-center">
                            Theres not data to show
                          </td>
                        </tr>
                      </ng-container>
                      <ng-container *ngIf="rentalsCancelled.length > 0">
                        <tr *ngFor="let rental of rentalsCancelled">
                          <td name="Folio">
                            {{ rental.rentalNumber ? rental.rentalNumber : "0" }}
                          </td>
                          <td name="Name">{{ rental.userInfo.name + " " + rental.userInfo.lastName }}</td>
                          <td name="Day Start">{{ rental.dayStart }}</td>
                          <td name="Day End">{{ rental.dayEnd }}</td>
                          <td name="Products">
                            <span *ngFor="let r of rental.productsNames" class="badge badge-light text-dark">
                              {{ r }}
                            </span>
                          </td>
                          <td name="Cost">$ {{ rental.cost }} USD</td>
                          <td name="Phone">{{ rental.userInfo.phone }}</td>
                          <td name="Status">
                            <span class="badge badge-danger">Cancelled</span>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
              </div>
              <!--Table of Repairs-->
              <div class="tab-pane fade table-responsive-xxl" id="repairs" role="tabpanel"
                aria-labelledby="repairs-tab">
                  <table class="table-hover bg-light" id="tablerepairs">
                    <thead class="sticky-top" [style.background]="backgroundColor" [style.color]="textColor">
                      <tr>
                        <th scope="col">Folio</th>
                        <th scope="col">Product Name</th>
                        <th scope="col">Category</th>
                        <th scope="col">Maintenance</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngIf="repairs.length == 0">
                        <tr>
                          <td colspan="9" class="text-center">
                            Theres not data to show
                          </td>
                        </tr>
                      </ng-container>
                      <ng-container *ngIf="repairs.length > 0">
                        <tr *ngFor="let repair of repairs">
                          <td name="Folio">{{ repair.productNumber }}</td>
                          <td name="Product Name">{{ repair.productName }}</td>
                          <td name="Category">{{ repair.productCategory ? repair.productCategory : "-"}}</td>
                          <td name="Maintenance">
                            <span *ngIf="repair.isCleaning" class="badge badge-danger ms-1">Cleaning</span>
                            <span *ngIf="repair.isDamaged" class="badge badge-danger ms-1">Damaged</span>
                            <span *ngIf="repair.isTuneUp" class="badge badge-danger ms-1">Tune Up</span>
                          </td>
                          <td name="Status">
                            <span *ngIf="repair.isActive == true" class="badge badge-success">Active</span>
                            <span *ngIf="repair.isActive == false || repair.isRetired == true"
                              class="badge badge-danger">Inactive</span>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
