import {
  Component,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { RentalService } from 'src/app/services/rental.service';
import { CurrentUserService } from '../../services/current-user.service';
import Swal from 'sweetalert2';
import { Company } from 'src/app/models/storage/company.model';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
var moment = require('moment');
import { OrderProcessingService } from 'src/app/services/order-processing.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { Subscription } from 'rxjs';
import { ImageLibraryService } from 'src/app/services/image-library.service';
import { BookingService } from 'src/app/services/booking.service';
import { ValidatorService } from 'src/app/services/validator.service'
import { ProductLocationService } from 'src/app/services/product-location.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { DEFAULT_THEME } from 'src/app/utils/config/themeDefault.config';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
})
export class CompanyComponent implements OnInit, OnDestroy {
  companyForm: UntypedFormGroup;
  country = new UntypedFormControl({ value: 'US' }, [Validators.required]);
  state = new UntypedFormControl('');
  city = new UntypedFormControl({ value: null, disabled: true });
  addressF = new UntypedFormControl({ value: null, hidden: true }, [
    Validators.required,
  ]);

  // Image gallery
  imgInfo; // current image info
  initImage; // image info upon loading from db OR after saving a new img (utilized if user presses 'cancel' to reset to initImage)
  companyLogo;
  protected colorText: string = DEFAULT_THEME.fontColor;
  protected colorBackground: string = DEFAULT_THEME.value;

  // Rxjs
  subs = new Subscription(); // group of subscriptions

  hidePassword = true;
  isSendingEmail = false;

  constructor(
    private imageLibraryService: ImageLibraryService,
    private _rentalService: RentalService,
    private _currentUser: CurrentUserService,
    private fb: UntypedFormBuilder,
    private imageCompress: NgxImageCompressService,
    private _orderService: OrderProcessingService,
    private bookingService: BookingService,
    public _productLocation: ProductLocationService,
    private functions: AngularFireFunctions
  ) {

    const domainPattern = /^((?:([a-z0-9]\.|[a-z0-9][a-z0-9\-]{0,61}[a-z0-9])\.)+)([a-z0-9]{2,63}|(?:[a-z0-9][a-z0-9\-]{0,61}[a-z0-9]))\.?$/;

    this.companyForm = this.fb.group({
      companyName: ['', [Validators.required]],
      companyEmail: ['', [Validators.required]],
      stripeID: [''],
      stripeIDTest: [''],
      smtpHost: ['', Validators.pattern(domainPattern)],
      smtpPort: [25, Validators.pattern(/^\d+$/)],
      smtpUser: ['', Validators.email],
      bcc: ['', ValidatorService.csvEmails],
      mailTo: ['', Validators.email],
      smtpPassword: [''],
      companyTax: ['', [Validators.required]],
      fleetTax: ['', [Validators.required]],
      companyPhone: ['', [Validators.required]],
      companyImg: [''],
      companyWebsite: ['', [Validators.required, Validators.pattern(domainPattern)]],
      address1: ['', [Validators.required]],
      address2: [''],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      country: ['', [Validators.required]],
      zip: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9]+$'),
          Validators.min(1),
        ],
      ],
      isTesting: [false],
      isIgnoreUDays: [false],
    });
  }
  get f() {
    return this.companyForm.controls;
  }
  public company: Company;
  public submitted: boolean = false;
  public states: string[];
  public selectedstate: string;
  public cities: string[];
  public countries: any[];
  public selectedcountry: string;
  public companyresp;
  public isAdmin: boolean = false;
  public isDeveloper: boolean = false;
  ngOnInit(): void {
    this.initSubscriptions();
    this.getCompany();
  }

  togglePassword() {
    this.hidePassword = !this.hidePassword;
  }

  initSubscriptions() {
    // Handles getting imgData from the image-library component using the imageLib service (when the user selects an img)
    this.subs.add(
      this.imageLibraryService.selectedImg.subscribe((imgInfo) => {
        // subscription is used for unsubscribing hot observable
        console.log(imgInfo);
        this.companyLogo = imgInfo.url; // set croppedImg to selected img
        this.imgInfo = imgInfo;
        this.closeImgGallery(); // close imgGallery bootstrap modal
      })
    );
  }

  async getCompany() {
    this.company = await this._rentalService.getCompanyByID(
      this._currentUser.currentUser.companyId
    );

    this.companyLogo = this.company?.companyImg; // sets default img

    this.initImage = this.company?.companyImg; // sets default img in case of cancel

    this._currentUser.currentUser.isAdmin
      ? (this.isAdmin = true)
      : (this.isAdmin = false);
    this._currentUser.currentUser.isDeveloper
      ? (this.isDeveloper = true)
      : (this.isDeveloper = false);

    this.companyForm
      .get('address1')
      .setValue(this.company.companyAddress?.address1);
    this.companyForm
      .get('address2')
      .setValue(this.company.companyAddress?.address2);
    this.companyForm.get('city').setValue(this.company.companyAddress?.city);
    this.companyForm.get('zip').setValue(this.company.companyAddress?.zip);
    this.countries = await this._rentalService.getCountries();
    this.countries.sort((a, b) => a.name.localeCompare(b.name));
    if (this.company.companyAddress?.country) {
      this.states = await this._rentalService.getStatesByCountry(
        this.company.companyAddress.country
      );
      this.companyForm
        .get('country')
        .setValue(this.company.companyAddress.country);
      this.selectedcountry = this.company.companyAddress.country;
    } else {
      this.states = await this._rentalService.getStatesByCountry('US');
      this.companyForm.get('country').setValue('US');
      this.selectedcountry = 'US';
    }
    if (this.company.companyAddress?.state) {
      this.selectedstate = this.company.companyAddress.state;
      this.companyForm.get('state').setValue(this.selectedstate);
    }
    let noStates = [
      'AA',
      'AE',
      'AP',
      'AS',
      'FM',
      'GU',
      'MH',
      'MP',
      'PR',
      'PW',
      'VI',
    ];
    this.states = this.states.filter((state) => !noStates.includes(state));
    this.companyForm
      .get('companyWebsite')
      .setValue(this.company?.companyWebsite);

    this.companyForm.get('companyName').setValue(this.company?.companyName);
    this.companyForm.get('companyEmail').setValue(this.company?.companyEmail);
    this.companyForm.get('stripeID').setValue(this.company?.stripeID);
    this.companyForm.get('stripeIDTest').setValue(this.company?.stripeIDTest);
    this.companyForm.get('bcc').setValue(this.company?.emailNotification?.bcc);
    this.companyForm.get('smtpHost').setValue(this.company?.emailNotification?.smtpHost);
    this.companyForm.get('smtpPort').setValue(this.company?.emailNotification?.smtpPort || 25);
    this.companyForm.get('smtpPassword').setValue(this.company?.emailNotification?.smtpPassword);
    this.companyForm.get('smtpUser').setValue(this.company?.emailNotification?.smtpUser);
    this.companyForm.get('companyTax').setValue(this.company?.companyTax);
    this.companyForm.get('fleetTax').setValue(this.company?.fleetTax);
    this.companyForm.get('companyPhone').setValue(this.company?.companyPhone);
    this.companyForm.get('isTesting').setValue(!this.company?.isTesting);
    this.companyForm
      .get('isIgnoreUDays')
      .setValue(this.company?.isIgnoreUDays || false);

    console.log('getCompany company=', this.company);
    console.log('getCompany companyForm=', this.companyForm);
  }
  checkForm() {
    this.submitted = true;
    if (this.companyForm.valid) {
      Swal.fire({
        title: 'Loading...',
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
      });
      Swal.showLoading();
      this.saveCompany();
    }
    //this.productForm.controls.allowTypeGroup.get('price').value
    //this.productForm.controls.allowTypeGroup.get('price').setValue(product.price)
  }
  async saveCompany() {
    this.company.companyAddress = this.company.companyAddress || {};
    this.company['companyAddress'].address1 =
      this.companyForm.get('address1').value;
    this.company['companyAddress'].address2 = this.companyForm.get('address2')
      .value
      ? this.companyForm.get('address2').value
      : '';
    this.company['companyAddress'].city = this.companyForm.get('city').value;
    this.company['companyAddress'].state = this.companyForm.get('state').value;
    this.company['companyAddress'].country =
      this.companyForm.get('country').value;
    this.company['companyAddress'].zip = this.companyForm.get('zip').value;

    this.company.companyWebsite =
      this.companyForm.get('companyWebsite').value || '';

    this.company.companyName = this.companyForm.get('companyName').value;
    this.company.companyEmail = this.companyForm.get('companyEmail').value;
    this.company.stripeID = this.companyForm.get('stripeID').value
      ? this.companyForm.get('stripeID').value
      : '';
    this.company.stripeIDTest = this.companyForm.get('stripeIDTest').value
      ? this.companyForm.get('stripeIDTest').value
      : '';
    this.company.emailNotification = this.company.emailNotification || {};
    this.company['emailNotification'].smtpHost = this.companyForm.get('smtpHost').value || '';
    this.company['emailNotification'].smtpPort = this.companyForm.get('smtpPort').value || 25;
    this.company['emailNotification'].smtpPassword = this.companyForm.get('smtpPassword').value || '';
    this.company['emailNotification'].smtpUser = this.companyForm.get('smtpUser').value || '';
    this.company['emailNotification'].bcc = this.companyForm.get('bcc').value || '';
    this.company.companyTax = this.companyForm.get('companyTax').value;
    this.company.fleetTax = this.companyForm.get('fleetTax').value;
    this.company.companyPhone =
      this.companyForm.get('companyPhone').value || '';
    this.company.isTesting = !this.companyForm.get('isTesting').value;
    this.company.isIgnoreUDays =
      this.companyForm.get('isIgnoreUDays').value || false;

    if (this.initImage != this.companyLogo) {
      // if logo has been changed
      let oldCompanyLogo = await this.imageLibraryService.getCompanyLogo(); // search for previous company logo by field & companyID (returns id:undefined if no logo set initally)
      let newCompanyLogo = this.imgInfo; // the current company logo seen on component

      // Query for all templates and pass to update companyLogo
      let templates = await this.bookingService.getTemplatesForCompany()

      // set isCompanyLogo to false / true - old / new, update templates with new company logo
      await this.imageLibraryService.updateCompanyLogo(
        oldCompanyLogo,
        newCompanyLogo,
        templates
      ); // batch write to edit image logo status + templates

      this.company['companyImg'] = this.imgInfo.url; // update the value of companyImg before updating company collection
      this.initImage = this.imgInfo.url; // reset initImage in case user does the following: save -> edit -> cancel
    }

    await this._rentalService.updateAllCompany(this.company); // update company collection

    Swal.close();
    Swal.fire({
      title: 'Company Changes Saved',
      icon: 'success',
    });
  }
  public imgResultBeforeCompress: string = '';
  public imgResultAfterCompress: string = '';

  uploadCompanyImg() {
    this.imageCompress.uploadFile().then(({ image, orientation }) => {
      console.log(image);
      Swal.fire({
        title: 'Please wait',
        text: 'Saving Company image',
        allowOutsideClick: false,
        showConfirmButton: false,
        showCloseButton: false,
        showCancelButton: false,
      });
      Swal.showLoading();
      this.imageCompress
        .compressFile(image, orientation, 50, 50) // 50% ratio, 50% quality
        .then((compressedImage) => {
          this.imgResultAfterCompress = compressedImage;
          let imgname =
            'company' + moment(new Date()).format('hh-mm-ss').toString();
          this._orderService
            .uploadCompanyImg(
              this.company.id,
              this.imgResultAfterCompress,
              imgname
            )
            .then((imageUrl) => {
              console.log(imageUrl);
              this.company.companyImg = imageUrl;
              this.companyLogo = imageUrl;
              Swal.close();
              Swal.fire({
                title: 'Image Uploaded',
                html: 'Remember to save Changes',
                icon: 'success',
              });
            });
        });
    });
  }
  async setStates() {
    console.log(this.f.country.value);
    this.selectedcountry = this.f.country.value;
    console.log(this.selectedcountry);
    this.states = await this._rentalService.getStatesByCountry(
      this.selectedcountry
    );
  }
  async cancelChanges() {
    this.companyLogo = this.initImage; // reset companyLogo to inital state / db state - saved in initImage
    this.companyForm
      .get('address1')
      .setValue(this.company.companyAddress.address1);
    this.companyForm
      .get('address2')
      .setValue(this.company.companyAddress.address2);
    this.companyForm.get('city').setValue(this.company.companyAddress.city);
    this.companyForm.get('zip').setValue(this.company.companyAddress.zip);
    if (this.company.companyAddress.country) {
      this.states = await this._rentalService.getStatesByCountry(
        this.company.companyAddress.country
      );
      this.companyForm
        .get('country')
        .setValue(this.company.companyAddress.country);
      this.selectedcountry = this.company.companyAddress.country;
    } else {
      this.states = await this._rentalService.getStatesByCountry('US');
      this.companyForm.get('country').setValue('US');
      this.selectedcountry = 'US';
    }
    if (this.company.companyAddress.state) {
      this.selectedstate = this.company.companyAddress.state;
      this.companyForm.get('state').setValue(this.selectedstate);
    }
    console.log(this.countries);
    console.log(this.states);
    let noStates = [
      'AA',
      'AE',
      'AP',
      'AS',
      'FM',
      'GU',
      'MH',
      'MP',
      'PR',
      'PW',
      'VI',
    ];
    this.states = this.states.filter((state) => !noStates.includes(state));
    console.log(this.f.state.value);
    console.log(this.selectedcountry);
    console.log(this.selectedstate);

    this.companyForm
      .get('companyWebsite')
      .setValue(this.company.companyWebsite);

    this.companyForm.get('companyName').setValue(this.company.companyName);
    this.companyForm.get('companyEmail').setValue(this.company.companyEmail);
    this.companyForm.get('stripeID').setValue(this.company.stripeID);
    this.companyForm.get('smtpHost').setValue(this.company.emailNotification.smtpHost);
    this.companyForm.get('smtpPort').setValue(this.company.emailNotification.smtpPort || 25);
    this.companyForm.get('smtpPassword').setValue(this.company.emailNotification.smtpPassword);
    this.companyForm.get('smtpUser').setValue(this.company.emailNotification.smtpUser);
    this.companyForm.get('companyTax').setValue(this.company.companyTax);
    this.companyForm.get('fleetTax').setValue(this.company.fleetTax);
    this.companyForm.get('companyPhone').setValue(this.company.companyPhone);
    this.companyForm.get('isIgnoreUDays').setValue(this.company.isIgnoreUDays);
    console.log(this.company);
  }
  setTesting() {
    console.log(!this.f.isTesting.value);
  }
  setIgnoreUDays(e) {
    if (e.target.checked) {
      Swal.fire({
        icon: 'warning',
        title:
          "Enabling this will make all calendar days available for rental including days you've specified as closed.  Are you sure you want to enable this?",
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          this.checkForm();
        } else {
          e.target.checked = false;
          this.companyForm.controls['isIgnoreUDays'].setValue(false);

          return;
        }
      });
    } else {
      Swal.fire({
        icon: 'warning',
        title:
          "Disabling this will make calendar days you've specified as closed become unavailable for in-house rentals.  Are you sure you want to disable this?",
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          this.checkForm();
        } else {
          e.target.checked = true;
          this.companyForm.controls['isIgnoreUDays'].setValue(true);
          return;
        }
      });
    }
  }
  setStripeAccount() {
    console.log('Stripe Account');
  }
  setStripeTestAccount() {
    console.log('Stripe Test Account');
  }
  openImgGallery() {
    $('#imgGallery').modal('show');
  }

  closeImgGallery() {
    $('#imgGallery').modal('hide');
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  async addCompanyLocation() {
    console.log(this.company);
    /**
     * * get the default location
     * */
    const defaultLocation =
      await this._productLocation.getCompanyLocationDefaultByID(
        this.company.defaultLocation
      );

    console.log(defaultLocation);

    /**
     * * get all the company products
     * */
    this._productLocation.getAllProducts(this.company.id).then((data) => {
      console.log(data);

      const Location = {
        productLocationID: defaultLocation.id,
        productLocationName: defaultLocation.name,
      };

      /**
       * * company's product array run, in order to update default location
       * */
      data.forEach(async (product) => {
        const editProduct: any = await this._productLocation.editProducts(
          product.id,
          Location
        );

        const promises = [editProduct];

        Promise.all(promises)
          .then((resp) => {
            console.log('Resp -->', resp);
          })
          .catch((reason) => console.log('Reason -->', reason));
      });
    });
  }

  sendEmail() {
    this.isSendingEmail = true;
    const smtpData = {
      smtpHost: this.companyForm.get('smtpHost').value,
      smtpPort: this.companyForm.get('smtpPort').value,
      smtpUser: this.companyForm.get('smtpUser').value,
      smtpPassword: this.companyForm.get('smtpPassword').value,
      mailTo: this.companyForm.get('mailTo').value,
    };

    console.log('smtpData', smtpData);


    const callable = this.functions.httpsCallable('sendEmailTest');
    callable(smtpData)
      .subscribe((res) => {
        console.log('test email response', res);
        this.isSendingEmail = false;
        if (res === 'success') {
          Swal.fire({
            title: 'Successful authentication',
            icon: 'success',
          });
        } else {
          Swal.fire({
            title: 'Failed authentication',
            text: `Please check your SMTP settings`,
            icon: 'error',
          });
        }
      });
  }

}
