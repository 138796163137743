<!--Booking Reports Title-->
<div class="container-fluid">
  <div class="row justify-content-center mt-2">
    <div class="col-md-12 text-left">
      <h1 class="mt-2 mb-2" style="font-size: 32px">Revenue Report</h1>
    </div>
  </div>
</div>
<!--Table-->
<div class="container mw-100">
  <div class="container-fluid mt-4 text-left">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb bg-white">
        <li class="breadcrumb-item stylecursor text-primary" *ngIf="breadlocation" (click)="changeData('Locations')">
          Locations <span class="text-secondary"
            *ngIf="this.locationID != undefined && this.locationID != ''">({{this.locationName}})</span>
        </li>
        <li disabled="true" class="breadcrumb-item stylecursor text-primary" *ngIf="breadGroups"
          (click)="changeData('Groups')">
          Product Groups <span class="text-secondary"
            *ngIf="this.groupID != undefined && this.groupID != ''">({{this.groupName}})</span>
        </li>
        <li disabled="true" class="breadcrumb-item stylecursor text-primary" *ngIf="breadSizeType"
          (click)="changeData('sizeType')">
          Size Type <span class="text-secondary"
            *ngIf="this.sizeTypeID != undefined && this.sizeTypeID != ''">({{this.sizeTypeName}})</span>
        </li>
        <li disabled="true" class="breadcrumb-item stylecursor text-primary" *ngIf="breadSize"
          (click)="changeData('size')">
          Size <span class="text-secondary"
            *ngIf="this.sizeID != undefined && this.sizeID != ''">({{this.sizeName}})</span>
        </li>
      </ol>
    </nav>
  </div>
  <div class="container-fluid mt-4 mb-3 tableheight">
    <table class="table text-center table-borderless table-hover">
      <thead [style.background]="colorTheme" [style.color]="colorText">
        <tr>
          <th>Type</th>
          <th>Last 30 Days</th>
          <th>1-3 Days Out</th>
          <th>4-10 Days Out</th>
          <th>11-30 Days Out</th>
          <th>31-60 Days Out</th>
          <th>61-99 Days Out</th>
          <th>100-150 Days Out</th>
        </tr>
      </thead>
      <tbody *ngIf="isLoadingData">
        <tr id="row">
          <td colspan="8" class="text-center">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading Data...</span>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="locationProducts.length == 0 && !isLoadingData">
        <tr id="row">
          <td colspan="8" class="text-center">There's not data to show</td>
        </tr>
      </tbody>
      <ng-container *ngIf="isLocation && !isLoadingData">
        <ng-container *ngIf="locationProducts.length == 0">
          <tr>
            <td colspan="8 text-center">
              There's not data to show.
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="locationProducts.length > 0">
          <tbody *ngFor="let location of locationProducts; let i = index">
            <tr id="row" (click)="changeData('Groups', location)">
              <td class="text-start">
                <!--Active, clic to hide register-->
                <i *ngIf="location.visible" class="fas fa-square" [style.color]="location.color"
                  (click)="hideOneLine('location',i, $event)"></i>
                <i *ngIf="!location.visible" class="far fa-square text-secondary"
                  (click)="showOneLine('location',i, $event)"></i>
                {{ location.locationName }}
              </td>
              <td>{{ location.last30daysPercentage }} %</td>
              <td>{{ location.daysout1to3Percentage }} %</td>
              <td>{{ location.daysout4to10Percentage }} %</td>
              <td>{{ location.daysout11to30Percentage }} %</td>
              <td>{{ location.daysout31to60Percentage }} %</td>
              <td>{{ location.daysout61to99Percentage }} %</td>
              <td>{{ location.daysout100to150Percentage }} %</td>
            </tr>
          </tbody>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="isGroup && !isLoadingData">
        <ng-container *ngIf="groupsProducts.length == 0">
          <tr>
            <td colspan="8 text-center">
              There's not data to show.
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="groupsProducts.length > 0">
          <tbody *ngFor="let group of groupsProducts; let i = index">
            <tr id="row" (click)="changeData('sizeType', group)">
              <td class="text-start">
                <!--Active, clic to hide register-->
                <i *ngIf="group.visible" class="fas fa-square" [style.color]="group.color"
                  (click)="hideOneLine('group',i, $event)"></i>
                <i *ngIf="!group.visible" class="far fa-square text-secondary"
                  (click)="showOneLine('group',i, $event)"></i>
                {{ group.groupName }}
              </td>
              <td>{{ group.last30daysPercentage }} %</td>
              <td>{{ group.daysout1to3Percentage }} %</td>
              <td>{{ group.daysout4to10Percentage }} %</td>
              <td>{{ group.daysout11to30Percentage }} %</td>
              <td>{{ group.daysout31to60Percentage }} %</td>
              <td>{{ group.daysout61to99Percentage }} %</td>
              <td>{{ group.daysout100to150Percentage }} %</td>
            </tr>
          </tbody>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="isSizeType && !isLoadingData">
        <ng-container *ngIf="sizeTypesProducts.length == 0">
          <tr>
            <td colspan="8 text-center">
              There's not data to show.
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="sizeTypesProducts.length > 0">
          <tbody *ngFor="let sizeType of sizeTypesProducts; let i = index">
            <tr id="row" (click)="changeData('size', sizeType)">
              <td class="text-start">
                <!--Active, clic to hide register-->
                <i *ngIf="sizeType.visible" class="fas fa-square" [style.color]="sizeType.color"
                  (click)="hideOneLine('sizeType',i, $event)"></i>
                <i *ngIf="!sizeType.visible" class="far fa-square text-secondary"
                  (click)="showOneLine('sizeType',i, $event)"></i>
                {{ sizeType.sizeType }}
              </td>
              <td>{{ sizeType.last30daysPercentage }} %</td>
              <td>{{ sizeType.daysout1to3Percentage }} %</td>
              <td>{{ sizeType.daysout4to10Percentage }} %</td>
              <td>{{ sizeType.daysout11to30Percentage }} %</td>
              <td>{{ sizeType.daysout31to60Percentage }} %</td>
              <td>{{ sizeType.daysout61to99Percentage }} %</td>
              <td>{{ sizeType.daysout100to150Percentage }} %</td>
            </tr>
          </tbody>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="isSize && !isLoadingData">
        <ng-container *ngIf="sizesProducts.length == 0">
          <tr>
            <td colspan="8 text-center">
              There's not data to show.
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="sizesProducts.length > 0">
          <tbody *ngFor="let size of sizesProducts; let i = index">
            <tr id="row" (click)="changeData('product', size)">
              <td class="text-start">
                <!--Active, clic to hide register-->
                <i *ngIf="size.visible" class="fas fa-square" [style.color]="size.color"
                  (click)="hideOneLine('size',i, $event)"></i>
                <i *ngIf="!size.visible" class="far fa-square text-secondary"
                  (click)="showOneLine('size',i, $event)"></i>
                {{ size.sizeName }}
              </td>
              <td>{{ size.last30daysPercentage }} %</td>
              <td>{{ size.daysout1to3Percentage }} %</td>
              <td>{{ size.daysout4to10Percentage }} %</td>
              <td>{{ size.daysout11to30Percentage }} %</td>
              <td>{{ size.daysout31to60Percentage }} %</td>
              <td>{{ size.daysout61to99Percentage }} %</td>
              <td>{{ size.daysout100to150Percentage }} %</td>
            </tr>
          </tbody>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="isProduct && !isLoadingData">
        <ng-container *ngIf="products.length == 0">
          <tr>
            <td colspan="8 text-center">
              There's not data to show.
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="products.length > 0">
          <tbody *ngFor="let product of products; let i = index">
            <tr id="row">
              <td class="text-start">
                <!--Active, clic to hide register-->
                <i *ngIf="product.visible" class="fas fa-square" [style.color]="product.color"
                  (click)="hideOneLine('product',i, $event)"></i>
                <i *ngIf="!product.visible" class="far fa-square text-secondary"
                  (click)="showOneLine('product',i, $event)"></i>
                {{ product.productName }}
              </td>
              <td>{{ product.last30daysPercentage }} %</td>
              <td>{{ product.daysout1to3Percentage }} %</td>
              <td>{{ product.daysout4to10Percentage }} %</td>
              <td>{{ product.daysout11to30Percentage }} %</td>
              <td>{{ product.daysout31to60Percentage }} %</td>
              <td>{{ product.daysout61to99Percentage }} %</td>
              <td>{{ product.daysout100to150Percentage }} %</td>
            </tr>
          </tbody>
        </ng-container>
      </ng-container>
    </table>
  </div>
</div>
<div class="container p-4 mw-100">
  <canvas class="chartcanvas" baseChart width="400" height="90" [data]="lineSalesData" [options]="lineChartOptions"
    [type]="lineChartType"></canvas>
</div>
