import { Component, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { ProductsService } from 'src/app/services/products.service';
import { Categories } from 'src/app/models/storage/categories.model';
import { ImageCroppedEvent, base64ToFile } from 'ngx-image-cropper';
import { AngularFireStorage, AngularFireUploadTask, } from '@angular/fire/compat/storage';
import imageSize from '@coderosh/image-size';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import Swal from 'sweetalert2';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { UntypedFormControl, UntypedFormGroup, Validators, } from '@angular/forms';
import { LogService } from 'src/app/services/log.service';
import { ImageLibrary } from 'src/app/models/image-library.model';
import { ImageLibraryService } from 'src/app/services/image-library.service';
import { DEFAULT_THEME } from 'src/app/utils/config/themeDefault.config';

@Component({
    selector: 'app-categories',
    templateUrl: './categories.component.html',
    styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent {
    //Rxjs
    selectedImgSub: Subscription;
    protected colorBackground: string = DEFAULT_THEME.value;
    protected colorText: string = DEFAULT_THEME.fontColor;
    categoryInfo: UntypedFormGroup;
    optionName: any;
    categoryImages: ImageLibrary[] = [];
    images: ImageLibrary[] = [];

    constructor(
        protected productsService: ProductsService,
        private _currentUser: CurrentUserService,
        public toastr: ToastrService,
        private storage: AngularFireStorage,
        private afs: AngularFirestore,
        private _logService: LogService,
        private _imageLib: ImageLibraryService,
    ) {
        this.categoryInfo = new UntypedFormGroup({
            title: new UntypedFormControl('', [Validators.required]),
            description: new UntypedFormControl(''),
            addons: new UntypedFormControl(''),
        });
    }

    get f() {
        return this.categoryInfo.controls;
    }

    public submitted: boolean;
    // Product Elements
    public user: any;
    //public earlyBirdDiscount = 0

    public categories: Categories[];
    public loadingCategories = true;
    public hasFinishedCategories = false;
    public loadingMoreCategories = false;
    public isAlertClosed = false;

    public categoryOptionTitle: string;
    public selectedAddons: any = {};
    // Alerts
    isButtonAlert = false;

    public category: any[] = [];

    // image uploader
    public task: AngularFireUploadTask;
    public image: any = {};
    public imageChangedEvent: any = '';
    public croppedImage: any = '';
    public originalImage;
    public originalImageName;
    public disabledbutton = false;
    public isEditing = false;
    public editCategoryID = '';
    public categoryResp: any
    public blobToFile = (theBlob: Blob, fileName: string): File => {
        var b: any = theBlob;
        //A Blob() is almost a File() - it's just missing the two properties below which we will add
        b.lastModifiedDate = new Date();
        b.name = fileName;
        //Cast to a File() type
        return <File>theBlob;
    };
    private _croppedFile: Blob;

    ngOnInit(): void {
        this.user = this._currentUser.currentUser;
        this.categories = [];
        this.getCategories(true);
        this.getCompanyInfo();

        this.selectedImgSub = this._imageLib.selectedImg.subscribe((imgInfo) => {
            // subscription is used for unsubscribing hot observable
            this.croppedImage = imgInfo.url; // set croppedImg to selected img
            this.closeImgGallery(); // close imgGallery bootstrap modal
        });
    }
    updateAllCategories() {
        Swal.fire({
            title: "Updating..."
        })
        Swal.showLoading();
        this.productsService.getAllCategoriesByPromise().then(categories => {
            console.log(categories)
            let promises = [];
            categories.forEach(category => {
                category['isRebuild'] = false;
                //promises.push(this.productsService.updateCategory(category.id, category));
            })
            Promise.all(promises).then(() => {
                Swal.close();
            })
        });
    }

    ngOnDestroy(): void {
        this.selectedImgSub.unsubscribe(); // unsubscribes from subscription upon component deconstruction
    }

    openImgGallery() {
        //opens ImgGallery bootstrap modal
        $('#imgGallery').modal('show');
    }

    closeImgGallery() {
        // closes imgGallery bootstrap modal
        $('#imgGallery').modal('hide');
    }

    selectImage(img) {
        this.croppedImage = img.url;
    }

    selectAddons(addon) {
        if (
            this.selectedAddons[addon.id] === false ||
            this.selectedAddons[addon.id] === undefined
        ) {
            this.selectedAddons[addon.id] = true;
        } else {
            this.selectedAddons[addon.id] = false;
        }
    }
    getCategories(reset?: boolean) {
        if (reset) {
            this.loadingMoreCategories = false;
            this.hasFinishedCategories = false;
        }
        this.categories = [];
        if (this.loadingMoreCategories || this.hasFinishedCategories) return;
        this.loadingMoreCategories = true;
        this.productsService
            .getCompanyCategoriesWithScrollRebuild(reset)
            .subscribe((categories) => {
                this.categories = [];
                if (categories.length < 25) {
                    this.hasFinishedCategories = true;
                }
                categories.forEach((category) => {
                    this.categories.push(category);
                    this.categories.sort((a, b) => {
                        if (a.sortOrder > b.sortOrder) {
                            return 1;
                        } else {
                            return -1;
                        }
                    });
                    this.loadingCategories = false;
                    this.loadingMoreCategories = false;
                });
            });
    }

    @HostListener('window:scroll', ['$event']) // for window scroll events
    onScroll(event: any) {
        let el = event.srcElement.scrollingElement;
        if (el.scrollTop + el.offsetHeight - el.scrollHeight > -30) {
            //this.getCategories()
        }
    }
    resetFormInput() {
        this.croppedImage = undefined;
        this.imageChangedEvent = undefined;
        this._croppedFile = undefined;
        this.categoryInfo.controls['title'].setValue('');
        this.categoryInfo.controls['description'].setValue('');
    }

    openNewCategoryModal(edit: boolean, category?: any) {
        this.categoryResp = category;
        this.categoryInfo.reset();
        this.reset();
        console.log(edit);
        console.log(category);
        if (edit === true) {
            this.editCategoryID = category.id;
            this.isEditing = true;
            this.categoryOptionTitle = category.optionName;
            this.categoryInfo.controls['title'].setValue(category.title);
            this.categoryInfo.controls['description'].setValue(category.description);

            if (category.imageUrl !== undefined) {
                this.croppedImage = category.imageUrl;
            }
        }
    }

    reset() {
        this.resetFormInput();
        this.categoryInfo.reset();
        this.isEditing = false;
        this.submitted = false;
        this.categoryOptionTitle = '';
        this.selectedAddons = {};
    }
    validateForm() {
        this.submitted = true;
        if (this.categoryInfo.valid) {
            this.disabledbutton = true;
            this.updateCategory();
        }
    }

    async fileChangeEvent(target: any) {
        let originalImage = target.target.files as FileList;
        this.originalImageName = originalImage[0].name;
        var tmppath = URL.createObjectURL(originalImage[0]);
        const size = await imageSize(tmppath);
        if (size.width < 960 || size.height < 540) {
            this.isButtonAlert = true;
            this.toastr.error(
                'The minimum size for the width and height settings is: "960 x 540"  ',
                'Empty Field:',
                { positionClass: 'toast-top-right' }
            );
        } else {
            this.isButtonAlert = false;
            alert('Please Crop Image.');
            $('#categoryCrop').modal('show');
        }
        if (this.isButtonAlert === false) {
            this.imageChangedEvent = target;
        }
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this._croppedFile = base64ToFile(this.croppedImage);
    }
    async imageLoaded() {
        // show cropper
    }

    cropperReady() {
        // cropper ready
    }
    loadImageFailed() {
        // show message
    }
    getCompanyInfo() {
        this._currentUser.getCompanies().subscribe((data) => {
            data.forEach((element: any) => {
                if (this._currentUser.currentUser.companyId == element.payload.doc.id) {
                    this.optionName = element.payload.doc.data().optionName;
                }
            });
        });
    }

    deleteCategory() {
        this.category = [];
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-primary ml-2',
                cancelButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
        });
        swalWithBootstrapButtons
            .fire({
                title: 'Are you sure you want to delete this Category?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it',
                cancelButtonText: 'Cancel',
                reverseButtons: true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.productsService
                        .searchDeleteCategories(this.editCategoryID)
                        .then((category) => {
                            this.category = category;
                            let s = category.length > 0 ? 's' : '';
                            if (category.length > 0) {
                                swalWithBootstrapButtons.fire(
                                    'Error!',
                                    'This category is being used in ' +
                                    category.length +
                                    ' product${s}, change it and try later',
                                    'error'
                                );
                            } else {
                                this.productsService.deleteCategory(this.editCategoryID);
                                swalWithBootstrapButtons.fire(
                                    'Deleted!',
                                    'The Category has been deleted.',
                                    'success'
                                );
                                $('#newCategoryModal').modal('hide');
                                this.getCategories(true);
                            }
                        });
                } else {
                    swalWithBootstrapButtons.fire(
                        'Cancelled',
                        'The Category has not been eliminated',
                        'error'
                    );
                }
            });
    }

    updateCategory() {
        let addonsIDarray = [];
        Object.keys(this.selectedAddons).forEach((element) => {
            if (this.selectedAddons[element] == true) {
                addonsIDarray.push(element);
            }
        });
        let obj: any = {
            companyID: this.user.companyId,
            companyName: this.user.company,
            description: this.categoryInfo.value.description,
            isAvailable: true,
            title: this.categoryInfo.value.title,
            optionName: this.categoryOptionTitle,
            addonsIDS: addonsIDarray,
            isRebuild: this.categoryResp?.isRebuild != undefined ? this.categoryResp.isRebuild : true
        };
        this.getImage()
            .then((url) => {
                obj.imageUrl = url;
                this.editCategory(obj);
            })
            .catch((error) => {
                this.editCategory(obj);
            });
    }
    editCategory(obj) {
        let isEdit: boolean = false;
        if (this.isEditing === true) {
            this.productsService.updateCategory(this.editCategoryID, obj);
            this._logService.addCategoryLog(this.editCategoryID, 'Category Updated');
            this.disabledbutton = false;
            isEdit = true;
        } else {
            obj.sortOrder = this.categories.length + 1;
            this.productsService.saveNewCategory(obj);
            this.disabledbutton = false;
            isEdit = false;
        }
        let title = isEdit ? 'Category Updated' : 'Category Created';
        let html = isEdit
            ? 'The category has been updated'
            : 'The new category has been created';
        Swal.fire({
            title: title,
            html: html,
            icon: 'success',
            allowOutsideClick: false,
            showConfirmButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                this.disabledbutton = false;
                $('#newCategoryModal').modal('hide');
            }
        });
    }
    getImage() {
        return new Promise((resolve, reject) => {
            if (
                this.croppedImage != undefined &&
                this.croppedImage != null &&
                this.croppedImage != ''
            ) {
                resolve(this.croppedImage);
                return;
            }

            if (this._croppedFile != undefined) {
                let pathOptionImage =
                    'rentalCategories/' +
                    this.categoryInfo.value.title +
                    '/' +
                    this.originalImageName;
                const fileRef = this.storage.ref(pathOptionImage);
                this.task = this.storage.upload(pathOptionImage, this._croppedFile);
                this.task
                    .then(() => {
                        fileRef.getDownloadURL().subscribe((url) => {
                            resolve(url);
                        });
                    })
                    .catch((error) => { });
                this.storage.upload(pathOptionImage, this._croppedFile);
            } else {
                reject('no Image');
            }
        });
    }


    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.categories, event.previousIndex, event.currentIndex);
        this.categories.forEach((category) => {
            let index = 0;
            index = this.categories.indexOf(category);
            let obj = {
                sortOrder: index,
            };
            this.productsService.updateCategory(category.id, obj);
        });
    }

    closeAlert() {
        this.isAlertClosed = true;
    }
    openModalOrderCategories() {
        $('#categoriesOrder').modal('show');
    }
    cleanUrl(url) {
        if (url) {
            url = url.replace(/\(/g, '%28');
            url = url.replace(/\)/g, '%29');
            return url.replace(/'/g, '%27');
        } else {
            return url;
        }
    }
}
