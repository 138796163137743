export const DEFAULT_THEME = {
  fontColor: "#FFFFFF",
  logo: "../../assets/images/logos/theme2.png",
  name: "Default Theme",
  value: "#114463",
  getColorBySeedNumber: function(seedNumber: number): string {
    switch (true) {
      case (seedNumber < 0.05):
      return '#114463';
      case (seedNumber < 0.1):
      return '#0d4b70';
      case (seedNumber < 0.15):
      return '#045585';
      case (seedNumber < 0.2):
      return '#036ead';
      case (seedNumber < 0.25):
      return '#1a79b0';
      case (seedNumber < 0.3):
      return '#2874a1';
      case (seedNumber < 0.35):
      return '#367396';
      case (seedNumber < 0.4):
      return '#478cb5';
      case (seedNumber < 0.45):
      return '#538cad';
      case (seedNumber < 0.5):
      return '#608ca6';
      case (seedNumber < 0.55):
      return '#71add1';
      case (seedNumber < 0.6):
      return '#7dc0e8';
      case (seedNumber < 0.65):
      return '#11405c';
      case (seedNumber < 0.7):
      return '#387aa1';
      case (seedNumber < 0.75):
      return '#002033';
      case (seedNumber < 0.8):
      return '#114869';
      case (seedNumber < 0.85):
      return '#8f9dbf';
      case (seedNumber < 0.9):
      return '#052166';
      case (seedNumber < 0.95):
      return '#98b4fa';
      default:
      return '#7a96de';
    }
  },
  getColorVariant: function(): string {
    const seedNumber = Math.random();
    return this.getColorBySeedNumber(seedNumber);
  }
};
