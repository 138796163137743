import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartEvent, ChartType } from 'chart.js';
import * as moment from 'moment';
import { BaseChartDirective } from 'ng2-charts';
import { ReportService } from 'src/app/services/report.service';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { DEFAULT_THEME } from 'src/app/utils/config/themeDefault.config';
interface Booked {
  //Interface to save the booke product
  name: string;
  total: number;
  year: number;
  month: number;
}

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit {
  constructor(
    protected _reportService: ReportService,
    private changeDetectorRef: ChangeDetectorRef,
    private _CurrentUser: CurrentUserService
  ) { }

  protected textColor: string = DEFAULT_THEME.fontColor;
  protected backgroundColor: string = DEFAULT_THEME.value;

  public datenow: any = moment().format('LL');

  rentals: any[] = []; //Array to save the rentals
  rentalsCancelled: any[] = []; //Array to saved rentals Cancelled
  sales: any[] = []; //Array to save the data from sales
  product: any[] = []; //Array to save the data from products
  producttm: any[][] = []; //Array to save the total of products from this month
  productlm: any[][] = []; //Array to save the total of products from the last month
  productthismonth: any[][] = [[0], [0]]; //Array to save the most product booked and the total in this month
  productlastmonth: any[][] = [[0], [0]]; //Array to save the most product booked and the total in the las month
  graphicRental: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; //Array aux to save the total of rentals by 12 months
  sellsThisMonth: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; //Array to save the sales by 12 months
  cancelled: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; //Array to save the total of bookeds cancelled in 12 months
  repairs: any[] = []; //Array to save the repairs
  graphicdatabooked: Booked[][] = [
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ]; //Variable to save most booked product
  bookeddata: any[] = []; //Variable aux
  booked: Booked[] = []; //Variable aux
  auxarraybooked: any[] = []; //Variable aux
  totalmostbooked: any[][] = []; //Variable aux
  monthbooked: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; //Variable aux
  yearbooked: number[] = []; //Variable aux
  textbooked: string[] = []; //Variable aux
  totalrentals: number; //Variable to save the number of total rentals
  totalrentalstm: number; //Variable to save the number of total rentals in this month
  totalrentalslm: number; //Variable to save the number of total rentals in the last month
  totalsalestm: number; //Variable to save the number of sales in this month
  totalsaleslm: number; //Variable to save the number of sales in the last month
  tcancelledtm: number; //Variable to save the number of cancelled in this month
  tcancelledlm: number; //Variable to save the number of cancelled in the last month
  trepairs: number; //Variable to save the number of repairs
  isEnabled: boolean; //Variable to check if one checkbox is check or not
  public startMonth: any = moment()
    .clone()
    .startOf('month')
    .format('YYYY-MM-DD'); //Variable to save the first date from the actual month
  public endMonth: any = moment().clone().endOf('month').format('YYYY-MM-DD'); //Variable to save the last date from the actual month
  public actualmonth: any = moment().format('M'); //Variable to save the number from the actual month
  public startLastMonth: any = moment()
    .add(-1, 'M')
    .clone()
    .startOf('month')
    .format('YYYY-MM-DD'); //Variable to save the first date from the last month
  public endLastMonth: any = moment()
    .add(-1, 'M')
    .clone()
    .endOf('month')
    .format('YYYY-MM-DD'); //Variable to save the last date from the last month
  public variableMonth: any; //Auxiliary variable to save the day of month in the registers
  public linesBookedData: ChartConfiguration['data'] = {
    datasets: [],
    labels: [],
  };
  countArr: any[] = []; //Array to save the number of registers in each month

  ngOnInit(): void {

    this.getRentals(); //Call Method to get the rentals
    this.getRepairs(); //Call Method to get the repairs
    this.getSales(); //Call Method to get the sales
    this.getSalesData(); //Call Method to get the sales data
    this.getCancelled(); //Call Method to get the repairs
    this.getBookedProduct();
  }

  Checked() {
    //Method to check if a checkbox is true
    let element = document.getElementById('todaycheck') as HTMLInputElement; //Get the element from HTML
    if (element.checked) {
      //Condition to check
      this.isEnabled = true; //change the value
    } else {
      this.isEnabled = false; //change the value
    }
  }

  getRepairs() {
    //Method to obtain all repairs
    this._reportService.getProductsRepairs().subscribe((data) => {
      //Data from service
      this.trepairs = 0;
      this.repairs = [];
      this.changeDetectorRef.detectChanges(); //Change detector
      data.forEach((element: any) => {
        //For to obtain each element
        this.trepairs += 1;
        this.repairs.push(element);
      });
      this.getGraphics(); //Call getGraphics
      this.chart.update(); //Update chart
    });
  }

  getRentals() {
    this._reportService.getRentals().subscribe((data) => {
      data.sort((a, b) => new Date(b.created.seconds * 1000).getTime() - new Date(a.created.seconds * 1000).getTime());
      this.initializeVariables();
      this.totalrentals = data.length;
      data.forEach((rental: any) => {
        this.processRental(rental);
      });
      this.updateMostBookedProducts();
      this.getGraphics();
      this.chart.update();
    });
  }

  initializeVariables() {
    this.totalrentals = 0;
    this.rentals = [];
    this.totalrentalstm = 0;
    this.totalrentalslm = 0;
    this.totalsalestm = 0;
    this.totalsaleslm = 0;
    this.productthismonth = [[0], [0]];
    this.productlastmonth = [[0], [0]];
    this.producttm = [];
    this.productlm = [];
    this.graphicRental = Array(12).fill(0);
    this.changeDetectorRef.detectChanges();
  }

  processRental(rental) {
    if (rental.isCancelled) return;
    const dayStart = this.formatDate(rental.dayStart.seconds, rental.rentalType);
    const dayEnd = this.formatDate(rental.dayEnd.seconds, rental.rentalType);
    this.rentals.push({ ...rental, dayStart, dayEnd });
    this.updateMonthlyData(rental);
  }

  formatDate(seconds, rentalType) {
    const format = rentalType === 'byHour' ? 'MMM DD YYYY, h:mm a' : 'MMM DD, YYYY';
    return moment(seconds * 1000).format(format);
  }

  updateMonthlyData(rental) {
    const month = moment(rental.dayStart.seconds * 1000).month();
    this.graphicRental[month]++;
    if (this.isThisMonth(rental.dayStart.seconds)) {
      this.totalrentalstm++;
      this.totalsalestm += Math.round(rental.cost);
      this.producttm.push(...rental.productsNames);
    } else if (this.isLastMonth(rental.dayStart.seconds)) {
      this.totalrentalslm++;
      this.totalsaleslm += Math.round(rental.cost);
      this.productlm.push(...rental.productsNames);
    }
  }

  isThisMonth(seconds) {
    const date = moment(seconds * 1000).format('YYYY-MM-DD');
    return date >= this.startMonth && date <= this.endMonth;
  }

  isLastMonth(seconds) {
    const date = moment(seconds * 1000).format('YYYY-MM-DD');
    return date >= this.startLastMonth && date <= this.endLastMonth;
  }

  updateMostBookedProducts() {
    this.countArr = this.getCount(this.producttm);
    this.productthismonth[0] = this.getMostRepeated(this.producttm);
    this.productlastmonth[0] = this.getMostRepeated(this.productlm);
  }

  getMostRepeated(array) {
    return array.reduce((accumulator, element) => {
      const count = array.filter(e => e === element).length;
      return count > accumulator[1] ? [element, count] : accumulator;
    }, ['', 0]);
  }
  getCount(arr) {
    let newArr = [];

    for (var i = 0; i < arr.length; i++) {
      if (newArr.length == 0) {
        newArr.push([arr[i], 1]);
      } else {
        let found = false;
        for (var j = 0; j < newArr.length; j++) {
          if (newArr[j][0] == arr[i]) {
            newArr[j][1]++;
            found = true;
          }
        }
        if (!found) {
          newArr.push([arr[i], 1]);
        }
      }
    }

    // ordena el arreglo de mayor a menor
    newArr.sort(function (a, b) {
      return b[1] - a[1];
    });
    // limita a 10
    newArr = newArr.slice(0, 10);

    return newArr;
  }

  getSales() {
    this._reportService.getRentals().subscribe((data) => {
      data.sort((a, b) => new Date(b.created.seconds * 1000).getTime() - new Date(a.created.seconds * 1000).getTime());
      this.initializeSalesVariables();
      data.forEach((sale: any) => {
        this.processSale(sale);
      });
      this.getGraphics();
      this.chart.update();
    });
  }

  initializeSalesVariables() {
    this.sellsThisMonth = Array(12).fill(0);
    this.changeDetectorRef.detectChanges();
  }

  processSale(sale) {
    const month = this.getMonthOfSale(sale.dayStart.seconds);
    this.sellsThisMonth[month] += sale.cost > 0 ? sale.cost : 0;
  }

  getMonthOfSale(seconds) {
    return moment(seconds * 1000).month();
  }


  getCancelled() {
    this._reportService.getRentals().subscribe((data) => {
      data.sort((a, b) => new Date(b.created.seconds * 1000).getTime() - new Date(a.created.seconds * 1000).getTime());
      this.initializeCancelledVariables();
      data.forEach((rental: any) => {
        this.processCancelledRental(rental);
      });
      this.getGraphics();
      this.chart.update();
    });
  }

  initializeCancelledVariables() {
    this.rentalsCancelled = [];
    this.tcancelledtm = 0;
    this.tcancelledlm = 0;
    this.cancelled = Array(12).fill(0);
    this.changeDetectorRef.detectChanges();
  }

  processCancelledRental(rental) {
    if (!rental.isCancelled) return;
    const dayStart = this.formatDate(rental.dayStart.seconds, rental.rentalType);
    const dayEnd = this.formatDate(rental.dayEnd.seconds, rental.rentalType);
    this.rentalsCancelled.push({ ...rental, dayStart, dayEnd });
    this.updateCancelledMonthlyData(rental);
  }

  updateCancelledMonthlyData(rental) {
    const month = moment(rental.dayStart.seconds * 1000).month();
    this.cancelled[month]++;
    if (this.isThisMonth(rental.dayStart.seconds)) {
      this.tcancelledtm++;
    } else if (this.isLastMonth(rental.dayStart.seconds)) {
      this.tcancelledlm++;
    }
  }

  getSalesData() {
    this._reportService.getSells().subscribe((data) => {
      data.sort((a, b) => new Date(b.date.seconds * 1000).getTime() - new Date(a.date.seconds * 1000).getTime());
      this.initializeSalesData();
      data.forEach((sale: any) => {
        this.processSaleData(sale);
      });
      this.getGraphics();
      this.chart.update();
    });
  }

  initializeSalesData() {
    this.sales = [];
    this.changeDetectorRef.detectChanges();
  }

  processSaleData(sale) {
    const date = this.formatDateLL(sale.date.seconds);
    this.sales.push({ ...sale, date });
  }

  formatDateLL(seconds) {
    return moment(seconds * 1000).format('LL');
  }

  getBookedProduct() {
    this._reportService.getRentals().subscribe((data) => {
      data.sort((a, b) => new Date(b.created.seconds * 1000).getTime() - new Date(a.created.seconds * 1000).getTime());
      this.initializeBookedProductVariables();
      data.forEach((rental: any) => {
        this.processBookedProduct(rental);
      });
      this.updateBookedProducts();
      this.getGraphics();
      this.chart.update();
    });
  }

  initializeBookedProductVariables() {
    this.totalmostbooked = [];
    this.auxarraybooked = [];
    this.booked = [];
    this.graphicdatabooked = Array(12).fill([]);
    this.monthbooked = Array(12).fill(0);
    this.textbooked = [];
    this.bookeddata = [];
    this.changeDetectorRef.detectChanges();
  }

  processBookedProduct(rental) {
    const month = this.getMonthOfRental(rental.dayStart.seconds);
    const bookedProduct = {
      name: rental.productsNames[0],
      total: month,
      year: Number(moment(rental.dayStart.seconds * 1000).format('YYYY')),
      month: month,
    };
    this.booked.push(bookedProduct);
    this.graphicdatabooked[month - 1].push(bookedProduct);
  }

  getMonthOfRental(seconds) {
    return Number(moment(seconds * 1000).format('M'));
  }

  updateBookedProducts() {
    this.graphicdatabooked.forEach((bookedProducts, index) => {
      this.auxarraybooked = bookedProducts.map(product => product.name);
      this.totalmostbooked.push(this.getMostRepeated(this.auxarraybooked));
      this.monthbooked[index] = this.totalmostbooked[index][1];
      this.textbooked.push(this.totalmostbooked[index][0]);
      this.auxarraybooked = [];
    });
  }

  getGraphics() {
    this.resetChartData();
    this.updateChartData();
    this.chart.update();
  }

  resetChartData() {
    this.lineRentalsData.datasets = [];
    this.lineSalesData.datasets = [];
    this.linesBookedData.datasets = [];
  }

  updateChartData() {
    const rentalsDataset = this.createDataset('Rentals', this.graphicRental, 'rgba(43, 155, 151,0.8)', 'rgba(43, 155, 151,1)');
    const cancelledDataset = this.createDataset('Cancelled', this.cancelled, 'rgba(255, 183, 29,0.8)', 'rgba(255, 183, 29,1)');
    const salesDataset = this.createLineDataset('Sales', this.sellsThisMonth, 'rgba(231, 67, 103,1)');
    const bookedDataset = this.createDataset('', [], 'rgba(43, 155, 151,0.8)', 'rgba(43, 155, 151,1)');

    this.lineRentalsData.datasets.push(rentalsDataset, cancelledDataset);
    this.lineSalesData.datasets.push(salesDataset);
    this.linesBookedData.datasets.push(bookedDataset);
    this.countArr.forEach((element) => {
      this.linesBookedData.datasets[0].data.push(element[1]);
      if (!this.linesBookedData.labels.includes(element[0])) {
        this.linesBookedData.labels.push(element[0]);
      }
    });
  }

  createDataset(label, data, backgroundColor, borderColor) {
    return {
      data,
      label,
      backgroundColor,
      borderColor,
      pointBackgroundColor: borderColor,
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: backgroundColor,
      fill: 'origin',
    };
  }

  createLineDataset(label, data, borderColor) {
    return {
      type: 'line' as const,
      data,
      label,
      backgroundColor: 'rgba(0, 0, 0,0)',
      borderColor,
      pointBackgroundColor: borderColor,
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: borderColor,
      fill: 'false',
    };
  }


  public lineSalesData: ChartConfiguration['data'] = {
    //data for the lineChart
    datasets: [], //array to obtain the data
    labels: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ], //labels with all days
  };
  public lineRentalsData: ChartConfiguration['data'] = {
    //data for the lineChart
    datasets: [], //array to obtain the data
    labels: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ], //labels with all days
  };
  public lineChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0,
        borderWidth: 4,
      },
    },
    responsive: true,
    maintainAspectRatio: false, // Esto permite que el gráfico se ajuste al tamaño del contenedor
    scales: {
      x: {
          ticks: {
              maxRotation: 90,
              minRotation: 90
          }
      },
      y: {
          ticks: {
              maxRotation: 0,
              minRotation: 0
          }
      }
    },
    plugins: {
      legend: { display: true },
    },
  };
  public barChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0.1,
      },
    },
    responsive: true,
    maintainAspectRatio: false, // Esto permite que el gráfico se ajuste al tamaño del contenedor
    scales: {
      x: {
          ticks: {
              maxRotation: 90,
              minRotation: 90
          }
      },
      y: {
          ticks: {
              maxRotation: 0,
              minRotation: 0
          }
      }
    },
    plugins: {
      legend: { display: true },
    },
  };
  public BarChartType: ChartType = 'bar';
  public lineChartType: ChartType = 'line';
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  // events
  public chartClicked({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: {}[];
  }): void {
    //console.log(event, active);
  }

  public chartHovered({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: {}[];
  }): void {
    //console.log(event, active);
  }

  public hideOne(): void {
    const isHidden = this.chart?.isDatasetHidden(1);
    this.chart?.hideDataset(1, !isHidden);
  }

  public changeColor(): void {
    this.lineRentalsData.datasets[2].borderColor = 'red';
    this.lineRentalsData.datasets[2].backgroundColor = `rgba(0, 255, 0, 0.3)`;

    this.lineSalesData.datasets[2].borderColor = 'red';
    this.lineSalesData.datasets[2].backgroundColor = `rgba(0, 255, 0, 0.3)`;

    this.chart?.update();
  }

  public changeLabel(): void {
    if (this.lineRentalsData.labels) {
      this.lineRentalsData.labels[2] = ['1st Line', '2nd Line'];
    }
    if (this.lineSalesData.labels) {
      this.lineSalesData.labels[2] = ['1st Line', '2nd Line'];
    }
    this.chart?.update();
  }
}
