<!-- Header / Search -->
<div class="container-fluid">
  <div class="row align-items-start mt-2">
    <div class="col-md-8 text-left">
      <!-- Header -->
      <h1 class="mt-2 mb-2" style="font-size: 32px; cursor: pointer">
        Image Library
        <button
          [style.background]="colorTheme"
          [style.color]="colorText"
          class="btn"
          type="button"
          (click)="interactWithImgGallery()"
        >
          <i class="fas fa-plus"></i>
        </button>
      </h1>
    </div>

    <!-- Search Pipe -->
    <div class="col-lg-3 col-md-12 col-sm-12">
      <label class="form-label" for="inputSearch">Search All Images</label>
      <input
        type="text"
        class="form-control"
        name="inputSearch"
        id="inputSearch"
        [(ngModel)]="inputSearch"
        placeholder="Type to search..."
        #keyword
      />
    </div>
  </div>
</div>

<!-- Nav Tabs -->
<div class="container-fluid">
  <div class="row mt-5 justify-content-between">
    <!-- Barra de busqueda -->
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <!-- Images -->
        <button
          [disabled]="!onImageLibraryPage && defaultTabSelection != 'image'"
          aria-disabled="!onImageLibraryPage && defaultTabSelection != 'image'"
          class="nav-link"
          id="image-tab"
          data-bs-toggle="tab"
          [ngClass]="{
            'active show': onImageLibraryPage || defaultTabSelection == 'image',
            disabled: !onImageLibraryPage && defaultTabSelection != ''
          }"
          data-bs-target="#standardImages"
          type="button"
          role="tab"
          aria-controls="all"
          aria-selected="true"
        >
          <div
            tooltip="Not available while editing {{ defaultTabSelection }}"
            *ngIf="
              !onImageLibraryPage && defaultTabSelection != 'image';
              else tab1NotDisabled
            "
          >
            <span>Images</span>
          </div>
          <ng-template #tab1NotDisabled>
            <span>Images</span>
          </ng-template>
        </button>

        <!-- Logos -->
        <button
          [disabled]="!onImageLibraryPage && defaultTabSelection != 'logo'"
          aria-disabled="!onImageLibraryPage && defaultTabSelection != 'logo'"
          class="nav-link"
          id="logo-tab"
          data-bs-toggle="tab"
          [ngClass]="{
            'active show': defaultTabSelection == 'logo',
            disabled: !onImageLibraryPage && defaultTabSelection != 'logo'
          }"
          data-bs-target="#logo"
          type="button"
          role="tab"
          aria-controls="logo"
          aria-selected="true"
        >
          <div
            tooltip="Not available while editing {{ defaultTabSelection }}"
            *ngIf="
              !onImageLibraryPage && defaultTabSelection != 'logo';
              else tab3NotDisabled
            "
          >
            <span>Logos</span>
          </div>
          <ng-template #tab3NotDisabled>
            <span>Logos</span>
          </ng-template>
        </button>
      </div>
    </nav>
  </div>
</div>

<!-- Tab Content -->
<div class="tab-content" style="overflow-y: auto; max-height: 75vh">
  <!-- Images -->
  <div
    class="tab-pane fade"
    id="standardImages"
    role="tabpanel"
    aria-labelledby="images-tab"
    [ngClass]="{
      'active show': defaultTabSelection == 'image' || onImageLibraryPage
    }"
  >
    <div class="row mt-3 container-fluid containerLibrary">
      <!-- If user is searching through all imgs and doesn't yield any results -->
      <div
        *ngIf="
          (standardImages | objectPropertyFilterPipe : keyword.value).length <=
            0 && standardImages.length > 0
        "
      >
        <span>No images matched your search</span>
      </div>

      <!-- Shows an alert if no images exist in that type tab -->
      <app-image-library-no-images
        *ngIf="standardImages?.length <= 0"
      ></app-image-library-no-images>
      <div
        class="col-lg-2 col-md-4 col-xs-6 thumb"
        style="
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          height: 14em !important;
        "
        *ngFor="
          let image of standardImages
            | objectPropertyFilterPipe : keyword.value;
          let i = index
        "
      >
        <img
          src="{{ image.url }}"
          class="zoom img-fluid"
          (click)="showImage(image)"
          alt=""
        />
        <div class="d-flex flex-column">
          <span
            class="badge rounded-pill bg-light text-dark"
            *ngIf="
              image.type == 'category' ||
              image.type == 'location' ||
              image.type == 'product'
            "
            >Legacy</span
          >
        </div>
      </div>
    </div>
  </div>

  <!-- Logos -->
  <div
    class="tab-pane fade"
    id="logo"
    role="tabpanel"
    aria-labelledby="logos-tab"
    [ngClass]="{ 'active show': defaultTabSelection == 'logo' }"
  >
    <div class="row mt-3 container-fluid containerLibrary">
      <!-- If user is searching through Category images and doesn't yield any results -->
      <div
        *ngIf="
          (logos | objectPropertyFilterPipe : keyword.value).length <= 0 &&
          logos.length > 0
        "
      >
        <span>No logos matched your search</span>
      </div>

      <!-- Shows an alert if no images exist in that category tab -->
      <app-image-library-no-images
        *ngIf="logos?.length <= 0"
      ></app-image-library-no-images>
      <div
        class="col-lg-2 col-md-4 col-xs-6 thumb"
        *ngFor="
          let image of logos | objectPropertyFilterPipe : keyword.value;
          let i = index
        "
      >
        <img
          src="{{ image.url }}"
          class="zoom img-fluid"
          (click)="showImage(image)"
          alt=""
        />
        <div class="text-center mt-4">
          <span
            class="badge rounded-pill bg-warning text-black"
            *ngIf="image.isCompanyLogo"
            >Logo</span
          >
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Form Modal -->
<div
  class="modal fade"
  id="addImageModal"
  tabindex="-1"
  role="dialog"
  data-bs-backdrop="static"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div
        class="modal-header d-flex align-items-center justify-content-center"
      >
        <h4 class="modal-title" style="padding-bottom: 0px !important">
          <ng-container>
            <h1 style="margin: 0px !important">Add Image</h1>
          </ng-container>
        </h4>
        <button
          type="button"
          (click)="resetForm()"
          class="btn btn-danger"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <i class="fa fa-close"></i>
        </button>
      </div>

      <div class="modal-body">
        <form
          class="row needs-validation"
          [formGroup]="imageInfo"
          (ngSubmit)="validateForm()"
          novalidate
        >
          <!---------------------------------------------------------------------Cropped Image-------------------------------------------------------------------->
          <div class="col-md-12">
            <!-- Radio buttons for image type -->
            <!-- Image Type title -->
            <h2>Upload as</h2>

            <div class="form-check form-check-inline">
              <input
                class="form-check-input mt-1"
                type="radio"
                formControlName="imageType"
                name="imageType"
                id="imageRdo"
                value="image"
                (click)="setImageType($event, 'image')"
              />
              <label class="form-check-label" for="imageRdo">Image</label>
            </div>

            <div class="form-check form-check-inline">
              <input
                class="form-check-input mt-1"
                type="radio"
                formControlName="imageType"
                name="imageType"
                id="logoRdo"
                value="logo"
                (click)="setImageType($event, 'logo')"
              />
              <label class="form-check-label" for="logoRdo">Logo</label>
            </div>

            <!-- Img specifications by img type -->
            <div
              *ngIf="imageInfo.controls.imageType?.value === 'image'"
              class="imgSpecs"
            >
              <span
                >The minimum upload size for an image is "{{ imageSpecs[0] }}w x
                {{ imageSpecs[1] }}h"</span
              >
            </div>

            <div
              *ngIf="imageInfo.controls.imageType?.value === 'logo'"
              class="imgSpecs"
            >
              <span
                >The minimum upload size for a logo is "{{ logoSpecs[0] }}w x
                {{ logoSpecs[1] }}h"</span
              >
            </div>

            <div
              *ngIf="croppedImage != undefined && croppedImage != ''"
              class="form-group-sm mb-1 input-group-sm"
            >
              <!-- <div *ngIf="croppedImage != undefined && croppedImage != ''" class="col-lg-12 mt-4">
                <img style="width:100%;" [src]="croppedImage"/>
              </div> -->

              <h2 class="mt-4">Preview</h2>
              <h3>{{ originalImageName }}</h3>

              <!-- Logo -->
              <div
                *ngIf="this.imageInfo?.controls?.imageType.value == 'logo'"
                class="d-flex align-items-center justify-content-center"
              >
                <div>
                  <h4 class="text-center">Circular Logo</h4>
                  <img
                    class="imgLogoCircle mr-2"
                    alt="Image Logo"
                    src="{{ croppedImage }}"
                  />
                </div>
                <div>
                  <h4 class="text-center">Square Logo</h4>
                  <img
                    class="imgLogoSquare ml-2"
                    alt="Image Logo"
                    src="{{ croppedImage }}"
                  />
                </div>
              </div>

              <!-- Standard img -->
              <div
                *ngIf="this.imageInfo?.controls?.imageType.value == 'image'"
                class="d-flex align-items-center"
              >
                <div>
                  <h4 class="text-center">Image</h4>
                  <img
                    class="imgPreview"
                    alt="Image Logo"
                    src="{{ croppedImage }}"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 mt-3">
            <div class="col">
              <button
                *ngIf="this.imageInfo?.controls?.imageType.value === 'image'"
                type="button"
                class="btn btn-file btn-primary"
                style="width: 150px !important; float: right"
                [disabled]="!typeSelected"
              >
                <i class="fa fa-upload"></i> Upload Image
                <input
                  id="file-upload"
                  formControlName="image"
                  type="file"
                  accept="image/*"
                  (change)="fileChangeEvent($event)"
                />
              </button>

              <button
                *ngIf="this.imageInfo?.controls?.imageType.value === 'logo'"
                type="button"
                class="btn btn-file btn-primary"
                style="width: 150px !important; float: right"
                [disabled]="!typeSelected"
              >
                <i class="fa fa-upload"></i> Upload Logo
                <input
                  id="file-upload"
                  formControlName="image"
                  type="file"
                  accept="image/*"
                  (change)="fileChangeEvent($event)"
                />
              </button>
            </div>
          </div>

          <!-----------------------------------------------------------------End of Cropped Image------------------------------------------------------------------->

          <div class="d-flex justify-content-center">
            <div class="col col-4 text-center">
              <button
                id="submitbtn"
                type="submit"
                class="btn btn-success"
                [disabled]="!croppedImage"
              >
                <ng-container> Submit </ng-container>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Crop -->
<div class="modal fade" id="crop" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title">Please Crop Image</h1>
        <button
          type="button"
          class="btn btn-danger rowBtn"
          data-bs-dismiss="modal"
          (click)="resetForm()"
        >
          <i class="fa fa-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="form-group-sm mb-1 input-group-sm">
            <div class="col-lg-7">
              <image-cropper
                [imageChangedEvent]="imageChangedEvent"
                [maintainAspectRatio]="true"
                [aspectRatio]="aspectRatio"
                [resizeToWidth]="1280"
                format="png"
                (imageCropped)="imageCropped($event)"
                style="width: 100%"
              >
              </image-cropper>
            </div>

            <!-- Cropping Preview -->
            <div
              *ngIf="croppedImage != undefined && croppedImage != ''"
              class="d-flex flex-column"
            >
              <h2>Preview</h2>
              <h3>{{ originalImageName }}</h3>

              <!-- Logo -->
              <div
                *ngIf="this.imageInfo?.controls?.imageType.value == 'logo'"
                class="d-flex align-items-center"
              >
                <div>
                  <h4 class="text-center">Circular Logo</h4>
                  <img
                    class="imgLogoCircle mr-2"
                    alt="Image Logo"
                    src="{{ croppedImage }}"
                  />
                </div>
                <div>
                  <h4 class="text-center">Square Logo</h4>
                  <img
                    class="imgLogoSquare ml-2"
                    alt="Image Logo"
                    src="{{ croppedImage }}"
                  />
                </div>
              </div>

              <!-- Standard img -->
              <div
                *ngIf="this.imageInfo?.controls?.imageType.value == 'image'"
                class="d-flex align-items-center"
              >
                <div>
                  <h4 class="text-center">Image</h4>
                  <img
                    class="imgPreview"
                    alt="Image Logo"
                    src="{{ croppedImage }}"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4" style="margin-top: 3.5rem !important">
          <div class="col">
            <button
              type="button"
              class="btn btn-success float-right"
              data-bs-dismiss="modal"
            >
              Finish
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
