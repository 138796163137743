
<ng-container *ngIf="loaded">
  <div class="container-fluid">
    <div class="row align-items-start mt-2">
      <div class="col-md-7 text-left">
        <h1 class="mt-2 mb-2" style="font-size: 32px;">
          Rental Embed
        </h1>
        <!-- <div id="divRental">
          <button id="buttonfleetmaidclosew" (click)="openFrame(false)">
            <img
              id="imagebuttonfleetmaid"
              src="https://fleetmaid.com/assets/images/icons/exit.svg"
              width="18"
              height="17"
            />
          </button>
          <iframe
            id="scrollframe"
            src="https://{{ app.appDomainName }}/rental/{{ companyURL }}"
          ></iframe>
        </div> -->

        <p>Link Fleetmaid to your website</p>
      </div>
    </div>
  </div>

  <div id="embed-generator" class="container-fluid">
    <div class="row align-items-start">
      <div class="col-lg-7 embed-step emphasize" [style.borderColor]="colorTheme">
        <div
          [ngStyle]="{
            '--get-Theme': stylesColors[0],
            '--get-Text': stylesColors[1]
          }"
          [ngClass]="{ 'step-counter': true }"
        ></div>
        <!-- <h2 class="embed-heading float-right">Select Options</h2> -->
        <h2 class="embed-heading float-right">Type a label</h2>
        <form #embedOptions="ngForm">
          <label for="ctrl-label" class="mt-1 mb-0">Label</label>
          <input
            type="text"
            class="form-control mt-0"
            id="ctrl-label"
            (input)="updateControlCode()"
            [(ngModel)]="generatorCtrl.label"
            name="ctrl-label"
          />
          <!-- <label for="type-of-url" class="mt-1 mb-0">Open to</label>
          <select class="form-control form-select mt-0" id="type-of-url" [(ngModel)]="typeOfURL" name="type-of-url" (change)="generateQueryString($event);updateControlCode()">
            <option value="" disabled>Link opens to...</option>
            <option value="default" selected>Default Storefront (Top Level)</option>
            <option value="location">Specific Location</option>
            <option value="category">Specific Category</option>
            <option value="product">Specific Product</option>
          </select>
          <select *ngIf="typeOfURL=='location'" class="form-control form-select" id="direct-link-id" name="direct-link-id" [(ngModel)]="directLinkID" (change)="generateQueryString($event);updateControlCode()">
            <option value="" selected disabled>Choose a location...</option>
            <ng-container *ngFor="let location of this.locations">
              <option value="{{location.id}}">{{location.title}}: {{location.subtitle}} - {{location.description}}</option>
            </ng-container>
          </select>
          <select *ngIf="typeOfURL=='category'" class="form-control form-select" id="direct-link-id" name="direct-link-id" [(ngModel)]="directLinkID" (change)="generateQueryString($event);updateControlCode()">
            <option value="" selected disabled>Choose a category...</option>
            <ng-container *ngFor="let category of this.categories">
              <option value="{{category.id}}">{{category.title}}<span *ngIf="category.description"> - {{category.description}}</span></option>
            </ng-container>
          </select>
          <select *ngIf="typeOfURL=='product'" class="form-control form-select" id="direct-link-id" name="direct-link-id" [(ngModel)]="directLinkID" (change)="generateQueryString($event);updateControlCode()">
            <option value="" selected disabled>Choose a product...</option>
            <ng-container *ngFor="let product of this.productData">
              <option value="{{product.id}}">{{product.productName}} #{{product.productNumber}}</option>
            </ng-container>
          </select>
          <button class="btn btn-secondary float-right" (click)="testQueryString()">View Selected Page</button><br class="dev-info">
          <span class="dev-info"><a href="{{generatedURL}}">(dev-info) Generated: {{generatedURL}}</a><br></span>
          <span class="dev-info">(dev-info) directLinkID: {{directLinkID}}, current typeOfURL: {{typeOfURL}}</span> -->
        </form>
      </div>
      <div class="col-lg-7 embed-step warn" [style.borderColor]="colorTheme">
        <div
          [ngStyle]="{
            '--get-Theme': stylesColors[0],
            '--get-Text': stylesColors[1]
          }"
          [ngClass]="{ 'step-counter': showBeforeElement }"
        ></div>
        <h2 class="embed-heading float-right">
          <span class="fas fa-code fa-circle-1"></span> Fleetmaid script -
          Required for iFrame overlay
        </h2>
        <p>
          Once per page, copy the code below and paste it above your site's
          closing &lt;/body&gt; tag
        </p>
        <div class="hover-text">
          <i class="fa fa-question-circle" aria-hidden="true"></i> Help
          <span class="tooltip-text tooltip-bottom"
            >This API is required to display a Fleetmaid overlay while remaining
            on your site. Without it, links to Fleetmaid will simply navigate to a
            new page or open in a new window. &#10;Depending on your site's
            architecture, the actual &lt;/body&gt; tag may be difficult to locate.
            Many template-based website builders have a designated "code block"
            element or "custom JS" section in their admin settings to properly run
            3rd-party scripts</span
          >
        </div>
        <!-- <textarea class="monospace url-copy-field" rows="7" onclick="this.focus();this.select()" readonly="readonly">&lt;!-- Fleetmaid script - do not remove --&gt;
  &lt;script src="https://fleetmaid.com/assets/js/fleetmaid.js"&gt;&lt;/script&gt;
        </textarea> -->
        <textarea
          class="monospace url-copy-field"
          rows="7"
          onclick="this.focus();this.select()"
          readonly="readonly"
          [innerHTML]="FLEETMAIDSCRIPT"
        ></textarea>
      </div>

      <div class="col-lg-7 align-items-start embed-step emphasize" [style.borderColor]="colorTheme">
        <div [ngStyle]="{
              '--get-Theme': stylesColors[0],
              '--get-Text': stylesColors[1]
            }" [ngClass]="{ 'step-counter': showBeforeElement }">
        </div>

        <div class="row">

          <div *ngIf="bookingTemplates.length > 0" >
            <h2 class="embed-heading">
              Choose one of the following options to add Fleetmaid to your Site:
            </h2>
            <div id="code-type-selection" class="btn-group" role="group" aria-label="Code type toggle button group">
              <label class="btn btn-outline-primary" for="btnradio-button" [style.--active-color]="colorText"
                [style.borderColor]="colorTheme" [style.color]="colorTheme">Button</label>
              <input type="radio" class="btn-check" name="btnradio" id="btnradio-button" autocomplete="off"
                (click)="updateControlCode('BUTTON_CODE_TYPE')" [checked]="generatorCtrl.codeType == 'BUTTON_CODE_TYPE'" />

              <input type="radio" class="btn-check" name="btnradio" id="btnradio-html" autocomplete="off"
                (click)="updateControlCode('HTML_CODE_TYPE')" [checked]="generatorCtrl.codeType == 'HTML_CODE_TYPE'" />
              <label class="btn btn-outline-primary" [style.--active-color]="colorText" [style.borderColor]="colorTheme"
                [style.color]="colorTheme" for="btnradio-html">HTML</label>

              <input type="radio" class="btn-check" name="btnradio" id="btnradio-link" autocomplete="off"
                (click)="updateControlCode('LINK_CODE_TYPE')" [checked]="generatorCtrl.codeType == 'LINK_CODE_TYPE'" />
              <label class="btn btn-outline-primary" [style.--active-color]="colorText" [style.borderColor]="colorTheme"
                [style.color]="colorTheme" for="btnradio-link">Link</label>
            </div>

            <div style="margin-bottom: 15px">
              <div *ngIf="bookingTemplates.length > 0; else noItemsTemplate">
                <h2 class="embed-heading">
                  <label for="dropdown" style="margin-bottom: 0">Select from the list of booking flow templates to generate the appropriate options:</label>
                </h2>
                <select id="dropdown" (change)="updateUrl()" [(ngModel)]="selectedTemplate"
                  class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                  <option *ngFor="let template of bookingTemplates" [value]="template.id">{{ template.title }}</option>
                </select>
              </div>
              <ng-template #noItemsTemplate>
                <div>
                  <div class="text-red-500"><span style="color: red;">No Booking Templates were found.</span> Visit the <a
                      routerLink="../booking-suite">Booking Suite</a> to create one.</div>
                </div>
              </ng-template>
            </div>

            <div class="col-lg-12 embed-step substep">
              <h2 class="embed-heading">
                <span class="fas fa-code fa-light fa-circle-3"></span> Copy this code
                for your site
              </h2>
              <textarea class="monospace url-copy-field" onclick="this.focus();this.select()" readonly="readonly"
                [innerHTML]="generatorCtrl.code"></textarea>
              <!-- Preview: <span id="generator-preview"></span> -->
            </div>
          </div>

        </div>

      </div>

    </div>
  </div>
</ng-container>

<!-- Loader / Spinner -->
<ng-container *ngIf="!loaded" class="d-flex text-center justify-content-center align-items-center"
  style="margin-top: 50px;">
  <div class="spinner-border" role="status"
    style="width: 4em !important; height: 4em !important; position: absolute; left: 50%; top: 30%">
    <span class="visually-hidden">Loading...</span>
  </div>
</ng-container>
