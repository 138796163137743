import {
  Component,
  ViewEncapsulation,
  Inject,
  ViewChild,
  OnInit,
  Input,
  OnDestroy,
  Output,
  ElementRef,
  HostListener,
  AfterViewInit,
  Renderer2,
} from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, DocumentChangeAction } from '@angular/fire/compat/firestore';
import { AuthService } from '../services/auth.service';
import { Subject, Subscription, filter, map, switchMap, takeUntil } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd, Event } from '@angular/router';
import { User } from 'src/app/models/storage/user.model';
import { RentalService } from '../services/rental.service';
import { PrimeNGConfig } from 'primeng/api';
import { PartnerService } from '../services/partner.service';
import { DocumentChange, DocumentReference, DocumentSnapshot, serverTimestamp } from 'firebase/firestore';
import Swal from 'sweetalert2';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { StorageService } from '../services/storage.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { UserService } from '../services/user.service';
import { Company } from 'src/app/models/storage/company.model';
import { ProductsService } from '../services/products.service';
import * as bootstrap from 'bootstrap';
import { MaintenanceService } from '../services/maintenance.service';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { environment } from 'src/environments/environment';
import { DatabaseSnapshot, SnapshotAction } from '@angular/fire/compat/database';
import { DEFAULT_THEME } from 'src/app/utils/config/themeDefault.config';

@Component({
  selector: 'app-default',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss'],
})
export class PartnerComponent implements OnInit, OnDestroy, AfterViewInit {
  collapsed: boolean = false;
  navbarCollapsed = true;
  mediaSub: Subscription = new Subscription();
  deviceXs: boolean = false;

  public currentCompany: string;

  @ViewChild('sidebarTreeviewInstance')
  private destroy$: Subject<void> = new Subject<void>();

  public width: string = '220px';
  public mediaQuery: string = '(min-width: 600px)';
  public target: string = '.main-content';
  public dockSize: string = '59px';
  public enableDock: boolean = true;
  public isClosed: boolean = false;
  protected companies: any[] = [];
  public user: User;
  public companyportal: any;
  public companyurl: any;
  public optionName: any;
  public isEmployee: boolean = false;
  public isFleetmaid: boolean = false;
  public presence$: any;

  currentUser: User;
  isAccount: boolean = true;
  isEditing = true;
  userInfo: UntypedFormGroup;
  icons: any[] = [];
  company: Company;
  isNew: boolean = false;
  isDev: boolean = false;
  isLocation: boolean = true; //Change to disable menu wizard
  isCategory: boolean = true; //Change to disable menu wizard
  isProduct: boolean = true; //Change to disable menu wizard
  isSchedule: boolean = true; //Change to disable menu wizard
  isCompanyInfo: boolean = true; //Change to disable menu wizard
  public backgroundColor: string;
  public colorFont: string;
  public theme: string;
  public logo: string;
  public mainStepMessage: string;
  public projectId: string = environment.firebase.projectId;
  currentUserID: string;
  @Output() public maintenanceQty: number = 0;
  @Output() public unavailableQty: number = 0;
  collapseStates: boolean[] = [true, true, true];
  public ariaExpend: { [key: string]: boolean } = {};
  public isExpanded = true;
  public levelType: string;
  public showOverflowXStyle: boolean = true; // Determines wether to set overflow-x to hidden or visible
  protected currentRoute: string;
  @ViewChild('collapseC') collapseC: ElementRef;
  constructor(
    private _pService: ProductsService,
    private _mService: MaintenanceService,
    private afAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private _rentalService: RentalService,
    public _currentUser: CurrentUserService,
    private primengConfig: PrimeNGConfig,
    private _partnerService: PartnerService,
    private _storageService: StorageService,
    private _afFun: AngularFireFunctions,
    private _userService: UserService,
    protected _productsService: ProductsService,
    private el: ElementRef,
    private renderer: Renderer2,
  ) {

    this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.currentRoute = event.urlAfterRedirects;
    });

    this.ariaExpend = {
      ariaexpend1: false,
      ariaexpend2: false,
      ariaexpend3: false,
    };
    this.userInfo = new UntypedFormGroup({
      firstName: new UntypedFormControl('', Validators.required),
      lastName: new UntypedFormControl('', Validators.required),
      email: new UntypedFormControl('', Validators.required),
      password: new UntypedFormControl(''),
      phone: new UntypedFormControl('', Validators.required),
      levelType: new UntypedFormControl('', Validators.required),
      icon: new UntypedFormControl(''),
      color: new UntypedFormControl(''),
    });
  }

  ngOnInit() {
    //localstorage for  collapseds Sumenus
    const storedStates = localStorage.getItem('collapseStates');
    if (storedStates) {
      this.collapseStates = JSON.parse(storedStates);
    }

    //localstorage for  aria-expandes in the submenus
    const expandenStates = localStorage.getItem('ariaExpend');
    this.ariaExpend = expandenStates ? JSON.parse(expandenStates) : {};

    //tooltips
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });

    this.getIcons();
    this.checkSteps();
    this.primengConfig.ripple = true;
    this.toggleSidebar();
    this.showCompanies();

    this.isDev = this._currentUser.currentUser.isDeveloper;
    this.levelType = this._currentUser.currentUser.levelType;

    if (this._currentUser.currentUser.levelType === 'Employee') {
      this.isEmployee = true;
      let transactions = document.getElementById('transactions');
      transactions.setAttribute('style', 'display:none');
      let reports = document.getElementById('reports');
      reports.setAttribute('style', 'display:none');
      let usersManagement = document.getElementById('usersManagement');
      usersManagement.setAttribute('style', 'display:none');
    }
    if (this._currentUser.currentUser.companyId == '7JQQkZqjwxshPILXaoBf') {
      this.isFleetmaid = true;
      let transactions = document.getElementById('transactions');
      transactions.setAttribute('style', 'display:none');
    } else {
      let applicationFees = document.getElementById('applicationFees');
      applicationFees.setAttribute('style', 'display:none');
    }
    this.checkCurrentVersion();
    this.currentUserID = this._currentUser.currentUser.id;

    this.colorFont = DEFAULT_THEME.fontColor;
    this.logo = DEFAULT_THEME.logo;
    this.backgroundColor = DEFAULT_THEME.value;

    this.getMaintenanceCount();

    this.subscribeToRouterEvents();

    this.currentRoute = this.router.url;
    console.log('this.currentRoute', this.currentRoute);

  }

  noSchedule() {
    Swal.fire({
      title: 'No Schedule',
      html: 'You have to define a schedule first',
      icon: 'error',
    });
    this.closeSubMenu();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  //checkSteps
  checkSteps() {
    this._rentalService
      .getCompanySubscription(this._currentUser.currentUser.companyId)
      .subscribe(async (company) => {
        const pLocation = await this._rentalService.getDefaultLocation(
          company.defaultLocation
        );
        this.isNew = this._currentUser.currentUser.currentCompany.isRebuild ?? false;
        company.companyAddress
          ? (this.isCompanyInfo = true)
          : (_) => {
            //this.isCompanyInfo = false;  //Disabled cause we need to show always the options
            //this.mainStepMessage = 'Company info needed';
          };
        if (pLocation) {
          pLocation.scheduleWeek
            ? (this.isSchedule = true)
            : (_) => {
              //this.isSchedule = false;  //Disabled cause we need to show always the options
              /*this.mainStepMessage !== '' &&
              this.mainStepMessage !== 'Company info needed'
                ? (this.mainStepMessage =
                    'At least one product location is needed')
                : '';*/
            };
        } else {
          //this.isSchedule = false;  //Disabled cause we need to show always the options
        }
        /*this._rentalService.stripePublicKey = company.stripePublicKey
          ? company.stripePublicKey
          : null;*/
        this._rentalService.stripeID = company.stripeID
          ? company.stripeID
          : null;
        this._rentalService.isTesting = company.isTesting
          ? company.isTesting
          : false;
        this._rentalService.fleetTax = company.fleetTax ? company.fleetTax : 0;
      });
  }

  //logout
  logOut() {
    this._partnerService.signOut();
    this.authService.logout();
    localStorage.clear();
  }

  get timestamp() {
    return serverTimestamp();
  }
  public companyImg;

  //show companies
  showCompanies() {
    this._currentUser.getCompanies().subscribe((data) => {
      console.log(`getCompanies subscription event with: data...${data.length}}`, {data})
      this.companies = []

      data.forEach((element: DocumentChangeAction<Company>) => {
        if (this._currentUser.currentUser.companyId == element.payload.doc.id) {
          this.companyportal = element.payload.doc.data().companyPortal;
          this.companyurl = element.payload.doc.data().companyURL;
          this.companyImg = element.payload.doc.data().companyImg;
          this.optionName = element.payload.doc.data().optionName;
          this._rentalService.isTesting = element.payload.doc.data().isTesting;
          this._rentalService
            .getStripePKey(element.payload.doc.data().isTesting)
            .then((key) => {
              this._rentalService.stripePublicKey = key;
            });
        }

        //isAdmin
        if (this._currentUser.currentUser.isAdmin === true) {
          if (element.payload.doc.data().companyName.indexOf('Dev.') > -1) {
            //Ignore Dev.
          } else {
            this.companies.push({
              id: element.payload.doc.id,
              ...element.payload.doc.data(),
            });
            return;
          }
        }

        //isDeveloper
        if (this._currentUser.currentUser.isDeveloper === true) {
          this.companies.push({
            id: element.payload.doc.id,
            ...element.payload.doc.data(),
          });
          return;
        }
      });
    });
  }

  //change active company
  changeActiveCompany(companyId: string, companyName: string) {
    for (let i = 0; i < this.companies.length; i++) {
      if (this.companies[i].id == companyId) {
        this.user = this._currentUser.currentUser;
        this.user.companyId = companyId;
        this.user.company = companyName;
        this._currentUser.updateUser(this.user.id, this.user).then(() => {
          location.reload();
        });
        break;
      }
    }
  }

  //toggleSidebar
  toggleSidebar() {
    const sidebar = document.getElementById('sidebar-container');
    const menuCollapsed = document.getElementsByClassName('menu-collapsed');
    const submenuIcon = document.getElementsByClassName('submenu-icon');

    if (sidebar.classList.contains('sidebar-expanded')) {
      sidebar.classList.remove('sidebar-expanded');
      sidebar.classList.add('sidebar-collapsed');

      for (let i = 0; i < menuCollapsed.length; i++) {
        menuCollapsed[i].setAttribute('style', 'display:none');
      }
      for (let i = 0; i < submenuIcon.length; i++) {
        submenuIcon[i].setAttribute('style', 'display:none');
      }
    } else {
      sidebar.classList.remove('sidebar-collapsed');
      sidebar.classList.add('sidebar-expanded');
      for (let i = 0; i < menuCollapsed.length; i++) {
        //remoce el atributo display:none
        menuCollapsed[i].setAttribute('style', 'display:contents');
      }
      for (let i = 0; i < submenuIcon.length; i++) {
        submenuIcon[i].setAttribute('style', 'display:contents');
      }
    }

    this.collapsed = !this.collapsed;
  }

  addUser() {
    // open modal to add user
    $('#addUser').modal('show');
  }

  openAccount(user) {
    this.currentUser = user;

    this.isEditing = true;
    $('#account').modal('show');
  }

  getIcons() {
    //get all icons from storage
    const path = 'assets/icons/';

    this._storageService.getIcons(path).then((icons) => {
      this.icons = icons;
    });
  }

  enableEdition() {
    const optDisabled = document.getElementsByClassName('opt-disabled');
    for (let i = 0; i < optDisabled.length; i++) {
      //add opt-enabled class
      optDisabled[i].classList.add('opt-enabled');
      optDisabled[i].classList.remove('opt-disabled');
    }
    this.isEditing = true;
  }

  disableEdition() {
    //close modal
    $('#account').modal('hide');
  }

  async saveUser() {
    //show loading
    Swal.showLoading();
    Swal.fire({
      title: 'Please wait...',
      allowOutsideClick: false,
      //hide the confirm button
      showConfirmButton: false,
    });

    let user = {
      id: this.currentUser.id,
      email: this.currentUser.email,
      password: this.currentUser.password,
    };

    await this._afFun
      .httpsCallable('updateUserAuthManagement')(user)
      .toPromise()
      .then((res) => {
        if (res) {
          let cUser = {
            email: this.currentUser.email,
            firstName: this.currentUser.firstName,
            lastName: this.currentUser.lastName,
            phone: this.currentUser.phone,
            icon: this.currentUser.icon,
            color: this.currentUser.color,
          };

          this._userService
            .updateUser(cUser, this.currentUser.id)
            .then(() => {
              Swal.fire({
                title: 'User updated successfully',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                this.disableEdition();
              });

              this.backgroundColor = DEFAULT_THEME.value;
              this.colorFont = DEFAULT_THEME.fontColor;
              this.logo = DEFAULT_THEME.logo;
              location.reload();
              localStorage.clear();
            })
            .catch((err) => {
              Swal.fire({
                title: 'Error updating user',
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
              });
            });
        } else if (!res) {
          //after 1.5s
          setTimeout(() => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Something went wrong!',
            });
          }, 1500);
        } else {
          //show error message with sweet alert
          Swal.fire({
            title: 'Error',
            text: res,
            icon: 'error',
          });
        }
      });
  }

  validateForm() {
    var form = document.getElementsByClassName(
      'needs-validation'
    )[0] as HTMLFormElement;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      this.saveUser();
    }
    form.classList.add('was-validated');
  }

  // deploy

  public newVersion = '';
  public oldVersion = '';
  public version = '';

  public newVersionTest = '';
  public oldVersionTest = '';
  public versionTest = '';

  remindMe() {
    $('#confirmUpdateP').modal('hide');

    setTimeout(() => {
      $('#confirmUpdateP').modal({ backdrop: 'static', keyboard: false });
      $('#confirmUpdateP').modal('show');
    }, 900000);
  }

  checkCurrentVersion() {
    this._currentUser.checkVersion().subscribe((data) => {
      if (this.oldVersion === '') {
        this.oldVersion = data.version;
      }

      // this.newVersion = data["version"]

      if (this.oldVersion === data.version) {
        this.version = data['version'];
      } else {
        this.newVersion = data.version + '';
        // this.newVersion = this.newVersion.slice(0, 1) + "." + this.newVersion.slice(1)

        $('#confirmUpdateP').modal({ backdrop: 'static', keyboard: false });
        $('#confirmUpdateP').modal('show');
      }
    });
  }

  confirm() {
    location.reload();
  }

  createTooltip(indicator, message) {
    if (!indicator) {
      Swal.mixin({
        toast: true,
        title: 'Disbled',
        text: 'You need to add a ' + message + ' to enable this feature',
        position: 'center',
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }

  /**
   * Retrieves the maintenance products and calculates the quantity of maintenance products and the quantity of unavailable products.
   */
  getMaintenanceCount(): void {
    // Fetch maintenance products
    this._pService
      .getMaintenanceProducts()
      .pipe(
        takeUntil(this.destroy$),
        switchMap((maintenanceProducts: any[]) => {
          // Fetch unavailable products
          return this._pService.getUnavailableProducts().pipe(
            map((unavailableProducts: any[]) => {
              return { maintenanceProducts, unavailableProducts };
            })
          );
        })
      )
      .subscribe(
        ({
          maintenanceProducts,
          unavailableProducts,
        }: {
          maintenanceProducts: any[];
          unavailableProducts: any[];
        }) => {
          // Calculate maintenance quantity
          this.maintenanceQty = maintenanceProducts.length;
          this._mService.maintenanceQty = maintenanceProducts.length;

          // Calculate unavailable quantity
          this.unavailableQty = unavailableProducts.length;
          this._mService.unavailableProducts = unavailableProducts.length;
        }
      );
  }

  // En el componente correspondiente
  isButtonSelected: string = '';

  setBtnSelected(buttonName: string) {
    this.isButtonSelected = buttonName;
  }

  // runScript() {
  //   this._rentalService.runScript();
  // }

  /* this open and close submenus, if the item is a submenu */
  toggleSubMenu(id: number, ariaexpend: string) {
    Object.keys(this.ariaExpend).forEach((key) => {
      this.ariaExpend[key] = false;
    });
    for (let i = 0; i < this.collapseStates.length; i++) {
      this.collapseStates[i] = true;
    }

    this.collapseStates[id] = false;
    this.ariaExpend[ariaexpend] = true;

    // save in the localSotrage if it's  espefic condition
    if (this.collapseStates[id] === false) {
      localStorage.setItem(
        'collapseStates',
        JSON.stringify(this.collapseStates)
      );
      localStorage.setItem('ariaExpend', JSON.stringify(this.ariaExpend));
    } else if (this.collapseStates[id] === true) {
      localStorage.clear();
    }
  }

  /*this close all submenus,if the item select is not a submenu*/
  closeSubMenu() {
    Object.keys(this.ariaExpend).forEach((key) => {
      this.ariaExpend[key] = false;
    });
    for (let i = 0; i < this.collapseStates.length; i++) {
      this.collapseStates[i] = true;

      localStorage.clear();
    }
  }

  /**
   * Focus on the sidebar
   */
  focusNocollapses() {
    const currentRoute = this.router.url;
    const dashboard = document.getElementById('dashboard');
    const order = document.getElementById('order');
    const booking = document.getElementById('bookings');
    const maintenance = document.getElementById('maintenance');
    const user = document.getElementById('info-user');
    if (currentRoute === '/partner') {
      dashboard.focus();
    } else if (currentRoute === '/partner/dashboard') {
      dashboard.focus();
    } else if (currentRoute === '/partner/order') {
      order.focus();
    } else if (currentRoute === '/partner/bookings') {
      booking.focus();
    } else if (currentRoute === '/partner/usersManagement') {
      user.focus();
    } else if (currentRoute === '/partner/maintenance') {
      maintenance.focus();
    }
  }

  /*focus collaspes  calendar  and setup*/

  focusCollapses() {
    const currentRoute = this.router.url;
    const collapsecalendar = {
      calendar: document.getElementById('Calendarcollapse'),
      inventory: document.getElementById('element-inventory-calendar'),
      bookingitems: document.getElementById('element-inventory-bookings'),
    };

    const collapseSetup = {
      setup: document.getElementById('collapseSetup'),
      companiesItems: document.getElementById('element-item-company'),
      product: document.getElementById('element-item-shop'),
      inventory: document.getElementById('element-item-inventory'),
      options: document.getElementById('element-item-options'),
      bookingsuite: document.getElementById('element-item-booking-suite'),
      categories: document.getElementById('element-item-categories'),
      size: document.getElementById('element-item-product-sizes'),
      library: document.getElementById('element-item-Library'),
      checkout: document.getElementById('element-item-Checkout'),
      waiver: document.getElementById('element-item-wiver'),
      cupons: document.getElementById('element-item-cupons'),
      embedes: document.getElementById('element-item-embedes'),
      productsInventory: document.getElementById('element-item-products'),
      orderProcessing: document.getElementById('element-item-orderrebuild'),
      inventoryBuilder: document.getElementById(
        'item-select-inventoryPageBuilder'
      ),
    };

    switch (currentRoute) {
      case '/partner/calendarproduct':
        collapsecalendar.calendar.focus();
        collapsecalendar.inventory.focus();

        break;
      case '/partner/bookingCalendar':
        collapsecalendar.calendar.focus();
        collapsecalendar.bookingitems.focus();

        break;

      case '/partner/company':
        collapseSetup.setup.focus();
        collapseSetup.companiesItems.focus();

        break;
      case '/partner/shop':
        collapseSetup.setup.focus();
        collapseSetup.product.focus();

        break;
      case 'element-item-orderrebuild':
        collapseSetup.setup.focus();
        collapseSetup.orderProcessing.focus();
        break;

      case '/partner/options':
        collapseSetup.setup.focus();
        collapseSetup.options.focus();
        break;
      case '/partner/booking-suite':
        collapseSetup.setup.focus();
        collapseSetup.bookingsuite.focus();
        break;
      case '/partner/categories':
        collapseSetup.setup.focus();
        collapseSetup.categories.focus();
        break;
      case '/partner/product-sizes':
        collapseSetup.setup.focus();
        collapseSetup.size.focus();
        break;
      case '/partner/library':
        collapseSetup.setup.focus();
        collapseSetup.library.focus();
        break;
      case '/partner/checkoutOptions':
        collapseSetup.setup.focus();
        collapseSetup.checkout.focus();
        break;
      case '/partner/waiver':
        collapseSetup.setup.focus();
        collapseSetup.waiver.focus();
        break;
      case '/partner/discounts':
        collapseSetup.setup.focus();

        collapseSetup.cupons.focus();
        break;
      case '/partner/page-embeds':
        collapseSetup.setup.focus();
        collapseSetup.embedes.focus();
        break;
      case '/partner/inventory':
        collapseSetup.setup.focus();
        collapseSetup.productsInventory.focus();
        break;
      case '/partner/inventoryPageBuilder':
        collapseSetup.setup.focus();
        collapseSetup.inventoryBuilder.focus();
        break;
    }
  }

  /*focus collaspes reports*/
  collapsedFocusReports() {
    const currentRoute = this.router.url;
    const collapseReports = {
      reports: document.getElementById('reportsCollapsed'),
      sales: document.getElementById('element-item-sales'),
      tax: document.getElementById('element-item-tax'),
      rental: document.getElementById('element-item-rental'),
      revenue: document.getElementById('element-item-revenue'),
    };

    if (currentRoute === '/partner/sales-report') {
      collapseReports.reports.focus();
      collapseReports.sales.focus();
    } else if (currentRoute === '/partner/tax-report') {
      collapseReports.reports.focus();
      collapseReports.tax.focus();
    } else if (currentRoute === '/partner/report') {
      collapseReports.reports.focus();
      collapseReports.rental.focus();
    } else if (currentRoute === '/partner/reports') {
      collapseReports.reports.focus();
      collapseReports.revenue.focus();
    }
  }

  ngAfterViewInit() {
    this.focusNocollapses();
    this.focusCollapses();
    this.collapsedFocusReports();
    this.renderer.listen(this.collapseC.nativeElement, 'mouseleave', () => {
      this.collapseC.nativeElement.blur();
    });
  }

  // Changes the overflow-x style based on the current route
  // When on the inventoryPageBuilder route, overflow-x cannot be applied, it will mess with the sticky-top
  subscribeToRouterEvents() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.includes('inventoryPageBuilder/')) {
          this.showOverflowXStyle = false;
        }
        else {
          this.showOverflowXStyle = true;
        }
      }
    });
  }

}
