<app-booking-next-status></app-booking-next-status>
<ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab"
      aria-controls="home" aria-selected="true">
      Bookings
    </button>
  </li>
  <li class="nav-item" role="presentation" *ngIf="rental && rental?.dayStart && rental?.dayEnd">
    <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab"
      aria-controls="profile" aria-selected="false" (click)="openLogs()">
      Logs
    </button>
  </li>
  <li class="nav-item" role="presentation" *ngIf="rental && rental?.dayStart && rental?.dayEnd">
    <button class="nav-link" id="charges-tab" data-bs-toggle="tab" data-bs-target="#charges" type="button" role="tab"
      aria-controls="charges" aria-selected="false" (click)="openCharges()">
      Transactions
    </button>
  </li>
</ul>
<div class="tab-content" id="myTabContent" *ngIf="rental && rental.dayStart || isQuickBook">
  <div
    class="tab-pane fade show active"
    id="home"
    role="tabpanel"
    aria-labelledby="home-tab"
  >
    <div class="container-fluid mt-3">
      <div class="row pt-2 pb-2 rounded-top" *ngIf="rental && rental?.dayStart && rental?.dayEnd" style="
          background-color: #e0ecff;
          color: #2a0083;
          border: 1px #adcdff solid;
        ">
        <div class="col-md-6 fs-6">
          Reservation Dates
          <span class="text-primary" *ngIf="rental?.dayStart && rental?.dayEnd">
            {{
              rental?.dayStart | luxonDisplayPipe : 'DATETIME_MED' : (rental?.rentalLocationID
                ? locationMap[rental.rentalLocationID]['timezone']
                : defaultLocationTimezone)
            }}
            -
            {{
              rental?.dayEnd | luxonDisplayPipe : 'DATETIME_MED' : (rental?.rentalLocationID
                ? locationMap[rental.rentalLocationID]['timezone']
                : defaultLocationTimezone)
            }}
          </span>
        </div>
        <div class="col-12 col-sm-12 col-md-6 pills-dates">
          <ng-container *ngIf="rental?.statusDate?.isCancelled">
            <span class="badge bg-danger me-1" >Cancelled:
              {{
                rental.statusDate.isCancelled | luxonDisplayPipe : 'DATETIME_SHORT' : (rental?.rentalLocationID ?
                locationMap[rental.rentalLocationID]['timezone'] : defaultLocationTimezone)
              }}
              </span>
          </ng-container>
          <ng-container *ngIf="!multipleWaivers && !smartwaiveractive">
            <ng-container *ngIf="
                !(
                  rental?.waiverSignature == null ||
                  rental?.waiverSignature == undefined ||
                  rental?.waiverSignature == ''
                );
                else waivernodigitally
              " class="col text-right">
              <ng-container *ngIf="
                  !(
                    rental.waiver == null ||
                    rental.waiver == undefined ||
                    rental.waiver == []
                  );
                  else waivernotupload
                ">
                <a *ngIf="rental.waiver" class="badge bg-success me-1" href="{{ rental.waiver[0] }}" target="_blank">
                  <i class="fas fa-file-pdf"></i>
                </a>
                <a href="/waiver/{{ rental.id }}" target="_blank" class="badge bg-primary me-1"
                  *ngIf="!multipleWaivers">
                  Waiver Digitally Signed and Uploaded</a>
              </ng-container>
            </ng-container>
            <ng-template #waivernotupload>
              <input class="form-control" type="file" id="id{{ rental.id }}" accept=".pdf"
                (change)="uploadPDFWaiver(rental.id, $event)" />
              <label for="id{{ rental.id }}" class="badge bg-secondary me-1 pointer">
                <i class="fas fa-upload me-1"></i>
                <i class="fas fa-file-pdf"></i>
              </label>
              <a href="/waiver/{{ rental.id }}" target="_blank" class="badge bg-success me-1" *ngIf="!multipleWaivers">
                Waiver Digitally Signed</a>
            </ng-template>
            <ng-template #waivernodigitally>
              <a *ngIf="rental?.waiver" class="badge bg-success me-1" href="{{ rental.waiver[0] }}" target="_blank">
                <i class="fas fa-file-pdf"></i>
              </a>
              <div id="tooltip" *ngIf="
                  !(
                    rental?.waiver == null ||
                    rental?.waiver == undefined ||
                    rental?.waiver == []
                  );
                  else pendingwaiver
                " class="col text-right">
                <a href="/waiver/{{ rental?.id }}" target="_blank" data-toggle="tooltip" title="Sign the Digital Waiver"
                  class="badge bg-success me-1">Waiver Uploaded</a>
                <a href="/waiver/{{ rental?.id }}" target="_blank" id="tooltiptext">
                  <div class="textinside">Sign the Digital Waiver</div>
                </a>
              </div>
            </ng-template>
            <ng-template #pendingwaiver>
              <input class="form-control" type="file" id="id{{ rental?.id }}" accept=".pdf"
                (change)="uploadPDFWaiver(rental.id, $event)" />
              <label for="id{{ rental?.id }}" class="badge bg-secondary me-1 pointer">
                <i class="fas fa-upload me-1"></i>
                <i class="fas fa-file-pdf"></i>
              </label>
              <div id="tooltip" class="col text-right">
                <a href="/waiver/{{ rental?.id }}" target="_blank" class="badge bg-danger me-1" data-toggle="tooltip"
                  title="Sign the Digital Waiver" style="background-color: orangered">Waiver Not Signed</a>
                <a href="/waiver/{{ rental?.id }}" target="_blank" id="tooltiptext">
                  <div class="textinside">Sign the Digital Waiver</div>
                </a>
              </div>
            </ng-template>
          </ng-container>
          <ng-container *ngIf="multipleWaivers && !smartwaiveractive">
            <ng-container *ngIf="rental?.waiversSigned; else waivernodigitally" class="col text-right">
              <ng-container *ngIf="
                  !(
                    rental?.waiver == null ||
                    rental?.waiver == undefined ||
                    rental?.waiver == []
                  );
                  else waivernotupload
                ">
                <a *ngIf="rental?.waiver" class="badge bg-success me-1" href="{{ rental?.waiver[0] }}" target="_blank">
                  <i class="fas fa-file-pdf"></i>
                </a>
                <a href="/waiver/{{ rental?.id }}" target="_blank" class="badge bg-primary me-1" *ngIf="multipleWaivers">
                  Waiver {{ waiversSigned }}/{{ waiversArray.length }} Digitally
                  Signed and Uploaded</a>
              </ng-container>
            </ng-container>
            <ng-template #waivernotupload>
              <input class="form-control" type="file" id="id{{ rental?.id }}" accept=".pdf"
                (change)="uploadPDFWaiver(rental.id, $event)" />
              <label for="id{{ rental?.id }}" class="badge bg-secondary me-1 pointer">
                <i class="fas fa-upload me-1"></i>
                <i class="fas fa-file-pdf"></i>
              </label>
              <a href="/waiver/{{ rental?.id }}" target="_blank" class="badge bg-success me-1" *ngIf="multipleWaivers">
                Waiver {{ waiversSigned }}/{{ waiversArray.length }} Digitally
                Signed</a>
            </ng-template>
            <ng-template #waivernodigitally>
              <a *ngIf="rental?.waiver" class="badge bg-success me-1" href="{{ rental.waiver[0] }}" target="_blank">
                <i class="fas fa-file-pdf"></i>
              </a>
              <div id="tooltip" *ngIf="rental?.waiversSigned; else pendingwaiver" class="col text-right">
                <a href="/waiver/{{ rental?.id }}" target="_blank" data-toggle="tooltip" title="Sign the Digital Waiver"
                  class="badge bg-success me-1">Waiver Uploaded</a>
                <a href="/waiver/{{ rental?.id }}" target="_blank" id="tooltiptext">
                  <div class="textinside">Sign the Digital Waiver</div>
                </a>
              </div>
            </ng-template>
            <ng-template #pendingwaiver>
              <input class="form-control" type="file" id="id{{ rental?.id }}" accept=".pdf"
                (change)="uploadPDFWaiver(rental.id, $event)" />
              <label for="id{{ rental?.id }}" class="badge bg-secondary me-1 pointer">
                <i class="fas fa-upload me-1"></i>
                <i class="fas fa-file-pdf"></i>
              </label>
              <div id="tooltip" class="col text-right">
                <a href="/waiver/{{ rental?.id }}" target="_blank" class="badge bg-danger me-1" data-toggle="tooltip"
                  title="Sign the Digital Waiver" style="background-color: orangered">Waivers Not Signed</a>
                <a href="/waiver/{{ rental?.id }}" target="_blank" id="tooltiptext">
                  <div class="textinside">Sign the Digital Waiver</div>
                </a>
              </div>
            </ng-template>
          </ng-container>
          <ng-container *ngIf="smartwaiveractive == true">
            <ng-container *ngIf="smartwaiversigned; else waivernodigitally" class="col text-right">
              <ng-container *ngIf="
                  !(
                    rental.waiver == null ||
                    rental.waiver == undefined ||
                    rental.waiver == []
                  );
                  else waivernotupload
                ">
                <a *ngIf="rental.waiver" class="badge bg-success me-1" href="{{ rental.waiver[0] }}" target="_blank">
                  <i class="fas fa-file-pdf"></i>
                </a>
                <span class="badge bg-primary me-1" *ngIf="!multipleWaivers">
                  SmartWaiver Digitally Signed and Uploaded</span>
              </ng-container>
            </ng-container>
            <ng-template #waivernotupload>
              <input class="form-control" type="file" id="id{{ rental?.id }}" accept=".pdf"
                (change)="uploadPDFWaiver(rental.id, $event)" />
              <label for="id{{ rental?.id }}" class="badge bg-secondary me-1 pointer">
                <i class="fas fa-upload me-1"></i>
                <i class="fas fa-file-pdf"></i>
              </label>
              <span class="badge bg-success me-1" *ngIf="!multipleWaivers">
                SmartWaiver Digitally Signed</span>
            </ng-template>
            <ng-template #waivernodigitally>
              <a *ngIf="rental?.waiver" class="badge bg-success me-1" href="{{ rental.waiver[0] }}" target="_blank">
                <i class="fas fa-file-pdf"></i>
              </a>
              <div id="tooltip" *ngIf="
                  !(
                    rental?.waiver == null ||
                    rental?.waiver == undefined ||
                    rental?.waiver == []
                  );
                  else pendingwaiver
                " class="col text-right">
                <a href="/waiver/{{ rental?.id }}" target="_blank" data-toggle="tooltip" title="Sign the Digital Waiver"
                  class="badge bg-success me-1">Waiver Uploaded</a>
                <a href="/waiver/{{ rental?.id }}" target="_blank" id="tooltiptext">
                  <div class="textinside">Sign the Digital Waiver</div>
                </a>
              </div>
            </ng-template>
            <ng-template #pendingwaiver>
              <input class="form-control" type="file" id="id{{ rental?.id }}" accept=".pdf"
                (change)="uploadPDFWaiver(rental.id, $event)" />
              <label for="id{{ rental?.id }}" class="badge bg-secondary me-1 pointer">
                <i class="fas fa-upload me-1"></i>
                <i class="fas fa-file-pdf"></i>
              </label>
              <div id="tooltip" class="col text-right">
                <a href="/waiver/{{ rental?.id }}" target="_blank" class="badge bg-danger me-1" data-toggle="tooltip"
                  title="Sign the Digital Waiver" style="background-color: orangered">SmartWaiver Not Signed</a>
                <a href="/waiver/{{ rental?.id }}" target="_blank" id="tooltiptext">
                  <div class="textinside">Sign the Digital Waiver</div>
                </a>
              </div>
            </ng-template>
          </ng-container>
          <span class="badge bg-primary" *ngIf="!rental.isCheckedOut &&
                  !rental.isCancelled">Pending
            Prep</span>
          <span class="badge bg-info" *ngIf="rental.isComplete">Booking Complete</span>
          <span class="badge badge-warning" *ngIf="
              rental.isCheckedOut && !rental.isCheckedIn
            ">Checked Out</span>
          <span class="badge badge-warning" *ngIf="
              rental.isCheckedIn && !rental.isComplete
            ">Checked In</span>
          <span class="badge bg-danger" *ngIf="rental.isCancelled">Cancelled</span>
        </div>
        <div class="col-md-6">
          Booking Reserved
          <span class="text-primary">
            {{ rental?.statusDate?.isReserved | luxonDisplayPipe : 'DATETIME_SHORT' : (rental?.rentalLocationID
              ? locationMap[rental.rentalLocationID]['timezone']
              : defaultLocationTimezone)
            }}
          </span>
          <span class="badge bg-info" *ngIf="rental?.isDeliver">
            Deliver to {{ rental.deliverInformation.name }}
          </span>
        </div>
        <div class="col-md-6 text-right">
          <p *ngIf="companyObj?.isSmartWaiver && smartwaiversigned" class="badge bg-success">
            Waiver signed by: {{ smartwaiverdata[0]?.waiver.firstName }}
            {{ smartwaiverdata[0]?.waiver.middleName }}
            {{ smartwaiverdata[0]?.waiver.lastName }} -
            {{ convertStringDate(smartwaiverdata[0]?.ts) }}
          </p>
          <p *ngIf="
              rental?.waiverSignature &&
              !multipleWaivers &&
              companyObj?.isSmartWaiver == undefined
            " class="badge bg-success">
            Waiver signed by: {{ rental.waiverSignature }} -
            {{ rental.waiverDate | luxonDisplayPipe : 'DATE_SHORT' : (rental?.rentalLocationID
              ? locationMap[rental.rentalLocationID]['timezone']
              : defaultLocationTimezone)
            }}
          </p>
        </div>
      </div>

      <div class="row pt-2 pb-2 rounded-bottom" style="border: 1px #adcdff solid" *ngIf="rental && rental?.dayStart && rental?.dayEnd">
        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
          <span class="fs-4 mt-2">{{ rental?.userInfo?.name }} {{ rental?.userInfo?.lastName }}</span>
          <br />
          <span class="mt-2" *ngIf="rental?.userInfo?.phone">
            {{ rental?.userInfo?.phone }}
            <span *ngIf="rental?.userInfo?.email"> • {{ rental?.userInfo?.email }}</span>
          </span>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 buttons-edst">
          <button type="button" class="btn btn-sm-primary" (click)="displayEditBooking(rental, 'show')">
            <i class="far fa-edit"></i> Edit Booking
          </button>
          <ng-container *ngIf="rental?.isCancelled; else nocancelled">
            <button type="button" class="btn btn-sm-secondary" (click)="reactivateBooking(rental)">
              <i class="fas fa-undo-alt"></i> Reactivate Booking
            </button>
          </ng-container>
          <ng-template #nocancelled>
            <ng-container>
              <button type="button" class="btn btn-sm-secondary" (click)="cancelBooking(rental)">
                <i class="fas fa-ban"></i> Cancel Booking
              </button>
            </ng-container>
          </ng-template>
        </div>
      </div>

      <!--Form to Edit Booking-->
      <div class="row pt-2 pb-2 rounded" id="formedit" [ngStyle]="{'border-top': isQuickBook ? '1px #adcdff solid' : null}">
        <form class="row g-3 needs-validation" [formGroup]="bookingInfo" (ngSubmit)="validateForm(rental)" novalidate style="margin-top: 8px;">
          <div *ngIf="editDateRange && locations && locationMap">

            <div *ngIf="rental?.dayStart && rental?.dayEnd">
              <app-date-range [selectedLocationID]="selectedLocationID" [locations]="locations" [isAvailabilityOverride]="isAvailabilityOverride"
              [availabilityOverrideConfig]="availabilityOverrideConfig" [dateRangeConfig]="dateRangeConfig"
                [locationMap]="locationMap" [cartObj]="psuedoCartObj" [isMobile]="false" [isMiniCartEdit]="true"

                [rentalStartDate]="dayStart | luxonDisplayPipe : undefined : ((rental?.rentalLocationID)
                ? locationMap[rental.rentalLocationID]['timezone']
                : defaultLocationTimezone)"

                [rentalEndDate]="dayEnd | luxonDisplayPipe : undefined : ((rental?.rentalLocationID)
                ? locationMap[rental.rentalLocationID]['timezone']
                : defaultLocationTimezone)"

                (dateRangeInputChanged)="dateRangeInputChanged($event)"
                (toggleAvailabilityOverride)="toggleAvailabilityOverride($event)"
                triggers="manual">
              </app-date-range>
            </div>

            <div *ngIf="!rental?.dayStart && !rental?.dayEnd">
              <app-date-range [locations]="locations" [isAvailabilityOverride]="isAvailabilityOverride"
                [locationMap]="locationMap" [cartObj]="{items:[]}" [isMobile]="false" [isMiniCartEdit]="true"
                [isQuickBooking]="true" [selectedStartDate]="dayStart" [selectedEndDate]="dayEnd" [availabilityOverrideConfig]="availabilityOverrideConfig" [dateRangeConfig]="dateRangeConfig"
                (toggleAvailabilityOverride)="toggleAvailabilityOverride($event)"
                (dateRangeInputChanged)="dateRangeInputChanged($event)"
                triggers="manual">
              </app-date-range>
            </div>

            <ng-template #popoverContent>
                <app-booking-timeslot-list [timezone]="locationMap[selectedLocationID]['timezone']">
                </app-booking-timeslot-list>
            </ng-template>
          </div>
          <div *ngIf="!runningAvailabilityCheck; else runningAvailability">
            <div *ngIf="!noAvailabilities.onDay && !noAvailabilities.onTime; else availWarning" class="d-flex justify-content-end align-items-center">
              <div class="text-right pr-0">
                <ng-container *ngIf="(newRentalData | keyvalue)?.length > 0; else timesNotEdited">
                  <div class="text-right pr-0">
                    <span>Edited Rental Dates:</span>
                    <a (click)="changeRentalTime()" style="cursor:pointer">
                      <span style="background-color: #e0ecff;">
                      {{
                        newRentalData.dayStart | luxonDisplayPipe : 'DATETIME_MED' : (rental?.rentalLocationID ? locationMap[rental.rentalLocationID]['timezone'] : defaultLocationTimezone)
                        }}
                        -
                        {{
                        newRentalData.dayEnd | luxonDisplayPipe : 'DATETIME_MED' : (rental?.rentalLocationID ? locationMap[rental.rentalLocationID]['timezone'] : defaultLocationTimezone)
                        }}
                      </span>
                      <span style="margin-left: 3px;">
                        <i class="fa fa-clock-o" aria-hidden="true"></i>
                      </span>
                    </a>
                  </div>
                </ng-container>
                <ng-template #timesNotEdited>
                  <div class="text-right pr-0" *ngIf="!isQuickBook">
                    <span>Rental Dates:</span>
                    <a (click)="changeRentalTime()" style="cursor:pointer">
                      <span style="background-color: #e0ecff;">
                      {{
                        rental?.dayStart | luxonDisplayPipe : 'DATETIME_MED' : (rental?.rentalLocationID ? locationMap[rental.rentalLocationID]['timezone'] : defaultLocationTimezone)
                        }}
                        -
                        {{
                        rental?.dayEnd | luxonDisplayPipe : 'DATETIME_MED' : (rental?.rentalLocationID ? locationMap[rental.rentalLocationID]['timezone'] : defaultLocationTimezone)
                        }}
                      </span>
                      <span style="margin-left: 3px;">
                        <i class="fa fa-clock-o" aria-hidden="true"></i>
                      </span>
                    </a>
                  </div>
                </ng-template>
              </div>
            </div>
            <ng-template #availWarning>
              <div class="col-md-6 offset-md-6">
                  <div style="color:red; margin-right: 4px;">No Availabilities, please try a different

                    <ng-container *ngIf="noAvailabilities.onDay && noAvailabilities.onTime">
                      date or time
                    </ng-container>
                    <ng-container *ngIf="noAvailabilities.onDay">
                      date
                    </ng-container>
                    <ng-container *ngIf="noAvailabilities.onTime">
                      time
                    </ng-container>

                    <a (click)="changeRentalTime()" style="cursor:pointer">
                      <span style="color:black">
                        <i class="fa fa-clock-o" aria-hidden="true"></i>
                      </span>
                    </a>
                  </div>
              </div>
            </ng-template>
          </div>
          <ng-template #runningAvailability>
            <div class="col-md-6 offset-md-6">
              <div *ngIf="runningAvailabilityCheck" class="d-flex align-items-center">
                <div class="spinner-border" role="status"
                  style="width: 1em !important; height: 1em !important; margin-right: 3px;">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div>Checking availability...</div>
              </div>
            </div>
          </ng-template>

        <!---------- Form Inputs ----------->
          <div class="col-md-6">
            <label for="txtFirstName" class="form-label">First Name <b class="text-danger">*</b></label>
            <input type="text" class="form-control" name="FirstName" formControlName="FirstName"
              [ngClass]="{ 'is-invalid': f.FirstName.errors && submitted }" id="txtFirstName" />
            <div *ngIf="f.FirstName.errors && submitted" class="invalid-feedback">
              Please provide a valid First Name
            </div>
          </div>
          <div class="col-md-6">
            <label for="txtLastName" class="form-label">Last Name <b class="text-danger">*</b></label>
            <input type="text" class="form-control" name="LastName" formControlName="LastName"
              [ngClass]="{ 'is-invalid': f.LastName.errors && submitted }" id="txtLastName" />
            <div *ngIf="f.LastName.errors && submitted" class="invalid-feedback">
              Please provide a valid Last Name
            </div>
          </div>
          <div class="col-md-6">
            <label for="txtEmail" class="form-label">Email <b class="text-danger" *ngIf="!isQuickBook">*</b></label>
            <input type="email" class="form-control" name="Email" email="true" formControlName="Email"
              [ngClass]="{ 'is-invalid': f.Email.errors && submitted && !isQuickBook}" id="txtEmail" />
            <div *ngIf="(f.Email.errors?.['email'] || f.Email.errors) && submitted"
              class="invalid-feedback">
              Please provide a valid Email
            </div>
          </div>
          <div
          class="col-md-6">
            <label for="txtPhone" class="form-label">Phone <b class="text-danger">*</b></label>
            <!-- <input type="text" class="form-control" name="Phone" formControlName="Phone"
              [ngClass]="{ 'is-invalid': f.Phone.errors && submitted }" id="txtPhone" /> -->
              <ngx-intl-tel-input [ngClass]="{ 'is-invalid': submitted && f.Phone.errors}" style="padding-left: 5px;"
              [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
              [searchCountryFlag]="true" [searchCountryField]="[searchCountryField.Iso2,searchCountryField.Name]"
              [selectFirstCountry]="false" [selectedCountryISO]="countryISO.UnitedStates" [maxLength]="15"
              [phoneValidation]="true" [separateDialCode]="true" [enableAutoCountrySelect]="true"
              [numberFormat]="phoneNumberFormat.International" formControlName="Phone" name="Phone" id="txtPhone" type="text">
            </ngx-intl-tel-input>
            <div *ngIf="f.Phone.errors && submitted" class="invalid-feedback">
              Please provide a valid Phone Number
            </div>
          </div>
          <div class="col-12">
            <label for="txtAddress" class="form-label">Address</label>
            <input type="text" class="form-control" name="Address" formControlName="Address"
              [ngClass]="{ 'is-invalid': f.Address.errors && submitted && !isQuickBook}" id="txtAddress" placeholder="1234 Main St" />
            <div *ngIf="f.Address.errors && submitted" class="invalid-feedback">
              Please provide a valid Address
            </div>
          </div>
          <div class="col-12">
            <label for="txtAddress2" class="form-label">Address 2</label>
            <input type="text" class="form-control" name="Address2" formControlName="Address2" id="txtAddress2"
              placeholder="Apartment, studio, or floor" />
          </div>
          <div class="col-md-3">
            <label for="inputContry" class="form-label">Country</label>
            <select (change)="setStates($event)" class="form-select" name="country" formControlName="country"
              [ngClass]="{ 'is-invalid': f.country.errors && submitted }">
              <option value="">Choose...</option>
              <ng-container *ngFor="let country of countries">
                <option [selected]="country.shortName == f.country.value" [value]="country.shortName">
                  {{ country.name }}
                </option>
              </ng-container>
            </select>
            <span class="invalid-feedback" *ngIf="f.country.errors?.required">Country is Required</span>
          </div>
          <div class="col-md-3">
            <label for="inputCity" class="form-label">City</label>
            <input type="text" class="form-control" name="city" formControlName="city"
              [ngClass]="{ 'is-invalid': f.city.errors && submitted }" />
            <span class="invalid-feedback" *ngIf="f.city.errors">City is Required</span>
          </div>
          <div class="col-md-3">
            <label for="inputState" class="form-label">State</label>
            <select class="form-select" name="state" formControlName="state"
              [ngClass]="{ 'is-invalid': f.state.errors && submitted }">
              <option value="">Choose...</option>
              <ng-container *ngFor="let state of states">
                <option [selected]="state == f.state.value" [value]="state">
                  {{ state }}
                </option>
              </ng-container>
            </select>
            <span class="invalid-feedback" *ngIf="f.state.errors">State is Required</span>
          </div>
          <div class="col-md-3">
            <label for="inputZip" class="form-label">Zip</label>
            <input type="text" class="form-control" name="zip" formControlName="zip"
              [ngClass]="{ 'is-invalid': f.zip.errors && submitted }" />
            <span class="invalid-feedback" *ngIf="f.zip.errors">Zip is Required</span>
          </div>

          <div class="col-12 text-right" *ngIf="!savingRentalChanges ">
            <ng-container *ngIf="rental && rental?.dayStart && rental?.dayEnd">
              <button type="button" class="btn btn-secondary" (click)="displayEditBooking(rental, 'hide')">
                Cancel Changes
              </button>
              <button [disabled]="(!daypickerFormIsValid && dateRangeFormDirty) || 
                            (!bookingInfo.valid && submitted) && !isQuickBook || 
                            (noAvailabilities.onDay || 
                            noAvailabilities.onTime)" type="submit" class="btn btn-primary ml-2">
                Accept Changes
              </button>
            </ng-container>

            <ng-container *ngIf="rental && !rental?.dayStart && !rental?.dayEnd">
              <button [disabled]="(!daypickerFormIsValid && dateRangeFormDirty) || !dayStart && !dayEnd  || !newRentalData"
                  type="submit" class="btn btn-primary ml-2">
                Create Rental
              </button>
            </ng-container>
          </div>
          <div class="col-12 text-right">
            <div class="float-right" *ngIf="savingRentalChanges">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="row pt-2 pb-2" *ngIf="rental?.dayStart && rental?.dayEnd">
        <div class="row">
          <div class="d-flex">
            <div>
              <strong>Scheduled Checkout</strong>
              <div *ngIf="rental?.dayStart && rental?.dayEnd">
                <i class="far fa-calendar-alt text-primary"></i>
                {{
                  rental?.dayStart | luxonDisplayPipe : 'DATE_SHORT' : ((rental?.rentalLocationID) ? locationMap[rental.rentalLocationID]['timezone'] : defaultLocationTimezone)
                }}
              </div>
            </div>

            <div class="ml-auto w-50 text-center" [ngClass]="rental?.cartObj?.additionalComments ? 'comment rounded border' : ''">
              <strong>Additional Comments</strong>
              <div class="text-start" *ngIf="rental?.cartObj?.additionalComments">
                <pre>{{ CommentsConversion(rental?.cartObj?.additionalComments) }}</pre>
              </div>
            </div>

            <div class="ml-auto">
              <strong>Scheduled Checkin</strong>
              <div style="text-align: right" *ngIf="rental?.dayStart && rental?.dayEnd">
                <ng-container *ngIf="isAvailabilityOverride">
                  <div
                      tooltip="Availability rules were overridden when selecting this rental date."
                      class="ml-1 badge bg-warning overrideStatusBtn" [delay]="400"
                      style="border: 1px solid; margin-right: 3px; padding: 3px 5px;">
                      <i class="fa fa-exclamation-triangle"
                          style="font-size: xx-small; pointer-events: none;"></i>
                </div>
                </ng-container>
                <i class="far fa-calendar-alt text-primary"></i>
                {{ rental?.dayEnd | luxonDisplayPipe : 'DATE_SHORT' : ((rental?.rentalLocationID) ? locationMap[rental.rentalLocationID]['timezone'] : defaultLocationTimezone) }}
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-1">
          <div class="d-flex">
            <div>
              <strong>Actual Checkout</strong>
              <div *ngIf="rental?.statusDate?.isCheckedOut">
                <i class="far fa-calendar-alt text-primary"></i>
                {{
                  rental.statusDate.isCheckedOut | luxonDisplayPipe : 'DATE_SHORT' : ((rental?.rentalLocationID) ? locationMap[rental.rentalLocationID]['timezone'] : defaultLocationTimezone)
                }}
              </div>
            </div>

            <div class="ml-auto w-50 text-center" [ngClass]="
                rental?.shopComments ? 'shopComment rounded border' : ''
              ">
              <strong style="cursor: pointer" (click)="
                  openModalShopComment(rental.shopComments); setInputFocus()
                ">
                Shop Comments
                <i class="fas fa-edit"></i>
              </strong>
              <div *ngIf="rental?.shopComments" class="text-start">
                <pre>{{ CommentsConversion(rental.shopComments) }}</pre>
              </div>
            </div>

            <div class="ml-auto">
              <strong>Actual Checkin</strong>
              <div *ngIf="rental?.statusDate?.isCheckedIn" style="text-align: right">
                <i class="far fa-calendar-alt text-primary"></i>
                {{
                  rental.statusDate.isCheckedIn | luxonDisplayPipe : 'DATE_SHORT' : ((rental?.rentalLocationID) ? locationMap[rental.rentalLocationID]['timezone'] : defaultLocationTimezone)
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Shop Comments -->
    <div class="modal fade" id="editShopComments" tabindex="-1" aria-labelledby="editShopCommentsLabel"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="editShopCommentsLabel">
              Shop comments
            </h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form autocomplete="off" [formGroup]="myForm">
              <div class="row">
                <div class="col-md-12 mb-2">
                  <label for="additionalComents"> <b>Shop Comments </b></label>
                  <div class="input-group">
                    <textarea #myTextarea class="form-control" id="shopComments" formControlName="shopComments" rows="4"
                      placeholder="Type here...."></textarea>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
            <button [disabled]="shopSaveButton" type="button" class="btn btn-primary" (click)="saveChanges(rental.id)">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row container-fluid mt-3 ms-1" style="margin-bottom: 15px;">
      <ng-container *ngIf="rental?.dayStart && rental?.dayEnd">
        <div class="col-sm-6">
          <div class="d-grid gap-2">
            <button *ngIf="!showcards" type="button" class="btn btn-sm btn-outline-primary" type="button"
              (click)="viewAllPayment()">
              <i class="far fa-credit-card me-1"></i>Manage Payment Methods
              <i class="fas fa-chevron-right ms-3"></i>
            </button>
            <button *ngIf="showcards" type="button" class="btn btn-sm btn-primary" type="button"
              (click)="hideAllPayment()">
              <i class="far fa-credit-card me-1"></i>Hide Payment Methods
              <i class="fas fa-chevron-down ms-3"></i>
            </button>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="d-grid gap-2">
            <button type="button" class="btn btn-sm btn-outline-primary" id="btnAddSP" (click)="generateReceipt()">
              <!-- <ng-container *ngIf="receiptComponent.isLoading">
                <i class="fa fa-spin fa-spinner"></i>
              </ng-container>-->
              <ng-container>
                <i class="fas fa-plus-square me-2"></i> Generate Receipt
              </ng-container>
            </button>
          </div>
        </div>
      </ng-container>
      <div *ngIf="showcards" class="col-sm-12 mt-1 bg-light pb-2">
        <div class="container-fluid">
          <!--<div class="row">
            <div class="col-md-4 offset-md-8 text-right align-items-end justify-content-end">
              <span class="fs-6 me-2">Pending Amount: ${{rental.amountPending}}</span> <i class="fas fa-edit hoveredit"
                (click)="editPending()"></i>
            </div>
          </div>-->
          <div class="row mt-3 pb-2 border-bottom">
            <div class="col-4 align-content-center align-middle text-left">
              <span><i class="far fa-credit-card me-1"></i> Cards</span>
            </div>
            <div class="col-8 d-flex align-items-center justify-content-end">
              <div style="margin-right: 6px;" *ngIf="rental?.amountPending > 0">
                  <button (click)="copyPaymentLink()" class="btn btn-sm btn-outline-primary no-active" type="button">
                    <i class="fas fa-copy"></i> Copy Payment Link
                  </button>
              </div>
              <div>
                <button class="btn btn-sm btn-warning" (click)="displayCalculator()">
                  <i class="fas fa-calculator"></i> Split Payment Calculator
                </button>
              </div>
            </div>
          </div>
          <!--<div class="row mt-3 pb-2 border-bottom">

          </div>-->
          <div *ngIf="loadingcards" class="row mt-3 text-center align-content-center justify-content-center">
            <div class="spinner-border text-dark" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <span class="mt-2">{{ legendLoad }}</span>
          </div>
          <div *ngIf="!loadingcards" class="row mt-3">
            <div class="col-12 mb-2">
              <span><b>1.-</b> Select a Payment Method</span>
            </div>
            <ng-container *ngIf="paymentsmethods?.length == 0">
              <div class="col-4 mt-2">
                <div [ngClass]="{ borderselected: selectedNew }"
                  class="carddiv2 container-fluid rounded border shadow-sm p-2 text-center"
                  (click)="selectPayment('', 0, true)">
                  <span *ngIf="selectedNew" class="text-success checkmark"><i class="fas fa-check-circle"></i></span>
                  <span class="mb-1"><i class="fas fa-plus-circle"></i> Create New
                  </span>
                  <br />
                  <span> Payment Method </span>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="paymentsmethods?.length > 0">
              <ng-container *ngFor="let method of paymentsmethods; let j = index">
                <div class="col-4 mt-2">
                  <div [ngClass]="{ borderselected: method.isSelected }"
                    class="carddiv container-fluid rounded border shadow-sm p-2 text-center"
                    (click)="selectPayment(method, j)">
                    <span *ngIf="method.isSelected" class="text-success checkmark"><i
                        class="fas fa-check-circle"></i></span>
                    <span class="namecard mb-1">{{
                      method.charges.data[0].shipping?.name
                      ? method.charges.data[0].shipping.name
                      : "Card"
                      }}</span>
                    <br />
                    <span>
                      <img src="../../../assets/images/cards/{{
                          method.charges.data[0].payment_method_details.card
                            .brand
                        }}.png" alt="mastercard" height="20" class="me-3" />
                      <span class="text-left">****
                        {{
                        method.charges.data[0].payment_method_details.card
                        .last4
                        }}</span>
                    </span>
                  </div>
                </div>
              </ng-container>
              <div class="col-4 mt-2">
                <div [ngClass]="{ borderselected: selectedNew }"
                  class="carddiv2 container-fluid rounded border shadow-sm p-2 text-center"
                  (click)="selectPayment('', 0, true)">
                  <span *ngIf="selectedNew" class="text-success checkmark"><i class="fas fa-check-circle"></i></span>
                  <span class="mb-1"><i class="fas fa-plus-circle"></i> Create New
                  </span>
                  <br />
                  <span> Payment Method </span>
                </div>
              </div>
            </ng-container>
            <div class="col-12 mb-2 mt-2">
              <span><b>2.-</b> Select Operation</span>
            </div>
            <div class="col-4">
              <div class="d-grid gap-2">
                <button type="button" class="btn btn-sm btn-outline-primary" type="button" id="btnAddCharge"
                  (click)="addCompletePopup()"
                  [disabled]="!selectedPayment || (rental && rental.amountPending <= 0)"
                  tooltip="Charge for the remaining unpaid balance, pending amount, of the booking."
                >
                  <i class="fas fa-check"></i> Complete Payment
                </button>
              </div>
            </div>
            <div class="col-4">
              <div class="d-grid gap-2">
                <button type="button" class="btn btn-sm btn-outline-primary" id="btnAddSP"
                  (click)="addSecurityPopup()"
                  [disabled]="!selectedPayment"
                  tooltip="Un-captured funds which are held for manual release / capture until after rental completion."
                >
                  <i class="fas fa-plus-square me-2"></i> Add Security Deposit
                </button>
              </div>
            </div>
            <div class="col-4">
              <div class="d-grid gap-2">
                <button type="button" class="btn btn-sm btn-outline-primary" type="button" id="btnAddCharge"
                  (click)="addChargePopup()"
                  [disabled]="!selectedPayment"
                  tooltip="Additional charges not included with the rental booking: damages, impulse purchases, other shop items, etc."
                >
                  <i class="fas fa-plus-square me-2"></i> Add Extra Charge
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid mt-3 pb-2" *ngIf="rental && rental?.dayStart && rental?.dayEnd">
      <div class="row container-ruid pt-2 pb-2 rounded-top" style="
          background-color: #e0ecff;
          color: #2a0083;
          border: 1px #adcdff solid;
        ">
        <div class="col-md-12 fs-6">Products</div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <app-booking-products-table2 *ngIf="bookingsIsOpen"
            [rental]="rental" 
            [availabilityOverrideConfig]="availabilityOverrideConfig"
            [company]="companyObj"
            [editBookingOpen]="editBookingOpen"
            (rentalTotalsChanged)="rentalTotalsUpdate($event)"
            (miniCartRentalUpdate)="miniCartUpdate($event)"
            (rentalModifiedUpdate)="rentalModifiedUpdate($event)">
          </app-booking-products-table2>
        </div>

      </div>
    </div>
  </div>
  <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
    <div class="container-fluid mt-3">
      <div class="row pt-2 pb-2 rounded-top ms-1 me-1" style="
          background-color: #e0ecff;
          color: #2a0083;
          border: 1px #adcdff solid;
        ">
        <div class="col-md-6 fs-6">Logs and Notes</div>
        <div class="col-md-6 text-right">
          <a class="text-primary" id="AddlinkNote" (click)="addNoteClick()"><i class="fas fa-plus-circle"></i>Add a new
            Note</a>
          <a class="text-primary" id="cancelLinkNote" (click)="cancelAddNoteClick()"><i
              class="fas fa-minus-circle"></i>Cancel New Note</a>
        </div>
      </div>
      <div class="pt-2 pb-2 rounded-bottom">
        <div class="row m-3" id="formNote">
          <form>
            <div class="mb-3">
              <label for="formArea" class="form-label">Note</label>
              <textarea class="form-control" id="formArea" rows="3" [(ngModel)]="noteForm" name="noteForm"></textarea>
            </div>
            <div class="mb-3 text-right">
              <button type="button" class="btn btn-primary" (click)="addNote()">
                Add Note
              </button>
            </div>
          </form>
        </div>
        <div class="row m-3">
          <table class="table table-hover table-borderless">
            <thead style="
                background-color: #e0ecff;
                color: #2a0083;
                border: 1px #adcdff solid;
              ">
              <tr>
                <th scope="col">User</th>
                <th scope="col">Details</th>
                <th scope="col">Date</th>
              </tr>
            </thead>
            <tbody *ngFor="let log of logs">
              <tr>
                <td>{{ log.firstName }} {{ log.lastName }}</td>
                <td *ngIf="log.details">{{ log.details }}</td>
                <td *ngIf="log.message">{{ log.message }}</td>
                <td>{{ convertTimeStamp(log.timestamp) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="tab-pane fade" id="charges" role="tabpanel" aria-labelledby="charges-tab">
    <div class="container-fluid mt-3">
      <div class="row pt-2 pb-2 rounded-top ms-1 me-1" style="
          background-color: #e0ecff;
          color: #2a0083;
          border: 1px #adcdff solid;
        ">
        <div class="col-md-12 fs-6">Transactions</div>
      </div>
      <div class="pt-2 pb-2 rounded-bottom">
        <div class="row m-3" *ngIf="charges.length <= 0; else withcharges">
          <div class="col-12 text-center">There's nothing to show</div>
        </div>
        <ng-template #withcharges>
          <div class="row m-3 bg-light cardcharge border rounded" *ngFor="let charge of charges; let j = index">
            <div class="col-6 text-left fs-5 pt-2 pb-2">
              {{
              charge.type == "deposit"
              ? "Security Deposit"
              : charge.type == "rental"
              ? "Rental Payment"
              : charge.type == "charge"
              ? "Charge"
              : charge.type == "refund"
              ? "Refund"
              : ""
              }}
            </div>
            <div class="col-6 text-right pt-2 pb-2 fs-5 text-primary">
              <span *ngIf="charge.totalrefunded; else norefunded">
                <del class="text-danger">${{ charge.amount / 100 }}</del> ${{
                (charge.amount - charge.totalrefunded) / 100
                }}
              </span>
              <ng-template #norefunded>
                ${{ charge.amount / 100 }}
              </ng-template>
            </div>
            <div class="col-6 text-left titlefont text-secondary pb-1">
              {{
              charge.metadata.title
              ? charge.metadata.title
              : charge.type == "refund"
              ? "Refund"
              : "Payment"
              }}{{
              charge.metadata.userName
              ? " by " + charge.metadata.userName
              : ""
              }}
              <img src="../../../assets/images/cards/{{
                  charge.charges.data[0].payment_method_details.card.brand
                }}.png" height="20" alt="{{
                  charge.charges.data[0].payment_method_details.card.brand
                }}" />
              ****
              {{ charge.charges.data[0].payment_method_details.card.last4 }}
            </div>
            <div class="col-6 text-right pb-2 pl-2">
              <!--<span id="display{{j}}" *ngIf="charge.refunds" (click)="displayRefund(charge,j)" class="text-primary me-2 hoverdisplay">Display Refunds</span>
              <span id="hide{{j}}" *ngIf="charge.refunds" (click)="hideRefund(charge,j)" class="text-primary me-2 hide hoverdisplay">Hide Refunds</span>-->
              <span *ngIf="
                  charge.type != 'rental' &&
                  charge.type != 'refund' &&
                  charge.type == 'deposit' &&
                  charge.status == 'requires_capture'
                " (click)="cancelPayment(charge)" class="badge bg-danger me-2 hover">Cancel</span>
              <span *ngIf="
                  charge.type != 'rental' &&
                  charge.type != 'refund' &&
                  charge.type == 'deposit' &&
                  charge.status == 'requires_capture'
                " (click)="confirmPayment(charge)" class="badge bg-success me-2 hover">Confirm</span>
              <span *ngIf="
                  charge.amount != charge.totalrefunded &&
                  charge.type != 'refund' &&
                  charge.status != 'requires_capture' &&
                  charge.status != 'canceled'
                " class="badge bg-warning me-2 hover" (click)="refundAmount(charge)">Partial Refund</span>
              <span *ngIf="charge.status == 'canceled'" class="badge bg-danger me-2">Canceled</span>
              <span *ngIf="
                  charge.amount != charge.totalrefunded &&
                  charge.type != 'refund' &&
                  charge.status != 'requires_capture' &&
                  charge.status != 'canceled'
                " class="badge bg-danger me-2 hover" (click)="refundPayment(charge)">Full Refund</span>
              <span *ngIf="charge.amount == charge.totalrefunded" class="badge bg-primary me-2">Refunded</span>
              <span class="badge bgdate">{{
                convertDate(charge.created)
                }}</span>
            </div>
            <div *ngIf="charge.refunds" class="col-12 border-top accord pt-1" id="accordion{{ j }}">
              <div *ngFor="let refund of charge.refunds">
                <div class="row bg-light border rounded mb-1 p-1 bgrefund">
                  <div class="col-6 text-left fw-semibold">
                    -${{ refund.amount / 100 }} Refunded
                    {{
                    refund.metadata.userName
                    ? " by " + refund.metadata.userName + " "
                    : ""
                    }}
                  </div>
                  <div class="col-6 text-right">
                    <span class="badge bgdate">{{
                      convertDate(refund.created)
                      }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<app-receipt [rental]="rental" [locationMap]="locationMap" [totalChargeAmount]="totalChargeAmount" [rentalReady]="receiptRentalReady" [rental]="receiptRental" [company]="companyObj" #receiptComponent></app-receipt>

<!--Modal para hacer pago-->
<div class="modal fade" style="z-index: 9999 !important" id="paymentModal" tabindex="-1"
  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Payment Method</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row" *ngIf="!customerCreated">
          <form  [formGroup]="checkoutForm" id="checkoutForm">
            <div class="form__stripe1">
              <div class="row">
                <div class="col-sm-12 col-12 col-md-6 col-lg-6">
                  <label for="firstName">First Name <b class="text-danger">*</b></label>
                  <div class="input-group mb-3">
                    <input type="text" id="firstName" placeholder="First Name" class="form-control" [ngClass]="{
                        'is-invalid': submittedpayment && co.firstName.errors
                      }" formControlName="firstName" required />
                    <div *ngIf="submittedpayment && co.firstName.errors" class="invalid-feedback">
                      <div *ngIf="co.firstName.errors.required">
                        First Name is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-12 col-md-6 col-lg-6">
                  <label for="name">Last Name <b class="text-danger">*</b></label>
                  <div class="input-group mb-3">
                    <input type="text" id="lastName" placeholder="Last Name" class="form-control" [ngClass]="{
                        'is-invalid': submittedpayment && co.lastName.errors
                      }" formControlName="lastName" required />
                    <div *ngIf="submittedpayment && co.lastName.errors" class="invalid-feedback">
                      <div *ngIf="co.lastName.errors.required">
                        Last Name is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-12 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label for="email">Email <b class="text-danger">*</b></label>
                    <div class="input-group mb-3">
                      <input type="email" #emailInput id="email" placeholder="Email" class="form-control" [ngClass]="{
                          'is-invalid': submittedpayment && co.email.errors
                        }" formControlName="email" [(ngModel)]="email" required />
                      <div *ngIf="submittedpayment && co.email.errors" class="invalid-feedback">
                        <div *ngIf="co.email.errors.required">
                          Email is required
                        </div>
                        <div *ngIf="co.email.errors.email">
                          Email is invalid
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-12 col-md-6 col-lg-6">
                  <div class="form-group">
                    <label for="phone">Phone <b class="text-danger">*</b></label>
                    <!--   <input type="text" #phoneInput id="phone"
                              (keypress)="onlyNumber($event,15)"
                              placeholder="Phone Number" class="form-control"
                              formControlName="phone" required> -->
                    <ngx-intl-tel-input [cssClass]="'custom'" [ngClass]="{
                        'is-invalid': submittedpayment && co.phone.errors
                      }" [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true"
                      [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[
                        SearchCountryField.Iso2,
                        SearchCountryField.Name
                      ]" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="15"
                      [phoneValidation]="true" [separateDialCode]="true" [numberFormat]="PhoneNumberFormat.National"
                      formControlName="phone" id="phone">
                    </ngx-intl-tel-input>
                    <div *ngIf="submittedpayment && co.phone.errors" class="invalid-feedback">
                      <div *ngIf="co.phone.errors.required">
                        Phone is required
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-12 col-md-6 col-lg-12">
                  <div class="form-group">
                    <label for="address">Address <b class="text-danger">*</b></label>
                    <div class="input-group mb-3">
                      <input type="text" #addressInput id="address" placeholder="Address" class="form-control"
                        [ngClass]="{
                          'is-invalid': submittedpayment && co.address.errors
                        }" formControlName="address" required />
                      <div *ngIf="submittedpayment && co.address.errors" class="invalid-feedback">
                        <div *ngIf="co.address.errors.required">
                          Address is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-12 col-md-6 col-lg-12">
                  <div class="form-group">
                    <label for="address2">Address 2</label>
                    <div class="input-group mb-3">
                      <input type="text" #addressInput id="address2" placeholder="Address 2" class="form-control"
                        formControlName="address2" />
                      <div class="invalid-feedback">
                        Please put a valid address
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-12 col-md-3 col-lg-3">
                  <div class="form-group">
                    <label for="country">Country <b class="text-danger">*</b></label>
                    <div class="input-group mb-3">
                      <select id="country" class="form-select" [ngClass]="{
                                                                                          'is-invalid': submittedpayment && co.country.errors
                                                                                        }" (change)="setStates($event)"
                        formControlName="country" required>
                        <option *ngFor="let country of countries" [selected]="country.shortName == 'US'" [value]="country.shortName">
                          {{ country.name }}
                        </option>
                      </select>
                      <div *ngIf="submittedpayment && co.country.errors" class="invalid-feedback">
                        <div *ngIf="co.country.errors.required">
                          Country is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-12 col-md-7 col-lg-7" *ngIf="states?.length <= 0">
                  <div class="form-group">
                    <label for="city">City <b class="text-danger">*</b></label>
                    <div class="input-group mb-3">
                      <input type="text" id="city" placeholder="City" class="form-control" [ngClass]="{
                          'is-invalid': submittedpayment && co.city.errors
                        }" formControlName="city" required />
                      <div *ngIf="submittedpayment && co.city.errors" class="invalid-feedback">
                        <div *ngIf="co.city.errors.required">
                          City is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-12 col-md-4 col-lg-4" *ngIf="states?.length > 0">
                  <div class="form-group">
                    <label for="city">City <b class="text-danger">*</b></label>
                    <div class="input-group mb-3">
                      <input type="text" id="city" placeholder="City" class="form-control" [ngClass]="{
                          'is-invalid': submittedpayment && co.city.errors
                        }" formControlName="city" required />
                      <div *ngIf="submittedpayment && co.city.errors" class="invalid-feedback">
                        <div *ngIf="co.city.errors.required">
                          City is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-12 col-md-3 col-lg-3" *ngIf="states?.length > 0">
                  <div class="form-group">
                    <label for="state">{{ stateTitle }} <b class="text-danger">*</b></label>
                    <div class="input-group mb-3">
                      <select id="state" class="form-select" [formControl]="state" formControlName="state" [ngClass]="{
                          'is-invalid': submittedpayment && co.state.errors
                        }" [required]="states.length > 0">
                        <option value="" selected disabled>Select State</option>
                        <option *ngFor="let state of states" [value]="state">
                          {{ state }}
                        </option>
                      </select>
                      <div *ngIf="submittedpayment && co.state.errors" class="invalid-feedback">
                        <div *ngIf="co.state.errors.required">
                          {{ stateTitle }} is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-12 col-md-2 col-lg-2">
                  <div class="form-group">
                    <label for="zip">{{ zipTitle }} <b class="text-danger">*</b></label>
                    <div class="input-group mb-3">
                      <input type="text" id="zip" placeholder="{{
                          zipTitle == 'Postal Code' ? 'P.C.' : zipTitle
                        }}" class="form-control" [ngClass]="{
                          'is-invalid': submittedpayment && co.zip.errors
                        }" formControlName="zip" required />
                      <div *ngIf="submittedpayment && co.zip.errors" class="invalid-feedback">
                        <div *ngIf="co.zip.errors.required">
                          {{ zipTitle }} is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-12 col-md-3 col-lg-3">
                  <button type="button" class="btn btn-primary" (click)="validateCustomer()">
                    Next
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <form *ngIf="customerCreated" (ngSubmit)="handleSubmit($event)" class="needs-validation"  novalidate>
          <div class="form__stripe mt-2">
            <div class="form-group">
              <div id="payment-request-button">
                <!-- A Stripe Element will be inserted here. -->
              </div>
              <div id="payment-element">
                <!--Stripe.js injects the Payment Element-->
              </div>
              <div class="row justify-content-center">
                <hr *ngIf="!isHidden" class="col-11" />
              </div>
              <div class="row mb-3">
                <div class="col-6 col-md-5 offset-md-1 fs-6 text-left text-bold" *ngIf="!isHidden">
                  Payment Total:
                </div>
                <div class="col-6 col-md-2 offset-md-3 fs-6 text-right text-bold" *ngIf="!isHidden">
                  {{ amountToPAYwTaxes.toFixed(2) | currency }}
                </div>
              </div>
              <div class="row justify-content-around">
                <div class="col-4">
                  <button id="submit" type="submit" class="btn btn-primary">
                    <div class="spinner hidden" id="spinner"></div>
                    <span id="button-text">Pay now</span>
                  </button>
                </div>
              </div>

              <div id="payment-message" class="hidden"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div #myAlert [hidden]="hideMyAlert">
  <form autocomplete="off">
    <div class="container text-start">
      <div class="hstack gap-3 fw-bold">
        <div class="p-2">Total</div>
        <div class="p-2 ms-auto"></div>
        <div class="vr"></div>
        <div class="p-2">$ {{ chargeAmount }}</div>
      </div>
      <hr />
      <div class="row">
        <div class="col-12">
          <div class="mb-3">
            <label class="form-label">Percentage to withhold from refund</label>
            <div class="input-group">
              <input class="form-control text-end" type="text" id="toSave" pattern="^[0-9]+"
                (keyup)="toSaveChange($event)" />
              <span class="input-group-text">%</span>
            </div>
            <div class="form-text">Saving $ {{ toSave }}</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="mb-3">
            <label class="form-label">Amount to refund</label>
            <div class="input-group">
              <span class="input-group-text">$</span>
              <input class="form-control" type="text" id="amount" pattern="^[0-9]+" (keyup)="amountChange($event)" />
            </div>
            <div class="form-text">{{ percent }} % to refund</div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- Modal Cards -->
<div class="modal fade" style="z-index: 9999 !important" id="cardsModal" data-bs-backdrop="static" data-bs-focus="false"
  data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="staticBackdropLabel">Payment Methods</h2>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <!--<div class="row">
            <div class="col-md-4 offset-md-8 text-right align-items-end justify-content-end">
              <span class="fs-6 me-2">Pending Amount: ${{rental.amountPending}}</span> <i class="fas fa-edit hoveredit"
                (click)="editPending()"></i>
            </div>
          </div>-->
          <!--<div class="row">
            <div class="col-md-3 offset-md-3 bg-primary">.col-md-3 .offset-md-3</div>
            <div class="col-md-3 offset-md-3 bg-danger">.col-md-3 .offset-md-3</div>
          </div>-->
          <div class="row mt-3 pb-2 border-bottom">
            <span><i class="far fa-credit-card me-1"></i> Cards</span>
          </div>
          <div *ngIf="loadingcards" class="row mt-3 ps-2 pe-2 text-center align-content-center justify-content-center">
            <div class="spinner-border text-dark" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <span class="mt-2">{{ legendLoad }}</span>
          </div>
          <div *ngIf="!loadingcards" class="row mt-3 ps-2 pe-2">
            <div class="col-12 mb-2">
              <span><b>1.-</b> Select a Payment Method</span>
            </div>
            <ng-container *ngIf="paymentsmethods?.length == 0">
              <div class="col-md-12 p-2 text-center">
                <span>There's nothing to show</span>
              </div>
            </ng-container>
            <ng-container *ngIf="paymentsmethods?.length > 0">
              <ng-container *ngFor="let method of paymentsmethods; let j = index">
                <div class="col-4 mt-2">
                  <div [ngClass]="{ borderselected: method.isSelected }"
                    class="carddiv container-fluid rounded border shadow-sm p-2 text-center"
                    (click)="selectPayment(method, j)">
                    <span *ngIf="method.isSelected" class="text-success checkmark"><i
                        class="fas fa-check-circle"></i></span>
                    <span class="namecard mb-1">{{
                      method.charges.data[0].shipping?.name
                      }}</span>
                    <br />
                    <span>
                      <img src="../../../assets/images/cards/{{
                          method.charges.data[0].payment_method_details.card
                            .brand
                        }}.png" alt="mastercard" height="20" class="me-3" />
                      <span class="text-left">****
                        {{
                        method.charges.data[0].payment_method_details.card
                        .last4
                        }}</span>
                    </span>
                  </div>
                </div>
              </ng-container>
              <div class="col-4 mt-2">
                <div [ngClass]="{ borderselected: selectedNew }"
                  class="carddiv2 container-fluid rounded border shadow-sm p-2 text-center"
                  (click)="selectPayment('', 0, true)">
                  <span *ngIf="selectedNew" class="text-success checkmark"><i class="fas fa-check-circle"></i></span>
                  <span class="mb-1"><i class="fas fa-plus-circle"></i> Create New
                  </span>
                  <br />
                  <span> Payment Method </span>
                </div>
              </div>
            </ng-container>
            <div class="col-12 mb-2 mt-2">
              <span><b>2.-</b> Select Operation</span>
            </div>
            <div class="col-4">
              <div class="d-grid gap-2">
                <button type="button" class="btn btn-sm btn-outline-primary" type="button" id="btnAddCharge"
                  (click)="addChargePopup()" [disabled]="!selectedPayment">
                  <i class="fas fa-plus-square me-2"></i> Add New Payment
                </button>
              </div>
            </div>
            <div class="col-4">
              <div class="d-grid gap-2">
                <button type="button" class="btn btn-sm btn-outline-primary" id="btnAddSP" (click)="addSecurityPopup()"
                  [disabled]="!selectedPayment">
                  <i class="fas fa-plus-square me-2"></i> Add Security Deposit
                </button>
              </div>
            </div>
            <div class="col-4">
              <div *ngIf="rental && rental.amountPending > 0" class="d-grid gap-2">
                <button
                  type="button"
                  class="btn btn-sm btn-outline-primary"
                  type="button"
                  id="btnAddCharge"
                  (click)="addCompletePopup()"
                  [disabled]="!selectedPayment"
                >
                  <i class="fas fa-check"></i> Complete Payment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--New modal charge-->
<div #chargesModal [hidden]="hideChargeModal" class="container-fluid mt-2 text-left">
  <form>
    <div class="mb-3">
      <label for="exampleInputEmail1" class="form-label">Amount</label>
      <div class="input-group mb-3">
        <span class="input-group-text" id="basic-addon1">$</span>
        <input [ngClass]="{ invalidinput: chargeamount <= 0 && submittedmodal }" type="text" class="form-control"
          placeholder="Charge Amount" name="chargeamount" [(ngModel)]="chargeamount" (keyup)="changeAllPrice('charge')"
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');" />
      </div>
    </div>
    <div class="mb-3">
      <label for="exampleInputPassword1" class="form-label">Description</label>
      <input [ngClass]="{ invalidinput: chargedescription == '' && submittedmodal }" type="text" class="form-control"
        placeholder="Description" name="chargedescription" [(ngModel)]="chargedescription" />
    </div>
    <div class="mb-3 form-check fs-6">
      <input type="checkbox" class="form-check-input" name="applytaxes" [checked]="applytaxes"
        (change)="changeAllPrice('taxes')" />
      <label class="form-check-label" for="exampleCheck1">Apply Taxes & Fees</label>
    </div>
    <div *ngIf="totalwithtaxes != ''" class="mb-3 fs-6">
      <span> {{ totalwithtaxes }} </span> <span style="font-weight: bold; color: #0d6efd;">{{ totalWithTaxesAmount | currency }}</span>
    </div>
  </form>
</div>
<!--New modal Security Deposit-->
<div #securityModal [hidden]="hideSecurityModal" class="container-fluid mt-2 text-left">
  <form>
    <div class="mb-3">
      <label for="exampleInputEmail1" class="form-label">Amount<b style="color: red"> *</b></label>
      <div class="input-group mb-3">
        <span class="input-group-text" id="basic-addon1">$</span>
        <input [ngClass]="{ invalidinput: chargeamount <= 0 && submittedmodal }" type="text" class="form-control"
          placeholder="Charge Amount" name="chargeamount" [(ngModel)]="chargeamount"
          (keyup)="changeAllPrice('security')"
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');" />
      </div>
    </div>
    <div class="mb-3">
      <label for="exampleInputPassword1" class="form-label">Description<b style="color: red"> *</b></label>
      <input [ngClass]="{ invalidinput: chargedescription == '' && submittedmodal }" type="text" class="form-control"
        value="Security Deposit" disabled />
    </div>
    <div class="mb-3 form-check fs-6">
      <input type="checkbox" class="form-check-input" name="applytaxes" [checked]="applytaxes"
        (change)="changeAllPrice('taxes')" />
      <label class="form-check-label" for="exampleCheck1">Apply Taxes & Fees</label>
    </div>
    <div *ngIf="totalwithtaxes != ''" class="mb-3 fs-6">
      <span> {{ totalwithtaxes }} </span> <span style="font-weight: bold; color: #0d6efd;">{{ totalWithTaxesAmount | currency }}</span>
    </div>
  </form>
</div>
<!--New modal Complete Payment-->
<div #completeModal [hidden]="hideCompleteModal" class="container-fluid mt-2 text-left">
  <form>
    <div class="mb-3">
      <label for="exampleInputEmail1" class="form-label">Amount<b style="color: red"> *</b></label>
      <div class="input-group mb-3">
        <span class="input-group-text">$</span>
        <input [ngClass]="{ invalidinput: chargeamount <= 0 && submittedmodal }" type="text" class="form-control"
          placeholder="Charge Amount" name="chargeamount" [(ngModel)]="chargeamount"
          (keyup)="changeAllPrice('complete')"
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');" />
        <span class="input-group-text">USD</span>
      </div>
    </div>
    <div class="mb-3 d-flex align-items-center justify-content-between">
      <label class="form-check-label mb-0" for="exampleCheck1">
        <i class="fas fa-info-circle text-secondary" tooltip="The amount to be paid will include the percentage of taxes and fees imposed by the company."></i>
        <ng-container *ngIf="applytaxes; else notaxes">
          Calculate taxes &amp; fees
        </ng-container>
        <ng-template #notaxes>
          Taxes &amp; Fess are already included
        </ng-template>
      </label>
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch" [checked]="applytaxes"
          (change)="changeAllPrice('taxes')">
      </div>
    </div>
    <div class="mb-3 pt-3">
      <div class="col-md-12 text-center justify-content-center">
        The client will be charged
      </div>
      <div class="col-md-12 fs-3 text-primary text-center justify-content-center">
        <b>{{ totalWithTaxesAmount | currency }}</b>
      </div>
    </div>
    <div class="mb-3 mt-2">
      <span class="fw-bold">Changes</span>
    </div>
    <div class="mb-3">
      <table class="w-100 table-borderless text-center">
        <thead>
          <tr class="fs-6">
            <th class="col-auto fw-light">Booking Total</th>
            <th class="col-auto fw-light">
              <i class="small-text fas fa-info-circle text-secondary" tooltip="This will be the new 'Total Amount Paid', after payment succeeds."></i>
              Total Paid
            </th>
            <th class="col-auto fw-light">
              <i class="small-text fas fa-info-circle text-secondary" tooltip="This will be the new 'Pending' amount, after payment succeeds."></i>
              Pending
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="fs-5">
            <td class="text-primary"><b>{{ rental.cost | currency }}</b></td>
            <td class="text-primary"><b>{{ (rentalTotalPaid + totalWithTaxesAmount) | currency }}</b></td>
            <td>
              <b [ngClass]="{'price-text': pendingAmount == 0,'pending-text': pendingAmount > 0, 'refund-text': pendingAmount < 0}">
                <span *ngIf="applytaxes">{{ pendingAmount | currency }}</span>
                <del *ngIf="!applytaxes" class="text-secondary">$0.00</del>
              </b>
            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="!applytaxes" class="col-md-12 mt-2 fs-5 text-center justify-content-center">
        <span class="small">This will set the <strong class="text-danger">pending</strong> amount to <strong class="text-danger">$0.00</strong>.</span>
      </div>
    </div>
  </form>
</div>

<!--New modal Calculator-->
<div #calculatorModal [hidden]="hideCalculatorModal" class="container-fluid mt-2 text-left">
  <form>
    <div class="mb-3">
      <label for="inputEmail4" class="form-label">People<b style="color: red"> *</b></label>
      <input type="number" [ngClass]="{ invalidinput: peoplenumber <= 0 }" name="peoplenumber"
        [(ngModel)]="peoplenumber" class="form-control" placeholder="Example: 1" (keyup)="calculateSplit()"
        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
    </div>
    <div class="mb-3">
      <label for="exampleInputEmail1" class="form-label">Total Payment (before Tax)<b style="color: red"> *</b></label>
      <div class="input-group mb-3">
        <span class="input-group-text" id="basic-addon1">$</span>
        <input [ngClass]="{ invalidinput: pendingtotal <= 0 }" type="number" class="form-control"
          placeholder="Charge Amount" name="pendingtotal" [(ngModel)]="pendingtotal" (keyup)="calculateSplit()"
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
      </div>
      <div id="emailHelp" class="form-text">
        Amount to bill each person: ${{ totalonewithout }}
      </div>
      <div id="emailHelp" class="form-text">
        Total amount each person will be billed after taxes: ${{ totalonewith }}
      </div>
      <div id="emailHelp" class="form-text">{{ totalaftertax }}</div>
    </div>
  </form>
  <!--<table class="table fs-6 table-bordered">
    <thead class="table-info">
      <tr>
        <td colspan="2" class="text-center">Total</td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Total per person without taxes & fees</td>
        <td>${{totalonewithout}}</td>
      </tr>
      <tr>
        <td>Total per person with taxes & fees</td>
        <td>${{totalonewith}}</td>
      </tr>
    </tbody>
  </table>-->
</div>

<div #cardModal [hidden]="hideCardModal" class="container-fluid mt-2 text-left"></div>

<div class="modal fade" id="bookings-timeslot-list" aria-hidden="true" data-bs-focus="false"
  aria-labelledby="exampleModalLabel" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h2>Select an Available Time</h2>
    </div>
      <div class="modal-body">
        <ng-container *ngIf="locationMap && selectedLocationID && backendBookingData && !runningAvailabilityCheck && bookingListModalOpen">
          <app-booking-timeslot-list [timezone]="locationMap[selectedLocationID]['timezone']" (getTimeslotSelection)="getTimeslotSelection($event)" (cancelTimeslotSelection)="closeBookingTimeslotList()"
            [timeslotArr]="backendBookingData['timeslotArr']" [productsMap]="productsMap" [itemsInCartObj]="itemsInCartObj" [rentalTypeOptions]="rentalTypeOptions"
            [hoursArray]="hoursArray" [bookingTimeSlotSelected]="bookingTimeSlotSelected">
          </app-booking-timeslot-list>
        </ng-container>
      </div>
    </div>
  </div>
</div>

