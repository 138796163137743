import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent } from 'angular-calendar';
import { CurrentUserService } from '../../services/current-user.service';
import { RentalService } from 'src/app/services/rental.service';
import { CalendarOptions, EventClickArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import * as moment from 'moment';
import { DEFAULT_THEME } from 'src/app/utils/config/themeDefault.config';
/*
  interface  customCndelarEvent
  type:  object
  elements:  obj  &  total

**/

interface CustomCalendarEvent extends CalendarEvent {
  obj?: any;
  total?: number;
  backgroundColor?: any;
  borderColor?: any;
  display?: any;
  description?: any;
  textColor?: any;
}

@Component({
  selector: 'app-booking-calendar',
  templateUrl: './booking-calendar.component.html',
  styleUrls: ['./booking-calendar.component.scss'],
})
export class BookingCalendarComponent implements OnInit {
  /*
  * constructor
    -NgModal
    -CurrentUserService
    -RentalService

  **/

  public backgroundColor: any;
  currentUserId: string;
  protected color: string;
  constructor(
    private modal: NgbModal,
    private currentUser: CurrentUserService,
    private rentalService: RentalService,
  ) {}

  //directive  acces  to modalContent

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  /*
  public  modalData,this point  is  access   to  EventClickArg, here we passed  data,from
  event: this.booking to modal html
  **/

  public modalData: {
    clickInfo: EventClickArg;
  };

  /*
   public  bookings  is  a  public  array, where we assign  the  events  in
   candelarOptions

   **/

  public bookings = [];
  public colors;
  public font;
  public usercolor;

  // candelarOptions,here we used the object  of  fullcalendar

  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],

    headerToolbar: {
      left: 'prev,next',
      center: 'title',
      right: 'dayGridMonth,dayGridWeek,timeGridDay,listWeek',
    },

    initialView: 'dayGridMonth',
    weekends: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    events: this.bookings,
    eventClick: this.openmodal.bind(this),
  };

  //rendered function  this.getRentalDays()
  ngOnInit(): void {
    this.getRentalDays();
  }

  /*function getRentalDays() and  subscribe

  here  this.booking , we assign data  information

**/

  getRentalDays() {
    this.rentalService
      .getNotCancelledRentalsByCompanyID(this.currentUser.currentUser.companyId)
      .subscribe((rentals) => {
        let data = rentals;

        this.bookings = data.map((rental) => {

          this.color = DEFAULT_THEME.getColorVariant();

          let reservedDate = rental.statusDate.isReserved instanceof Date ? moment(
            rental.statusDate.isReserved
          ).format('MMMM Do YYYY') : '';
          let eventObj: CustomCalendarEvent;
          //color event for user  name

          let totalValue = 0; //  if  productos  length  is    elderly  than  1,
          if (rental.products && rental.products.length > 0) {
            rental.products.forEach((product) => {
              totalValue += product.subtotal;
            });
          }

          let date = '';
          let dayStart;
          let dayEnd;
          if (rental.rentalType == 'byHour') {
            dayStart = moment(rental.dayStart.seconds * 1000).format(
              'MMM DD YYYY, h:mm a'
            );
            dayEnd = moment(rental.dayEnd.seconds * 1000).format('h:mm a');
            date = ' - ' + dayStart + ' - ' + dayEnd;
            eventObj = {
              start: new Date(rental.dayStart.seconds * 1000),
              end: new Date(rental.dayEnd.seconds * 1000),
              title:
                moment(rental.dayStart.seconds * 1000).format('h:mm a') +
                ' - ' +
                ' #' +
                rental.rentalNumber +
                ' - ' +
                rental.userInfo.name +
                ' ' +
                rental.userInfo.lastName,
              obj: rental,
              total: totalValue,
              backgroundColor: this.color,
              borderColor: this.color,
              display: 'block',
              description:
                '#' +
                rental.rentalNumber +
                ' - ' +
                rental.userInfo.name +
                ' ' +
                rental.userInfo.lastName,
              textColor: DEFAULT_THEME.fontColor,
            };

            return eventObj;
          } else {
            //eventObj  on  the  full-callendar

            eventObj = {
              start: new Date(rental.dayStart.seconds * 1000),
              end: new Date(rental.dayEnd.seconds * 1000),
              title:
                ' All day - ' +
                '#' +
                rental.rentalNumber +
                ' - ' +
                rental.userInfo.name +
                ' ' +
                rental.userInfo.lastName,
              obj: rental,
              total: totalValue,
              backgroundColor: this.color,
              borderColor: this.color,
              display: 'block',
              description:
                '#' +
                rental.rentalNumber +
                ' - ' +
                rental.userInfo.name +
                ' ' +
                rental.userInfo.lastName,
              textColor: DEFAULT_THEME.fontColor,
            };
            return eventObj;
          }
        });

        this.calendarOptions.events = this.bookings;
      });
  }

  //openmodal, this  function is for passed calendar booking, to modal

  openmodal(clickInfo: EventClickArg): void {
    this.modalData = { clickInfo };
    this.modal.open(this.modalContent, { size: 'lg' });

    //color theme selector
    this.currentUserId = this.currentUser.currentUser.id;
    this.colors = DEFAULT_THEME.value;
    this.font = DEFAULT_THEME.fontColor;
  }
}
