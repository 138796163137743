<table class="table table-bordered">
  <thead [style.background]="themeSelect" [style.color]="themeText">
    <tr>
      <th (click)="toggleOrder('name')">
        Name
        <i
          class="fas"
          [ngClass]="{
            'fa-sort-up': isNameAscending && sortBy == 'name',
            'fa-sort-down': !isNameAscending && sortBy == 'name'
          }"
        ></i>
      </th>
      <th (click)="toggleOrder('size')">
        Size
        <i
          class="fas"
          [ngClass]="{
            'fa-sort-up': isSizeAscending && sortBy == 'size',
            'fa-sort-down': !isSizeAscending && sortBy == 'size'
          }"
        ></i>
      </th>
      <th>Description</th>
      <th>Status</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>

    <ng-container *ngFor="let p of getProducts(); let i = index">
      <ng-container>
        <tr>
          <td name="Name" class="">
            {{ p.productName }}

            <ng-container *ngIf="isRebuild; else original">
              <a
                href=""
                [routerLink]="['/partner/inventory/', p.productGroupID]"
                >#{{ (p.productNumber) ? p.productNumber : p.id || p.productGroupID }}</a
              >
            </ng-container>

            <ng-template #original>
              <a
                href=""
                [routerLink]="['/partner/inventory/', p.id]"
                >#{{ (p.productNumber) ? p.productNumber : p.id || p.productGroupID }}</a
              >
            </ng-template>
          </td>
          <td name="Size" class="">{{ p.productSize }}</td>
          <td name="Description" class="">
            <div *ngIf="productStatus === 'Unavailable'; else otherStatus">
              {{ p.unavailableReason }}
            </div>

            <ng-template #otherStatus>
              {{ p.maintenanceReason }}
            </ng-template>
          </td>
          <td name="Status" class="badge-container">
            <ng-container *ngIf="!p.isEditing; else editMaintenance">
              <div>
                <ng-container *ngIf="!p.isAvailable">
                  <span class="badge badge-secondary">Unavailable</span>
                </ng-container>
                <ng-container *ngIf="p.isAvailable">
                  <span class="badge badge-success">Available</span>
                </ng-container>
                <ng-container *ngIf="p.isDamaged">
                  <span class="badge badge-danger">Damaged</span>
                </ng-container>
                <ng-container *ngIf="p.isTuneUp">
                  <span class="badge badge-info">TuneUp</span>
                </ng-container>
                <ng-container *ngIf="p.isCleaning">
                  <span class="badge badge-warning">Cleaning</span>
                </ng-container>
              </div>
            </ng-container>
            <ng-template #editMaintenance>
              <div class="row align-items-center">
                <div class="col">
                  <label class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      [checked]="!p.isAvailable"
                      (click)="onCheckboxChange(p, 'isAvailable', $event)"
                    />
                    Unavailable
                  </label>
                </div>
                <div class="col">
                  <label class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      [checked]="p.isDamaged"
                      (click)="onCheckboxChange(p, 'isDamaged', $event)"
                    />
                    Damaged
                  </label>
                </div>
                <div class="col">
                  <label class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      [checked]="p.isTuneUp"
                      (click)="onCheckboxChange(p, 'isTuneUp', $event)"
                    />
                    TuneUp
                  </label>
                </div>
                <div class="col">
                  <label class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      [checked]="p.isCleaning"
                      (click)="onCheckboxChange(p, 'isCleaning', $event)"
                    />
                    Cleaning
                  </label>
                </div>
              </div>
            </ng-template>
          </td>
          <td name="Actions">
            <ng-container *ngIf="!p.isEditing; else editButtonsMaintenance">
              <div class="btn-container">
                <button
                  class="btn btn-success btn-sm"
                  (click)="toggleEditMaintenance(p, i)"
                >
                  <i class="fa fa-pencil"></i>
                </button>
                <button
                  class="btn btn-primary btn-sm"
                  (click)="openHistoryModal(p)"
                >
                  History
                </button>
              </div>
            </ng-container>
            <ng-template #editButtonsMaintenance>
              <div class="btn-container">
                <button
                  class="btn btn-success btn-sm"
                  (click)="toggleEditMaintenance(p, i, true)"
                >
                  <i class="fas fa-check"></i>
                </button>
                <button
                  class="btn btn-danger btn-sm"
                  (click)="toggleEditMaintenance(p, i, false, true)"
                >
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </ng-template>
          </td>
        </tr>
      </ng-container>
      <!-- Modal History -->
      <div
        class="modal"
        tabindex="-1"
        role="dialog"
        id="pHistory{{ p.id }}{{ productStatus.replace(' ', '') }}"
      >
        <div class="modal-dialog modal-xl" role="document">
          <div class="modal-content" *ngIf="modalOpen[p.id]">
            <div class="modal-header">
              <h2 class="modal-title">
                <b>{{ p.productName }}</b> History
              </h2>
              <button type="button" class="close" (click)="closeModal(p.id)">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" *ngIf="modalOpen[p.id]">
              <ng-container *ngIf="modalOpen[p.id]">
                <app-product-history [productID]="p.id"></app-product-history>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </tbody>
</table>
