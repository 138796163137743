import { Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';
import { Subscription, filter } from 'rxjs';
import { ImageLibraryService } from 'src/app/services/image-library.service';
import { v4 as uuid } from 'uuid';
import Swal from 'sweetalert2';
import * as _ from 'lodash';
import { Location } from '@angular/common'
import { WidgetService } from 'src/app/services/widget.service';
import { WidgetType } from 'src/app/models/widget.model';


@Component({
  selector: 'app-saved-widget-page',
  templateUrl: './saved-widget-page.component.html',
  styleUrls: ['./saved-widget-page.component.scss']
})
export class SavedWidgetPageComponent {
  @Output() onBindVariableChange: EventEmitter<any> = new EventEmitter();


  widgetId;
  subs = new Subscription(); // group of subscriptions
  widget;
  component;
  isDataAvailable = false;
  public croppedImage: any = '';

  layoutForm;
  toggleAccordion;
  allProducts = [];
  public selectedAddon;
  widgets;
  widgetsLoaded = false;
  productGroups;
  groupsLoaded = false;
  originalWidget // Keep track of if there were any changes made to the widget
  isAlertClosed = false;
  currentUrl
  previousUrl


  componentSettings = {
    background_color: '',
    border: false,
    rounded_corners: 0,
    padding_top: 10,
    padding_right: 0,
    padding_bottom: 10,
    padding_left: 0,
    height: 0,
    alignment: '',
    text_color: '',
    text_size: 0,
  };


  constructor(private router: Router, private activatedRoute: ActivatedRoute, private productsService: ProductsService,
    private imageLibraryService: ImageLibraryService, private location: Location, private widgetService: WidgetService){};

  async ngOnInit() {
    // Gets Id from params
    await this.subs.add(this.activatedRoute.params.subscribe(
      (params: Params) => {
          this.widgetId = params['widgetId'];        
      }));

    this.productsService.getWidgetById(this.widgetId).then(data => { 
      this.widget = data;
      
      // if(this.widget.widgetType != 'image' && this.widget.widgetType != 'textarea' && this.widget.widgetType != "heightInput"){
      //   this.originalInput = JSON.parse(JSON.stringify(this.widget.element.inputValue));
      // }
      // else if(this.widget.widgetType == "heightInput"){
      //   this.heightInputOriginalInputFeet = JSON.parse(JSON.stringify(this.widget.element.feetInput));
      //   this.heightInputOriginalInputInch = JSON.parse(JSON.stringify(this.widget.element.inchInput));
      // }

      this.originalWidget = JSON.parse(JSON.stringify(this.widget));
 
      console.log(this.widget)
      this.getSubs();
    });

  
  }

  getSubs(){
    this.subs.add(this.imageLibraryService.selectedImg.subscribe((imgInfo) =>{ 
      this.croppedImage = imgInfo.url; 
      this.closeImgGallery()
      this.widget.element.image_link = this.croppedImage;
    }));
    
    if(this.widget.widgetType == 'product'){
      // Gets all product group information
      this.subs.add(this.productsService.getProductGroups().subscribe(data => {
        this.productGroups = data;
        // Calls function after first load
        if(this.groupsLoaded){
          this.setProductWidgetData();
        }
        this.groupsLoaded = true;
        this.areSubsLoaded();
      }))

    }

    // Gets saved widgets for the accordian display and set display
    this.subs.add(this.widgetService.getWidgets().subscribe(data =>{
      this.widgets = data;
      this.widgetsLoaded = true;
      this.areSubsLoaded();
    }))
  }

  async areSubsLoaded(){
    if(this.widget.widgetType == 'product'){
      if(this.groupsLoaded && this.widgetsLoaded){
        this.getAllData()
      }
    }
    else{
      if(this.widgetsLoaded){
        this.getAllData()
      }
    }

  }

  async getAllData(){
    console.log("GET ALL DATA")
    await this.updateSavedWidgets();
    if(this.widget.widgetType == 'product'){
      await this.setProductWidgetData();
    }
    console.log("AVAIL")
    console.log(this.widget)
    this.isDataAvailable = true;
  }

    // Gets the data for saved widgets to make sure they are up to date when loaded in
    updateSavedWidgets(){
      this.widgets.forEach(item => {
        if(item.id == this.widget.savedWidgetId){
          this.widget['element'] = item.element;
          this.widget['savedWidgetId'] = item.id;
          this.widget['id'] = this.widget.id;  
        }
      })
    }
  
  
  setProductWidgetData(){
    let newWidgetElement = JSON.parse(JSON.stringify(this.widget['element']));

    // If product widget has a manual price do not update prices saved
    this.productGroups.forEach(group => {
        if(group.id == newWidgetElement['groupId']){
          newWidgetElement.name = group.groupName;
          newWidgetElement.description = group.groupDescription;
          newWidgetElement.groupId = group.id;
          newWidgetElement.is24hrsPrice = group.is24hrsPrice;
          newWidgetElement['priceByDayOriginal'] = group.priceByDay;
          newWidgetElement['priceByHourOriginal'] = group.priceByHour;
        }
    })

    this.widget['element'] = newWidgetElement;

  }

  async onSaveWidget(){
    
    Swal.fire({
      title: 'Saving',
    })

    Swal.showLoading();

    let newWidget = await this.cleanWidget();
    const validate = this.productsService.validateWidgetsValue(newWidget);
    if(validate) {
      delete newWidget.element['invalid']
      this.productsService.updateWidget(this.widgetId, newWidget).then(data => {

        this.originalWidget = newWidget;
        
  
        Swal.close();
        Swal.fire({
          title: "Completed!",
          icon: "success",
          showConfirmButton: true,
          allowEnterKey: true,
        })
      });
    }else {
      this.widget.element['invalid'] = true;
      Swal.close();
    }
  }

  cleanWidget(){

    let widgetStructureList = this.widgetService.getWidgetStructureList();

    let newWidget = JSON.parse(JSON.stringify(this.widget));
    // Make sure the saved widget keep the right price information based on if its a manual price or not
    
    newWidget['lastModified'] = new Date();

    // Change input to original value
    widgetStructureList.forEach(item => {

      if (item.widgetType == newWidget.widgetType) {
    
        if (this.widget.widgetType == WidgetType.heightInput) {
          newWidget.element.feetInput = item.element?.feetInput ?? 0;
          newWidget.element.inchInput = item.element?.inchInput ?? 0;
        }

        else if(this.widget.widgetType == WidgetType.dropdown || this.widget.widgetType == WidgetType.radios) {
          newWidget.element.inputValue = item.element?.inputValue ?? '';
          newWidget.element.options.forEach((option, i) => {
            option.is_selected = item.element.options[i]?.is_selected ?? false;
          })
        }
        
        else if (this.widget.widgetType == WidgetType.quantity || this.widget.widgetType == WidgetType.weightInput) {
          newWidget.element.inputValue = item.element?.inputValue ?? 0;
        }
        
        else if (this.widget.widgetType == WidgetType.checkbox) {
          newWidget.element.inputValue = item.element?.inputValue ?? false;
        }

        else if (this.widget.widgetType != WidgetType.image && this.widget.widgetType != WidgetType.textarea && this.widget.widgetType != WidgetType.price) {
          newWidget.element.inputValue = item.element?.inputValue ?? null;
        }

        //Make sure all the product widget option values are 0
        else if (newWidget.widgetType == WidgetType.product) {
          delete newWidget.element.priceByDayOriginal;
          delete newWidget.element.priceByHourOriginal; 

          if (newWidget['element']['isDropdown']) {
            newWidget['element']['options'].forEach(option => {
              if(option.is_selected){
                option.is_selected = false;
              }
            })
          }
          else {
            newWidget['element']['options'].forEach(option => {
              option.inputValue = 0;
            })
          }
        }
      }
    })
    
    return newWidget
  }
  
  
  // Navigates back to inventory page builder home page
  async onGoBack(){
    let newWidget = this.cleanWidget();
    let needSave = await this.doesPageNeedSave(newWidget);

    if(needSave){
      Swal.fire({
        title: 'Leave Page?',
        html: 'Changes that were made will not be saved',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#7066e0',
        cancelButtonText: 'Leave',
        showConfirmButton: true,
        confirmButtonColor: '#6e7881',
        confirmButtonText: 'Cancel',
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isDismissed) {
          // this.router.navigate(['/partner/inventoryPageBuilder']) 
          this.location.back();
        } 
      });
    }
    else{
      // this.router.navigate(['/partner/inventoryPageBuilder'])
      this.location.back(); 
    }
  }

  
  doesPageNeedSave(newWidget){
    let needToSavePage;

    if(_.isEqual(newWidget['element'], this.originalWidget['element'])){
      needToSavePage = false;
    }
    else {
      needToSavePage = true;
    }
    return needToSavePage
  }

  closeAlert(){
    this.isAlertClosed = true;
  }


  openImgGallery(){
    $('#imgGallery').modal('show');
  }

  closeImgGallery(){
    $('#imgGallery').modal('hide');
  }

// ---------------- Images ----------------------- //
  public handleInputChange(e: any) {
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const pattern = /image-*/;
    const reader = new FileReader();

    if (!file.type.match(pattern)) {
      alert('Invalid format');
      return;
    }

    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e: any) {
    this.widget.element.image_url = e.target.result;    
  }


  ngOnDestroy(): void {
    this.subs.unsubscribe(); // unsubscribe from all subscriptions in this group
  }


}
