import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

// Services
import { AffiliateService } from 'src/app/services/affiliate.service';

// Libraries
import Swal from 'sweetalert2';

// Models
import { Affiliate, defaultValues } from 'src/app/models/storage/affiliate.model';

@Component({
  selector: 'app-affiliateCRUD_Modal',
  templateUrl: './affiliateCRUD_Modal.component.html',
  styleUrls: ['./affiliateCRUD_Modal.component.css']
})
export class AffiliateCRUD_ModalComponent implements OnInit {
  @Input() public isEditing: boolean;
  @Input() public affiliateObj?: Affiliate | null
  @Input() public affiliateID?: string | null;
  @Input() public companyID?: string | null;
  @Output() private affiliateFormSubmission = new EventEmitter<string>();

  private ogAffiliateObj: Affiliate | null = null; // Original affiliate object - Populated when affiliate obj is being edited

  public awaitingDB: boolean = false;
  public affiliateForm: FormGroup;

  constructor(private affiliateService: AffiliateService) { }

  public ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.affiliateForm = new FormGroup({
      name: new FormControl("", [Validators.required]),
      landingURL: new FormControl("", [Validators.required, this.validURLFormatValidator()]),
      active: new FormControl(true, [Validators.required]) // Default to 'active'
    })
  }

  validURLFormatValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null; // don't validate empty value
      }
      const regex = /^(https?):\/\/[^\s$.?#].[^\s]*$/;
      return regex.test(control.value) ? null : { 'urlInvalid': true };
    };
  }

  // private firstCharAlphanumericValidator(): ValidatorFn {
  //   return (control: AbstractControl): ValidationErrors | null => {
  //     const value = control.value || '';
  //     if (value && !/^[a-zA-Z0-9]/.test(value.charAt(0))) {
  //       return { 'firstCharAlphanumeric': true };
  //     }
  //     return null;
  //   };
  // }

  // private limitConsecutiveSlashesValidator(maxConsecutive: number = 1): ValidatorFn {
  //   return (control: AbstractControl): ValidationErrors | null => {
  //     // Regex to match more than 'maxConsecutive' slashes in a row
  //     const regex = new RegExp(`/{${maxConsecutive + 1},}`);
  //     return !regex.test(control.value) ? null : { 'tooManyConsecutiveSlashes': true };
  //   };
  // }

  // private noQueryParamsValidator(): ValidatorFn {
  //   return (control: AbstractControl): ValidationErrors | null => {
  //     const regex = /^[^?]*$/;
  //     const valid = regex.test(control.value);
  //     return valid ? null : { 'queryParamsPresent': true };
  //   };
  // }

  public setFormWithPageValues(affiliate: Affiliate): void {
    this.isEditing = true;
    this.affiliateForm.controls['name'].setValue(affiliate.name);
    this.affiliateForm.controls['landingURL'].setValue(affiliate.landingURL ? affiliate.landingURL : ''); // set form value to empty string in case it's not defined
    this.affiliateForm.controls['active'].setValue(affiliate.active ? affiliate.active : false); // set form value to false in case it's not defined
    this.affiliateID = affiliate.id;
    this.ogAffiliateObj = affiliate;
  }

  public resetForm(): void {
    this.affiliateForm.reset();
    this.affiliateForm.controls['active'].setValue(true); // Default to 'active'
  }

  public onSubmit(): void {
    this.awaitingDB = true;

    if (this.isEditing) {
      this.awaitingDB = true;
      let updateObj = {}; // holds all the properties that need to be updated

      for (let control in this.affiliateForm.controls) { // Loop through form values and update the affiliate object
        if (!this.ogAffiliateObj[control]) { // Control not found in OG affiliate object
          if (control in defaultValues) { // Check if control exists in affiliate interface then set to default value
            updateObj[control] = defaultValues[control];
          }
          else {
            // Control not found in original affiliate object, please update interface and ensure keyname matches form control name
            this.awaitingDB = false;
            throw Error('Control not found in interface');
          }
        }

        if (this.affiliateForm.get(control)?.value !== this.ogAffiliateObj[control]) { // If the form value is different from the original affiliate object
          updateObj[control] = this.affiliateForm.get(control)?.value;
        }
      }

      this.affiliateService.updateAffiliate(this.affiliateID, updateObj).then(() => {
        this.awaitingDB = false;
        this.deliverToast('Affiliate Vendor has been successfully updated!', 'success');
        // Update the affiliate in the database
        this.affiliateFormSubmission.emit('success')
      })
    }
    else {
      // Create a new affiliate in the database
      let name = this.affiliateForm.get('name')?.value;
      let landingURL = this.affiliateForm.get('landingURL')?.value;
      let active = this.affiliateForm.get('active')?.value;

      let affiliateObj = {
        name: name,
        landingURL: landingURL,
        companyID: this.companyID,
        dateCreated: new Date(),
        softDeleted: false,
        active: active
      }
      this.affiliateService.createAffiliate(affiliateObj).then(() => {
        this.awaitingDB = false;
        this.deliverToast('Affiliate Vendor Created!', 'success');
        this.affiliateFormSubmission.emit('success')
      });
    }
  }

  private deliverToast(title: string, icon: any): void {
    Swal.fire({
      toast: true,
      position: 'bottom-right',
      title: title,
      icon: icon,
      showConfirmButton: false,
      timer: 1500
    })
  }
}
