import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AbstractControl, FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { lastValueFrom, Subject, takeUntil } from 'rxjs';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input-gg';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import Swal from 'sweetalert2';
declare var Stripe;

//Models
import { Collection } from 'src/app/v2/models/collection-reference.model';
import { rental, CheckedOut, CheckedIn, RentalProduct, Preparedcard } from 'src/app/models/order-processing.model';
import { ProductV1 } from 'src/app/models/storage/product.model';
import { Maintenance } from 'src/app/models/storage/maintenance.model';
import { Rental } from 'src/app/models/storage/rental.model';

//Services
import { FirestoreService } from 'src/app/v2/services/firestore.service';
import { RentalServiceV2 } from 'src/app/v2/services/rental.service';
import { CheckoutService } from 'src/app/services/checkout.service';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { ProductsService } from 'src/app/services/products.service';
import { LogService } from 'src/app/services/log.service';
import { MaintenanceService } from 'src/app/services/maintenance.service';
import { RentalService } from 'src/app/services/rental.service';
import { OrderProcessingService } from 'src/app/services/order-processing.service';
import { AvailabilityService } from 'src/app/services/availability.service';
import { DateTime } from 'luxon';

interface Country {
  shortName: string;
  name: string;
}

interface PrepareItem {
  id: string,
  position: number,
  companyID: string,
  paymentID: string,
  date: string,
  formattedDayStart: Date,
  formattedDayEnd: Date,
  dayStart: string,
  dayEnd: string,
  rentalNumber: string,
  productName1: string,
  productSize: string,
  productNumber: string,
  rentalCost: number,
  isPrepared: boolean,
  isCheckedOut: boolean,
  isChekedIn: boolean,
  paddons: any[],
  notes: string,
  shopComments?: string,
  isDeliver: boolean,
  deliverInformation: {
    name: string,
    address: string
  }
}

interface CheckoutRental extends Partial<Rental> {
  datestart: Date,
  isPrepared: boolean,
  dateend: Date
}

@Component({
  selector: 'app-order-processing',
  templateUrl: './order-processing.component.html',
  styleUrls: ['./order-processing.component.scss']
})
export class OrderProcessingComponent {
  private subs$ = new Subject<void>(); // Observable to manage subscriptions
  protected backgroundColor: string = '#114463';
  protected textColor: string = '#FFFFFF';
  protected currentDate: Date = new Date(new Date().setHours(0, 0, 0, 0));
  protected actualAvailableDate: Date;
  protected nextAvailableDate: Date;
  protected month: any = moment().format('MMMM'); //variable to get the actual month
  protected currentMomentDate: any = moment().format('dddd, LL'); //variable to get the actual date
  protected tomorrowMomentDate: any = moment().add(1, 'days').format('dddd, LL'); //Variable to get tomorrow day
  protected dt: any;
  protected rentalfull: any;
  protected today: any = moment().format('YYYY-MM-DD'); //Variable to get the actual day
  protected tomorrow: any = moment().add(1, 'days').format('YYYY-MM-DD'); //Variable to get tomorrow day
  protected weekdays: any = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  protected prepare: { today: PrepareItem[], nextday: PrepareItem[] } = {
    today: [],
    nextday: []
  };
  protected checkout: { today: CheckoutRental[], nextday: CheckoutRental[] } = {
    today: [],
    nextday: []
  };
  protected rentalaux: any;
  protected charges: any[] = [];
  protected lateProductList = []; //A list of all checkedin and checkedout rentals, used for the past due section
  protected recentlyCompletedRentals = []; //A list of all recently cheked in orders in the last 24 hours
  // Rxjs
  protected hideCheckIn: boolean = false;
  protected submittedpayment: boolean = false;
  protected submitted: boolean = false;
  protected city = new UntypedFormControl({ value: null, disabled: true });
  protected isLoading: boolean = true;
  protected productIsLoading: boolean = false;
  protected SearchCountryField = SearchCountryField;
  protected fullpayment: boolean = false;
  protected PhoneNumberFormat = PhoneNumberFormat;
  protected countries: Country[];
  protected isHidden: boolean = true;
  protected amountToPAYwTaxes: number = 0;
  protected CountryISO = CountryISO;
  protected preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.Mexico,
    CountryISO.Canada,
  ];
  protected maintenanceForm: UntypedFormGroup;
  protected products: UntypedFormArray;
  protected maintenanceSubmitted: boolean = false;

  protected phoneForm = new UntypedFormGroup({
    phone: new UntypedFormControl(undefined, [Validators.required]),
  });

  protected country = new UntypedFormControl({ value: 'US' }, [Validators.required]);
  protected states: string[];
  protected cities: string[];
  protected countriesWStates = [
    'Australia',
    'Austria',
    'Brazil',
    'Germany',
    'India',
    'Malaysia',
    'Mexico',
    'Micronesia',
    'Myanmar',
    'New Zealand',
    'Nigeria',
    'Palau',
    'South Sudan',
    'United States',
  ];
  protected isError: boolean = false;
  protected isCountrySelect: boolean = false;
  protected countriesWZipCode = ['United States', 'Philippines'];
  protected isAddPaymentMethod: boolean = false;

  protected state = new UntypedFormControl('');
  protected zipTitle: string = 'Zip';
  protected stateTitle: string = 'State';
  protected email = '';
  protected datepipe = new DatePipe('en-US');
  protected datenow3: any = this.datepipe.transform(this.currentMomentDate, 'YYYYMMdd'); //Varibale used to compare dates

  protected preparedcard: Preparedcard;
  protected checkoutcard: Preparedcard;
  protected checkincard: Preparedcard;
  protected ppupcoming: any[] = []; //Variable array to sabe the products that are pending to prepare but fot tomorrow
  protected rentalstocheckedin: any[] = []; //Variable to save the rentals to checked in
  // protected productsrental: rental[] = []; //Variable to save the rentals
  protected rentalDocumentMap: { [key: string]: rental } = {}; // Based off of the previous productsrental array of rentals
  protected total = 0;
  protected totalfees = 0;
  protected companyTax = 0;
  protected fleetTax = 0;
  protected totalfeesfleet = 0;
  protected positionci = 0;
  protected fleetTaxNumber = 0;
  protected deposits: any = [];
  protected priceobject = {
    title: '',
  };
  protected dataPI: any = {
    stripeID: '',
    paymentID: '',
    isTesting: '',
  };
  protected rentaltowaiver: rental;
  protected productsArray: ProductV1[] = [];
  protected checkedoutdata: CheckedOut;
  protected lastrental: Rental;
  protected checkedindata: CheckedIn;
  protected productdata: RentalProduct;
  protected maintenancereg: Maintenance = {};
  protected typemodal: any = '';
  protected id: any;
  protected position: number;
  protected isEditing = false;
  protected product: string;
  rental: Rental = {
    chargesID: undefined,
  };
  protected imgco = [];
  protected pdfco = [];
  protected imgci = [];
  protected myRadio = '';
  protected iproduct: number;
  protected company: any;
  protected productsselect = [];
  protected addonsresp: RentalProduct['addons'] = [
    {
      title: '',
    },
  ];
  protected waiversArray: any[];
  protected waiversSigned: number = 0;
  protected multipleWaivers: boolean = false;
  protected validateCharge: boolean = false;
  protected description: string = '';
  protected amount: number = 0;
  protected stripeID: string = '';
  protected stripePublicKey: string = '';
  protected stripe = Stripe(environment.stripe.key, {
    stripeAccount: environment.stripe.defaultAccount,
  });
  protected paymentElement: any;
  protected paymentElement2: any;
  protected clientSecret: any;
  protected elementTest: any;
  protected paymentIntentID: string;
  protected rentalUserInfo: any;
  protected checkoutForm: UntypedFormGroup;
  protected pastDueRental = []; //group of rental products that are past due
  protected myForm: FormGroup = this.fb.group({
    shopComments: ['', []],
  });
  protected typeModal: string;
  protected shopSaveButton: boolean = true;
  protected addonsbyproduct;
  protected imgResultBeforeCompress: string = '';
  protected imgResultAfterCompress: string = '';

  protected smartwaiversigned: boolean = false;
  protected smartwaiverdata: any;
  protected smartwaiveractive = false;
  protected amountToPay: number = 0;

  changePreferredCountries() {
    this.preferredCountries = [CountryISO.India, CountryISO.Canada];
  }
  setStates(event: any) {
    let country = event.target.value;
    this.state.reset();
    this.state.disable();
    if (country) {
      let currentCountry = this.countries.find((c) => c.shortName === country);

      if (this.countriesWStates.includes(currentCountry.name)) {
        this.stateTitle = 'State';
      } else {
        this.stateTitle = 'Province';
      }
      if (this.countriesWZipCode.includes(currentCountry.name)) {
        this.zipTitle = 'Zip';
      } else {
        this.zipTitle = 'Postal Code';
      }

      this.states = this._rentalService.getStatesByCountry(country);
      let noStates = [
        'AA',
        'AE',
        'AP',
        'AS',
        'FM',
        'GU',
        'MH',
        'MP',
        'PR',
        'PW',
        'VI',
      ];
      //si this.states includes noStates, then delete noStates from this.states
      this.states = this.states.filter((state) => !noStates.includes(state));
      this.isCountrySelect = true;
      this.state.enable();
    }
  }

  @ViewChild('myTextarea') myTextarea: ElementRef;

  constructor(
    protected _maintenanceService: MaintenanceService,
    protected _orderService: OrderProcessingService,
    private _logService: LogService,
    private _rentalService: RentalService,
    private afFun: AngularFireFunctions,
    private imageCompress: NgxImageCompressService,
    private _currentUser: CurrentUserService,
    private _productService: ProductsService,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private checkoutService: CheckoutService,
    private route: ActivatedRoute,
    private _firestoreService: FirestoreService,
    private _rentalServiceV2: RentalServiceV2,
    private _availabilityService: AvailabilityService,
  ) {
    this.checkoutForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
      address: ['', [Validators.required]],
      address2: [''],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zip: ['', [Validators.required]],
      country: ['US', [Validators.required]],
    });

    this.maintenanceForm = this.fb.group({
      products: this.fb.array([]),
    });
    this.products = this.maintenanceForm.get('products') as UntypedFormArray;
  }

  get co() {
    return this.checkoutForm.controls;
  }


  async ngOnInit() {
    try {
      await this.initializeApplication();
      await Promise.all([
        this.getRentals(),
        this.updateProductList(),
        this.getRecentlyCompleteRentals()
      ]);

      this.getColorTheme();

      await this.handleRental();

    } catch (error) {
      console.error('Error in ngOnInit:', error);
    }
  }

  private async initializeApplication() {
    await this.getCompany();
    await this.initializeStripe();
    await this.filterTitleDays();
  }

  private async handleRental() {
    const rentalId = this.route.snapshot.params.rentalID;
    if (rentalId) {
      try {
        this.rental = await this._rentalService.getRentalByPromise(rentalId);
        if (this.rental) {
          this.checkRentalStatus(this.rental);
        } else {
          console.error('Rental not found:', rentalId);
        }
      } catch (error) {
        console.error('Error fetching rental:', error);
      }
    }
  }

  private checkRentalStatus(rental) {
    if (!rental.isCheckedOut && !rental.isCancelled) {
      this.openModalCheckedOut(rental);
    } else if (rental.isCheckedOut && !rental.isCheckedIn) {
      this.openModalCheckedIn(rental);
    }
  }

  private async initializeStripe() {
    const isTest = environment.production ? this.company.isTesting : true;
    await this.setStripeInfo(isTest, this._currentUser.currentUser.companyId);
  }
  private setStripeInfo(isTest: boolean, companyID: string): Promise<any> {
    //set this._rentalService.stripeID and this._rentalService.stripePublicKey
    let promise = new Promise(async (resolve, reject) => {
      if (isTest) {
        this.stripeID = environment.stripe.defaultAccount;
        this.stripePublicKey = environment.stripe.key;
        this.stripe = Stripe(environment.stripe.key, {
          stripeAccount: environment.stripe.defaultAccount,
        });
        resolve(true);
      } else {
        this.stripePublicKey = await this.checkoutService.getStripePKey(isTest);
        this.stripeID = this.company.stripeID;
        this.stripe = Stripe(environment.stripe.key, {
          stripeAccount: this.stripeID,
        });
        resolve(false);
      }
    });
    return promise;
  }
  async filterTitleDays() {

    const result = await this._availabilityService.getNextAvailableDate();

    if (result === null) {
      Swal.fire({
        title: "Shop schedule Missing",
        html: "Please add a schedule before using this page.",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Take me there",
        cancelButtonText: "Ok",
        reverseButtons: true,
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          let domain = window.location.origin;
          window.location.href = domain + '/partner/shop';
        }
      })
    } else {
      this.nextAvailableDate = result.nextAvailableDate;
      this.actualAvailableDate = result.actualAvailableDate;
    }

  }

  ngOnDestroy(): void {
    // Notificar que el componente se está destruyendo y completar el Subject
    this.subs$.next();  // Emitir valor para desuscribir todos los observables
    this.subs$.complete(); // Completar el Subject para liberar recursos
  }

  async getCompany() {
    this.company = this._currentUser.currentUser.currentCompany;
    this.fleetTax = this.company.fleetTax;
    this.companyTax = this.company.companyTax;
  }

  uploadPDFWaiver(id: string, event: any) {
    let pdfFile = event.target.files;
    for (let i = 0; i < pdfFile.length; i++) {
      let reader = new FileReader();
      reader.readAsDataURL(pdfFile[i]);
      reader.onloadend = () => {
        this.pdfco.push(reader.result);
        this._orderService
          .uploadPDF(id, reader.result, id + '_waiver', 'CheckedOut')
          .then((pdfFile) => {
            this.rentaltowaiver.waiver = [pdfFile];
            delete this.rentaltowaiver.dateend;
            delete this.rentaltowaiver.datestart;
            this._orderService.updateWaiver(
              this.rentaltowaiver.id,
              this.rentaltowaiver
            );
            this._logService.addRentalLog(
              this.rentaltowaiver.id,
              'Waiver Document Uploaded', "Order Processing Rebuild"
            );
            Swal.fire({
              title: 'Waiver Document Uploaded',
              icon: 'success',
            });
            this.ngOnInit();
          });
      };
    }
  }

  compressImageCo(id: any, r: any, i: any, type: string) {
    this.imageCompress.uploadFile().then(({ image, orientation }) => {
      Swal.fire({
        title: 'Please wait',
        text: 'Saving ' + type + ' image',
        allowOutsideClick: false,
        showConfirmButton: false,
        showCloseButton: false,
        showCancelButton: false,
      });
      Swal.showLoading();
      this.imageCompress
        .compressFile(image, orientation, 50, 50) // 50% ratio, 50% quality
        .then((compressedImage) => {
          this.imgResultAfterCompress = compressedImage;
          if (type == 'Check Out') {
            this.uploadImgCheckedOut(id, this.imgResultAfterCompress, r, i);
          } else {
            this.uploadImgCheckedIn(id, this.imgResultAfterCompress, r, i);
          }
        });
    });
  }

  uploadImgCheckedOut(id: string, imagecheckout: any, r: any, i: any) {
    let imgname =
      r.productName + moment(new Date()).format('hh-mm-ss').toString() + i;
    let imgthumbname = 'thumb_' + imgname;
    this._orderService
      .uploadImg(id, imagecheckout, imgname, 'CheckedOut')
      .then((imageUrl) => {
        let lengthPublicUrl = imageUrl.length;
        let imageNameWithoutSpace = r.productName.replace(' ', '%20');
        let fileNameIndex = imageUrl.indexOf(imageNameWithoutSpace + '.');
        let firstPartUrl = imageUrl.substring(0, fileNameIndex);
        let secundPartUrl = imageUrl.substring(fileNameIndex, lengthPublicUrl);
        let publicThumbnailURL = firstPartUrl + 'thumb_' + secundPartUrl;
        let obj = {
          rentalID: id,
          productName: r.productName,
          productID: r.productId,
          dateUpload: new Date(),
          url: [imageUrl][0],
          urlthumbnail: publicThumbnailURL,
          type: 'Check Out',
        };
        this._orderService.updateRentalPhotos(obj);
        this._logService.addRentalLog(obj.rentalID, 'Image Check Out uploaded', "Order Processing Rebuild");
        this.checkedoutdata.items.forEach((element) => {
          this._orderService.getCOImagesURL(id, element.productId).then((img) => {
            element.imagesco = img;
          });
          this._orderService.getCIImagesURL(id, element.productId).then((img) => {
            element.imagesci = img;
          });
        });
        Swal.close();
      });
  }

  uploadImgCheckedIn(id: string, imagecheckin: any, r: any, i: any) {
    let imgname =
      r.productName + moment(new Date()).format('hh-mm-ss').toString() + i;
    let imgthumbname = 'thumb_' + imgname;
    this._orderService
      .uploadImg(id, imagecheckin, imgname, 'CheckedIn')
      .then((imageUrl) => {
        let lengthPublicUrl = imageUrl.length;
        let imageNameWithoutSpace = r.productName.replace(' ', '%20');
        let fileNameIndex = imageUrl.indexOf(imageNameWithoutSpace + '.');
        let firstPartUrl = imageUrl.substring(0, fileNameIndex);
        let secundPartUrl = imageUrl.substring(fileNameIndex, lengthPublicUrl);
        let publicThumbnailURL = firstPartUrl + 'thumb_' + secundPartUrl;
        let obj = {
          rentalID: id,
          productName: r.productName,
          productID: r.productId,
          dateUpload: new Date(),
          url: [imageUrl][0],
          urlthumbnail: publicThumbnailURL,
          type: 'Check In',
        };
        this._orderService.updateRentalPhotos(obj);
        this._logService.addRentalLog(obj.rentalID, 'Image Check In uploaded', "Order Processing Rebuild");
        this.checkedindata.items.forEach((element) => {
          this._orderService.getCOImagesURL(id, element.productId).then((img) => {
            element.imagesco = img;
          });
          this._orderService.getCIImagesURL(id, element.productId).then((img) => {
            element.imagesci = img;
          });
        });
        Swal.close();
      });
  }

  openModalImg(img: any) {
    Swal.fire({
      title: 'Image',
      html:
        '<div style="width:100%;"><img src="' +
        img +
        '" style="width:100%;"></div>',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText:
        '<i class="far fa-image"></i> Open the Image in another Tab',
      cancelButtonText: 'Close',
    }).then((result) => {
      if (result.isConfirmed) {
        window.open(img, '_blank');
      }
    });
  }

  getCheckOut(obtainedData) {
    let data = obtainedData.filter(item => !item.isCheckedOut);
    //Service to obtain all the rentals
    this.checkout.today = []; //Clean array checkout.today
    this.checkout.nextday = []; //Clean array rentalstocheckedout

    let dstart: any; //Auxiliar variable to save the start date
    let dend: any; //Auxiliar variable to sale the end date
    let ProductisPrepared: boolean = false; //Variable to save the status of a product
    let ProductisCheckedOut: boolean = false; //Variable to save the status of a rental/products
    var datependingpastoday = 0;
    data.forEach((rental: any) => {
      rental = this.validateProductNumberInCartObj(rental)
      //For to obtain all the documents

      this.rentalDocumentMap[rental.id] = rental;

      dstart = new Date(rental['dayStart'].seconds * 1000);
      dend = new Date(rental['dayEnd'].seconds * 1000);
      //Check if the Product is not checked Out
      if (moment(rental.dayStart.seconds * 1000).format('LL').toString() == moment(this.actualAvailableDate).format('LL').toString()) {
        this.checkout.today.push({
          //Save rental on array rentalstockeckedout
          id: rental.id,
          datestart: dstart,
          dateend: dend,
          isPrepared: ProductisPrepared,
          ...rental,
        });
      } else if (moment(rental.dayStart.seconds * 1000) < moment(this.currentMomentDate)) {
        datependingpastoday += 1;
      } else {
        this.checkout.nextday.push({
          //Save rental on array rentalstockeckedout
          id: rental.id,
          datestart: dstart,
          dateend: dend,
          isPrepared: ProductisPrepared,
          ...rental,
        });
      }
    });
    this.checkoutcard = {
      todayd: this.currentMomentDate,
      tomorrod: this.dt,
      numberpast: datependingpastoday,
      numbertoday: this.checkout.today.length,
      numbertomorrow: this.checkout.nextday.length,
      pastoday: Math.round(Math.abs((datependingpastoday * 100) / (this.checkout.today.length + this.checkout.nextday.length + datependingpastoday))) + '%',
      todayproducts: Math.round(Math.abs(((this.checkout.today.length) * 100) / (this.checkout.today.length + this.checkout.nextday.length + datependingpastoday))) + '%',
      tomorrowproducts: Math.round(Math.abs((this.checkout.nextday.length * 100) / (this.checkout.today.length + this.checkout.nextday.length + datependingpastoday))) + '%',
    };
  }

  getPrepare(data) {
    //Service to obtain all rentals products
    this.prepare.today = []; //Clean array, items in prepared column for today
    this.prepare.nextday = []; //Clean array, items in prepared column for tomorrow
    var datependingpastoday = 0;
    data.forEach((element: any) => {
      element.cartObj.items.forEach((item, index) => {
        if ((item['isPrepared'] === undefined || item['isPrepared'] === false) && element.isCancelled === false) {
          const {dayStart, dayEnd} = this.formatRentalDates(element);
          let dstart = new Date(element['dayStart'].seconds * 1000);
          let dend = new Date(element['dayEnd'].seconds * 1000);
          if (moment(element.dayStart.seconds * 1000).format('LL').toString() == moment(this.actualAvailableDate).format('LL').toString()) {
            this.prepare.today.push({
              id: element.id,
              position: index,
              companyID: element.companyID,
              paymentID: element.paymentID,
              date: moment(element.dayStart.seconds * 1000).format('LL'),
              formattedDayStart: dstart,
              formattedDayEnd: dend,
              dayStart: dayStart,
              dayEnd: dayEnd,
              rentalNumber: element.rentalNumber,
              productName1: item.productName,
              productSize: item.productSize,
              productNumber: this.getProductNumber(item),
              rentalCost: element.cost,
              isPrepared: item['isPrepared'] ? item['isPrepared'] : false,
              isCheckedOut: item['isCheckedOut'] ? item['isCheckedOut'] : false,
              isChekedIn: item['isCheckedIn'] ? item['isCheckedIn'] : false,
              paddons: item.widgetList,
              notes: element.notes,
              isDeliver: element.isDeliver ? element.isDeliver : false,
              deliverInformation: element.deliverInformation ? element.deliverInformation : '',
            });
          } else if (moment(element.dayStart.seconds * 1000) < moment(this.currentMomentDate)) {
            datependingpastoday += 1;
          } else {
            this.prepare.nextday.push({
              //Put the elements on the array
              id: element.id,
              position: index,
              companyID: element.companyID,
              paymentID: element.paymentID,
              formattedDayStart: dstart,
              formattedDayEnd: dend,
              dayStart: dayStart,
              dayEnd: dayEnd,
              rentalNumber: element.rentalNumber,
              productName1: item.productName,
              productSize: item.productSize,
              productNumber: this.getProductNumber(item),
              rentalCost: element.cost,
              isPrepared: item['isPrepared'] ? item['isPrepared'] : false,
              isCheckedOut: item['isCheckedOut'] ? item['isCheckedOut'] : false,
              isChekedIn: item['isCheckedIn'] ? item['isCheckedIn'] : false,
              paddons: item.widgetList,
              notes: element.notes,
              shopComments: element.shopComments,
              isDeliver: element.isDeliver ? element.isDeliver : false,
              deliverInformation: element.deliverInformation ? element.deliverInformation : '',
              date: ''
            });
          }

        }
      });
    }); //End For
    this.preparedcard = {
      todayd: this.currentMomentDate,
      tomorrod: this.dt,
      numberpast: datependingpastoday,
      numbertoday: this.prepare.today.length,
      numbertomorrow: this.prepare.nextday.length,
      pastoday: Math.round(Math.abs((datependingpastoday * 100) / (this.prepare.today.length + this.prepare.nextday.length + datependingpastoday))) + '%',
      todayproducts: Math.round(Math.abs(((this.prepare.today.length) * 100) / (this.prepare.today.length + this.prepare.nextday.length + datependingpastoday))) + '%',
      tomorrowproducts: Math.round(Math.abs((this.prepare.nextday.length * 100) / (this.prepare.today.length + this.prepare.nextday.length + datependingpastoday))) + '%',
    };
  }

  getProductNumber(item) {
    if (item.productNumber != null && item.productNumber != undefined && item.productNumber != 0) {
      return item.productNumber;
    }
    const aux_item = this.productsArray.find(p => p.id === item.productId);

    return Number(aux_item?.productNumber ?? aux_item?.automaticNumber ?? 0);
  }

  validateProductNumberInCartObj(rental) {
    if (rental && rental.cartObj && Array.isArray(rental.cartObj.items)) {
      rental.cartObj.items = rental.cartObj.items.map(item => {
        return {
          ...item,
          productNumber: this.getProductNumber(item)
        };
      });
    } else {
      console.warn('Rental.cartObj is missing');
    }

    return rental;
  }

  addProductsToProductArray(data){
    console.log(data)
    // && item.isComplete === false
    let filteredData = data.filter(item => item.cartObj !== undefined);
    filteredData.forEach(rental => {
      rental.cartObj.items.forEach(async item => {
        if (item.productId) {
          const exists = this.productsArray.some(p => p.id === item.productId);
          if (!exists) {
            this._productService.getProductByID(item.productId).then((product) => {
              this.productsArray.push(product);
            });
          }
        }
      });
    });

    return filteredData;
  }

  getRentals() {
    this._orderService.getRentalsRebuild(this.nextAvailableDate)
      .pipe(takeUntil(this.subs$))  // Desubscribe automatically
      .subscribe((data) => {
        try {
          let filteredData = this.addProductsToProductArray(data);
          this.getPrepare(filteredData);
          this.getCheckOut(filteredData);
          this.getCheckIn(filteredData);
        } catch (error) {
          console.error("Error processing rentals:", error);
        }
      });
  } //Method end

  formatRentalDates(rental: Rental): { dayStart: string; dayEnd: string } {
    let dayStart: string;
    let dayEnd: string;

    if (rental.rentalType === 'byHour') {
      dayStart = moment(rental.dayStart.seconds * 1000).format('MMM DD YYYY, h:mm a');
      dayEnd = moment(rental.dayEnd.seconds * 1000).format('- h:mm a');
    } else if (rental.rentalType === 'byDay') {
      if (moment(rental.dayEnd.seconds * 1000).diff(moment(rental.dayStart.seconds * 1000), 'days') < 1) {
        dayStart = moment(rental.dayEnd.seconds * 1000).format('MMM DD, yyyy');
        dayEnd = '';
      } else {
        dayStart = moment(rental.dayStart.seconds * 1000).format('MMM DD');
        dayEnd = moment(rental.dayEnd.seconds * 1000).format('- MMM DD, yyyy');
      }
    }

    return { dayStart, dayEnd };
  }

  getCheckIn(data) {
    //Service to obtain all the rentals
    this.rentalstocheckedin = []; //Clean array rentalstocheckedin
    let dstart: any; //Auxiliar variable to save the start date
    let dend: any; //Auxiliar variable to sale the end date
    var datependingpastoday = 0;
    var datependingupcoming = 0;
    data.filter(rental => rental.isCheckedOut)
    data.forEach((rental: any) => {
      rental = this.validateProductNumberInCartObj(rental)

      this.rentalDocumentMap[rental.id] = rental;

      dstart = new Date(rental['dayStart'].seconds * 1000);
      dend = new Date(rental['dayEnd'].seconds * 1000);

      this.rentalstocheckedin.push({
        //Save rental on array rentalstocheckedin
        id: rental.id,
        datestart: dstart,
        dateend: dend,
        ...rental,
      });

      if (moment(new Date(rental.dayEnd.seconds * 1000)) < moment(new Date(this.currentMomentDate))) {
        datependingpastoday += 1;
      } else if (
        moment(new Date(rental.dayEnd.seconds * 1000)) > moment(new Date(this.tomorrowMomentDate))) {
        datependingupcoming += 1;
      }
    });

    this.checkincard = {
      todayd: this.currentMomentDate,
      tomorrod: this.dt,
      numberpast: datependingpastoday,
      numbertomorrow: datependingupcoming,
      numbertoday: Math.abs(this.rentalstocheckedin.length - datependingpastoday - datependingupcoming),
      pastoday: Math.round(Math.abs((datependingpastoday * 100) / this.rentalstocheckedin.length)) + '%',
      todayproducts:
        Math.round(
          Math.abs(
            ((this.rentalstocheckedin.length - datependingpastoday - datependingupcoming) * 100) / this.rentalstocheckedin.length)) + '%',
      tomorrowproducts: Math.round(Math.abs((datependingupcoming * 100) / this.rentalstocheckedin.length)) + '%',
    };
  }

  // Erases old productlist array and add new data from checkedin and checkedout arrays
  // Keeps the info on all the past due products in one array
  updateProductList() {
    this._orderService.getRentalsPastDue(this.currentMomentDate)
      .pipe(takeUntil(this.subs$))
      .subscribe(data => {
        this.lateProductList = [];
        data.forEach(rental => {
          rental = this.validateProductNumberInCartObj(rental)
          let dstart: any; //Auxiliar variable to save the start date
          let dend: any; //Auxiliar variable to sale the end date
          dstart = new Date(rental['dayStart'].seconds * 1000);
          dend = new Date(rental['dayEnd'].seconds * 1000);
          if (rental.isCheckedOut == false) {
            this.lateProductList.push({
              //Save element on array rentalstocheckedin
              id: rental.id,
              datestart: dstart,
              dateend: dend,
              ...rental,
            });
          } else if (rental.isCheckedOut == true && rental.isCheckedIn == false) {
            this.lateProductList.push({
              //Save element on array rentalstocheckedin
              id: rental.id,
              datestart: dstart,
              dateend: dend,
              ...rental,
            });
          }
        })

      });

  }

  // Gets all completed rentals within 24 hours for the recently cheked in collumns
  async getRecentlyCompleteRentals() {
 // Calculate 24 hours ago using Luxon
    const now = DateTime.now();
    const twentyFourHoursAgo = now.minus({ hours: 24 });

    const data = await lastValueFrom(this._firestoreService.getCollection(Collection.Rentals, [
      { field: 'companyID', operator: '==', value: this._currentUser.currentUser.companyId },
      { field: 'statusDate.isCheckedIn', operator: '>=', value: twentyFourHoursAgo.toJSDate() },
      { field: 'isComplete', operator: '==', value: true },
      { field: 'isConfirmed', operator: '==', value: true },
    ], [{ field: 'rentalNumber', direction: 'desc' }]));
      // .pipe(takeUntil(this.subs$))
      // .subscribe((data) => {
        //Service to obtain all the rentals
        this.recentlyCompletedRentals = [];
        let dstart: any; //Auxiliar variable to save the start date
        let dend: any; //Auxiliar variable to sale the end date
        let dayComplete;

        data.forEach((rental: any) => {
          rental = this.validateProductNumberInCartObj(rental)
          dstart = new Date(
            rental['dayStart'].seconds * 1000
          );
          dend = new Date(rental['dayEnd'].seconds * 1000);

          if (rental['statusDate']['isCheckedIn']) {
            dayComplete = new Date(
              rental['statusDate']['isCheckedIn'].seconds *
              1000
            );
            if (
              this.datepipe.transform(dayComplete, 'YYYYMMdd') == this.datenow3
            ) {
              this.recentlyCompletedRentals.push({
                id: rental.id,
                ...rental,
              });

              this.rentalDocumentMap[rental.id] = rental;
              this.addProductsToProductArray(data);
            }
          }
        });
        return
  }

  // Activates the right type of modal based on if rental is a checked out or checked in or prepare item
  modalIdentifier(i: number, rental: any) {
    if (rental.isCheckedOut) {
      this.openModalCheckedIn(rental);
    } else {
      this.openModalCheckedOut(rental);
    }
  }


  //Formats the hours and minuetes
  getHourMinuetesFormat(start, end) {
    start = this.datepipe.transform(start, 'h:mm a');
    end = this.datepipe.transform(end, '- h:mm a');
    let timeString = `${start} ${end}`;
    return timeString;
  }

  closeOpenModals() {
    $('.modal').modal('hide');
  }
  createCharge(newcost, oldcost, oldfinaltotal, productrentalaux) {
    let chargecost = newcost - oldcost;
    productrentalaux.cost = oldcost;
    productrentalaux.finalTotal = oldfinaltotal;
    let fleetTax = Number((chargecost * this.fleetTax) / 100);
    Swal.fire({
      title: 'Processing Charge',
      html: '<div style="height: 100px"><span>Please wait while processing charge...</span><br><br><div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div></div>',
      allowOutsideClick: false,
      showConfirmButton: false,
    });
    this.afFun
      .httpsCallable('retrievePayment')(this.dataPI)
      .subscribe((result) => {
        let customerAccount = result.customer;
        let paymentMethod = result.payment_method;
        let metadata = result.metadata;
        let difference;
        var dataCharge = {
          title:
            'Charge by new payment in the booking #' +
            productrentalaux.rentalNumber,
          customer: customerAccount,
          id: this.dataPI.paymentID,
          amount: Number(chargecost.toFixed(2)),
          fleetTax: this.fleetTax,
          fleetTaxNumber: Math.round(Number(fleetTax.toFixed(2))),
          totaltotransfer: difference,
          paymentMethod: paymentMethod,
          destination: this.dataPI.stripeID,
          companyID: this.dataPI.paymentID,
          amountDestination: difference,
          transactiondata: metadata,
          isDeposit: false,
        };
        this.afFun
          .httpsCallable('createCharge')({
            data: dataCharge,
            isTesting: this._rentalService.isTesting,
          })
          .subscribe((datatransfer) => {
            if (datatransfer.code) {
              let error = '';
              datatransfer.code == 'parameter_invalid_empty'
                ? (error =
                  'There is an invalid parameter in the generated payment.')
                : (error = datatransfer.raw.message);
              Swal.fire({
                title: 'Error',
                html:
                  'There was a problem processing the selected payment. <br>' +
                  'Reason: ' +
                  '<b>"' +
                  error +
                  '"</b>',
                icon: 'error',
              });
            } else {
              datatransfer['type'] = 'charge';
              datatransfer['rentalID'] = this.rental.id;
              datatransfer.metadata['type'] = 'charge';
              datatransfer.metadata['rentalID'] = this.rental.id;
              datatransfer.metadata['userName'] =
                this._currentUser.currentUser.firstName +
                ' ' +
                this._currentUser.currentUser.lastName;
              datatransfer.metadata['userID'] =
                this._currentUser.currentUser.id;
              datatransfer.metadata['title'] =
                'Charge cause increment of total';
              datatransfer.metadata['companyID'] = this.rental.companyID;
              this._rentalService.addStripeTransaction(datatransfer);
              this._orderService.updatePrepared(
                productrentalaux.id,
                productrentalaux
              );
              this._logService.addRentalLog(
                this.rental.id,
                'Booking Charge, amount: ' + chargecost.toFixed(2)
                , "Order Processing Rebuild"
              );
              this._logService.addRentalLog(
                this.rental.id,
                'Booking Updated with Charge'
                , "Order Processing Rebuild"
              );
              this._rentalService
                .getRentalByIDPromise(this.rental.id)
                .then((rental) => {
                  this.lastrental = rental;
                });
              $('#ProductModal').modal('hide'); //Hide the modal
              Swal.fire({
                title: 'The charge was made and the changes were saved',
                icon: 'success',
              });
            }
          });
      });
  }

  createRefund(newcost, oldcost, oldfinaltotal, productrentalaux) {
    let difference = oldcost - newcost;
    productrentalaux.cost = oldcost;
    productrentalaux.finalTotal = oldfinaltotal;
    Swal.fire({
      title: 'Saving Booking',
      html: '<div style="height: 100px"><span>Please wait while processing refund...</span><br><br><div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div></div>',
      allowOutsideClick: false,
      showConfirmButton: false,
    });
    this.afFun
      .httpsCallable('retrievePayment')(this.dataPI)
      .subscribe((result) => {
        let pricePayment = result.amount / 100;
        let status = false;
        let paymentID = '';
        let position = 0;
        let cost = 0;
        if (Number(difference.toFixed(2)) < pricePayment) {
          status = true;
          paymentID = result.id;
        } else {
          for (let i = 0; i < productrentalaux.chargesID.length; i++) {
            const element = productrentalaux.chargesID[i];
            if (Number(difference.toFixed(2)) < element.cost) {
              cost = element.cost;
              position = i;
              status = true;
              paymentID = element.chargeID;
            }
          }
        }
        if (status) {
          let dataRefund = {
            isTesting: this._rentalService.isTesting,
            stripeID: this.dataPI.stripeID,
            paymentID: paymentID,
            amount: Number(difference.toFixed(2)) * 100,
            description: 'Refund cause total decrease',
            companyID: productrentalaux.companyID,
            email: productrentalaux.userInfo.email,
            id: productrentalaux.id,
          };
          this.afFun
            .httpsCallable('refundPaymentAmount')(dataRefund)
            .subscribe((resultrefund) => {
              if (resultrefund.code) {
                Swal.fire({
                  title: 'Error creating Refund',
                  html: 'There was a problem processing the refund.',
                  icon: 'error',
                });
              } else {
                if (productrentalaux.refundsID == undefined) {
                  productrentalaux.refundsID = [
                    {
                      refundID: resultrefund.id,
                      amount: Number(difference.toFixed(2)),
                      date: new Date(),
                    },
                  ];
                } else {
                  productrentalaux.refundsID.push({
                    refundID: resultrefund.id,
                    amount: Number(difference.toFixed(2)),
                    date: new Date(),
                  });
                }
                resultrefund['type'] = 'refund';
                resultrefund['rentalID'] = this.rental.id;
                resultrefund.metadata['type'] = 'refund';
                resultrefund.metadata['rentalID'] = this.rental.id;
                resultrefund.metadata['userName'] =
                  this._currentUser.currentUser.firstName +
                  ' ' +
                  this._currentUser.currentUser.lastName;
                resultrefund.metadata['userID'] =
                  this._currentUser.currentUser.id;
                resultrefund.metadata['title'] =
                  'Refund cause decrease of total';
                resultrefund.metadata['companyID'] = this.rental.companyID;
                this._rentalService.addStripeTransaction(resultrefund);
                this._rentalService.updateRental(
                  productrentalaux,
                  productrentalaux.id
                );
                this._logService.addRentalLog(
                  productrentalaux.id,
                  'Booking Refund, amount: ' + difference.toFixed(2)
                  , "Order Processing Rebuild"
                );
                this._logService.addRentalLog(
                  productrentalaux.id,
                  'Booking Updated'
                  , "Order Processing Rebuild"
                );
                $('#ProductModal').modal('hide'); //Hide the modal
                Swal.fire({
                  title: 'The refund was made successfully',
                  icon: 'success',
                });
              }
            });
        }
      });
  }

  preparedBike(id: string, position: number, r) {
    //Method to prepare the bike
    r.isPrepared = true;
    this.productdata ? this.productdata['dataIsLoading'] = true : "";
    let productId: any;
    const rental = this.rentalDocumentMap[id];
    productId = rental.cartObj.items[position].productId;
    delete rental.dateend;
    delete rental.datestart;
    rental.cartObj.items[position].isPrepared = true; //Update the value to true in isPrepared variable
    rental.statusDate['isPrepared'] = new Date();
    var checkout = true;
    const pendingPrepareProducts = rental.cartObj.items.filter(item => item.isPrepared === false || item.isPrepared === undefined);
    pendingPrepareProducts.length > 0 ? checkout = false : ""
    rental.isPrepared = checkout;
    rental.cartObj.items[position]['dataIsLoading'] = false;
    this._orderService.updatePrepared(id, rental); // Call the service to update
    this._logService.addRentalLog(id, 'Product with ID: ' + productId + ' and Name: ' + rental.cartObj.items[position].productName + ' is Prepared', "Order Processing Rebuild");
    const productById = this.productsArray.find(product => product.id === productId);
    if (productById) {
      if (productById.id == productId) {
        productById.processingID = rental.rentalNumber;
        this.cleanFields(productById);
        this._logService.addProductLog(productById.id, 'Product Prepared, RentalNumber: ' + rental.rentalNumber, "Order Processing Rebuild");
      }
    }
    $('#ProductModal').modal('hide'); //Hide the modal
  }

  cancelPrepared(id: string, position: number) {
    let productId: any;
    this.checkedoutdata.items[position].isPrepared = false;
    const rental = this.rentalDocumentMap[id];
    productId = rental.cartObj.items[position].productId;
    rental.cartObj.items[position].isPrepared = false; //Update the value to true in isPrepared variable
    rental.statusDate['isPrepared'] = '';
    rental.isPrepared = false;
    this._orderService.updatePrepared(id, rental); // Call the service to update
    this._logService.addRentalLog(id, 'Product with ID: ' + productId + ' was canceled in preparation', "Order Processing Rebuild");
    const product = this.productsArray.find(product => product.id === productId);
    this.cleanFields(product);
    product.processingID = '';
    this._orderService.updateDamaged(product.id, product);
    this._logService.addProductLog(product.id, 'Product with ID: ' + productId + ' Cancel Prepared, RentalNumber: ' + rental.rentalNumber, "Order Processing Rebuild");
  }

  cleanFields(obj: any) {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === 'object') {
        this.cleanFields(obj[key]);
      } else if (obj[key] === null || obj[key] === undefined) {
        delete obj[key];
      }
    });
    return obj;
  }

  cancelCheckOut(id: string, position: number) {
    //Method to cancel the bike
    let productId: any;
    this.checkedoutdata ? this.checkedoutdata.items[position].isCheckedOut = false : "";
    this.checkedindata ? this.checkedindata.items[position].isCheckedOut = false : "";
    const rental = this.rentalDocumentMap[id];
    //If the product
    productId = rental.cartObj.items[position].productId;
    rental.cartObj.items[position].isCheckedOut = false; //Update the false to true in isCheckedOut variable
    rental.isCheckedOut = false; //Update the value to false in isCheckedOut variable
    rental.statusDate['isCheckedOut'] = '';
    this._orderService.updatePrepared(id, rental); // Call the service to update
    this._logService.addRentalLog(id, 'Product with ID: ' + productId + ' was canceled in check out', "Order Processing Rebuild");
    const product = this.productsArray.find(p => p.id === productId);
    product['processingID'] = '';
    this.cleanFields(product)
    this._orderService.updateDamaged(product.id, product);
    this._logService.addProductLog(product.id, 'Product with ID: ' + productId + ' Cancel Check Out, RentalNumber: ' + rental.rentalNumber, "Order Processing Rebuild");
    this._logService.addRentalLog(id, 'Product with ID: ' + productId + ' Cancel Checked Out, RentalNumber: ' + rental.rentalNumber, "Order Processing Rebuild");
  }

  cancelCheckIn(id: string, position: number) {
    //Method to cancel the bike
    let productId: any;
    this.checkedindata.items[position].isCheckedIn = false;
    // const rental = this.productsrental.find(rental => rental.id === id);
    const rental = this.rentalDocumentMap[id];
    productId = rental.cartObj.items[position].productId;
    rental.cartObj.items[position].isCheckedIn = false; //Update the value to true in isCheckedIn variable
    rental.isCheckedIn = false; //Update the value to false in isCheckedIn variable
    rental.isComplete = false; // Update the value to false in isComplete variable
    rental.cartObj.items[position].maintenanceSelection = {};
    rental.statusDate['isCheckedIn'] = '';
    this._orderService.updatePrepared(id, rental); // Call the service to update
    this._logService.addRentalLog(id, 'Product with ID: ' + productId + ' was canceled in check in', "Order Processing Rebuild");
    const product = this.productsArray.find(product => product.id === productId);
      product['processingID'] = '';
      product.isCleaning = false;
      product.isDamaged = false;
      product.isMaintenance = false;
      product.isTuneUp = false;
      this._orderService.updateDamaged(product.id, product);
      this._logService.addProductLog(product.id, 'Product with ID: ' + productId + ' Cancel Check In, RentalNumber: ' + rental.rentalNumber, "Order Processing Rebuild");
      this._logService.addRentalLog(id, 'Product with ID: ' + productId + ' Cancel Check In, RentalNumber: ' + rental.rentalNumber, "Order Processing Rebuild");

      this.recentlyCompletedRentals.forEach((item, index) => {
        if(item.id === id){
          this.recentlyCompletedRentals.splice(index, 1);
        }
      })
  }

  disabledTest(event) {
    event.disabled = true;
  }

  checkedOutProduct(id: string, position: number) {
    //Method to checked out the product
    this.imgco = [];
    var rentalstatus = true;
    let productId = this.checkedoutdata.items[position].productId;
    const rental = this.rentalDocumentMap[id];
    delete rental.dateend;
    delete rental.datestart;
    rental.cartObj.items[position].isCheckedOut = true; //Checked Out the product rental
    const productPendingCheckedOut = rental.cartObj.items.filter(item => item.isCheckedOut === false || item.isCheckedOut === undefined);
    productPendingCheckedOut.length > 0 ? rentalstatus = false : "";
    if (rentalstatus) {
      this._logService.addRentalLog(rental.id, 'Booking checked Out', "Order Processing Rebuild");
      rental.isCheckedOut = true;
      rental.statusDate['isCheckedOut'] = new Date();
    }
    rental.cartObj.items[position]['dataIsLoading'] = false;
    this._orderService.updatePrepared(id, rental); //Update the rental
    this._logService.addRentalLog(id, 'Product with ID: ' + productId + ' and Name: ' + rental.cartObj.items[position].productName + ' was checked out', "Order Processing Rebuild");
    const productById = this.productsArray.find(product => product.id === productId);
    if (productById) {
      if (productById.id == productId) {
        productById.processingID = rental.rentalNumber;
        this.cleanFields(productById);
        this._logService.addProductLog(productById.id, 'Product Checked Out', "Order Processing Rebuild");
      }
    }
    this.checkedoutdata.items[position]['dataIsLoading'] = false;
    rentalstatus ? $('#CheckedOutModal').modal('hide') : ""; //Hide the modal
    this.rental.cartObj.items[position].isCheckedOut = true;
    Swal.close();
  }

  async checkDeposits(id: string, position: number, type: string) {
    if (type == 'checkin') {
      this.checkedindata.items[position]['dataIsLoading'] = true;
    } else {
      this.checkedoutdata.items[position]['dataIsLoading'] = true;
    }
    const payment = await this._rentalService.getDepositPending(this.rental.id);
    this.deposits = payment;
    if (payment.length > 0) {
      Swal.close();
      Swal.fire({
        title:
          'You have ' +
          payment.length +
          ' security deposits pending to confirm',
        html: 'Check the Transactions tab from the Bookings module later or confirm it now.',
        icon: 'question',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Confirm Now',
        cancelButtonText:
          type == 'checkout'
            ? 'Check out without confirm'
            : 'Check in without confirm',
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          if (type == 'checkin') {
            $('#CheckedInModal').modal('hide');
            $('#DepositsModal').modal('show');
            // this.checkedInProduct(id, position);
          } else if (type == 'checkout') {
            $('#CheckedOutModal').modal('hide');
            $('#DepositsModal').modal('show');
            this.checkedOutProduct(id, position);
          }
        } else {
          if (type == 'checkin') {
            Swal.fire({
              title: 'Loading...',
            });
            Swal.showLoading();
            this.checkedInProduct(id, position);
          } else if (type == 'checkout') {
            Swal.fire({
              title: 'Loading...',
            });
            Swal.showLoading();
            this.checkedOutProduct(id, position);
          }
        }
      });
    } else {
      if (type == 'checkin') {
        this.checkedInProduct(id, position);
      } else if (type == 'checkout') {
        this.checkedOutProduct(id, position);
      }
    }
  }

  async checkedInProduct(id: string, position: number) {
    Swal.close();
    let maintenanceobj: {} = {};
    var current = new Date();
    this.imgci = [];
    let status = true;
    let productId = this.checkedindata.items[position].productId;
    const desc = document.getElementById('description' + position.toString()) as HTMLTextAreaElement | null;
    const texterror = document.getElementById('repeatedNumber' + position.toString()) as HTMLTextAreaElement | null;
    const radioerror = document.getElementById('radioSelect' + position.toString()) as HTMLTextAreaElement | null;
    const value = $("input[type='radio'][name=" + 'radioSelect' + position.toString() + ']:checked').val();
    const valuecleaning = $("input[type='checkbox'][name=" + 'cleaningCheck' + position.toString() + ']:checked').val();
    const valuetuneup = $("input[type='checkbox'][name=" + 'tuneupCheck' + position.toString() + ']:checked').val();
    const valuedamaged = $("input[type='checkbox'][name=" + 'damagedcheck' + position.toString() + ']:checked').val();
    const valueunavailable = $("input[type='checkbox'][name=" + 'unavailableCheck' + position.toString() + ']:checked').val();
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary ml-2',
        cancelButton: 'btn btn-secondary',
      },
      buttonsStyling: false,
    });
    var constcleaning: boolean = false;
    var consttuneup: boolean = false;
    var constdamaged: boolean = false;
    var constunavailable: boolean = false;
    valuecleaning ? constcleaning = true : "";
    valuetuneup ? consttuneup = true : "";
    valuedamaged ? constdamaged = true : "";
    valueunavailable ? constunavailable = true : "";
    maintenanceobj = {
      maintenance: true,
      isCleaning: constcleaning,
      isTuneUp: consttuneup,
      isDamaged: constdamaged,
      isAvailable: !constunavailable,
      isMaintenance: true,
      date: new Date(),
    };

    if (value == null || value == '' || value == undefined) {
      texterror.style.display = 'block';
      radioerror.style.border = 'red 1px solid';
      this.checkedindata.products[position]['dataIsLoading'] = false;
    } else if (value == 'yes') {
      maintenanceobj['notes'] = desc.value;
      maintenanceobj['maintenanceReason'] = desc.value;
      const productControls = (
        this.maintenanceForm.get('products') as UntypedFormArray
      ).controls;
      const formGroup = productControls[position] as UntypedFormGroup;

      if (formGroup.invalid) {
        this.maintenanceSubmitted = true;
        this.checkedindata.products[position]['dataIsLoading'] = false;
        return;
      }
      const product = this.productsArray.find(p => p.id === productId);
      product.isCleaning = constcleaning;
      product.isTuneUp = consttuneup;
      product.isDamaged = constdamaged;
      product.maintenanceReason = desc.value;
      product.isMaintenance = true;
      this.maintenancereg['companyName'] = product.companyName ? product.companyName : "";
      this.maintenancereg['companyID'] = product.companyID;
      this.maintenancereg['productName'] = product.productName;
      this.maintenancereg['productID'] = product.id;
      this.maintenancereg['date'] = current;
      this.maintenancereg['isCleaning'] = product.isCleaning;
      this.maintenancereg['isTuneUp'] = product.isTuneUp;
      this.maintenancereg['isDamaged'] = product.isDamaged;
      this.maintenancereg['isReady'] = false;
      this.maintenancereg['rentalID'] = this.checkedindata.id;
      this.maintenancereg['rentalNumber'] = this.checkedindata.rentalNumber;
      this.maintenancereg['notes'] = desc.value;
      this.maintenancereg['description'] = desc.value;
      this.maintenancereg['maintenanceReason'] = desc.value;
      this._logService.addProductLog(product.id, 'Product Damaged Updated, RentalNumber: ' + this.checkedindata.rentalNumber, "Order Processing Rebuild");
      this._logService.addProductLog(this.id, 'Product Maintenance, isDamaged: ' + product.isDamaged + ', isCleaning: ' + product.isCleaning + ', isTuneUp: ' + product.isTuneUp + ', description: ' + desc.value, "Order Processing Rebuild");
      this._maintenanceService.addMaintenance(this.maintenancereg);
      this.checkedInProcess(position, id, maintenanceobj);
    } else {
      maintenanceobj['notes'] = "";
      maintenanceobj['maintenanceReason'] = "";
      maintenanceobj['maintenance'] = false;
      maintenanceobj['isMaintenance'] = false;
      const product = this.productsArray.find(p => p.id === productId);
      this.checkedInProcess(position, id, maintenanceobj);
    }
    //Checks if all products for order are checked in
    const productPendingCheckedIn = this.checkedindata.items.filter(p => p.isCheckedIn === false || p.isCheckedIn === undefined);
    productPendingCheckedIn.length > 0 ? status = false : ""
    // Closes modal after data finishes updating in column
    this.checkedindata.products[position]['dataIsLoading'] = false;

    if (status) {
       // Check if any rental in the array matches the id of checkedindata
    const exists = this.recentlyCompletedRentals.some(rental => rental.id === id);
    // If no match found, push checkedindata into the array
    if (!exists) {
      const rentalDoc = await this._firestoreService.getDocument(Collection.Rentals, id);

      if (rentalDoc) {
        this.recentlyCompletedRentals.push(rentalDoc);
      }
    }
      $('#CheckedInModal').modal('hide')
    }
  }

  onCheckBoxChange(e: any, controlName: string, i: number) {
    const formArray = this.maintenanceForm.get('products') as UntypedFormArray;

    const productGroup = formArray.at(i) as UntypedFormGroup;
    const control = productGroup.get(controlName);

    if (control) {
      if (e.target.checked) {
        control.setValue(true);
      } else {
        control.setValue(false);
      }

      productGroup.updateValueAndValidity();
      productGroup.markAsTouched({ onlySelf: true });

      if (productGroup.invalid) {
        console.log(`Invalid form group at index ${i}`, productGroup.errors);
      }
    }
  }

  /**
   * Resets the form controls of a specific product group in the maintenance form.
   * @param i The index of the product group in the form array.
   * @param p The data object containing the values for the product group.
   */
  resetForm(i: number, p: any) {
    const productsArray = this.maintenanceForm.get(
      'products'
    ) as UntypedFormArray;
    const productGroup = productsArray.at(i) as UntypedFormGroup;

    if (productGroup) {
      // Set values for the form controls
      productGroup.controls['cleaningCheck'].setValue(p.isCleaning);
      productGroup.controls['tuneupCheck'].setValue(p.isTuneUp);
      productGroup.controls['damagedCheck'].setValue(p.isDamaged);
      productGroup.controls['unavailableCheck'].setValue(false);
      productGroup.controls['description'].setValue(p.maintenanceReason);

      // Add validators to the 'description' control
      productGroup.controls['description'].setValidators([Validators.required]);
      // Update value and validation of the 'description' control
      productGroup.controls['description'].updateValueAndValidity();

      // Add the 'atLeastOneCheckboxSelected' validator to the product group
      productGroup.setValidators(atLeastOneCheckboxSelected());

      productGroup.updateValueAndValidity();
    }

    this.maintenanceSubmitted = false;
  }

  convertDate(timestamp) {
    return moment.unix(timestamp).format('LLL');
  }

  cancelPayment(deposit, type) {
    Swal.fire({
      title: 'Cancel Deposit',
      html: 'Are you sure you want to cancel this deposit?',
      icon: 'question',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Yes',
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Loading...',
        });
        Swal.showLoading();
        this.rental.stripeID = this.company.stripeID;
        this.afFun
          .httpsCallable('cancelPayment')({
            paymentID: deposit.id,
            isTesting: this._rentalService.isTesting,
            rental: this.rental,
          })
          .subscribe(async (resultcancel) => {
            if (resultcancel.error) {
              Swal.close();
              Swal.fire({
                title: 'Error',
                html: 'There was a problem canceling the selected payment.',
                icon: 'error',
              });
            } else {
              resultcancel.cancel['type'] = 'deposit';
              resultcancel.cancel['rentalID'] = this.rental.id;
              resultcancel.cancel.metadata['type'] = 'deposit';
              resultcancel.cancel.metadata['userName'] =
                this._currentUser.currentUser.firstName +
                ' ' +
                this._currentUser.currentUser.lastName;
              resultcancel.cancel.metadata['userID'] =
                this._currentUser.currentUser.id;
              resultcancel.cancel.metadata['rentalID'] = this.rental.id;
              resultcancel.cancel.metadata['title'] = 'Security Deposit';
              resultcancel.cancel.metadata['companyID'] = this.rental.companyID;
              this._rentalService.addStripeTransaction(resultcancel.cancel);
              this._logService.addRentalLog(
                this.rental.id,
                'Security Deposit Cancel, amount: ' +
                Number(deposit.amount / 100).toFixed(2)
                , "Order Processing Rebuild"
              );
              Swal.close();
              Swal.fire({
                title: 'Deposit Canceled',
                html: 'The security Deposit has been canceled',
                icon: 'success',
              });
              if (type == 'deposit') {
                const payment = await this._rentalService.getDepositPending(
                  this.rental.id
                );
                this.deposits = payment;
              } else {
                if (this.typemodal == 'checkout') {
                  this.openModalCheckedOut(this.rental);
                } else {
                  this.openModalCheckedIn(this.rental);
                }
              }
            }
          });
      }
    });
  }

  confirmPayment(deposit, type) {
    Swal.fire({
      title: 'Confirm Deposit',
      html: 'Are you sure yo want to confirm this deposit?',
      icon: 'question',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Yes',
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Loading...',
        });
        Swal.showLoading();
        this.rental.stripeID = this.company.stripeID;
        this.afFun
          .httpsCallable('capturePayment')({
            paymentID: deposit.id,
            isTesting: this._rentalService.isTesting,
            rental: this.rental,
          })
          .subscribe(async (resultconfirm) => {
            if (resultconfirm.error) {
              Swal.close();
              Swal.fire({
                title: 'Error',
                html: 'There was a problem confirming the selected payment.',
                icon: 'error',
              });
            } else {
              resultconfirm.capture['type'] = 'deposit';
              resultconfirm.capture['rentalID'] = this.rental.id;
              resultconfirm.capture.metadata['type'] = 'deposit';
              resultconfirm.capture.metadata['userName'] =
                this._currentUser.currentUser.firstName +
                ' ' +
                this._currentUser.currentUser.lastName;
              resultconfirm.capture.metadata['userID'] =
                this._currentUser.currentUser.id;
              resultconfirm.capture.metadata['rentalID'] = this.rental.id;
              resultconfirm.capture.metadata['title'] = 'Security Deposit';
              resultconfirm.capture.metadata['companyID'] = this.rental.companyID;
              this._rentalService.addStripeTransaction(resultconfirm.capture);
              this._logService.addRentalLog(
                this.rental.id,
                'Security Deposit Confirmed, amount: ' +
                Number(deposit.amount / 100).toFixed(2)
                , "Order Processing Rebuild"
              );
              Swal.close();
              Swal.fire({
                title: 'Deposit Confirmed',
                html: 'The security Deposit has been confirmed',
                icon: 'success',
              });
              if (type == 'deposit') {
                const payment = await this._rentalService.getDepositPending(
                  this.rental.id
                );
                this.deposits = payment;
              } else {
                if (this.typemodal == 'checkout') {
                  this.openModalCheckedOut(this.rental);
                } else {
                  this.openModalCheckedIn(this.rental);
                }
              }
            }
          });
      }
    });
  }

  async checkedInProcess(position: any, id: any, maintenanceobj: any) {
    var deposits = false;
    var rentalstatus = true;
    const btn = document.getElementById('checkedinbtn') as HTMLButtonElement | null;
    var iddesc = 'description' + position.toString();
    const desc = document.getElementById(iddesc) as HTMLTextAreaElement | null;
    const rental = this.rentalDocumentMap[id];
    const productId = rental.cartObj.items[position].productId;
    const product = this.productsArray.find(p => p.id === productId);
    this.cleanFields(rental)
    rental.cartObj.items[position]['dataIsLoading'] = false;
    rental.cartObj.items[position].maintenanceSelection = maintenanceobj;
    product.isCheckedIn = true;
    product.isCheckedOut = false;
    product.processingID = '';
    product.isMaintenance = maintenanceobj.isMaintenance;
    product.isTuneUp = maintenanceobj.isTuneUp;
    product.isDamaged = maintenanceobj.isDamaged;
    product.isCleaning = maintenanceobj.isCleaning;
    product.isAvailable = maintenanceobj.isAvailable;
    this.cleanFields(product)
    delete rental.dateend;
    delete rental.datestart;
    rental.cartObj.items[position].isCheckedIn = true;
    const pendingCheckedInProduct = rental.cartObj.items.filter(item => item.isCheckedIn === false || item.isCheckedIn === undefined);
    pendingCheckedInProduct.length > 0 ? rentalstatus = false : "";

    if (rentalstatus) {
      this._logService.addRentalLog(rental.id, 'Booking Checked In', "Order Processing Rebuild");
      this._logService.addRentalLog(rental.id, 'Booking Complete', "Order Processing Rebuild");
      rental.statusDate['isCheckedIn'] = new Date();
      rental.statusDate['isComplete'] = new Date();
      rental.isCheckedIn = true;
      rental.isComplete = true;
    }
    this._orderService.updateDamaged(product.id, product);
    this._orderService.updatePrepared(id, rental);
    if (product.isMaintenance) {
      this._logService.addProductLog(product.id, 'Product Checked In, RentalNumber: ' + this.checkedindata.rentalNumber, "Order Processing Rebuild");
      this._logService.addProductLog(product.id, 'Product Maintenance, isDamaged: ' + product.isDamaged + ', isCleaning: ' + product.isCleaning + ', isTuneUp: ' + product.isTuneUp + ', description: ' + desc.value, "Order Processing Rebuild");
      this._logService.addRentalLog(this.checkedindata.id, 'Product: ' + product.productName + ' is checked in', "Order Processing Rebuild");
      this._logService.addRentalLog(this.checkedindata.id, 'Product #' + product.productNumber + 'Name: ' + product.productName + ' is in maintenance, isDamaged: ' + product.isDamaged + ', isCleaning: ' + product.isCleaning + ', isTuneUp: ' + product.isTuneUp + ', description: ' + desc.value, "Order Processing Rebuild");
    } else {
      this._logService.addRentalLog(this.checkedindata.id, 'Product: ' + product.productName + ' is checked in', "Order Processing Rebuild");
      this._logService.addProductLog(product.id, 'Product Checked In, RentalNumber: ' + this.checkedindata.rentalNumber, "Order Processing Rebuild");
    }
    this.rental.cartObj.items[position].isCheckedIn = true;
    deposits ? '' : Swal.close();
  }

  openModalBack() {
    if (this.typemodal == 'checkout') {
      this.openModalCheckedOut(this.rental);
    } else {
      this.openModalCheckedIn(this.rental);
    }
  }

  displayAddSecurityDeposit() {
    this._rentalService.companyID = this.rental.companyID;
    this.dataPI = {
      stripeID: this.company.stripeID,
      paymentID: this.rental.paymentID,
      isTesting: this._rentalService.isTesting,
    };
    const accordionCharge = document.getElementById(
      'accordionSecurity'
    ) as HTMLElement | null;
    const btnAddCharge = document.getElementById(
      'btnAddSP'
    ) as HTMLButtonElement | null;
    const btnCancelCharge = document.getElementById(
      'btnCancelSP'
    ) as HTMLButtonElement | null;
    accordionCharge.style.display = 'block';
    btnAddCharge.style.display = 'none';
    btnCancelCharge.style.display = 'block';
  }

  hideAddSecurity() {
    const accordionCharge = document.getElementById(
      'accordionSecurity'
    ) as HTMLElement | null;
    const btnAddCharge = document.getElementById(
      'btnAddSP'
    ) as HTMLButtonElement | null;
    const btnCancelCharge = document.getElementById(
      'btnCancelSP'
    ) as HTMLButtonElement | null;
    const txtAmountSecurity = document.getElementById(
      'txtAmountSecurity'
    ) as HTMLInputElement | null;
    const element: HTMLElement = document.getElementById(
      'SecurityText'
    ) as HTMLElement;
    element.innerHTML = '';
    txtAmountSecurity.value = '';
    accordionCharge.style.display = 'none';
    btnAddCharge.style.display = 'block';
    btnCancelCharge.style.display = 'none';
  }

  writeTextSecurity() {
    const element: HTMLElement = document.getElementById(
      'SecurityText'
    ) as HTMLElement;
    const txtAmountSecurity = document.getElementById(
      'txtAmountSecurity'
    ) as HTMLInputElement | null;
    if (txtAmountSecurity == null || txtAmountSecurity.value == '') {
      element.innerHTML = '';
    } else {
      this.total = Number(txtAmountSecurity.value);

      this.totalfees = Number(
        (this.total + this.total * (this.companyTax / 100)).toFixed(2)
      );
      this.totalfeesfleet =
        this.totalfees +
        Number((this.total * (this.fleetTax / 100)).toFixed(2));
      this.fleetTaxNumber = Number(
        (this.total * (this.fleetTax / 100)).toFixed(2)
      );
      element.innerHTML =
        'Total with Taxes & Fees $' + this.totalfeesfleet.toFixed(2);
    }
  }

  securityCharge() {
    Swal.fire({
      title: 'Creating Charge',
      html: 'This alert will close when the charge has been created.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        const b = Swal.getHtmlContainer().querySelector('b');
      },
      willClose: () => {
      },
    }).then((result) => {
    });
    this.dataPI.paymentID = this.rental.paymentID;
    this.afFun
      .httpsCallable('retrievePayment')(this.dataPI)
      .subscribe(async (payment) => {
        const companyTaxes = ((this.companyTax / 100) * this.total);
        const fleetTaxes = ((this.fleetTax / 100) * this.total);
        var data = {
          title: 'Security Deposit',
          customer: payment.customer,
          id: payment.id,
          cost: this.totalfees.toFixed(2),
          applyTaxes: true,
          amount: Number(this.totalfeesfleet.toFixed(2)),
          companyTaxes: companyTaxes || 0,
          fleetTaxes: fleetTaxes || 0,
          fleetTax: (this.fleetTax / 100),
          companyTax: (this.companyTax / 100),
          totaltotransfer: Number(this.total),
          paymentMethod: payment.payment_method,
          destination: this.dataPI.stripeID,
          companyID: this.rental.companyID,
          amountDestination: Number(this.total),
          transactiondata: payment.metadata,
          isDeposit: true,
        };
        await this.afFun
          .httpsCallable('createCharge')({
            data: data,
            isTesting: this._rentalService.isTesting,
            type: 'deposit',
          })
          .subscribe((datatransfer) => {
            if (datatransfer.code) {
              let error = '';
              datatransfer.code == 'parameter_invalid_empty'
                ? (error =
                  'There is an invalid parameter in the generated payment.')
                : (error = datatransfer.raw.message);
              Swal.fire({
                title: 'Error',
                html:
                  'There was a problem processing the selected payment. <br>' +
                  'Reason: ' +
                  '<b>"' +
                  error +
                  '"</b>',
                icon: 'error',
              });
            } else {
              datatransfer['type'] = 'deposit';
              datatransfer['rentalID'] = this.rental.id;
              datatransfer['companyID'] = this.rental.companyID;
              datatransfer['title'] = this.description;
              datatransfer.metadata.type = 'deposit';
              datatransfer.metadata['userName'] =
                this._currentUser.currentUser.firstName +
                ' ' +
                this._currentUser.currentUser.lastName;
              datatransfer.metadata['userID'] =
                this._currentUser.currentUser.id;
              datatransfer.metadata.rentalID = this.rental.id;
              datatransfer.metadata.companyID = this.rental.companyID;
              datatransfer.metadata.title = this.description;
              this._rentalService.addStripeTransaction(datatransfer);
              this.openCharges(this.rental);
              this._logService.addRentalLog(
                this.rental.id,
                'Security Deposit created, amount: ' +
                this.totalfeesfleet.toFixed(2)
                , "Order Processing Rebuild"
              );
              Swal.fire({
                title: 'The charge has been created',
                icon: 'success',
              }).then((result) => {
              });
              this.hideAddSecurity();
            }
          });
      });
  }

  async openModalPrepare(rental: any) {
    this._rentalService.getRentalByIDPromise(rental.id).then((rent) => {
      this.lastrental = rent;
    });
    this.rentalaux = await this._rentalService.getRentalByIDPromise(rental.id);
    this.rental = rental;
    this.id = rental.id;
    this.position = rental.position;
    this.product = rental.productName1;
    this.productdata = {
      id: rental.id,
      position: rental.position,
      rentalNumber: rental.rentalNumber,
      product: rental.productName1,
      productNumber: rental.productNumber,
      rentalcost: rental.rentalCost,
      productcost: rental.productcost,
      isPrepared: rental.isPrepared,
      isCheckedOut: rental.isCheckedOut,
      isCheckedIn: rental.isChekedIn,
      dayStart: rental.dayStart,
      dayEnd: rental.dayEnd,
      size: rental.productSize,
      notes: rental.notes,
      isDeliver: rental.isDeliver,
      deliverInformation: rental.deliverInformation,
      shopComments: rental.shopComments,
      widgetList: rental.paddons,
    };
    //Option select calculation for prepared
    this.productdata.widgetList.forEach((addon, index) => {
      if (addon.widgetType == 'product' || addon.widgetType == 'dropdown' || addon.widgetType == 'radios') {
        var selected = false;
        addon.element.options.forEach((option) => {
          if (option.is_selected) {
            selected = true;
            this.productdata.widgetList[index].selectedOption = option;
            this.productdata.widgetList[index].noSelected = false;
          }
        });
        if (selected == false) {
          this.productdata.widgetList[index].noSelected = true;
        }
      }
    });

    this._rentalService.companyID = this.rental.companyID;
    this.dataPI = {
      stripeID: this.company.stripeID,
      paymentID: this.rental.paymentID,
      isTesting: this._rentalService.isTesting,
    };
    $('#ProductModal').modal('show');
  }

  async getWaiversByRental() {
    this.waiversArray = [];
    this.multipleWaivers = true;
    if (this.company.multipleWaivers) {
      let promises = [];
      this.rental.productsID.forEach((productid, i) => {
        let promise = new Promise(async (resolve, reject) => {
          const product = await this._rentalService.getWaiversByProduct(
            productid
          );
          if (product.waivers) {
            product.waivers.forEach(async (waiverid) => {
              const waiver = await this._rentalService.getWaiverById(
                this.company.id,
                waiverid
              );
              if (waiver?.multipleSignature) {
                this.waiversArray.push(waiverid);
              } else {
                const found = this.waiversArray.find(
                  (element) => element == waiverid
                );
                found ? '' : this.waiversArray.push(waiverid);
              }
            });
          }
          resolve(true);
        });
        promises.push(promise);
      });
      Promise.all(promises).then((_) => {
        setTimeout(() => {
          this.waiversSigned = this.rental.waiversSigned
            ? this.rental.waiversSigned.length
            : 0;
        }, 500);
      });
    } else {
      if (this.company.multipleSignature) {
        this.rental.productsID.forEach((productid, i) => {
          this.waiversArray.push(productid);
        });
        this.waiversSigned = this.rental.waiversSigned
          ? this.rental.waiversSigned.length
          : 0;
      } else {
        this.waiversArray.push(this.rental.productsID[0]);
        this.waiversSigned = this.rental.waiversSigned
          ? this.rental.waiversSigned.length
          : 0;
      }
    }
  }

  async openModalCheckedOut(rental: any) {
    this._rentalService.companyID = rental.companyID;
    if (this.company.isSmartWaiver) {
      this.smartwaiveractive = true;
      await this._rentalService
        .getSmartWaiver(rental.id)
        .pipe(takeUntil(this.subs$))
        .subscribe((waiver) => {
          if (waiver.length > 0) {
            this.smartwaiversigned = true;
            this.smartwaiverdata = waiver;
          } else {
            this.smartwaiversigned = false;
          }
        });
    } else {
      this.smartwaiveractive = false;
    }
    this.typemodal = 'checkout';
    $('#CheckedOutModal').modal('hide');
    this.openCharges(rental, 'co');
    let dayStart;
    let dayEnd;
    this.imgco = [];
    this.rental = rental;
    this.rentalUserInfo = rental.userInfo;
    this.rentaltowaiver = rental;
    this.id = rental.id;
    this.position = rental.position;
    this.product = rental.productName1;
    if (rental.rentalType == 'byHour') {
      dayStart = moment(rental.dayStart.seconds * 1000).format(
        'MMM DD, yyyy, h:mm a'
      );
      dayEnd = moment(rental.dayEnd.seconds * 1000).format('- h:mm a');
    } else if (rental.rentalType == 'byDay') {
      if (
        moment(rental.dayEnd.seconds * 1000).diff(
          moment(rental.dayStart.seconds * 1000),
          'days'
        ) < 1
      ) {
        dayStart = moment(rental.dayStart.seconds * 1000).format(
          'MMM DD, yyyy'
        );
        dayEnd = '';
      } else {
        dayStart = moment(rental.dayStart.seconds * 1000).format('MMM DD');
        dayEnd = moment(rental.dayEnd.seconds * 1000).format('- MMM DD, yyyy');
      }
    }
    this.checkedoutdata = {
      id: rental.id,
      rentalNumber: rental.rentalNumber,
      name: rental.userInfo.name + ' ' + rental.userInfo.lastName,
      datestart: dayStart,
      dateend: dayEnd,
      items: rental.cartObj.items,
      productsID: rental.productsID,
      waiver: rental.waiver,
      waiverSignature: rental.waiverSignature,
      waiverDate: rental.waiverDate,
      notes: rental.notes,
      waiversSigned: rental.waiversSigned,
      isReservation: rental.isReservation ? rental.isReservation : false,
      amountPaid: rental.amountPaid ? rental.amountPaid : 0,
      amountPending: rental.amountPending ? rental.amountPending : 0,
      reservationPaid: rental.reservationPaid ? rental.reservationPaid : 0,
      reservationPending: rental.reservationPending
        ? rental.reservationPending
        : false,
      isDeliver: rental.isDeliver,
      deliverInformation: rental.deliverInformation,
      shopComments: rental.shopComments,
    };

    //Option select calculation for checked out
    this.checkedoutdata.items.forEach((items, numberp) => {
      items.widgetList.forEach((addon, index) => {
        if (addon.widgetType == 'product' || addon.widgetType == 'dropdown' || addon.widgetType == 'radios') {
          var selected = false;
          addon.element.options.forEach((option) => {
            if (option.is_selected) {
              selected = true;
              this.checkedoutdata.items[numberp].widgetList[index].selectedOption = option;
              this.checkedoutdata.items[numberp].widgetList[index].noSelected = false;
            }
          });
          if (selected == false) {
            this.checkedoutdata.items[numberp].widgetList[index].noSelected = true;
          }
        }
      });
    });
    this.checkedoutdata.items.forEach((element) => {
      this._orderService.getCOImagesURL(rental.id, element.productId).then((img) => {
        element.imagesco = img;
      });
      this._orderService.getCIImagesURL(rental.id, element.productId).then((img) => {
        element.imagesci = img;
      });
    });
    this.getWaiversByRental();
    $('#CheckedOutModal').modal('show');
  }

  openModalCheckedIn(rental: any) {
    // Remove all existing controls in the FormArray
    const productsArray = this.maintenanceForm.get(
      'products'
    ) as UntypedFormArray;
    while (productsArray.length) {
      productsArray.removeAt(0);
    }

    // Add new product groups to the FormArray based on rental data
    rental.products.forEach((p) => {
      const formGroup = this.fb.group(
        {
          cleaningCheck: [p.isCleaning],
          tuneupCheck: [p.isTuneUp],
          damagedCheck: [p.isDamaged],
          unavailableCheck: [false],
          description: [p.maintenanceReason, Validators.required],
        },
        { validators: atLeastOneCheckboxSelected() }
      );

      this.products.push(formGroup);
    });
    this.typemodal = 'checkin';
    $('#CheckedInModal').modal('hide');
    this.openCharges(rental);
    this._rentalService.companyID = rental.companyID;
    this.dataPI = {
      stripeID: this.company.stripeID,
      paymentID: rental.paymentID,
      isTesting: this._rentalService.isTesting,
    };
    let dayStart;
    let dayEnd;
    this.imgci = [];
    this.rental = rental;
    this.rentalUserInfo = rental.userInfo;
    this.id = rental.id;
    this.position = rental.position;
    if (rental.rentalType == 'byHour') {
      dayStart = moment(rental.dayStart.seconds * 1000).format(
        'MMM DD, yyyy, h:mm a'
      );
      dayEnd = moment(rental.dayEnd.seconds * 1000).format('- h:mm a');
    } else if (rental.rentalType == 'byDay') {
      if (
        moment(rental.dayEnd.seconds * 1000).diff(
          moment(rental.dayStart.seconds * 1000),
          'days'
        ) < 1
      ) {
        dayStart = moment(rental.dayStart.seconds * 1000).format(
          'MMM DD, yyyy'
        );
        dayEnd = '';
      } else {
        dayStart = moment(rental.dayStart.seconds * 1000).format('MMM DD');
        dayEnd = moment(rental.dayEnd.seconds * 1000).format('- MMM DD, yyyy');
      }
    }
    this.checkedindata = {
      id: rental.id,
      rentalNumber: rental.rentalNumber,
      name: rental.userInfo.name + ' ' + rental.userInfo.lastName,
      datestart: dayStart,
      dateend: dayEnd,
      products: rental.products,
      productsID: rental.productsID,
      waiver: rental.waiver,
      waiverSignature: rental.waiverSignature,
      waiverDate: rental.waiverDate,
      notes: rental.notes,
      isDeliver: rental.isDeliver,
      deliverInformation: rental.deliverInformation,
      shopComments: rental.shopComments,
      items: rental.cartObj.items
    };

    //Option select calculation for checked in
    this.checkedindata.items.forEach((items, numberp) => {
      items.widgetList.forEach((addon, index) => {
        if (addon.widgetType == 'product' || addon.widgetType == 'dropdown' || addon.widgetType == 'radios') {
          var selected = false;
          addon.element.options.forEach((option) => {
            if (option.is_selected) {
              selected = true;
              this.checkedindata.items[numberp].widgetList[index].selectedOption = option;
              this.checkedindata.items[numberp].widgetList[index].noSelected = false;
            }
          });
          if (selected == false) {
            this.checkedindata.items[numberp].widgetList[index].noSelected = true;
          }
        }
      });
    });

    this.checkedindata.items.forEach((element) => {
      this._orderService.getCOImagesURL(rental.id, element.productId).then((img) => {
        element.imagesco = img;
      });
      this._orderService.getCIImagesURL(rental.id, element.productId).then((img) => {
        element.imagesci = img;
      });
    });
    $('#CheckedInModal').modal('show');
  }


  enableForm(i: any, r?) {
    var idform = 'disabledform' + i.toString();
    const element = document.getElementById(idform);
    element?.classList.add('displayed');

    // Obtener los valores guardados del FormGroup y asignarlos nuevamente
  }

  /**
   * Disables a specific product group in the maintenance form.
   * @param i The index of the product group in the form array.
   */
  disabledForm(i: any) {
    var idform = 'disabledform' + i.toString();

    // Remove the 'displayed' class from the form group
    const element = document.getElementById(idform);
    element?.classList.remove('displayed');

    const productsArray = this.maintenanceForm.get(
      'products'
    ) as UntypedFormArray;
    const productGroup = productsArray.at(i) as UntypedFormGroup;

    if (productGroup) {
      // Reset the form controls within the product group
      productGroup.reset();

      // Clear validators for the 'description' control
      const descriptionControl = productGroup.get('description');
      if (descriptionControl) {
        descriptionControl.clearValidators();
      }

      // Clear the 'atLeastOneCheckboxSelected' validator from the product group
      productGroup.clearValidators();
    }
  }

  enableDamageCharge(i: any, type: string) {
    var idform = 'formtocharge' + i.toString();
    const divform = document.getElementById(idform) as HTMLButtonElement | null;

    var idadd = 'addBtn' + i.toString();
    const addbtn = document.getElementById(idadd) as HTMLButtonElement | null;
    var idcancel = 'cancelBtn' + i.toString();
    const cancelbtn = document.getElementById(
      idcancel
    ) as HTMLButtonElement | null;
    if (type == 'add') {
      divform.style.display = 'block';
      addbtn.style.display = 'none';
      cancelbtn.style.display = 'block';
    } else {
      divform.style.display = 'none';
      addbtn.style.display = 'block';
      cancelbtn.style.display = 'none';
      this.resetCharge(i);
    }
  }

  createMaintenanceCharge(i) {
    Swal.fire({
      title: 'Loading...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        const b = Swal.getHtmlContainer().querySelector('b');
      },
      willClose: () => {
      },
    }).then((result) => {
    });
    this.afFun
      .httpsCallable('retrievePayment')(this.dataPI)
      .subscribe(async (payment) => {
        var data = {
          title: this.description,
          customer: payment.customer,
          id: payment.id,
          cost: this.totalfees.toFixed(2),
          amount: this.totalfeesfleet.toFixed(2),
          fleetTax: this.fleetTax,
          fleetTaxNumber: this.fleetTaxNumber,
          totaltotransfer: this.total,
          paymentMethod: payment.payment_method,
          destination: this.dataPI.stripeID,
          companyID: this.rental.companyID,
          amountDestination: this.total,
          transactiondata: payment.metadata,
          isDeposit: false,
          rentalID: this.rental.id,
        };
        await this.afFun
          .httpsCallable('createCharge')({
            data: data,
            isTesting: this._rentalService.isTesting,
            isRental: false,
          })
          .subscribe((datatransfer) => {
            if (datatransfer.code) {
              let error = '';
              datatransfer.code == 'parameter_invalid_empty'
                ? (error =
                  'There is an invalid parameter in the generated payment.')
                : (error = datatransfer.raw.message);
              Swal.fire({
                title: 'Error',
                html:
                  'There was a problem processing the selected payment. <br>' +
                  'Reason: ' +
                  '<b>"' +
                  error +
                  '"</b>',
                icon: 'error',
              });
            } else {
              if (this.rental.chargesID == undefined) {
                this.rental.chargesID = [
                  {
                    chargeID: datatransfer.id,
                    cost: Number(this.totalfeesfleet.toFixed(2)),
                    date: new Date(),
                    title: this.description,
                  },
                ];
              } else {
                this.rental.chargesID.push({
                  chargeID: datatransfer.id,
                  cost: Number(this.totalfeesfleet.toFixed(2)),
                  date: new Date(),
                  title: this.description,
                });
              }
              delete this.rental['datestart'];
              delete this.rental['dateend'];
              this._rentalService.updateRental(this.rental, this.rental.id);
              this._logService.addRentalLog(
                this.rental.id,
                'Charge created, amount: ' + Number(this.totalfeesfleet)
                , "Order Processing Rebuild"
              );
              datatransfer['type'] = 'charge';
              datatransfer['rentalID'] = this.rental.id;
              datatransfer['companyID'] = this.rental.companyID;
              datatransfer['title'] = this.description;
              datatransfer.metadata['userName'] =
                this._currentUser.currentUser.firstName +
                ' ' +
                this._currentUser.currentUser.lastName;
              datatransfer.metadata['userID'] =
                this._currentUser.currentUser.id;
              datatransfer.metadata['type'] = 'charge';
              datatransfer.metadata['rentalID'] = this.rental.id;
              datatransfer.metadata['companyID'] = this.rental.companyID;
              datatransfer.metadata['title'] = this.description;
              this.openCharges(this.rental);
              this._rentalService.addStripeTransaction(datatransfer);
              this.resetCharge(i);
              Swal.fire({
                title: 'The charge has been created',
                icon: 'success',
              }).then((result) => {
                if (result.isConfirmed) {
                }
              });
            }
          });
      });
  }

  createNewChargeCard(i) {
    Swal.fire({
      title: 'Initializing Transaction..',
      allowOutsideClick: false,
    });
    Swal.showLoading();
    let rentalObj = {
      fleetTax: this._rentalService.fleetTax,
      cost: Number(this.totalfeesfleet.toFixed(2)),
      stripeID: this.rental.stripeID,
      companyID: this.rental.companyID,
    };
    this._rentalService.companyID = this.rental.companyID;
    this.afFun
      .httpsCallable('retrievePayment')(this.dataPI)
      .subscribe((mainpayment) => {
        this.afFun
          .httpsCallable('createPaymentIntentCharge')({
            rental: rentalObj,
            isTesting: this._rentalService.isTesting,
            rentalID: this.rental.id,
            isTest: true,
            customer: mainpayment.customer,
            title: this.description,
            //se manda el rental ID para guardar en metadata
          })
          .subscribe(async (result) => {
            if (!result) {
              return;
            }
            this.paymentIntentID = result.id;
            this.clientSecret = result.client_secret;
            const clientSecret = result.client_secret;
            const appearance = {
              theme: 'stripe',
            };

            const elements = this.stripe.elements({ appearance, clientSecret });
            this.elementTest = elements;

            //options for payment element
            const options = {
              wallets: {
                applePay: 'auto',
              },
              fields: {
                billingDetails: {
                  name: 'auto',
                  phone: 'auto',
                  email: 'auto',
                  address: {
                    line1: 'auto',
                    line2: 'auto',
                    city: 'auto',
                    state: 'auto',
                    postalCode: 'auto',
                    country: 'auto',
                  },
                },
              },
            };

            this.paymentElement = elements.create('payment', options);
            this.paymentElement.mount('#payment-element');
            this.paymentElement.on('ready', (event) => {
              this._rentalService.companyID = this.rental.companyID;
              this.dataPI = {
                stripeID: this.company.stripeID,
                paymentID: this.rental.paymentID,
                isTesting: this._rentalService.isTesting,
              };
              this.resetCharge(i);
              $('#CheckedInModal').modal('hide');
              $('#chargeModal').modal('show');
              Swal.close();
            });
          });
      });
  }

  async handleSubmit(event) {
    Swal.fire({
      title: 'Processing Payment',
      text: 'Please wait...',
      allowOutsideClick: false,
    });
    Swal.showLoading();

    let isInvalid = document.querySelector('.is-invalid');
    if (isInvalid) {
      let input = document.getElementById(isInvalid.id) as HTMLInputElement;
      input.style.borderColor = '#ced4da';
      isInvalid.classList.remove('is-invalid');
      isInvalid.nextElementSibling.innerHTML = '';
    }
    delete this.rental['datestart'];
    delete this.rental['dateend'];
    this._logService.addRentalLog(this.rental.id, 'Charge Added', "Order Processing Rebuild");
    //this._rentalService.updateRental(this.rental, this.rental.id);

    const currentURL = window.location.href;
    const url = new URL(currentURL);
    event.preventDefault();

    const { paymentIntent, error } = await this.stripe.confirmPayment({
      elements: this.elementTest,
      confirmParams: {
        // Make sure to change this to your payment completion page
        //  return_url: window.location.origin + '/success'
        return_url: currentURL,
      },
      redirect: 'if_required',
    });
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.message,
        confirmButtonText: 'I got it',
      });
    } else {
      this.dataPI.paymentID = paymentIntent.id;
      let transaction = {
        type: 'charge',
        companyID: this.rental['companyID'],
        ...paymentIntent,
        rentalID: this.rental.id,
        title: this.description,
        metadata: {
          type: '',
          rentalID: '',
          companyID: '',
          title: '',
        },
      };
      transaction['type'] = 'charge';
      transaction['rentalID'] = this.rental.id;
      transaction['companyID'] = this.rental.companyID;
      transaction['title'] = this.description;
      transaction.metadata.type = 'charge';
      transaction.metadata.rentalID = this.rental.id;
      transaction.metadata.companyID = this.rental.companyID;
      transaction.metadata.title = this.description;
      this._rentalService.addStripeTransaction(transaction);
      this.openCharges(this.rental);
      if (this.rental.chargesID == undefined) {
        this.rental.chargesID = [
          {
            chargeID: paymentIntent.id,
            cost: Number(this.totalfeesfleet.toFixed(2)),
            date: new Date(),
            title: this.description,
          },
        ];
      } else {
        this.rental.chargesID.push({
          chargeID: paymentIntent.id,
          cost: Number(this.totalfeesfleet.toFixed(2)),
          date: new Date(),
          title: this.description,
        });
      }
      delete this.rental['datestart'];
      delete this.rental['dateend'];

      this._rentalService.updateRental(this.rental, this.rental.id);
      this._logService.addRentalLog(
        this.rental.id,
        'Charge created, amount: ' + Number(this.totalfeesfleet)
        , "Order Processing Rebuild"
      );
      Swal.fire({
        title: 'Payment Successful',
        text: 'Your payment was successful',
        icon: 'success',
        confirmButtonText: 'Continue',
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false,
      })
        .then((result) => {
        })
        .then((result) => {
          $('#chargeModal').modal('hide');
          $('#CheckedInModal').modal('show');
        });

      this._rentalService.paymentIntent = paymentIntent.id;
    }
  }

  backModal() {
    $('#chargeModal').modal('hide');
    $('#CheckedInModal').modal('show');
  }

  resetCharge(i) {
    var idform = 'formtocharge' + i.toString();
    const divform = document.getElementById(idform) as HTMLButtonElement | null;
    var idadd = 'addBtn' + i.toString();
    const addbtn = document.getElementById(idadd) as HTMLButtonElement | null;
    var idcancel = 'cancelBtn' + i.toString();
    const cancelbtn = document.getElementById(
      idcancel
    ) as HTMLButtonElement | null;
    var idtextcharge = 'ChargeText' + i.toString();
    const element: HTMLElement = document.getElementById(
      idtextcharge
    ) as HTMLElement;
    element.innerHTML = '';
    addbtn.style.display = 'block';
    cancelbtn.style.display = 'none';

    divform.style.display = 'none';
    this.validateCharge = false;
    var iddescription = 'descriptioncharge' + i.toString();
    const descriptioninput = document.getElementById(
      iddescription
    ) as HTMLButtonElement | null;
    var idamount = 'amountcharge' + i.toString();
    const amountinput = document.getElementById(
      idamount
    ) as HTMLInputElement | null;
    descriptioninput.value = '';
    amountinput.value = '';
  }

  validate(i, type) {
    var iddescription = 'descriptioncharge' + i.toString();
    const descriptioninput = document.getElementById(
      iddescription
    ) as HTMLInputElement | null;
    var idamount = 'amountcharge' + i.toString();
    const amountinput = document.getElementById(
      idamount
    ) as HTMLInputElement | null;
    var iddescriptione = 'descriptionerror' + i.toString();
    const derror = document.getElementById(
      iddescriptione
    ) as HTMLElement | null;
    var idamounte = 'amounterror' + i.toString();
    const aerror = document.getElementById(idamounte) as HTMLElement | null;
    var idtextcharge = 'ChargeText' + i.toString();
    const element: HTMLElement = document.getElementById(
      idtextcharge
    ) as HTMLElement;
    this.amount = Number(amountinput.value);
    this.description = descriptioninput.value;

    if (type == 'amt') {
      if (this.amount <= 0) {
        amountinput.style.border = '1px red solid';
        aerror.style.display = 'block';
        element.innerHTML = '';
      } else {
        this.totalfees = Number(
          (this.amount + this.amount * (this.companyTax / 100)).toFixed(2)
        );
        this.totalfeesfleet =
          this.totalfees +
          Number((this.amount * (this.fleetTax / 100)).toFixed(2));
        this.fleetTaxNumber = Number(
          (this.amount * (this.fleetTax / 100)).toFixed(2)
        );
        element.innerHTML =
          'Total with Taxes & Fees $' + this.totalfeesfleet.toFixed(2);
        amountinput.style.border = '1px lightgray solid';
        aerror.style.display = 'none';
      }
    } else {
      if (this.description == '' || this.description == null) {
        descriptioninput.style.border = '1px red solid';
        derror.style.display = 'block';
      } else {
        descriptioninput.style.border = '1px lightgray solid';
        derror.style.display = 'none';
      }
    }
    this.amount <= 0 || this.description == ''
      ? (this.validateCharge = false)
      : (this.validateCharge = true);
  }

  formatDate(date) {
    return moment.unix(date).format('LLL');
  }

  async openCharges(rental, type?) {
    this.charges = [];
    if (type) {
      const deposits = await this._rentalService.getDeposit(rental.id);
      this.charges.push(...deposits);
    } else {
      const charges = await this._rentalService.getCharges(rental.id);
      const deposits = await this._rentalService.getDeposit(rental.id);
      this.charges.push(...charges);
      this.charges.push(...deposits);
    }
  }

  async createLogs(productrentalaux) {
    this.checkAddons(this.lastrental, productrentalaux);
  }

  ObjCompare(obj1, obj2) {
    const Obj1_keys = Object.keys(obj1);
    const Obj2_keys = Object.keys(obj2);
    if (Obj1_keys.length !== Obj2_keys.length) {
      return false;
    }
    for (let k of Obj1_keys) {
      if (obj1[k] !== obj2[k]) {
        return false;
      }
    }
    return true;
  }

  async checkAddons(lastrental, rental) {
    let addonschange = false;
    await lastrental.products.forEach((oldproduct) => {
      const sameproduct = rental.products.find(
        (newproduct) => newproduct.id === oldproduct.id
      );
      if (sameproduct) {
        oldproduct.addons.forEach((oldaddon) => {
          const sameaddon = sameproduct.addons.find(
            (newaddon) => newaddon.id === oldaddon.id
          );
          if (sameaddon) {
            if (oldaddon.addonType === 'select') {
              const oldselected = oldaddon.optionsSelect.find(
                (oldadd) => oldadd.isSelected === true
              );
              const newselected = sameaddon.optionsSelect.find(
                (newadd) => newadd.isSelected === true
              );
              if (this.ObjCompare(oldselected, newselected)) {
              } else {
                addonschange = true;
              }
            }
            if (oldaddon.addonType === 'number') {
              if (
                this.ObjCompare(
                  oldaddon.optionsSelect[0],
                  sameaddon.optionsSelect[0]
                )
              ) {
              } else {
                addonschange = true;
              }
            }
          }
        });
        if (addonschange == false) {
        } else {
          this.checkFirstLog(lastrental);
          var oldtotal = 0;
          var newtotal = 0;
          oldtotal += oldproduct.cost;
          newtotal += sameproduct.cost;
          oldproduct.addons.forEach((addon) => {
            if (addon.addonType == 'number') {
              oldtotal +=
                addon.optionsSelect[0].price * addon.optionsSelect[0].qty;
            }
            if (addon.addonType == 'select') {
              addon.optionsSelect.forEach((option) => {
                if (option.isSelected) {
                  oldtotal += option.price;
                }
              });
            }
          });
          sameproduct.addons.forEach((addon) => {
            if (addon.addonType == 'number') {
              newtotal +=
                addon.optionsSelect[0].price * addon.optionsSelect[0].qty;
            }
            if (addon.addonType == 'select') {
              addon.optionsSelect.forEach((option) => {
                if (option.isSelected) {
                  newtotal += option.price;
                }
              });
            }
          });
          let logaddon = {
            type: 'pdfRental',
            key: 'productChange',
            details: 'Product ' + oldproduct.productName + ' Addons Change',
            oldProduct: oldproduct,
            newProduct: sameproduct,
            totaldifference: newtotal - oldtotal,
            timestamp: new Date(),
            firstName: this._currentUser.currentUser
              ? this._currentUser.currentUser.firstName
              : 'public',
            lastName: this._currentUser.currentUser
              ? this._currentUser.currentUser.lastName
              : 'public',
            userID: this._currentUser.currentUser
              ? this._currentUser.currentUser.id
              : 'public',
          };
          this._logService.addRentalPDFLog(this.rental.id, logaddon);
        }
      }
    });
  }

  async checkProductsDeleted(lastrental, rental) {
    await lastrental.products.forEach((oldproduct) => {
      const sameproduct = rental.products.find(
        (newproduct) => newproduct.id === oldproduct.id
      );
      if (sameproduct) {
      } else {
        this.checkFirstLog(lastrental);
        let logaddon = {
          type: 'pdfRental',
          details: 'Product ' + oldproduct.productName + ' Deleted',
          oldProduct: oldproduct,
          newProduct: '',
          timestamp: new Date(),
          firstName: this._currentUser.currentUser
            ? this._currentUser.currentUser.firstName
            : 'public',
          lastName: this._currentUser.currentUser
            ? this._currentUser.currentUser.lastName
            : 'public',
          userID: this._currentUser.currentUser
            ? this._currentUser.currentUser.id
            : 'public',
        };
        this._logService.addRentalPDFLog(this.rental.id, logaddon);
      }
    });
  }

  async checkProductsAdded(lastrental, rental, productresp?) {
    await rental.products.forEach((newproduct) => {
      const sameproduct = lastrental.products.find(
        (oldproduct) => oldproduct.id === newproduct.id
      );
      if (sameproduct) {
      } else {
        this.checkFirstLog(lastrental);
        let logaddon = {
          type: 'pdfRental',
          key: 'productChange',
          details: 'Product ' + productresp.productName + ' Change',
          oldProduct: productresp,
          newProduct: newproduct,
          timestamp: new Date(),
          firstName: this._currentUser.currentUser
            ? this._currentUser.currentUser.firstName
            : 'public',
          lastName: this._currentUser.currentUser
            ? this._currentUser.currentUser.lastName
            : 'public',
          userID: this._currentUser.currentUser
            ? this._currentUser.currentUser.id
            : 'public',
        };
        this._logService.addRentalPDFLog(this.rental.id, logaddon);
      }
    });
  }

  async checkFirstLog(lastrental) {
    const firstlog = await this._logService.searchFirstLogCollection(
      lastrental.id,
      true
    );
    if (firstlog.length == 0) {
      let logaddon = {
        type: 'pdfRental',
        details: 'Original Rental Saved',
        change: 'first',
        rental: lastrental,
        timestamp: new Date(),
        firstName: this._currentUser.currentUser
          ? this._currentUser.currentUser.firstName
          : 'public',
        lastName: this._currentUser.currentUser
          ? this._currentUser.currentUser.lastName
          : 'public',
        userID: this._currentUser.currentUser
          ? this._currentUser.currentUser.id
          : 'public',
      };
      this._logService.addRentalPDFLog(this.rental.id, logaddon);
    }
  }

  completePayment(r) {
    //get all rental

    this._rentalService.getRentalByIDPromise(r.id).then((rentalInfo) => {
      this.rental = rentalInfo;

      this.fleetTax = this.company.fleetTax;
      this.companyTax = this.company.companyTax;
      let totalfees = this.fleetTax + this.companyTax;
      let total = Number(
        (
          this.rental.amountPending +
          this.rental.amountPending * (totalfees / 100)
        ).toFixed(2)
      );
      this.fleetTaxNumber = this.rental.amountPending * (this.fleetTax / 100);
      this.totalfeesfleet = total;
      this.totalfees =
        this.rental.amountPending +
        this.rental.amountPending * (this.companyTax / 100);
      Swal.fire({
        title: 'Complete Payment',
        html:
          'The pending amount is: <b>' +
          this.rental.amountPending +
          '</b><br>' +
          'The total with fees is: <b>' +
          total +
          '</b><br>Do you want to create a charge with this amount?',
        icon: 'question',
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'Loading...',
          });
          Swal.showLoading();
          this.dataPI.stripeID = this.company.stripeID;
          this.dataPI.isTesting = this.company.isTesting;
          this.dataPI.paymentID = this.rental.paymentID;
          const companyTaxes = ((this.companyTax / 100) * this.rental.amountPending);
          const fleetTaxes = ((this.fleetTax / 100) * this.rental.amountPending);
          this.afFun
            .httpsCallable('retrievePayment')(this.dataPI)
            .subscribe(async (payment) => {
              var data = {
                title: 'Payment Pending Complete',
                customer: payment.customer,
                id: payment.id,
                cost: this.totalfees.toFixed(2),
                applyTaxes: true,
                amount: Number(this.totalfeesfleet.toFixed(2)),
                companyTaxes: companyTaxes || 0,
                fleetTaxes: fleetTaxes || 0,
                fleetTax: this.fleetTax,
                companyTax: (this.companyTax / 100),
                totaltotransfer: this.rental.amountPending,
                paymentMethod: payment.payment_method,
                destination: this.dataPI.stripeID,
                companyID: this.rental.companyID,
                amountDestination: this.rental.amountPending,
                transactiondata: payment.metadata,
                isDeposit: false,
              };
              await this.afFun
                .httpsCallable('createCharge')({
                  data: data,
                  isTesting: this._rentalService.isTesting,
                })
                .subscribe((datatransfer) => {
                  if (datatransfer.code) {
                    let error = '';
                    datatransfer.code == 'parameter_invalid_empty'
                      ? (error =
                        'There is an invalid parameter in the generated payment.')
                      : (error = datatransfer.raw.message);
                    Swal.fire({
                      title: 'Error',
                      html:
                        'There was a problem processing the selected payment. <br>' +
                        'Reason: ' +
                        '<b>"' +
                        error +
                        '"</b>',
                      icon: 'error',
                    });
                  } else {
                    datatransfer['type'] = 'charge';
                    datatransfer['rentalID'] = this.rental.id;
                    datatransfer.metadata['userName'] =
                      this._currentUser.currentUser.firstName +
                      ' ' +
                      this._currentUser.currentUser.lastName;
                    datatransfer.metadata['userID'] =
                      this._currentUser.currentUser.id;
                    datatransfer['companyID'] = this.rental.companyID;
                    this._rentalService.addStripeTransaction(datatransfer);
                    this.rental.amountPaid += total;
                    this.rental.amountPending = 0;
                    this.rental.reservationPending = false;
                    this._rentalService.updateRental(
                      this.rental,
                      this.rental.id
                    );
                    this._logService.addRentalLog(
                      this.rental.id,
                      'Charge created, amount: ' + Number(data.amount)
                      , "Order Processing Rebuild"
                    );
                    Swal.close();
                    Swal.fire({
                      title: 'The charge has been created',
                      icon: 'success',
                    }).then((result) => {
                      if (result.isConfirmed) {
                        this.checkedoutdata.isReservation = false;

                        Swal.close();
                      }
                    });
                  }
                });
            });
        }
      });
    });
  }

  addPaymentMethod() {
    this.fullpayment = false;
    this.amountToPay = 0;
    let FullAmountPay = Number(
      this.rental.amountPending +
      this.rental.amountPending * (this.fleetTax / 100) +
      this.rental.amountPending * (this.companyTax / 100)
    ).toFixed(2);
    Swal.fire({
      title: 'The amount to be charged is $' + FullAmountPay,
      icon: 'info',
      text: 'Do you want to continue or do you want to change the amount?',
      showCancelButton: true,
      confirmButtonText: 'Continue',
      cancelButtonText: 'Change Amount',
      showDenyButton: true,
      denyButtonText: 'Cancel',
      allowEnterKey: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      reverseButtons: true,
      customClass: {
        actions: 'my-actions',
        denyButton: 'order-1 right-gap',
        cancelButton: 'order-2',
        confirmButton: 'order-3',
      },
    }).then((result) => {
      if (result.isDenied) {
        return;
      } else if (result.isConfirmed) {
        this.isAddPaymentMethod = true;
        this.amountToPay = this.rental.amountPending;
        this.fullpayment = true;
        this.createPaymentMethod();
      } else if (result.isDismissed) {
        Swal.fire({
          title: 'Please put an amount to charge',
          icon: 'info',
          allowOutsideClick: false,
          input: 'text',
          inputLabel: 'Amount',
          inputPlaceholder: 'Enter the amount to charge',
          inputAttributes: {
            autocapitalize: 'off',
          },
          showCancelButton: true,
          confirmButtonText: 'Continue',
          cancelButtonText: 'Cancel',
          showLoaderOnConfirm: true,
          preConfirm: (value) => {
            if (
              value <= 0 ||
              value == null ||
              value == undefined ||
              value == '' ||
              isNaN(Number(value))
            ) {
              Swal.showValidationMessage(`Please enter a valid amount`);
            } else {
              return value;
            }
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.isAddPaymentMethod = true;
            this.fullpayment = false;
            this.amountToPay = Number(result.value);
            this.createPaymentMethod();
          }
        });
      } else {
        return;
      }
    });
  }

  async initAddressForm(country: string) {
    country = country || 'US'

    this.countries = await this._rentalService.getCountries();
    this.states = this._rentalService.getStatesByCountry(country);

    let noStates = [
      'AA',
      'AE',
      'AP',
      'AS',
      'FM',
      'GU',
      'MH',
      'MP',
      'PR',
      'PW',
      'VI',
    ];
    //si this.states includes noStates, then delete noStates from this.states
    this.states = this.states.filter((state) => !noStates.includes(state));

    this.state.valueChanges.subscribe((state) => {
      this.city.reset();
      this.city.disable();
      if (state) {
        this.cities = this._rentalService.getCitiesByState(
          this.country.value,
          state
        );
        this.city.enable();
      }
    });
  }

  createPaymentMethod() {
    Swal.fire({
      title: 'Initializing Transaction..',
      allowOutsideClick: false,
    });

    Swal.showLoading();
    this.amountToPAYwTaxes =
      this.amountToPay +
      this.amountToPay * (this.fleetTax / 100) +
      this.amountToPay * (this.companyTax / 100);
    let rentalObj = {
      fleetTax: this._rentalService.fleetTax,
      cost:
        this.amountToPay +
        this.amountToPay * (this.fleetTax / 100) +
        this.amountToPay * (this.companyTax / 100),
      stripeID: this.rental.stripeID,
      companyID: this.rental.companyID,
    };
    this.afFun
      .httpsCallable('createPaymentIntent')({
        rental: rentalObj,
        isTesting: this._rentalService.isTesting,
        rentalID: this.rental.id,
        isTest: true,
        //se manda el rental ID para guardar en metadata cus_NchKH5KASKd7vY
      })
      .subscribe(async (result) => {
        if (!result) {
          this.isError = true;
          this.toastr.error(result.data.error, 'Error');
          return;
        }
        this.paymentIntentID = result.id;
        this.clientSecret = result.client_secret;
        const clientSecret = result.client_secret;
        const appearance = {
          theme: 'stripe',
        };

        const elements = this.stripe.elements({ appearance, clientSecret });
        this.elementTest = elements;

        //options for payment element
        const options = {
          wallets: {
            applePay: 'auto',
          },
          fields: {
            billingDetails: {
              name: 'auto',
              phone: 'auto',
              email: 'auto',
              address: {
                line1: 'auto',
                line2: 'auto',
                city: 'auto',
                state: 'auto',
                postalCode: 'auto',
                country: 'auto',
              },
            },
          },
        };

        this.paymentElement2 = elements.create('payment', options);
        this.paymentElement2.mount('#payment-element');
        this.paymentElement2.on('ready', (event) => {
          this._rentalService.companyID = this.rental.companyID;
          this.dataPI = {
            stripeID: this.company.stripeID,
            paymentID: this.rental.paymentID,
            isTesting: this._rentalService.isTesting,
          };
          this.checkoutForm
            .get('firstName')
            .setValue(this.rental.userInfo.name);
          this.checkoutForm
            .get('lastName')
            .setValue(this.rental.userInfo.lastName);
          this.email = this.rental.userInfo.email;
          this.checkoutForm.get('email').setValue(this.rental.userInfo.email);
          /*var islada = false
                    var phone = ""
                    var lada = ""
                    for (let i = 0; i < this.rental.userInfo.phone.length; i++) {
                      const element = this.rental.userInfo.phone.charAt(i);
                      element == "+" ? islada = true : ""
                      if(islada){
                        lada += element
                        element == " " ? islada = false : ""
                      }else{
                        element != " " ? phone +=element : ""
                      }
                    }
                    this.checkoutForm.get("phone").setValue(phone)*/
          this.checkoutForm.get('phone').setValue(this.rental.userInfo.phone);
          this.checkoutForm
            .get('address')
            .setValue(this.rental.userInfo.address);
          this.checkoutForm.get('city').setValue(this.rental.userInfo.city);
          this.checkoutForm.get('state').setValue(this.rental.userInfo.state);
          this.checkoutForm.get('zip').setValue(this.rental.userInfo.zip);
          this.checkoutForm
            .get('country')
            .setValue(this.rental.userInfo.country);
          $('#paymentModal').modal('show');
          Swal.close();

          // Handle ready event
          this.initAddressForm(this.rental.userInfo.country);
          this.isHidden = false;
          this.isLoading = false;
        });
      });
  }

  createCustomer(data: any, rental: any) {
    if (rental) {
      let cardData = data.charges.data[0].payment_method_details;
      let objCustomer = {
        name: rental.userInfo.name,
        lastName: rental.userInfo.lastName,
        email: rental.userInfo.email,
        phone: rental.userInfo.phone,
        address: rental.userInfo.address,
        address2: rental.userInfo.address2,
        city: rental.userInfo.city,
        state: rental.userInfo.state,
        zip: rental.userInfo.zip,
        payment_method: data.payment_method,
      };
      this.afFun
        .httpsCallable('createCustomer')({
          objCustomer: objCustomer,
          rental: rental,
          isTesting: this._rentalService.isTesting,
        })
        .subscribe((customer) => {
          $('#paymentModal').modal('hide');
          Swal.fire(
            'Booking Updated',
            'The Booking has been updated',
            'success'
          );
          this.isAddPaymentMethod = false;
        });
    }
  }

  async handleSubmitPayment(e) {
    this.submittedpayment = true;
    if (this.checkoutForm.valid) {
      this.rental.userInfo.name = this.checkoutForm.value.firstName;
      this.rental.userInfo.lastName = this.checkoutForm.value.lastName;
      this.rental.userInfo.email = this.checkoutForm.value.email;
      this.rental.userInfo.phone =
        this.checkoutForm.value.phone.internationalNumber;
      this.rental.userInfo.address = this.checkoutForm.value.address;
      this.rental.userInfo.address2 = this.checkoutForm.value.address2;
      this.rental.userInfo.city = this.checkoutForm.value.city;
      this.rental.userInfo.state = this.checkoutForm.value.state;
      this.rental.userInfo.zip = this.checkoutForm.value.zip;
      Swal.fire({
        title: 'Processing Payment',
        text: 'Please wait...',
        allowOutsideClick: false,
        allowEnterKey: false,
        allowEscapeKey: false,
      });
      Swal.showLoading();

      let isInvalid = document.querySelector('.is-invalid');
      if (isInvalid) {
        let input = document.getElementById(isInvalid.id) as HTMLInputElement;
        input.style.borderColor = '#ced4da';
        isInvalid.classList.remove('is-invalid');
        isInvalid.nextElementSibling.innerHTML = '';
      }
      if (this.fullpayment) {
        this.rental.amountPending = 0;
        this.rental.reservationPending = false;
      } else {
        this.rental.amountPending -= this.amountToPay;
        if (this.rental.amountPending <= 0) {
          this.rental.amountPending = 0;
          this.rental.reservationPending = false;
        }
      }
      delete this.rental['datestart'];
      delete this.rental['dateend'];
      this._logService.addRentalLog(this.rental.id, 'Payment Method Added', "Order Processing Rebuild");
      this._rentalService.updateRental(this.rental, this.rental.id);

      const currentURL = window.location.href;
      const url = new URL(currentURL);
      e.preventDefault();

      const { paymentIntent, error } = await this.stripe.confirmPayment({
        elements: this.elementTest,
        confirmParams: {
          // Make sure to change this to your payment completion page
          //  return_url: window.location.origin + '/success'
          return_url: currentURL,
        },
        redirect: 'if_required',
      });
      if (error) {
        this.submitted = false;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.message,
          confirmButtonText: 'I got it',
        });
      } else {
        //this.bookingscomponent.asyncTotals(this.rental);
        Swal.fire({
          title: 'Payment Successful',
          text: 'Your payment was successful',
          icon: 'success',
          confirmButtonText: 'Continue',
          allowOutsideClick: false,
        }).then((result) => {
          this.dataPI.paymentID = paymentIntent.id;
          setTimeout(() => {
            this._rentalService
              .getStripeTransaction(paymentIntent.id)
              .then((transaction) => {
                this.createCustomer(transaction, this.rental);
              });
          }, 2500);
          Swal.fire({
            title: 'Saving Data...',
            allowOutsideClick: false,
            allowEnterKey: false,
            allowEscapeKey: false,
          });
          Swal.showLoading();
          //$('#paymentModal').modal('hide');
        });

        this._rentalService.paymentIntent = paymentIntent.id;
      }
    }
  }

  getColorTheme() {
    try {
      const current_user = this._currentUser?.currentUser;
      if (current_user?.color?.value && current_user?.color?.fontcolor) {
        this.backgroundColor = current_user.color.value;
        this.textColor = current_user.color.fontcolor;
      } else {
        console.error("There's not color pallete in the user document");
      }
    } catch (error) {
      console.error("Error getting color pallete from user:", error);
    }
  }


  openModalShopComment(comments: string, typeModal: string) {
    this.shopSaveButton = false;
    const textFromFirebase = comments == undefined ? '' : comments.replace(/>>>>/g, '\n');

    this.typemodal = typeModal;
    this.myForm.get('shopComments').setValue(textFromFirebase);
    $('#editShopComments').modal({ backdrop: 'static', keyboard: false });
    $('#editShopComments').modal('show');
  }

  setInputFocus(): void {
    setTimeout(() => {
      document.getElementById('shopComments').focus();
    }, 500);
  }

  async saveChanges(rentalID: any) {
    this.shopSaveButton = true;

    Swal.fire({
      title: 'Loading...',
      timer: 5000,
    });
    Swal.showLoading();

    if (this.myForm.get('shopComments').value === undefined) {
      $('#editShopComments').modal('hide');
      Swal.fire({
        icon: 'success',
        iconColor: '#198754',
        text: 'Shop comments saved successfully',
        showConfirmButton: false,
        timer: 2500,
        showLoaderOnConfirm: true,
      });
      return;
    }

    const textareaElement: HTMLTextAreaElement = this.myTextarea.nativeElement;
    const textOriginal = textareaElement.value;
    const textToSave = textOriginal.replace(/\n/g, '>>>>');

    const shopComment = {
      shopComments: textToSave,
    };

    const updateShopComments: any =
      await this._rentalService.updateShopComments(
        String(rentalID),
        shopComment
      );

    if (updateShopComments.success) {
      if (this.typemodal === 'productdata') {
        $('#editShopComments').modal('hide');
        Swal.fire({
          icon: 'success',
          iconColor: '#198754',
          text: 'Shop comments saved successfully',
          showConfirmButton: false,
          timer: 2500,
        });
        this.productdata.shopComments =
          this.myForm.get('shopComments').value;
        return;
      }
      if (this.typemodal === 'checkedoutdata') {
        $('#editShopComments').modal('hide');
        Swal.fire({
          icon: 'success',
          iconColor: '#198754',
          text: 'Shop comments saved successfully',
          showConfirmButton: false,
          timer: 2500,
        });
        this.checkedoutdata.shopComments =
          this.myForm.get('shopComments').value;
        return;
      }
      if (this.typemodal === 'checkedindata') {
        $('#editShopComments').modal('hide');
        Swal.fire({
          icon: 'success',
          iconColor: '#198754',
          text: 'Shop comments saved successfully',
          showConfirmButton: false,
          timer: 2500,
        });
        this.checkedindata.shopComments =
          this.myForm.get('shopComments').value;
        return;
      }
    }
  }

  CommentsConversion(Comments: string) {
    const textCommets = Comments
      .replace(/>>>>/g, '\n');
    return textCommets;
  }

}

function atLeastOneCheckboxSelected(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const cleaningCheck = control.get('cleaningCheck');
    const tuneupCheck = control.get('tuneupCheck');
    const damagedCheck = control.get('damagedCheck');

    if (!cleaningCheck.value && !tuneupCheck.value && !damagedCheck.value) {
      return { atLeastOneCheckboxSelected: true };
    }

    return null;
  };
}
