import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';

/* Libraries */
import { DateTime } from 'luxon';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

/* Components */
import { DateRangeComponent } from 'src/app/partner/booking-suite/date-range/date-range.component';

/* Models & Types */
import { AvailabilityInterface, RentalLengthType } from 'src/app/models/availability.model';
import { AvailabilityOverrides } from 'src/app/models/availability-overrides.model';
import { BookingFlowCollectionPreReqs } from 'src/app/v2/models/booking-flow.model';
import { Cart } from 'src/app/models/storage/cart.model';
import { PsuedoCartItem } from 'src/app/models/cart.model';
import { DateRangeInputOnChange, DateRangeInputType, DateRangeConfig, DateRangeInputState } from 'src/app/v2/models/date-range.model';
import { ErrorCodes, ErrorHandlingObject } from 'src/app/v2/models/errors.model';
import { InventoryPage } from 'src/app/models/storage/inventory-page.model';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Product } from 'src/app/models/storage/product.model';
import { ProductGroup } from 'src/app/models/storage/product-group.model';
import { ProductLocation } from 'src/app/models/storage/product-location.model';
import { ProductSize } from 'src/app/v2/models/product-size.model';
import { ProductSizeType } from 'src/app/models/storage/product-size-type.model';
import { TimeslotGroupedByType, TimeslotOption } from 'src/app/v2/models/booking-flow.model';
import { TimeslotType } from 'src/app/models/availability-timeslot.model';
import { User } from 'src/app/models/storage/user.model';
import { WidgetInterface } from 'src/app/models/widget.model';

/* Types */
type ProductGroupUI = {
  availableQuantity: number
  productGroupInventoryPage: InventoryPage
  productGroupName: string,
  sizes: ProductGroupSizeUI[]
  totalAvailableQty: number,
}

type ProductGroupSizeUI = {
  cartQty: number,
  currentAvailQty: number,
  inputValue: number,
  size: string;
  sizeID: string,
  sizeTypeID: string
  totalAvailQty: number,
}

export enum ErrorType {
  NoTimeslots = 'noTimeslots',
  NoLocations = 'noLocations',
}

/* Services */
import { AvailabilityService } from 'src/app/services/availability.service';
import { BookingFlowService } from 'src/app/v2/services/booking-flow.service';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { DateRangeService } from 'src/app/v2/services/date-range.service';
import { ErrorService } from 'src/app/v2/services/errors.service';
import { PricingService } from 'src/app/services/pricing.service';
import { ProductLocationService } from 'src/app/v2/services/product-location.service';
import { WidgetService } from 'src/app/services/widget.service';

@Component({
  selector: 'app-inventory-filter-search-wrapper',
  templateUrl: './inventory-filter-search-wrapper.component.html',
  styleUrls: ['./inventory-filter-search-wrapper.component.css']
})

export class InventoryFilterSearchWrapperComponent implements OnInit {

  /* Loaders */
  protected paginationResultLoaded: boolean = false; // Shows loader near pagination + disables pagination buttons
  protected paginationResultLoadedFirstTime: boolean = false; // Keeps the pagination buttons hidden initally until accordian is built
  protected resultsLoaded: boolean = false;
  protected timeslotOptionsLoaded: boolean = false; // Requires Collections, Avail to have been ran with date range, etc

  /* Date Range Props */
  protected selectedEndDate: DateTime = null;
  protected selectedLocationID: string = null;
  protected selectedStartDate: DateTime = null;
  protected selectedTimeslot: TimeslotOption = null;
  protected timeslotsGroupedByType: TimeslotGroupedByType[];
  protected dateRangeFormValid: boolean = false;
  protected dateRangeConfig: DateRangeConfig = {
    showAdditionalInputs: {
      showTimeslots: true
    },
    showFormLabels: true,
    filterByCurrentDay: true,
    showAvailabilityOverrideToggle: true,
    runErrorValidationOnInit: false
  }
  protected isAvailabilityOverride: boolean;
  protected availabilityOverrideConfig: AvailabilityOverrides;

  /* Collection Props */
  private inventoryPageMap: { [key: string]: InventoryPage } = null;
  private productGroupsMap: { [key: string]: ProductGroup } = null;
  private productsArray: Product[] = null;
  private productSizesMap: { [key: string]: ProductSize } = null;
  private productSizeTypeMap: { [key: string]: ProductSizeType } = null;
  private productsMap: { [key: string]: Product } = null;
  private widgetArr: WidgetInterface[] = null;
  private widgetMap: { [key: string]: WidgetInterface } = null;
  protected locationsArr: ProductLocation[] = null;
  protected locationsMap: { [key: string]: ProductLocation } = null;

  /* Pagination Props */
  protected productGroupsPerPage: number = 25; // Number of product groups to display on each page
  protected totalProductGroups: number = 0; // Total number of displayable product groups

  /* Currently Editing Props */
  protected selectedAddonPrice: number = null;
  protected selectedProductGroupID: string = null;
  protected selectedProductGroupQtySummed: number = null; // Sums the order quantities for a product group (sums each size)
  protected selectedProductPrice: number = null;

  /* Data for UI */
  protected paginatedProductGroupUIKeys: string[] = []; // Array of productGroupUI keys (matches current pagination)
  protected productGroupUI: { [key: string]: ProductGroupUI } = {};
  protected productGroupUIKeys: string[] = []; // Array of productGroupUI keys (holds all of the productGroupKeys in an already sorted order)

  /* Availability Related Props */
  private numberOfDaysChosen: number = null; // Used by widget methods (for calculating pricing, etc)
  protected availability: AvailabilityInterface;
  protected cartObj: Cart = { items: [] };

  /* Misc */
  private companyID: string = null;
  protected componentName: string = "Inventory Filter Search";

  /* Error */
  protected errorHandlingObj: ErrorHandlingObject = null;
  protected ErrorTypeEnum = ErrorType;
  protected ErrorDictionary: Record<ErrorType, string> = { // Define error msg per ErrorType here
    [ErrorType.NoTimeslots]: 'No available timeslots were found.',
    [ErrorType.NoLocations]: `No locations were found. At least 1 company defined location is required to use the ${this.componentName}`,
  };

  /* Elements */
  @ViewChild('paginatorBottom') private paginatorBottom: MatPaginator;
  @ViewChild('paginatorTop') private paginatorTop: MatPaginator;
  @ViewChild(DateRangeComponent) private dateRangeComponent: DateRangeComponent;
  @ViewChildren('productGroupAccordian') private accordian: QueryList<ElementRef>;

  desiredQuantities: { [ProductIDKey: string]: any } = {}; // May edit this in the future

  constructor(private bookingFlowService: BookingFlowService,
    private availabilityService: AvailabilityService,
    private currentUserService: CurrentUserService,
    private dateRangeService: DateRangeService,
    private errorService: ErrorService,
    private pricingService: PricingService,
    private productLocation: ProductLocationService,
    private widgetService: WidgetService,
  ) {
    // Initialize the desired quantities with 0
    for (let productKey in this.productGroupUI) {
      this.desiredQuantities[productKey] = 0;
    }
    this.calculateTotalAvailableQty(); // Pre-process the total available quantity
  }

  public async ngOnInit(): Promise<void> {
    await this.init();
  }

  private async init() {
    try {
      this.companyID = await this.getLoggedInUsersCompany();
      await this.setBookingFlowCollectionProps(); // Inital Collection Queries
      this.assignDefaultDateRangeParams(this.locationsMap); // Default Location + Current Day
      this.dateRangeFormValid = true; // Needed to show inital result
      await this.callAvailabilityAndGenerateAvailableTimeslots(); // Calls the availability once to generate available timeslots
    }
    catch (err) {
      console.log(err);
      console.error(`Unable to generate ${this.componentName} results due to errors`);
    }
  }

  // Method to pre-calculate the total available quantity for each product
  calculateTotalAvailableQty() {
    Object.keys(this.productGroupUI).forEach(productKey => {
      const product = this.productGroupUI[productKey];
      product.totalAvailableQty = product.sizes.reduce((total, size) => total + size.totalAvailQty, 0);
    });
  }

  // Method to validate the quantity for each size
  protected validateSizeQty(productKey: string, sizeIndex: number, event: any): void {
    const size = this.productGroupUI[productKey].sizes[sizeIndex];
    const enteredValue = parseInt(event.target.value, 10);

    if (enteredValue < 0) {
      size.inputValue = 0;  // Reset to 0 if below minimum
    } else if (enteredValue > size.totalAvailQty) {
      size.inputValue = size.totalAvailQty;  // Reset to max if above total available
    } else {
      size.inputValue = enteredValue;  // Set to entered value if within range
    }

    // Update the model to reflect the valid value
    event.target.value = size.inputValue;

    let total = 0;

    this.productGroupUI[productKey].sizes.forEach((size) => {
      total += size.inputValue;
    })

    this.selectedProductGroupQtySummed = total;
  }

  // Method to reset the size inputs for a specific product
  private resetProductSizes(productKey: string): void {
    const product = this.productGroupUI[productKey];
    product.sizes.forEach(size => {
      size.inputValue = 0; // Reset the quantity input to 0
    });
  }

  // Method to handle adding the product to the cart
  addProductToCart(productKey: string) {
    const selectedProduct = this.productGroupUI[productKey];
    const selectedSizes = selectedProduct.sizes.filter(size => size.inputValue > 0);

    const selectedSizesSummary = selectedSizes.map(size => ({
      size: size.size,
      selectedQty: size.inputValue
    }));

    // Handle further cart logic here (e.g., send selected quantities to the backend or update the UI)
  }

  validateQuantity(productKey: string) {
    const maxQuantity = this.productGroupUI[productKey].availableQuantity;
    const quantity = this.desiredQuantities[productKey];

    // Ensure the quantity is between 0 and the max available
    if (quantity < 0) {
      this.desiredQuantities[productKey] = 0;
    } else if (quantity > maxQuantity) {
      this.desiredQuantities[productKey] = maxQuantity;
    }
  }

  isValidQuantity(productKey: string): boolean {
    const quantity = this.desiredQuantities[productKey];
    return quantity > 0 && quantity <= this.productGroupUI[productKey].availableQuantity;
  }

  // currentlySelectedProductGroupID

  generateProductGroupsInventoryPage(widgetStruct, invPage) {
    let widgetForm = JSON.parse(JSON.stringify(invPage));

    // Resetting input values
    widgetForm.widgetList.forEach(widget => {

      // Change input to original value
      widgetStruct.forEach(item => {

        if (item.widgetType == widget.widgetType) {
          // Height input doesnt use the inputValue variable, it only has feetInput and heightInput
          if (widget.widgetType == 'heightInput') {
            widget.element.feetInput = item.element?.feetInput || null;
            widget.element.inchInput = item.element?.inchInput || null;
          }

          else if (widget.widgetType == 'dropdown' || widget.widgetType == 'radios') {
            widget.element.inputValue = item.element.inputValue;
            widget.element.options.forEach((option, i) => {
              option.is_selected = item.element.options[i]?.is_selected || false;
            })
          }

          else if (widget.widgetType == 'quantity' || widget.widgetType == 'checkbox' || widget.widgetType == 'weightInput') {
            widget.element.inputValue = item.element?.inputValue || null;
          }

          else if (widget.widgetType == "product") {
            delete widget['tempProductWidgets']
            delete widget.element.priceByDayOriginal;
            delete widget.element.priceByHourOriginal;

            if (widget['element']['isDropdown']) {
              widget['element']['options'].forEach(option => {
                if (option?.is_selected) {
                  option.is_selected = false;
                }
              })
            }
            else {
              widget['element']['options'].forEach(option => {
                option.inputValue = 0;
              })
            }
          }
        }
      });

      // Unselecting widgets
      if (widget?.isSelected == true) {
        widget.isSelected = false;
      }

      // // Removing saved widgets unecessary info
      // if (widget?.savedWidgetId) {
      //   delete widget['element'];
      //   delete widget['lastModified'];
      // }

      // // Remove extra variables
      // delete widget['iconClass'];
      // delete widget['name'];

    })

    return widgetForm;
  }

  protected onPanelChange($event: NgbPanelChangeEvent): void {
    const productGroupKey = $event.panelId.split('-')[1];
    if (productGroupKey !== this.selectedProductGroupID) {
      this.resetProductSizes(productGroupKey); // reset previous product group
      this.selectedProductGroupID = productGroupKey;
      this.alterSelectedProductGroupID(productGroupKey);
    }
  }

  // productGroupKey: string
  protected alterSelectedProductGroupID(productGroupKey): void {
    let invPage = this.productGroupUI[productGroupKey].productGroupInventoryPage;
    const widgetStruct = this.widgetService.getWidgetStructureList();
    this.productGroupUI[productGroupKey].productGroupInventoryPage = this.generateProductGroupsInventoryPage(widgetStruct, invPage);

    this.selectedProductGroupID = productGroupKey;
    this.selectedProductPrice = 0;
    this.selectedAddonPrice = 0;
    this.selectedProductGroupQtySummed = 0;

    const selectedHours = this.determineSelectedHours(this.selectedTimeslot);
    this.selectedProductPrice = this.pricingService.calculateIndividualRentalPrice(this.numberOfDaysChosen, selectedHours, this.productGroupsMap[this.selectedProductGroupID]).rentalPrice
  }

  private determineSelectedHours(timeslot: { type: TimeslotType, dayStart: DateTime, dayEnd: DateTime }): number | string {
    switch (timeslot.type) {
      case TimeslotType.Hourly:
        return timeslot.dayEnd.diff(timeslot.dayStart, 'hours').hours; // number
      case TimeslotType.ShopDay:
        return RentalLengthType.AllDay;
      case TimeslotType.TwentyFourHour:
        return RentalLengthType.TwentyFourHour;
    }
  }

  protected pricingUpdate(): void {
    const selectedHours = this.determineSelectedHours(this.selectedTimeslot);
    try {
      this.selectedAddonPrice = this.pricingService.calculateAddonPrice(this.productGroupUI[this.selectedProductGroupID].productGroupInventoryPage.widgetList, this.numberOfDaysChosen, selectedHours, this.productGroupsMap[this.selectedProductGroupID], false);
    }
    catch (err) {
      console.error(err);
    }
  }

  private async callAvailabilityWithACartObj(): Promise<void> {
    if (!this.selectedTimeslot) {
      this.resultsLoaded = true;
      this.clearProductUI();
      return;
    }

    let cartObj = JSON.parse(JSON.stringify(this.cartObj));
    if (this.cartObj.items.length <= 0) { // Provide a psuedo cart if no items currently exist in the cart
      const psuedoCartObj: PsuedoCartItem = { dayStart: this.selectedTimeslot.dayStart, dayEnd: this.selectedTimeslot.dayEnd, timeslotType: this.selectedTimeslot.type };
      cartObj.items = [psuedoCartObj];
    }

    await this.runAvailabilitySearch(cartObj);
    this.numberOfDaysChosen = this.availability.selectedDaySpan; // Needed for widget pricing, etc
    await this.generateProductUI('productName');
    this.resultsLoaded = true; // Show ProductGroup Accordian UI
  }

  // Handle paginator events
  protected onPagination(event: PageEvent): void {
    this.productGroupsPerPage = event.pageSize;
    this.updateDisplayedProductKeys(event); // Update displayed keys when page changes
  }

  async setUpInventoryPage(inventoryPage: InventoryPage): Promise<InventoryPage> {
    let updatedInventoryPage = inventoryPage;
    try {
      updatedInventoryPage = await this.widgetService.getSavedWidgetsInfo(updatedInventoryPage, this.widgetArr);
      updatedInventoryPage = this.widgetService.getProductWidgetData(updatedInventoryPage, this.productGroupsMap);
      updatedInventoryPage = await this.widgetService.getProductOptionSizes(updatedInventoryPage, this.productSizesMap, this.productSizeTypeMap, this.companyID);
      updatedInventoryPage.widgetList = this.widgetService.filterWidgetCartList(updatedInventoryPage.widgetList, false);
    }
    catch (err) {
      console.error("Something went wrong", err)
    }
    return updatedInventoryPage;
  }

  async getProductGroupInventoryPage(productGroupID: string): Promise<InventoryPage> {
    let defaultInvPageID: string = this.productGroupsMap[productGroupID].inventoryPageId;
    let defaultInvPage: InventoryPage = this.inventoryPageMap[defaultInvPageID];
    return await this.setUpInventoryPage(defaultInvPage);
  }

  async generateProductUI(sortCriteria: 'totalAvailableQty' | 'productName'): Promise<void> {
    let productGroups: { [key: string]: ProductGroupUI } = {};
    let productGroupKeys: string[] = [];

    const productSizePromises = Object.keys(this.availability.cartQuantities).map(async key => {
      const productGroupID = key.split('_')[0];
      const productGroupDoc = this.productGroupsMap[productGroupID];

      if (!this.productGroupsMap[productGroupID]) {
        return null; // Skip irrelevant product groups
      }

      const cartQuantityObj = this.availability.cartQuantities[key];

      // Initialize product group if it doesn't exist
      if (!productGroups[productGroupID]) {
        productGroups[productGroupID] = {
          productGroupName: productGroupDoc.groupName,
          sizes: [],
          totalAvailableQty: 0,
          availableQuantity: 0,
          productGroupInventoryPage: null
          // awaiting here breaks because it's possible that the sizes array is not yet created on completition of first promise (meaning sizes could not be pushed) (doesn't err, but will be missing)
          // productGroupInventoryPage: await this.getProductGroupInventoryPage(productGroupID)
        };
        productGroups[productGroupID]['productGroupInventoryPage'] = await this.getProductGroupInventoryPage(productGroupID)
      }

      // Add size information to the product group
      productGroups[productGroupID].sizes.push({
        inputValue: 0,
        size: cartQuantityObj.sizeName,
        cartQty: cartQuantityObj.cartQty,
        currentAvailQty: cartQuantityObj.currentAvail,
        totalAvailQty: cartQuantityObj.totalAvail,
        sizeID: cartQuantityObj.sizeID,
        sizeTypeID: cartQuantityObj.sizeTypeID
      });

      // Update total available quantity
      productGroups[productGroupID].totalAvailableQty += cartQuantityObj.currentAvail;
      return productGroups[productGroupID]; // Return the modified product group
    });

    // Wait for all promises to complete
    await Promise.all(productSizePromises);

    // Sort sizes array for each product group based on sizeID and sizeTypeID
    Object.keys(productGroups).forEach(productGroupID => {
      productGroups[productGroupID].sizes.sort((a, b) => {
        const sizeTypeID = a.sizeTypeID;
        const sortOrder = this.productSizeTypeMap[sizeTypeID]?.sortOrder;

        // Check if sortOrder is an array and contains string elements
        if (Array.isArray(sortOrder)) {
          const indexA = sortOrder.indexOf(a.sizeID);
          const indexB = sortOrder.indexOf(b.sizeID);

          // Handle cases where sizeID might not be found in sortOrder
          if (indexA === -1 || indexB === -1) {
            return a.size.localeCompare(b.size); // Fallback
          }

          return indexA - indexB;
        }

        return 0; // No sorting if sortOrder is not an array
      });
    });

    // Sort product groups based on the specified sort criteria
    let sortedProductEntries = Object.entries(productGroups).sort(([, productA], [, productB]) => {
      if (sortCriteria === 'totalAvailableQty') {
        return productB['totalAvailableQty'] - productA['totalAvailableQty']; // Sort by totalAvailableQty in descending order
      } else if (sortCriteria === 'productName') {
        return productA['productGroupName'].localeCompare(productB['productGroupName']); // Sort by productName in ascending order
      }
      return 0; // Default: No sorting if criteria does not match
    });

    // Rebuild products object and update productKeys
    productGroups = {};
    sortedProductEntries.forEach(([productGroupID, product]) => {
      productGroups[productGroupID] = product;
      productGroupKeys.push(productGroupID); // Directly update productKeys with sorted keys
    });

    // Assign products and productKeys to component state
    this.productGroupUI = productGroups;
    this.productGroupUIKeys = productGroupKeys;
    this.totalProductGroups = productGroupKeys.length; // Update totalProducts count

    // Apply pagination after products are generated and sorted
    this.updateDisplayedProductKeys();
  }

  private clearProductUI(): void {
    this.productGroupUI = {};
    this.productGroupUIKeys = [];
    this.paginatedProductGroupUIKeys = [];
    this.totalProductGroups = 0; // Update totalProducts count
  }

  // Method to update the displayed product keys based on the current page and itemsPerPage
  private updateDisplayedProductKeys(event?: PageEvent): void {
    // On Init (page load)
    let pageIndex: number = 0;
    let pageSize: number = this.productGroupsPerPage;

    if (event) { // On button press
      pageIndex = event.pageIndex;
      pageSize = event.pageSize;

      // Update Paginator Top
      this.paginatorTop.pageIndex = pageIndex
      this.paginatorTop.pageSize = pageSize

      // Update Paginator Bottom
      this.paginatorBottom.pageIndex = pageIndex
      this.paginatorBottom.pageSize = pageSize
    }

    const startIndex = pageIndex * pageSize;
    const endIndex = startIndex + pageSize;

    // Determine which ProductGroups to show on the UI based off of the pagination stepper
    this.paginatedProductGroupUIKeys = this.productGroupUIKeys.slice(startIndex, endIndex);
  }

  private async getLoggedInUsersCompany(): Promise<string> {
    const currentUser: User = await this.currentUserService.getCurrentUserOnce();
    return currentUser.companyId;
  }

  private async setBookingFlowCollectionProps(): Promise<void> {
    const response: BookingFlowCollectionPreReqs = await this.bookingFlowService.getBookingFlowCollections(this.companyID);
    this.inventoryPageMap = response.inventoryPageMap;
    this.locationsArr = Object.values(response.locationMap);
    this.locationsMap = response.locationMap;
    this.productGroupsMap = response.productGroupsMap;
    this.productsArray = response.productCollectionArray;
    this.productSizesMap = response.productSizesMap;
    this.productSizeTypeMap = response.productSizeTypeMap;
    this.productsMap = response.allProductsMap;
    this.widgetArr = response.widgetCollectionArray
    this.widgetMap = response.widgetCollectionMap;
  }


  private assignDefaultDateRangeParams(locationsMap: { [key: string]: ProductLocation }): void {
    /* Assign Default Location */
    try {
      const defaultLocation: ProductLocation = this.productLocation.getDefaultLocation(locationsMap);
      this.selectedLocationID = defaultLocation.id;
      if (!this.locationsMap[this.selectedLocationID] || !this.locationsMap[this.selectedLocationID].timezone) {
        throw new Error();
      }
    }
    catch (err) {
      const errObj: ErrorHandlingObject = { isValid: false, errors: [this.errorService.getErrorByCode(ErrorCodes.NO_COMPANY_LOCATIONS)] };
      this.errorHandlingObj = errObj;
      throw new Error(this.ErrorTypeEnum.NoLocations);
    }

    /* Assign Default Date Range (today by default) */
    const defaultDates = this.bookingFlowService.getDefaultDates(this.locationsMap[this.selectedLocationID].timezone);
    this.selectedStartDate = defaultDates.dayStart;
    this.selectedEndDate = defaultDates.dayEnd;
  }

  async dateRangeInputChanged($event: DateRangeInputOnChange): Promise<void> {
    this.dateRangeFormValid = $event.isFormValid;

    if (!$event.isFormValid) {
      return
    }

    const inputs: DateRangeInputState = $event.componentInputState;

    this.selectedStartDate = inputs.selectedStartDate;
    this.selectedEndDate = inputs.selectedEndDate;
    this.selectedLocationID = inputs.selectedLocationID
    this.selectedTimeslot = inputs.selectedTimeslot;

    if ($event.changedInput === DateRangeInputType.Timeslot) {
      this.resultsLoaded = false;
      await this.callAvailabilityWithACartObj(); // Re-calls the availability to obtain the cart quantity response (used for quantities + validation)
    }

    if ($event.changedInput === DateRangeInputType.DatePicker || $event.changedInput === DateRangeInputType.Location || $event.changedInput === DateRangeInputType.AvailabilityOverride) {
      this.callAvailabilityAndGenerateAvailableTimeslots();
    }
  }

  async callAvailabilityAndGenerateAvailableTimeslots(): Promise<TimeslotOption | null> {
    this.resultsLoaded = false;
    try {
      await this.runAvailabilitySearch();
      const { timeslotOptions, firstAvailableTimeslot } = this.dateRangeService.generateUniqueAvailableTimeslots(this.availability);

      /* If at least one timeslot is available */
      if (firstAvailableTimeslot) { // Only provide timeslotOptions and load results if availabilities were found
        this.selectedTimeslot = firstAvailableTimeslot;
        this.timeslotsGroupedByType = timeslotOptions;
        this.timeslotOptionsLoaded = true;
        this.dateRangeConfig.showAdditionalInputs.showTimeslots = true;
        this.callAvailabilityWithACartObj();
      }
      else {
        const errObj: ErrorHandlingObject = { isValid: false, errors: [this.errorService.getErrorByCode(ErrorCodes.NO_TIMESLOTS_AVAILABLE)] };
        this.dateRangeComponent.setErrorMessage(errObj);
        this.dateRangeConfig.showAdditionalInputs.showTimeslots = false;
        this.clearProductUI();
        this.dateRangeFormValid = false; // Manually done here... for init (is this the best way??)
        this.resultsLoaded = true;
      }

      return firstAvailableTimeslot;
    }
    catch (err) {
      throw new Error();
    }
  }

  private async runAvailabilitySearch(cartObj?: Cart): Promise<void> {
    this.availability = await this.availabilityService.runAvailabilityAlgorithm(
      this.selectedStartDate,
      this.selectedEndDate,
      this.selectedLocationID,
      this.companyID,
      this.locationsArr,
      this.productsArray,
      this.productGroupsMap,
      this.inventoryPageMap,
      this.widgetMap,
      this.productsMap,
      cartObj ? cartObj : this.cartObj,
      undefined,
      undefined,
      undefined,
      this.availabilityOverrideConfig
    );
    console.log("availability run", this.availability);
  }

  protected toggleAvailabilityOverride($event: boolean): void {
    this.isAvailabilityOverride = $event;
    this.availabilityOverrideConfig = (this.isAvailabilityOverride ? this.availabilityService.getDefaultAvailabilityOverride() : null);
  }
}
