import { Timestamp } from "firebase/firestore";
// When saving to db, should be in Date format (new Date())

export interface Affiliate {
    companyID: string;
    dateCreated: null | Timestamp | Date;
    landingURL: string;
    name: string;
    active: boolean;
    softDeleted: boolean;
    id?: string; // sometimes included in collection when stampped from query
}

export const defaultValues: Partial<Affiliate> = {
    companyID: "",
    dateCreated: null,
    name: "",
    active: true,
    softDeleted: false,
};
