import { Component, OnInit, ViewChild } from '@angular/core';
import { PartnerService } from '../../services/partner.service';
import { CurrentUserService } from '../../services/current-user.service';
import { User } from 'src/app/models/storage/user.model';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { StorageService } from '../../services/storage.service';
import { SelectItem } from 'primeng/api';
import { SelectItemGroup } from 'primeng/api';
import Swal from 'sweetalert2';
import { UserService } from '../../services/user.service';
import { Subject } from 'rxjs';
import { data } from 'jquery';
import { CreateAccountComponent } from '../create-account/create-account.component';
import { environment } from 'src/environments/environment';
import { DEFAULT_THEME } from 'src/app/utils/config/themeDefault.config';
@Component({
  selector: 'app-users-management',
  templateUrl: './users-management.component.html',
  styleUrls: ['./users-management.component.scss'],
})
export class UsersManagementComponent implements OnInit {
  users: User[] = [];
  icons: any[] = [];
  currentUser: User;
  userInfo: UntypedFormGroup;
  isEditing = false;
  isDeveloper: boolean = false;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  projectId: string = environment.firebase.projectId;
  protected textColor: string = DEFAULT_THEME.fontColor;
  protected themeColor: string = DEFAULT_THEME.value;

  constructor(
    private _partnerService: PartnerService,
    public _currentUserService: CurrentUserService,
    private _storageService: StorageService,
    private _afFun: AngularFireFunctions,
    private _userService: UserService
  ) {
        this.userInfo = new UntypedFormGroup({
      firstName: new UntypedFormControl('', Validators.required),
      lastName: new UntypedFormControl('', Validators.required),
      email: new UntypedFormControl('', Validators.required),
      password: new UntypedFormControl(''),
      phone: new UntypedFormControl('', Validators.required),
      levelType: new UntypedFormControl('', Validators.required),
      icon: new UntypedFormControl(''),
    });
    this.isDeveloper = this._currentUserService.currentUser.isDeveloper;
  }

  @ViewChild(CreateAccountComponent) createComponent: CreateAccountComponent;
  ngOnInit() {
    this.getIcons();
    this.getUsers();
  }
  cleanValidation() {
    this.createComponent.resetFormValidation();
  }
  getUsers() {
    console.log(this._currentUserService.currentUser.id);
    this.users = [];
    this._partnerService
      .getAllUsers(
        this._currentUserService.currentUser.companyId,
        this._currentUserService.currentUser.isDeveloper,
        this._currentUserService.currentUser.isAdmin
      )
      .subscribe((users) => {
        users.forEach((user) => {
          //look for the user in the users array
          let index = this.users.findIndex((u) => u.id == user.id);
          if (index == -1) {
            this.users.push(user);
          }
        });
        //sort users by name
        this.users.sort((a, b) => {
          if (a.firstName < b.firstName) {
            return -1;
          }
          if (a.firstName > b.firstName) {
            return 1;
          }
          return 0;
        });
      });
  }

  getIcons() {
    //get all icons from storage
    let path = 'assets/icons/';

    this._storageService.getIcons(path).then((icons) => {
      this.icons = icons;
      //sort icons by name
    });
  }

  onLogoChange(event) {
    console.log(event);
    this.userInfo.get('logo').setValue(event.value);
    console.log(this.userInfo.value);
  }

  enableEdition() {
    let optDisabled = document.getElementsByClassName('opt-disabled');
    for (let i = 0; i < optDisabled.length; i++) {
      //add opt-enabled class
      optDisabled[i].classList.add('opt-enabled');
      optDisabled[i].classList.remove('opt-disabled');
    }
    this.isEditing = true;
  }

  disableEdition() {
    let optEnabled = document.getElementsByClassName('opt-enabled');
    for (let i = 0; i < optEnabled.length; i++) {
      //add opt-disabled class
      optEnabled[i].classList.add('opt-disabled');
      optEnabled[i].classList.remove('opt-enabled');
    }
    this.isEditing = false;
  }

  deleteUser() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to remove access to this user?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Please wait...',
          allowOutsideClick: false,
          //hide the confirm button
          showConfirmButton: false,
        });
        this._afFun
          .httpsCallable('disableUser')(this.currentUser.id)
          .subscribe((res) => {
            if (res) {
              this._userService
                .deactivateUser(this.currentUser.id)
                .then((res) => {
                  if (res) {
                    Swal.fire(
                      'Deleted!',
                      'User has been removed.',
                      'success'
                    ).then((_) => {
                      this.currentUser.isActive = false;
                      this.currentUser = null;
                      $('#editUser').modal('hide');
                    });
                  } else {
                    Swal.fire(
                      'Error!',
                      'User has not been removed.',
                      'error'
                    ).then((_) => {
                      this.currentUser = null;
                      $('#editUser').modal('hide');
                    });
                  }
                });
            } else if (!res) {
              Swal.fire('Error!', 'Something went wrong.', 'error').then(
                (_) => {
                  this.currentUser = null;
                  $('#editUser').modal('hide');
                }
              );
            } else {
              Swal.fire('Error!', res, 'error').then((_) => {
                this.currentUser = null;
                $('#editUser').modal('hide');
              });
            }
          });
      } else {
        Swal.fire('Cancelled', 'User is safe :)', 'error');
      }
    });
  }

  checkUsers() {
    console.log(this.users, JSON.stringify(this.users));
  }

  enableUser() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to reactivate this user?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, reactivate it!',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Please wait...',
          allowOutsideClick: false,
          //hide the confirm button
          showConfirmButton: false,
        });
        this._afFun
          .httpsCallable('enableUser')(this.currentUser.id)
          .subscribe((res) => {
            if (res) {
              this._userService
                .reactivateUser(this.currentUser.id)
                .then((res) => {
                  if (res) {
                    Swal.fire(
                      'Reactivated!',
                      'User has been reactivated.',
                      'success'
                    ).then((_) => {
                      this.currentUser.isActive = true;
                      this.currentUser = null;
                      $('#editUser').modal('hide');
                    });
                  } else {
                    Swal.fire(
                      'Error!',
                      'User has not been reactivated.',
                      'error'
                    ).then((_) => {
                      this.currentUser = null;
                      $('#editUser').modal('hide');
                    });
                  }
                });
            } else if (!res) {
              Swal.fire('Error!', 'Something went wrong.', 'error').then(
                (_) => {
                  this.currentUser = null;
                  $('#editUser').modal('hide');
                }
              );
            } else {
              Swal.fire('Error!', res, 'error').then((_) => {
                this.currentUser = null;
                $('#editUser').modal('hide');
              });
            }
          });
      } else {
        Swal.fire('Cancelled', 'User is safe :)', 'error').then((_) => {
          this.currentUser = null;
          $('#editUser').modal('hide');
        });
      }
    });
  }

  //save updated user
  async saveUser() {
    //show loading
    Swal.fire({
      title: 'Please wait...',
      allowOutsideClick: false,
      //hide the confirm button
      showConfirmButton: false,
    });

    let user = {
      id: this.currentUser.id,
      email: this.currentUser.email,
      password: this.currentUser.password,
    };
    await this._afFun
      .httpsCallable('updateUserAuthManagement')(user)
      .toPromise()
      .then((res) => {
        if (res) {
          let cUser = {
            email: this.userInfo.value.email,
            firstName: this.userInfo.value.firstName,
            lastName: this.userInfo.value.lastName,
            phone: this.userInfo.value.phone,
            levelType: this.userInfo.value.levelType,
            icon: this.userInfo.value.icon,
          };
          this._userService
            .updateUser(cUser, this.currentUser.id)
            .then(() => {
              Swal.fire({
                title: 'User updated successfully',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                this.currentUser = null;
                $('#editUser').modal('hide');
              });
              this.disableEdition();
            })
            .catch((err) => {
              Swal.fire({
                title: 'Error updating user',
                icon: 'error',
                showConfirmButton: false,
                timer: 1500,
              });
            });
        } else if (!res) {
          //after 1.5s
          setTimeout(() => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Something went wrong!',
            });
          }, 1500);
        } else {
          //show error message with sweet alert
          Swal.fire({
            title: 'Error',
            text: res,
            icon: 'error',
          });
        }
      });
  }

  validateForm() {
    console.log('validating form');
    var form = document.getElementsByClassName(
      'needs-validation'
    )[0] as HTMLFormElement;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    form.classList.add('was-validated');
    this.saveUser();
    console.log('hey');
  }

  openEdit(user) {
    console.log(user);
    this.currentUser = user;
    this.isEditing = true;

    $('#editUser').modal('show');
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
