import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import Swal from 'sweetalert2';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ProductsService } from 'src/app/services/products.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ProductSizeType } from 'src/app/models/storage/product-size-type.model';
import { Subscription, filter } from 'rxjs';
import { Firestore } from 'firebase/firestore';
import firestore from 'firebase/compat/app';
import { User } from 'src/app/models/storage/user.model';
import { environment } from 'src/environments/environment';
import { DEFAULT_THEME } from 'src/app/utils/config/themeDefault.config';

@Component({
  selector: 'app-product-sizes',
  templateUrl: './product-sizes.component.html',
  styleUrls: ['./product-sizes.component.scss'],
})
export class ProductSizesComponent implements OnInit {
  step = 0;

  myForm: FormGroup = this.fb.group({
    size: ['', [Validators.required]],
    products: this.fb.array([]),
  });

  get productsControl() {
    return this.myForm.get('products') as FormArray;
  }

  listForm: FormGroup = this.fb.group({
    productType: ['', [Validators.required]],
    sizes: [''],
    editable: [true, [Validators.required]],
    productsList: this.fb.array([]),
    //sizes: new UntypedFormControl('', [Validators.required])
  });

  get productsListControl() {
    return this.listForm.get('productsList') as FormArray;
  }

  public productSizeType: string = '';
  public productSize: string = '';
  public productSizeID: string = '';
  public productTypeID: string = '';
  public productID: string = '';
  public customSizeObjectsArray = [];
  public addNewSize: boolean = false;
  public deleteSize: boolean = false;
  public title: string = '';
  public editableH: boolean = true;
  protected colorTheme: string = DEFAULT_THEME.value;
  protected colorText: string = DEFAULT_THEME.fontColor;
  protected currentUser: User;
  protected projectId: string = environment.firebase.projectId;
  constructor(
    public fb: FormBuilder,
    private _afs: AngularFirestore,
    private _productsService: ProductsService,
    public _currentUserService: CurrentUserService
  ) {
    // this.listForm = new UntypedFormGroup({
    //   productType: new UntypedFormControl('', [Validators.required]),
    //   sizes: new UntypedFormControl(''),
    //   editable:  new UntypedFormControl(true, Validators.required),

    //   //sizes: new UntypedFormControl('', [Validators.required])
    // })
    this.sizeForm = new UntypedFormGroup({
      size: new UntypedFormControl('', [Validators.required]),
    });
    this.currentUser = this._currentUserService.currentUser;
  }

  _companyId = this._currentUserService.currentUser.companyId;
  // listForm: UntypedFormGroup;
  sizeForm: UntypedFormGroup;
  get listFormControls() {
    return this.listForm.controls;
  }
  get sizeFormControls() {
    return this.sizeForm.controls;
  }

  public isEditing = false;
  public submitted: boolean = false;
  public disabledButton: boolean = false;
  public editId = ''; //Stores DocId of item being edited (productSizeTypes list doc or productSizes single custom size doc)
  public defaultID = 'nJaaXROMVSfPEEU7JXOS';

  /* Observables variables */
  subs = new Subscription();
  productData;
  productsSizeType: any[] = [];
  customSizesData: Array<any>;
  customSizeTypesData: Array<any>;
  hasLoadedTypes = false;
  hasLoadedSizes = false;

  customSizesDataDefault: Array<any>;
  customSizeTypesDataDefault: Array<any>;
  hasLoadedTypesDefault = false;
  hasLoadedSizesDefault = false;

  sizesMap = new Map(); // Map to store and organize all the lists&sizes displayed to the editor

  ngOnInit(): void {
    this.initialSubscriptions();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  initialSubscriptions() {
    // Subscribe to changes in product collection (in order to add checkbox list applying sizes directly to products)
    this.subs.add(
      this._productsService.getProducts().subscribe((data) => {
        // console.log('get product data from collection')
        this.productData = data;
        //console.log("productData: ", this.productData);
      })
    );

    //Subscribe to changes in productSizes collection
    this.subs.add(
      this._productsService
        .getCustomSizesObservableDefault(this.defaultID)
        .subscribe((data) => {
          this.customSizesDataDefault = data;
          this.hasLoadedSizesDefault = true;
          //console.log('after sizes change, hasLoadedSizes&Types:', this.hasLoadedSizes, this.hasLoadedTypes);
          // if(this.hasLoadedSizesDefault && this.hasLoadedTypesDefault) {
          //   this.initialize();
          // }
        })
    );

    // //Subscribe to changes in productSizeTypes collection
    this.subs.add(
      this._productsService
        .getCustomSizeTypesObservableDefault()
        .subscribe((data) => {
          this.customSizeTypesDataDefault = data;
          this.hasLoadedTypesDefault = true;
          //console.log('after types change, hasLoadedSizes&Types:', this.hasLoadedSizes, this.hasLoadedTypes);
          // if(this.hasLoadedSizesDefault && this.hasLoadedTypesDefault) {
          //   this.initialize();
          // }
        })
    );

    //Subscribe to changes in productSizes collection
    this.subs.add(
      this._productsService
        .getCustomSizesObservable(this._companyId)
        .subscribe((data) => {
          this.customSizesData = data;
          this.hasLoadedSizes = true;
          //console.log('after sizes change, hasLoadedSizes&Types:', this.hasLoadedSizes, this.hasLoadedTypes);
          if (this.hasLoadedSizes && this.hasLoadedTypes) {
            this.initialize();
          }
        })
    );

    //Subscribe to changes in productSizeTypes collection
    this.subs.add(
      this._productsService
        .getCustomSizeTypesObservable(this._companyId)
        .subscribe((data) => {
          this.customSizeTypesData = data;
          this.hasLoadedTypes = true;
          console.log(
            'Change in customSizeTypesData: ',
            this.customSizeTypesData
          );
          //console.log('after types change, hasLoadedSizes&Types:', this.hasLoadedSizes, this.hasLoadedTypes);
          if (this.hasLoadedSizes && this.hasLoadedTypes) {
            this.initialize();
          }
        })
    );
  }

  initialize() {
    this.sizesMap.clear();
    console.log('reinitializing, cleared sizesMap');
    // debugging 4/28/23: deep copies are required when writing to sizesMap to avoid accidentally
    // carrying over old nested data after clear (by default, copied objects pass data by reference as a "shallow copy")
    let customSizeTypesDataDeepCopyDefault = JSON.parse(
      JSON.stringify(this.customSizeTypesDataDefault)
    );
    let customSizesDataDeepCopyDefault = JSON.parse(
      JSON.stringify(this.customSizesDataDefault)
    );

    let customSizeTypesDataDeepCopy = JSON.parse(
      JSON.stringify(this.customSizeTypesData)
    );
    let customSizesDataDeepCopy = JSON.parse(
      JSON.stringify(this.customSizesData)
    );

    //Read array of types from productSizeTypes & write into sizesMap
    customSizeTypesDataDeepCopyDefault.forEach((typeDocument) => {
      let productTypeId = typeDocument.id;
      if (!this.sizesMap.has(productTypeId)) {
        this.sizesMap.set(productTypeId, typeDocument);
      }
    });

    // //Read array of sizes from productSizes, check against IDs in the productTypes in sizesMap, and write into matching sizesMap entries
    customSizesDataDeepCopyDefault.forEach((sizeDocument) => {
      let sizeId = sizeDocument.id;
      let productTypeId = sizeDocument.productType;
      if (this.sizesMap.has(productTypeId)) {
        this.sizesMap
          .get(productTypeId)
          .sortOrder.forEach((sortedSizeId, index) => {
            if (sizeId == sortedSizeId) {
              this.sizesMap.get(productTypeId).sortOrder[index] = sizeDocument;
              return;
            }
          });
      }
    });

    //Read array of types from productSizeTypes & write into sizesMap
    customSizeTypesDataDeepCopy.forEach((typeDocument) => {
      let productTypeId = typeDocument.id;
      if (productTypeId != this.defaultID) {
        if (!this.sizesMap.has(productTypeId)) {
          this.sizesMap.set(productTypeId, typeDocument);
        }
      }
    });

    //Read array of sizes from productSizes, check against IDs in the productTypes in sizesMap, and write into matching sizesMap entries
    customSizesDataDeepCopy.forEach((sizeDocument) => {
      let sizeId = sizeDocument.id;
      let productTypeId = sizeDocument.productType;
      if (productTypeId != this.defaultID) {
        if (this.sizesMap.has(productTypeId)) {
          this.sizesMap
            .get(productTypeId)
            .sortOrder.forEach((sortedSizeId, index) => {
              if (sizeId == sortedSizeId) {
                this.sizesMap.get(productTypeId).sortOrder[index] =
                  sizeDocument;
                return;
              }
            });
        }
      }
    });
    console.log('Sizes Map:', this.sizesMap);
  }

  openNewSizeList(edit: boolean, id?: any) {
    //triggers when modal is opened
    this.resetForm('list');
    this.isEditing = edit;
    if (this.isEditing) {
      let listTitle = this.sizesMap.get(id).productType;
      this.listForm.controls['productType'].setValue(listTitle);
      this.listForm.controls['sizes'].setValue('');
      this.listForm.controls['editable'].setValue(true);
      if (id) {
        //Save either productType or size docId to know what is being edited
        this.editId = id;
        console.log('editId: ', this.editId);
      }
      this.productTypeID = id;
      this.productsSizeType.length = 0;
      this.productData.forEach((elem: any) => {
        if (elem.productSizeTypeID == id) {
          this.productsSizeType.push(elem);
        }
      });

      this.productsSizeType.forEach((p, index) => {
        this.customSizeObjectsArray[index] = this.sizesMap.get(
          p.productSizeTypeID
        ).sortOrder;
        this.productsListControl.push(
          this.fb.group({
            productID: [p.id],
            productSizeType: [p.productSizeTypeID],
            productSize: [p.productSizeID],
          })
        );
      });

      if (this.productsSizeType.length > 0) {
        Swal.fire({
          icon: 'info',
          title: 'Edit product size type.',
          text: 'There are products linked to this size type, the products will be affected by the modifications you make.',
          confirmButtonColor: '#114463',
        }).then(() => {
          $('#newSizeListModal').modal('show');
        });
      } else {
        $('#newSizeListModal').modal('show');
      }
    } else {
      this.listForm.controls['editable'].setValue(true);
      this.isEditing = false; //redundant
      $('#newSizeListModal').modal('show');
    }
  }

  closeModalList() {
    this.listForm.reset();
    this.productsListControl.clear();
    this.productsSizeType.length = 0;
    $('#newSizeListModal').modal('hide');
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  changeCustomSizeType(event: any, index: number, type: string) {
    const selectedCustomSizeTypeID = event.target.value;
    this.customSizeObjectsArray[index] = this.sizesMap.get(
      selectedCustomSizeTypeID
    ).sortOrder;
    if (type === 'size') {
      const myForm = (<FormArray>this.myForm.get('products')).at(index);
      myForm
        .get('productSize')
        .setValue(this.customSizeObjectsArray[index][0].id);
    } else {
      const listForm = (<FormArray>this.listForm.get('productsList')).at(index);
      listForm
        .get('productSize')
        .setValue(this.customSizeObjectsArray[index][0].id);
    }
  }

  readonly EDIT_ID_PREFIX = 'size-edit-'; //naming prefix for ids of hidden edit divs, targeted to toggle show/hide
  showEdit: string = this.EDIT_ID_PREFIX;
  showEditDiv(
    id: string,
    edit: boolean,
    currentValue?: string,
    productType?: string,
    deleteSize?: boolean
  ) {
    if (deleteSize) {
      this.deleteSize = deleteSize;
      this.productsSizeType.length = 0;
      this.productData.forEach((elem: any) => {
        if (elem.productSizeTypeID == productType && elem.productSizeID == id) {
          this.productsSizeType.push(elem);
        }
      });

      this.productsSizeType.forEach((p, index) => {
        this.customSizeObjectsArray[index] = this.sizesMap.get(
          p.productSizeTypeID
        ).sortOrder;
        this.productsControl.push(
          this.fb.group({
            productID: [p.id],
            productSizeType: [p.productSizeTypeID],
            productSize: [p.productSizeID],
          })
        );
      });

      this.productSizeID = id;
      this.productTypeID = productType;
      this.sizeForm.controls['size'].setValue(currentValue);
      this.myForm.controls['size'].setValue(currentValue);

      this.title = 'Delete size';

      if (this.productsSizeType.length > 0) {
        Swal.fire({
          icon: 'info',
          title: 'Delete size of products.',
          text: 'There are products linked to this size, if you delete this size you will delete all the product assigned to this size.',
          confirmButtonColor: '#114463',
        }).then(() => {
          $('#EditSizeProduct').modal('show');
        });
      } else {
        $('#EditSizeProduct').modal('show');
      }
      return;
    }

    if (currentValue) {
      this.productsSizeType.length = 0;
      this.productData.forEach((elem: any) => {
        if (elem.productSizeTypeID == productType && elem.productSizeID == id) {
          this.productsSizeType.push(elem);
        }
      });

      this.productsSizeType.forEach((p, index) => {
        this.customSizeObjectsArray[index] = this.sizesMap.get(
          p.productSizeTypeID
        ).sortOrder;
        this.productsControl.push(
          this.fb.group({
            productID: [p.id],
            productSizeType: [p.productSizeTypeID],
            productSize: [p.productSizeID],
          })
        );
      });

      this.productSizeID = id;
      this.productTypeID = productType;
      this.sizeForm.controls['size'].setValue(currentValue);
      this.myForm.controls['size'].setValue(currentValue);

      this.title = 'Edit size';

      if (this.productsSizeType.length > 0) {
        Swal.fire({
          icon: 'info',
          title: 'Edit size of products.',
          text: 'There are products linked to this size, the products will be affected by the modifications you make.',
          confirmButtonColor: '#114463',
        }).then(() => {
          $('#EditSizeProduct').modal('show');
        });
      } else {
        $('#EditSizeProduct').modal('show');
      }
    } else {
      this.isEditing = edit;
      this.showEdit = this.EDIT_ID_PREFIX;
      this.showEdit += id;
      console.log('showEdit variable:', this.showEdit);
    }
  }

  closeModal() {
    this.myForm.reset();
    this.productsControl.clear();
    this.myForm.controls['size'].setValue('');
    this.sizeForm.controls['size'].setValue('');
    this.productsSizeType.length = 0;
    this.deleteSize = false;
    $('#EditSizeProduct').modal('hide');
  }

  hideEditDiv() {
    this.isEditing = false;
    this.showEdit = this.EDIT_ID_PREFIX;
    this.sizeForm.controls['size'].setValue('');
    this.sizeForm.controls['size'].setErrors({ incorrect: false });
  }

  resetForm(type: string) {
    this.submitted = false;
    this.disabledButton = false;
    if (type == 'list') {
      this.listForm.reset();
      this.productsListControl.clear();
      this.listForm.controls['productType'].setValue('');
      this.listForm.controls['sizes'].setValue('');
      $('#newSizeListModal').modal('hide');
    }
    if (type == 'size') {
      this.sizeForm.reset();
      this.sizeForm.controls['size'].setValue('');

      this.myForm.reset();
      this.productsControl.clear();
      this.myForm.controls['size'].setValue('');
      $('#EditSizeProduct').modal('hide');
    }
  }

  async delete(type: string, id: string) {
    switch (type) {
      case 'list':
        const products = this.listForm.value.productsList;
        const existsSize = [];
        products.forEach((e: any) => {
          if (e.productSizeType === this.productTypeID) {
            existsSize.push(e);
          }
        });
        if (existsSize.length === 0) {
          const products = this.listForm.value.productsList;
          products.forEach((e: any) => {
            let productSizeType = this.customSizeTypesData.find(
              (sizeType) => sizeType.id === e.productSizeType
            );
            let productSize = this.customSizesData.find(
              (sizeType) => sizeType.id === e.productSize
            );
            const sizeObj = {
              productSize: productSize.size,
              productSizeID: productSize.id,
              productSizeType: productSizeType.productType,
              productSizeTypeID: productSizeType.id,
            };
            this._productsService
              .updateProductSizes(
                this._companyId,
                'product',
                e.productID,
                sizeObj
              )
              .catch((err) => console.error(err));
          });

          await this._productsService
            .updateProductSizes(this._companyId, 'isActiveList', id, 'delete')
            .then(() => {
              Swal.fire({
                title: 'Removed Size List',
                html: 'Deleted Successfully',
                icon: 'success',
                allowOutsideClick: true,
                showConfirmButton: true,
              }).then((result) => {
                //if (result.isConfirmed) {
                this.disabledButton = false;
                $('#newSizeListModal').modal('hide');
                //}
              });
            });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Delete product size type',
            text: 'Remember that to remove the size type it is necessary to reassign all products to another size type.',
            confirmButtonColor: '#114463',
          });
        }
        break;
      case 'size':
        await this._productsService.updateProductSizes(
          this._companyId,
          'isActiveSize',
          id,
          'delete'
        );
        break;
    }
  }

  /**
   * @description Checks listForm before submit (prevent duplicate or empty submissions)
   * @author Dustin Chen
   * @date 04/26/2023
   */
  validateListForm() {
    this.submitted = true;
    console.log('Submitted');

    //Still need to check for duplicates here (RegExp or validators? or convert to lowercase and compare?)
    //var regex = new RegExp('[^,]', 'i'); //comma separated list, ignore case

    if (this.listForm.valid) {
      this.disabledButton = true;
      this.submit('list');
    }
  }

  /**
   * @description Checks sizeForm before submit (prevent duplicate or empty submissions)
   * @author Dustin Chen
   * @date 04/26/2023
   * @param {*} data user submitted string from sizeForm
   * @param {*} id when adding new size, id is the docID of the productSizeTypes (list) being added to;
   *                when editing an existing size, id is the docID of the productSizes (individual size) being changed
   */
  validateSizeForm(data, id) {
    console.log('size form data: ', id, data);

    /*
    need to add duplicate checking here
    (should allow changing capitalization of current size,
    but should not allow creating or changing to a name
    matching others that are already in the list)
    */

    const SizeArray = this.sizesMap.get(id).sortOrder;
    const size = SizeArray.some(
      (size) => size.size == this.sizeForm.value.size
    );

    if (size) {
      this.sizeForm.controls['size'].setErrors({ incorrect: true });
      Swal.fire({
        icon: 'error',
        title: 'Update size',
        text: `The ${this.myForm.value.size} size already exists in the list`,
        confirmButtonColor: '#114463',
      });
    } else {
      if (this.sizeForm.valid) {
        this.editId = id;
        //resetting showEdit hides edit div - move to submit function?
        this.showEdit = this.EDIT_ID_PREFIX;
        this.disabledButton = true;
        this.addNewSize = true;
        this.submit('size');
      }
    }
  }

  validateSizeFormProducts(data, id) {
    console.log('size form data products: ', id, data);
    console.log(this.deleteSize)
    /*
    need to add duplicate checking here
    (should allow changing capitalization of current size,
    but should not allow creating or changing to a name
    matching others that are already in the list)
    */

    if (this.deleteSize) {
      Swal.fire({
        title: "Deleting Size",
        html: "Remember, by deleting this size, the products linked to this size will also be deleted, are you sure?",
        icon: "question",
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        showCancelButton: true,
        reverseButtons: true
      }).then(result => {
        if (result.isConfirmed) {
          const products = this.myForm.value.products;
          const existsSize = [];
          products.forEach((e: any) => {
            if (e.productSize === this.productSizeID) {
              existsSize.push(e);
            }
          });
          console.log(existsSize)

          if (this.myForm.valid) {
            this.editId = id;
            //resetting showEdit hides edit div - move to submit function?
            this.showEdit = this.EDIT_ID_PREFIX;
            this.disabledButton = true;
            this.submit('sizeDelete');
          }

          return;
        }
      })
    } else {
      const SizeArray = this.sizesMap.get(this.productTypeID).sortOrder;
      const filterSize = SizeArray.filter(
        (size) => size.id != this.productSizeID
      );
      const size = filterSize.some((size) => size.size == this.myForm.value.size);

      if (size) {
        this.myForm.controls['size'].setErrors({ incorrect: true });
        Swal.fire({
          icon: 'error',
          title: 'Update size',
          text: `The ${this.myForm.value.size} size already exists in the list`,
          confirmButtonColor: '#114463',
        });
      } else {
        if (this.myForm.valid) {
          this.editId = id;

          //resetting showEdit hides edit div - move to submit function?
          this.showEdit = this.EDIT_ID_PREFIX;
          this.disabledButton = true;
          this.submit('size');
        }
      }
    }
  }

  stringToArray(string: string) {
    //split this.listForm.value.sizes string input into an array to pass along to submit function
    //list separated by commas, trim whitespace
    let result = string.split(',').map(function (value) {
      return value.trim();
    });
    return result;
  }

  arrayToString() { }

  asIsOrder(a, b) {
    //Used to preserve the sorting of the sizesMap when run through the *ngFor | keyvalue pipe
    return 0;
  }

  submit(type: string) {
    if (type == 'list') {
      //submission from listForm
      if (this.isEditing === true) {
        //If editing, just update existing documents...
        if (this.listForm.value.sizes !== '') {
          this._productsService
            .updateProductSizes(
              this._companyId,
              'productType',
              this.editId,
              this.listForm.value.productType
            )
            .catch((err) => console.error(err));
          this.new(
            'listAdd',
            this.editId,
            this.stringToArray(this.listForm.value.sizes)
          ) // ... add new sizes (if any) to current list ...
            .then(() => {
              Swal.fire({
                title: 'Size List',
                html: 'Multiple New Sizes Created Successfully',
                icon: 'success',
                allowOutsideClick: false,
                showConfirmButton: true,
              }).then((result) => {
                if (result.isConfirmed) {
                  this.disabledButton = false;
                  $('#newSizeListModal').modal('hide');
                  this.isEditing = false; //... and reset isEditing variable ...
                }
              });
            });
        } else {
          // if just editing the name of list (not adding any extra sizes)
          const products = this.listForm.value.productsList;
          products.forEach((e: any) => {
            let productSizeType = this.customSizeTypesData.find(
              (sizeType) => sizeType.id === e.productSizeType
            );
            let productSize = this.customSizesData.find(
              (sizeType) => sizeType.id === e.productSize
            );

            if (this.productTypeID == productSizeType.id) {
              const sizeObj = {
                productSize: productSize.size,
                productSizeID: productSize.id,
                productSizeType: this.listForm.value.productType,
                productSizeTypeID: productSizeType.id,
              };
              this._productsService
                .updateProductSizes(
                  this._companyId,
                  'product',
                  e.productID,
                  sizeObj
                )
                .catch((err) => console.error(err));
            } else {
              const sizeObj = {
                productSize: productSize.size,
                productSizeID: productSize.id,
                productSizeType: productSizeType.productType,
                productSizeTypeID: productSizeType.id,
              };
              this._productsService
                .updateProductSizes(
                  this._companyId,
                  'product',
                  e.productID,
                  sizeObj
                )
                .catch((err) => console.error(err));
            }
          });
          this._productsService
            .updateProductSizes(
              this._companyId,
              'productType',
              this.editId,
              this.listForm.value.productType
            )
            .catch((err) => console.error(err))
            .then(() => {
              Swal.fire({
                title: 'Size List',
                html: 'Product Type Updated Successfully',
                icon: 'success',
                allowOutsideClick: true,
                showConfirmButton: true,
                confirmButtonColor: '#114463',
              }).then((result) => {
                this.disabledButton = false;
                this.isEditing = false; //... and reset isEditing variable ...
              });
            });
        }
      } else {
        // ... Otherwise, create a new list and documents in firebase
        if (this.listForm.value.sizes == '') {
          //if form was submitted without any sizes
          this.new('type', this.listForm.value.productType).then(() => {
            Swal.fire({
              title: 'Size List',
              html: 'New Empty Size List Created Successfully',
              icon: 'success',
              allowOutsideClick: true,
              showConfirmButton: true,
            }).then((result) => {
              this.disabledButton = false;
              $('#newSizeListModal').modal('hide');
            });
          });
          console.log('Product List Created');
        } else {
          //if form was submitted with sizes
          this.new(
            'list',
            this.listForm.value.productType,
            this.stringToArray(this.listForm.value.sizes)
          )
            //this.newCustomSizeList(this._companyId, this.listForm.value.productType, this.stringToArray(this.listForm.value.sizes))
            .then(() => {
              Swal.fire({
                title: 'Size List',
                html: 'New Size List Created Successfully',
                icon: 'success',
                backdrop: true,
                allowOutsideClick: true,
                showConfirmButton: true,
              }).then((result) => {
                this.disabledButton = false;
                $('#newSizeListModal').modal('hide');
              });
            });
          this.disabledButton = false;
          $('#newSizeListModal').modal('hide');
        }
      }
      this.resetForm('list');
      //this.initialize(true);
    }

    if (type == 'size') {
      //submission from sizeForm
      if (this.addNewSize === false) {
        this._productsService
          .updateProductSizes(
            this._companyId,
            'size',
            this.editId,
            this.myForm.value.size
          )
          .catch((err) => console.error(err));
        this.isEditing = false;

        // Start update product size
        const products = this.myForm.value.products;
        products.forEach((e: any) => {
          let productSizeType = this.customSizeTypesData.find(
            (sizeType) => sizeType.id === e.productSizeType
          );
          let productSize = this.customSizesData.find(
            (sizeType) => sizeType.id === e.productSize
          );
          if (this.productSizeID == productSize.id) {
            const sizeObj = {
              productSize: this.myForm.value.size,
              productSizeID: productSize.id,
              productSizeType: productSizeType.productType,
              productSizeTypeID: productSizeType.id,
            };
            this._productsService
              .updateProductSizes(
                this._companyId,
                'product',
                e.productID,
                sizeObj
              )
              .catch((err) => console.error(err));
          } else {
            const sizeObj = {
              productSize: productSize.size,
              productSizeID: productSize.id,
              productSizeType: productSizeType.productType,
              productSizeTypeID: productSizeType.id,
            };
            this._productsService
              .updateProductSizes(
                this._companyId,
                'product',
                e.productID,
                sizeObj
              )
              .catch((err) => console.error(err));
          }
        });

        Swal.fire({
          icon: 'success',
          title: 'Changes have been saved successfully',
          showConfirmButton: false,
          timer: 2000,
        });

        // End update product size
      } else {
        this.new('size', this.editId, this.sizeForm.value.size);
        this.addNewSize = false;
        //this.newSize(this._companyId, this.editId, this.sizeForm.value.size);
      }
      this.resetForm('size');
    }

    if (type == 'sizeDelete') {
      this._productsService
        .updateProductSizes(
          this._companyId,
          'isActiveSize',
          this.productSizeID,
          'delete'
        )
        .catch((err) => console.error(err));
      this.isEditing = false;
      this.deleteSize = false;
      // Start update product size
      const products = this.myForm.value.products;
      products.forEach(async (e: any) => {
        e['isActive'] = false;
        e['lastModified'] = new Date();
        /*let productSizeType = this.customSizeTypesData.find(
          (sizeType) => sizeType.id === e.productSizeType
        );
        let productSize = this.customSizesData.find(
          (sizeType) => sizeType.id === e.productSize
        );

        const sizeObj = {
          productSize: productSize.size,
          productSizeID: productSize.id,
          productSizeType: productSizeType.productType,
          productSizeTypeID: productSizeType.id,
        };
        this._productsService
          .updateProductSizes(this._companyId, 'product', e.productID, sizeObj)
          .catch((err) => console.error(err));*/
        await this._productsService.deleteActiveProduct(e.productID); //Delete Products
      });

      Swal.fire({
        icon: 'success',
        title: 'Size successfully deleted',
        showConfirmButton: false,
        timer: 2000,
      });

      this.resetForm('size');
    }
  }

  //
  /**
   * @description Handle writing new documents to firebase through products.service and linking docIDs in relevant fields
   *              (Refactored redundant "Create" operations by combining new list, new size, new type, and multiple new sizes into one function)
   * @author Dustin Chen
   * @date 04/28/2023
   * @param {string} action what will be created: 'type': empty list; 'size': single size;
   *                        'list': new type and sizes together; 'listAdd': add multiple new sizes to existing type
   * @param {string} productType for 'type'list': name of new sizeType; for 'size', 'listAdd': firebase docID of existing sizeType
   * @param {(string|Array)} [sizes] for 'size': string with name of new size; for 'list', 'listAdd': Array with names of new sizes
   */
  async new(
    action: string,
    productType: string,
    sizes?: string | Array<string>
  ) {
    let sizeTypeObject = {
      companyId: this._companyId,
      productType: productType,
      sortOrder: [],
      isActive: true,
      created: new Date(),
      editable: this.listForm.get('editable').value,
    };
    if (action == 'size' || action == 'listAdd') {
      var objectArray = this.sizesMap.get(productType).sortOrder;
      var sortOrderArray = []; //stores just the docIDs
    }
    switch (action) {
      case 'type':
        await this._productsService
          .newCustomSizeType(sizeTypeObject)
          .then((productTypeId) => {
            console.log('New List created with id: ', productTypeId);
          });
        break;
      case 'size':
        let sizeObject: ProductSizeType = {
          companyId: this._companyId,
          productType: productType,
          size: sizes as string,
          isActive: true,
          created: new Date(),
        };
        /*
        let sizeId = await this._productsService.newCustomSize(sizeObject);
        for (let index = 0; index < objectArray.length; index++) {
          sortOrderArray.push(objectArray[index].id);
        }
        sortOrderArray.push(sizeId);
        this._productsService.updateProductSizes(this._companyId,'sortOrder', productType, sortOrderArray).catch(err => console.error(err));
        console.log("newSize: ", sizeId, sizeObject);
        */
        /*
        // instead of regenerating and rewriting entire sortOrder array, could try arrayUnion to just append new ID to existing sortOrder
        // await this._productsService.newCustomSize(sizeObject)
        // .then(docId => {}) //update list sortOrder with new size docID (possibly use firestore.firestore.FieldValue.arrayUnion(DocID))
        */
        let productTypeDocRef = await this._afs
          .collection('productSizeTypes')
          .ref.doc(productType);
        await this._productsService.newCustomSize(sizeObject).then((docId) => {
          productTypeDocRef.update({ sortOrder: firestore.firestore.FieldValue.arrayUnion(docId) });
        });

        break;
      case 'list':
        await this._productsService
          .newCustomSizeType(sizeTypeObject)
          .then(async (productTypeId) => {
            let sortOrderArray = [];
            for (let index = 0; index < sizes.length; index++) {
              let sizeObject: ProductSizeType = {
                companyId: this._companyId,
                productType: productTypeId as string,
                size: sizes[index],
                isActive: true,
                created: new Date(),
              };
              let sizeId = await this._productsService.newCustomSize(
                sizeObject
              );
              sortOrderArray.push(sizeId);
            }
            this._productsService
              .updateProductSizes(
                this._companyId,
                'sortOrder',
                productTypeId as any,
                sortOrderArray
              )
              .catch((err) => console.error(err));
          });
        break;
      case 'listAdd':
        for (let index = 0; index < objectArray.length; index++) {
          sortOrderArray.push(objectArray[index].id);
        }
        for (let index = 0; index < sizes.length; index++) {
          let sizeObject: ProductSizeType = {
            companyId: this._companyId,
            productType: productType as string,
            size: sizes[index],
            isActive: true,
            created: new Date(),
          };
          let sizeId = await this._productsService.newCustomSize(sizeObject);
          sortOrderArray.push(sizeId); //push ID of newly created size
        }
        this._productsService.updateProductSizes(
          this._companyId,
          'sortOrder',
          productType as any,
          sortOrderArray
        );
        break;
    }
  }

  /**
   * @description Create new documents in 2 collections (productSizeTypes & productSizes) and link DocIDs in relevant fields
   * @author Dustin Chen
   * @date 04/19/2023
   * @param {string} companyId from currentUserService
   * @param {string} productType the name of the size list
   * @param {Array<any>} sizes an array containing the names of new sizes to be created
   */
  async newCustomSizeList(
    companyId: string,
    productType: string,
    sizes: Array<any>
  ) {
    let sizeTypeObject = {
      companyId: companyId,
      productType: productType,
      sortOrder: [],
      isActive: true,
      created: new Date(),
    };
    await this._productsService
      .newCustomSizeType(sizeTypeObject)
      .then(async (productTypeId) => {
        let sortOrderArray = [];
        for (let index = 0; index < sizes.length; index++) {
          let sizeObject: ProductSizeType = {
            companyId: companyId,
            productType: productTypeId as string,
            size: sizes[index],
            isActive: true,
            created: new Date(),
          };
          //let sizeId = this._productsService.newCustomSize(sizeObject);
          let sizeId = await this._productsService.newCustomSize(sizeObject);
          sortOrderArray.push(sizeId);
        }
        this._productsService.updateProductSizes(
          this._companyId,
          'sortOrder',
          productTypeId as any,
          sortOrderArray
        );
      });
  }

  async newSize(companyId: string, productTypeId: string, size: string) {
    let sizeObject: ProductSizeType = {
      companyId: companyId,
      productType: productTypeId,
      size: size,
      isActive: true,
      created: new Date(),
    };
    let sizeId = await this._productsService.newCustomSize(sizeObject);
    let sortOrderArray = this.sizesMap.get(productTypeId).sortOrder;
    sortOrderArray.push(sizeId);
    this._productsService.updateProductSizes(
      this._companyId,
      'sortOrder',
      productTypeId,
      sortOrderArray
    );

    // this._productsService.newCustomSize(sizeObject);
    // .then(docId => {}) //update list sortOrder with new size docID (possibly use firestore.firestore.FieldValue.arrayUnion(DocID))
  }

  /* Drag and drop functions */
  drop(event: CdkDragDrop<string[]>, productTypeId) {
    //console.log("Drop event: ", event, productTypeId);
    moveItemInArray(
      this.sizesMap.get(productTypeId).sortOrder,
      event.previousIndex,
      event.currentIndex
    );
    // Update sortOrder array in productSizeTypes document
    let updatedObjectArray = this.sizesMap.get(productTypeId).sortOrder;
    let sizeIdArray = []; // Stores just the size docIDs
    for (let index = 0; index < updatedObjectArray.length; index++) {
      sizeIdArray.push(updatedObjectArray[index].id);
    }
    this._productsService.updateProductSizes(
      this._companyId,
      'sortOrder',
      productTypeId,
      sizeIdArray
    );
  }

  /* // 4/17/2023 Working drag and drop with single "productSizes" collection
    // Commented out to try 2 collection implementation (productSizes & productTypes)
    // Hoping to solve 'flickering' encountered with too many firebase reads/writes
    // (due to sortOrder updating every document in a list + numerous corresponding observable emits)
    // If 2 collections doesn't work, we can try this single collection code with batch writes + 'edit' & 'save changes' buttons
    // (at the cost of real-time updates to drag and drop)

  //Basic Test Arrays
  testSizeArrayDefault: string[] = ['XXS','XS','S','M','L','XL','XXL']; // Matches current (Inventory-component) and (RapidCycling-component) sizing to faciliate easy migration
  testSizeArrayBikes: string[] = ['29"','27.5"','26"','51cm','49cm','56cm']; // Test combining both tire sizes and frame sizes in one list
  // testSizeArraySnowboards: string[] = ['150cm','151cm','152cm','153cm'];
  // testSizeArrayBoots: number[] = [6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5];
  // testSizeArrayUSWomenShoe: number[] = [4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5];
  // testSizeArrayUSMenShoe: number[] = [10, 10.5, 11, 11.5, 12, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5]; //Test ordering of number array
  // testSizeArrayEuroShoe: string[] = ['Euro 34','Euro 42','Euro 41.5','Euro 40','Euro 39.5','Euro 38']; //Test ordering of string array
  testSizeArray: any = [];
  productTypes = {default: this.testSizeArrayDefault, bikes: this.testSizeArrayBikes};
  // productTypes = {default: this.testSizeArrayDefault, bikes: this.testSizeArrayBikes, boots: this.testSizeArrayBoots, snowboards: this.testSizeArraySnowboards, usMenShoe: this.testSizeArrayUSMenShoe, euroShoe: this.testSizeArrayEuroShoe};

  //Arrays of objects (like will be received from firebase)
  dummyListA = [{companyId: 'I3ANuzttq4Qrvld5xuYb', size: 'S', productType: 'dummyListA', sortOrder: 0, id: 'A0'},{companyId: 'I3ANuzttq4Qrvld5xuYb', size: 'M', productType: 'dummyListA', sortOrder: 1, id: 'A1'},{companyId: 'I3ANuzttq4Qrvld5xuYb', size: 'L', productType: 'dummyListA', sortOrder: 2, id: 'A2'}];
  dummyListB = [{companyId: 'I3ANuzttq4Qrvld5xuYb', size: '150cm', productType: 'dummyListB', sortOrder: 0, id: 'B0'},{companyId: 'I3ANuzttq4Qrvld5xuYb', size: '151cm', productType: 'dummyListB', sortOrder: 1, id: 'B1'},{companyId: 'I3ANuzttq4Qrvld5xuYb', size: '152cm', productType: 'dummyListB', sortOrder: 2, id: 'B2'}];
  // dummyMap = {'dummyListA': this.dummyListA, 'dummyListB': this.dummyListB};
  dummyMap = new Map([['mapDummyListA',this.dummyListA],['mapDummyListB',this.dummyListB]])

  //Actual Map
  sizesMap = new Map();
  inactiveSizesMap = new Map();
  currentSize: CustomSize;

  sizeListForm: UntypedFormGroup;
  constructor(
    private _afs: AngularFirestore,
    private _productsService: ProductsService,
    public _currentUserService: CurrentUserService,

  ) {
    this.sizeListForm = new UntypedFormGroup({
      productType: new UntypedFormControl('', [Validators.required]),
      sizes: new UntypedFormControl('', [Validators.required])
    })
  }

  get formControls() { return this.sizeListForm.controls; };
  public isEditing = false;
  public submitted: boolean = false;
  public disabledButton: boolean = false;
  public editId = '';

  _companyId = this._currentUserService.currentUser.companyId;
  subs = new Subscription();
  productData;
  customSizesData;

  ngOnInit(): void{
    this.initialSubscriptions();
    this.initialize();
    //this.restoreTest();
    // Testing Edge Cases & responses from firebase
    console.log("Current User: ", this._currentUserService.currentUser);
    this._productsService.getCustomSizes(this._companyId).then(value => (console.log("value returned from firebase: ", value)));
    // this._productsService.getCustomSizes('nonsenseCompanyId').then(value => (console.log("(NonsenseId) value returned from firebase: ", value))).catch(err => console.error(err));
    // this._productsService.getCustomSizes(this._companyId,'productType','dummyListA').then(value => (console.log("Custom Sizes query dummyListA returned: ", value))).catch(err => console.error(err));
    // this._productsService.getCustomSizes(this._companyId,'size','9aKJ0A46fucnDb6MqKbV').then(value => (console.log("Query of single size doc returned: ", value))).catch(err => console.error(err));
    // this._productsService.getCustomSizes(this._companyId,'badAttribute','badId').catch(err => console.error(err));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }


  initialSubscriptions() {
      // Subscribe to changes in product collection
      this.subs.add(this._productsService.getProducts().subscribe((data) => {
        // console.log('get product data from collection')
        this.productData = data;
        //console.log("productData: ", this.productData);
      }));

      //Subscribe to changes in productSizes collection
      this.subs.add(this._productsService.getCustomSizesObservable(this._companyId).subscribe((data) => {
        this.customSizesData = data;
        console.log("Change in customSizesData: ", this.customSizesData);
        this.initialize(true);
      }));

  }

  // restoreTest() {
  //   //this.inactiveSizesMap.clear();
  //   //restore all sizes in 'test' list to isActive = true
  //   this.getInactiveSizes(this._companyId).then((inactiveSizes: Array<CustomSize>) => {
  //     inactiveSizes.forEach(inactiveDocument => {
  //       let productType = inactiveDocument.productType;
  //       if(!this.inactiveSizesMap.has(productType)) this.inactiveSizesMap.set(productType,[inactiveDocument]);
  //         else this.inactiveSizesMap.get(productType).push(inactiveDocument);
  //     });
  //   });
  //   console.log("inactiveSizesMap: ",this.inactiveSizesMap);
  //   console.log("inactiveSizesMap.get()",this.inactiveSizesMap.get("Test"));
  //   this.sizesMap.get('Test').forEach(async (sizeInList) => {
  //     await this._productsService.updateCustomSizes(this._companyId,'isActive',sizeInList.id,'restore')
  //   });
  //   console.log("Test sizes restored");
  // }

  // async getInactiveSizes(companyId: string, type?: string, id?: string) {
  //   let ref = await this._afs.collection('productSizes').ref.where('isActive','==',false).where('companyId','==',companyId);
  //   let snapshot = await ref.get();
  //   return snapshot.docs.map(response => { let data = response.data() as any; data.id = response.id; return data});
  // }

  initialize(reset?: boolean) {
    if (reset) {
      this.sizesMap.clear();
    }
    //Read array of sizes from firebase, organize by productType into sizesMap
    this._productsService.getCustomSizes(this._companyId).then((allSizes: Array<CustomSize>) => {
      allSizes.forEach(sizeDocument => {
        let productType = sizeDocument.productType;
        if(!this.sizesMap.has(productType)) this.sizesMap.set(productType,[sizeDocument]);
          else this.sizesMap.get(productType).push(sizeDocument);
      });
      console.log('SizesMap: ', this.sizesMap);
  });

  }

  openNewSizeList(edit: boolean, id?: any) {
    this.resetForm();
    if(edit) {
      this.isEditing = edit;
      this.sizeListForm.controls['productType'].setValue(id);
      this.sizeListForm.controls['sizes'].setValue('Sizes from arrayToString Function (separated by commas)');
      if(id) { //Save either productType or size docId to know what is being edited
        this.editId = id;
        console.log('editId: ', this.editId);
      };
    } else this.isEditing = false;
  }

  resetForm() {
    this.sizeListForm.reset();
    this.submitted = false;
    this.disabledButton = false;

    this.sizeListForm.controls['productType'].setValue('');
    this.sizeListForm.controls['sizes'].setValue('');
  }

  //Need to change delete function to just change "isActive" attribute to false. maybe through productsService updateCustomSizes function?
  async delete(type: string, id: string) {
    switch(type) {
      case 'list':
        this.sizesMap.get(id).forEach(async (sizeInList) => {
          await this._productsService.updateCustomSizes(this._companyId,'isActive',sizeInList.id,'delete')
        });
        break;
      case 'size':
        await this._productsService.updateCustomSizes(this._companyId,'isActive',id,'delete');
        break;
    }

    //this.initialize(true);

  }

  validateForm() {
    this.submitted = true;
    console.log("Submitted");

    //Check for duplicates (RegExp or validators?)
    var regex = new RegExp('[^,]', 'i'); //comma separated list, ignore case

    if (this.sizeListForm.valid) {
      this.disabledButton = true;
      this.submit();
    }
  }

  stringToArray(string: string) {
    //split this.sizeListForm.value.sizes string input into an array to pass along to submit function
    //list separated by commas, trim whitespace
    let result = string.split(',').map(function (value) {
      return value.trim();
    });
    return result;
  }

  arrayToString() {

  }

  submit() {
    if (this.isEditing === true) { //If editing, just update existing documents...
      this._productsService.updateCustomSizes(this._companyId,'productType',this.editId,this.sizeListForm.value.productType)
    } else { //Otherwise, create a new list and documents in firebase

      this.newCustomSizeList(this._companyId, this.sizeListForm.value.productType, this.stringToArray(this.sizeListForm.value.sizes));
      // .then(() => {
      //   Swal.fire({
      //     title: 'Size List',
      //     html: 'New Size List Created successfully',
      //     icon: 'success',
      //     allowOutsideClick: false,
      //     showConfirmButton: true
      //   }).then((result) => {
      //     if (result.isConfirmed) {
      //       this.disabledButton = false
      //       $('#newSizeListModal').modal('hide')
      //     }
      //   })
      // });
      this.disabledButton = false;
      $('#newSizeListModal').modal('hide');
      console.log("Product List Created");

    }
    this.resetForm();
    //this.initialize(true);

  }

  newSize(companyId: string, productType: string, size: string) {
    let sizeObject: CustomSize = {
      companyId: companyId,
      productType: productType,
      size: size,
      isActive: true,
      sortOrder: this.sizesMap.get(productType).length, //append new size to end of list
      created: new Date()
    }
    console.log("newSize: ", sizeObject);

    //this._productsService.newCustomSize(sizeObject);
  }

  newCustomSizeList(companyId: string, productType: string, sizes: Array<any>) {

    for (let index = 0; index < sizes.length; index++) {
      let sizeObject = {
        companyId: companyId,
        productType: productType,
        size: sizes[index],
        sortOrder: index,
        isActive: true,
        created: new Date(),
      };
      this._productsService.newCustomSize(sizeObject);
    }
    return;
  }


  // *** Drag and drop functions ***
  drop(event: CdkDragDrop<string[]>, productType) {
    console.log("Drop event: ", event);
    moveItemInArray(this.sizesMap.get(productType), event.previousIndex, event.currentIndex);

    // Update 'sortOrder' of all sizes in list
    this.sizesMap.get(productType).forEach(size => {
      let index = this.sizesMap.get(productType).indexOf(size);
      this._productsService.updateCustomSizes(this._companyId,'sortOrder',size.id,index);
      //console.log("updated sort order",size,index);
    })
  }

  */
}
