<ng-container *ngIf="affiliatesLoaded">
  <div class="container-fluid">
    <!-- Header and Filters -->
    <div class="row mb-2 mt-2">
      <!-- Header -->
      <div class="col-lg-6">
        <div class="row align-items-start">
          <div class="col-md-6 text-left mt-2 mb-2">
            <h1 style="font-size: 32px">Affiliate</h1>
          </div>
        </div>

        <!-- Affiliate - Buttons -->
        <div class="row">
          <div class="col-md-12 col-sm-12">
            <button type="button" class="btn btn-success" (click)="openAffiliateModal(false)">
              New Affiliate
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Table -->
    <div class="table-responsive" style="height: 76vh !important; margin-top: 0.2em">
      <table class="table" id="productListForGroup">
        <thead>
          <tr>
            <th style="width: 14%" scope="col">Affiliate Name</th>
            <th style="width: 10%" scope="col">Date Created</th>
            <th style="width: 30%" scope="col">Provider URL</th>
            <th style="width: 30%" scope="col">Landing URL</th>
            <th style="width: 8%" scope="col">Status</th>
            <th style="width: 8%" scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let affiliate of affiliatesCollection">
            <tr>
              <td>{{affiliate?.name}}</td>
              <td>{{affiliate?.dateCreated ? (affiliate?.dateCreated | luxonDisplayPipe: 'DATE_MED') : 'N/A'}}</td>
              <td>
                <app-copyToClipboard class="mr-1"
                  [textToCopy]="publicUrl + '/affiliate/?af=' + affiliate.id"></app-copyToClipboard>
                {{publicUrl}}/affiliate/?af={{affiliate.id}}
              </td>
              <td>
                <span>{{affiliate?.landingURL}}</span>
              </td>
              <td>{{affiliate?.active ? 'Active' : 'Disabled'}}</td>
              <td>
                <button type="button" (click)="openAffiliateModal(true, affiliate)" style="margin-bottom: 1em"
                  class="btn btn-success mr-2">
                  <i class="fa fa-pencil"></i>
                </button>
                <button (click)="softDeleteAffiliate(affiliate.id)" type="button" style="margin-bottom: 1em"
                  class="btn btn-danger mr-2">
                  <i class="fas fa-trash" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>

      <!-- If no affiliates -->
      <div *ngIf="affiliatesCollection.length <= 0">
        <span>No affiliate vendors found. <span id="createOne" (click)="openAffiliateModal(false)">Create
            one?</span></span>
      </div>
    </div>

    <!-- Affiliate Modal -->
    <div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="affiliateModal" tabindex="-1"
      role="dialog">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title"><span>{{isEditingAf ? 'Editing' : 'Create'}} |</span> Affiliate Vendor</h4>
            <button data-bs-dismiss="modal" type="button" class="btn btn-danger" aria-label="Close">
              <i class="fa fa-close"></i>
            </button>
          </div>

          <div class="modal-body">
            <app-affiliateCRUD_Modal #affiliateCRUD_Modal [isEditing]="isEditingAf" [affiliateObj]="null"
              [affiliateID]="null" [companyID]="companyID"
              (affiliateFormSubmission)="onAffiliateFormSubmission($event)">
            </app-affiliateCRUD_Modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- Loader / Spinner -->
<ng-container *ngIf="!affiliatesLoaded" class="d-flex text-center justify-content-center align-items-center"
  style="margin-top: 50px;">
  <div class="spinner-border" role="status"
    style="width: 4em !important; height: 4em !important; position: absolute; left: 50%; top: 30%">
    <span class="visually-hidden">Loading...</span>
  </div>
</ng-container>