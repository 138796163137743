<!--Title Dasboard-->
<div class="container-fluid">
  <div class="row justify-content-center mt-2">
    <div class="col-md-12 text-left">
      <h1 class="mt-2 mb-2" style="font-size: 32px">
        Inventory Calendar
        <button [style.background]="colorText" [style.color]="colorBackground" class="btn" (click)="showSelector()">
          <i class="fas fa-plus">&ngsp;</i> Add New Booking
        </button>
      </h1>
    </div>
  </div>
</div>
<div class="container-fluid align-items-end d-flex justify-content-end pe-2">
  <div class="row g-3">
    <div class="col-4 col-lg-4">
      <label for="inputPassword6" class="col-form-label">Searh Product</label>
      <input type="text" class="form-control" placeholder="search by name" name="search" (keyup)="searchByName()"
        [(ngModel)]="search" />
    </div>
    <div class="col-3 col-lg-3">
      <label for="inputPassword6" class="col-form-label">Date Start <b style="color: red">*</b></label>
      <input type="date" name="startDate" class="form-control" [ngModel]="startDate" (change)="setStartDate($event)" />
    </div>
    <div class="col-3 col-lg-3">
      <label for="inputPassword6" class="col-form-label">Date End <b style="color: red">*</b></label>
      <input type="date" name="endDate" class="form-control" [ngModel]="endDate" (change)="setEndDate($event)" />
    </div>
    <div class="col-2 col-lg-2 align-midle d-flex align-items-center">
      <button [style.background]="colorText" [style.color]="colorBackground" type="button" class="btn topbutton"
        (click)="handleEditDate()">
        search
      </button>
    </div>
  </div>
</div>

<div class="divoverflow">
  <table class="table mt-3 table-hover table-borderless container-fluid">
    <thead class="thead bg-light">
      <tr class="trhead">
        <th class="thproduct">PRODUCTS</th>
        <ng-container *ngFor="let date of headersDate">
          <th class="thdays">{{ date | date : "MMM d" }}</th>
        </ng-container>
      </tr>
    </thead>
    <tbody class="bg-light">
      <ng-container *ngIf="dataCalendar.length <= 0">
        <tr>
          <td [colSpan]="headersDate.length + 1" class="text-left">
            No records match this name
          </td>
        </tr>
      </ng-container>
      <ng-container *ngFor="let data of dataCalendar; let i = index">
        <tr (click)="hideAccordion(i)" class="hover">
          <td class="text-left">
            <b><i class="fas fa-caret-right ms-3 me-1 hide" id="arrowright{{ i }}"></i>
              <i class="fas fa-caret-down ms-3 me-1" id="arrowdown{{ i }}"></i>
              {{ data.categoryName }}
              <span class="badge bg-info"> {{ data.productsInCategory }} </span>
            </b>
          </td>
          <td [colSpan]="headersDate.length" class="text-left" style="background-color: white"></td>
        </tr>
        <ng-container *ngFor="let products of data.products">
          <tr class="accordion{{ i }}">
            <td class="tdvcenter tdhcenter nonepointer" [ngClass]="{isUnavailable: !products.isAvailable}">
              <span (click)="showSelector()">
                <!-- true,data.categoryID, products.nameProduct,'undefined',data.categoryID -->
                <span class="pointer">
                  {{products.productName}}
                </span>
              </span>
              <span (click)="loadProduct(data.categoryID)" class="statustext"
                *ngIf="!products.isAvailable && !products.isMaintenance">
                <span>Unavailable<i class="fas fa-external-link-alt iconlink ms-1"></i></span>
              </span>
              <span (click)="loadProduct(data.categoryID)" class="statustext"
                *ngIf="products.isAvailable && !products.isMaintenance">
                <span>Available<i class="fas fa-external-link-alt iconlink ms-1"></i></span>
              </span>
              <span (click)="loadProduct(data.categoryID)" class="statustext" *ngIf="products.isMaintenance">
                <span>Maintenance<i class="fas fa-external-link-alt iconlink ms-1"></i></span>
              </span>
            </td>
            <ng-container *ngFor="let available of products.days; let i = index">
              <ng-container *ngIf="available != undefined; else nodata">
                <div *ngIf="available != 1; then notAvailable; else Available"></div>
                <ng-template #notAvailable>
                  <!-- loadBooking(available) -->
                  <td (click)="showSelector(products.productGroupID, products.productLocationID, i, data.is24hrsPrice)" class="tdvcenter tdhcenter cursorpointer"
                    [ngClass]="{ tdbgnotavailable: available != 1}">
                    ?
                  </td>
                </ng-template>
                <ng-template #Available>
                  <td class="tdvcenter tdhcenter"
                    [ngClass]="{ tdbgavailable: available > 0 && products.isAvailable, isUnavailable: !products.isAvailable  }"
                    (click)="
                      showSelector(products.productGroupID, products.productLocationID, i, data.is24hrsPrice)
                    ">
                    <!-- loadIFrameProductId(
                      true,
                      data.categoryID,
                      products.nameProduct,
                      headersDate[i],
                      data.categoryID
                    ) -->
                    <!-- {{ products.productsInCategory }} -->
                    ?
                  </td>
                </ng-template>
              </ng-container>
              <ng-template #nodata>
                <td class="tdbgnotregister tdhcenter tdvcenter">-</td>
              </ng-template>
            </ng-container>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</div>

<div id="divRental" *ngIf="isIFrameLoaded">
  <button id="buttonfleetmaidclosew" (click)="loadIFrame(false)">
    <img id="imagebuttonfleetmaid" src="https://fleetmaid.com/assets/images/icons/exit.svg" width="18" height="17" />
    <!--Exit Shop-->
  </button>
  <iframe id="scrollframe" [src]="urlIframe"></iframe>
</div>

<div id="divRental" *ngIf="isIFrameLoadedProduct">
  <button id="buttonfleetmaidclosew" (click)="loadIFrameProduct(false)">
    <img id="imagebuttonfleetmaid" src="https://fleetmaid.com/assets/images/icons/exit.svg" width="18" height="17" />
    <!--Exit Shop-->
  </button>
  <iframe id="scrollframe" [src]="urlIframeProduct"></iframe>
</div>

<div id="divRental" *ngIf="isIFrameLoadedNew">
  <button id="buttonfleetmaidclosew" (click)="loadIFrameNew(false)">
    <img id="imagebuttonfleetmaid" src="https://fleetmaid.com/assets/images/icons/exit.svg" width="18" height="17" />
    <!--Exit Shop-->
  </button>
  <iframe id="scrollframe" [src]="urlIframeNew"></iframe>
</div>

<!-- Pop-up with Bootstrap -->
<div class="modal fade" id="popupModal" tabindex="-1" aria-labelledby="popupModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="popupModalLabel">Select a Booking Flow</h3>
        <button type="button" class="btn-close" (click)="closeShowSelector()" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <select class="form-select mb-3" [ngClass]="{'is-invalid': invalid}" placeholder="Select a Booking Flow" [(ngModel)]="selectedOption" required>
          <option value="" disabled selected>Select a Booking Flow</option>
          <option *ngFor="let option of templates" [value]="option.id">{{ option.title }}</option>
        </select>
        <div class="invalid-feedback">
          Please select a booking flow.
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button *ngIf="currentUserService.currentUser.isDeveloper" type="button" class="btn btn-danger" (click)="openNewBookingIframe()" >Dev Iframe</button>
        <button type="button" class="btn btn-primary" (click)="openNewBookingNewTab()">New Booking</button>
        <button type="button" class="btn btn-secondary" (click)="closeShowSelector()">Cancel</button>
      </div>
    </div>
  </div>
</div>
