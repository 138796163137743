<!-- If URL provided bad dates -->
<ng-container *ngIf="badDatesProvided">
    <h2 i18n>The selected dates are unavailable.</h2>
</ng-container>

<!-- Search View -->
<ng-container *ngIf="!badDatesProvided">
    <div class="container" style="padding: 15px;">
        <div class="row d-flex justify-content-center text-center">
            <div *ngIf="dateRangeContentCards.length < 1" style="border-bottom: 1px solid white; padding: 10px;">
                <h2 i18n>Use the search above to find product availabilities</h2>
            </div>

            <div *ngFor="let productGroupContentCard of dateRangeContentCards" class="col-lg-4 col-md-4 col-sm-6"
                [ngClass]="productGroupContentCard.hasAvailableQuantity ? 'resultContainer' : 'noAvailResultContainer'">
                <!-- Product Images -->
                <div class="img-container">
                    <div style="height: 170px; margin: 1em" class="imgStyles m-0 p-0"
                        [ngStyle]="productGroupContentCard.productData.groupImage !== undefined && productGroupContentCard.productData.groupImage !== null ?
                                                    {'background-image': 'url(&quot;' + productGroupContentCard?.productData.groupImage + '&quot;)'} :
                                                    {'background-image': 'url(&quot;' + '/assets/images/notAvailable.png' + '&quot;)'}">
                    </div>
                </div>
                <div *ngIf="!productGroupContentCard.hasAvailableQuantity" class="freeShippingRibbon" i18n>Unavailable</div>
                <div>
                    <!-- Content Info -->
                    <div style="margin-top: 10px;">
                        <h2 class="p-0 m-0" style="font-size:1.2em">{{productGroupContentCard?.productData.groupName}}</h2>
                        <!-- Size Display -->
                        <div class="d-flex justify-content-center">
                            <div *ngFor="let size of productGroupContentCard.sizingAvail; index as i;"
                                [ngStyle]="{ 'visibility': size.sizeName === 'NA' ? 'hidden' : 'visible' }">
                                <!-- Size is marked with isAvailable when no items exist in cart. Otherwise, relies on currentAvail count -->
                                <span [ngClass]="size.currentAvail > 0 || size['isAvailable'] ? 'sizesAvail': 'noSizesAvail'">
                                    <p class="m-0 p-1">{{size.sizeName}}<span *ngIf="i < productGroupContentCard.sizingAvail.length - 1">,&nbsp;
                                        </span></p>
                                    <span>
                                    </span>
                                </span>
                            </div>
                        </div>

                        <!-- Availability Messages -->
                        <!-- Time past shop hours -->
                        <span *ngIf="!productGroupContentCard.hasAvailableQuantity && productGroupContentCard['shopClosedForToday']"
                            style="font-weight: 500;" class="text-danger" i18n>The shop has already closed for today, please
                            search for a later date</span>

                        <ng-container *ngIf="!productGroupContentCard['shopClosedForToday']">
                            <!-- No Qty Available -->
                            <span *ngIf="!productGroupContentCard.hasAvailableQuantity && !productGroupContentCard['unavailableReason']"
                                style="font-weight: 500;" class="text-danger" i18n>Unavailable
                                during the selected date range
                            </span>

                            <span *ngIf="!productGroupContentCard.hasAvailableQuantity && productGroupContentCard['unavailableReason'] == 'hourlyOnly'"
                                style="font-weight: 500;" class="text-danger">{{productGroupContentCard['unavailableDesc']}}</span>

                            <!-- Item is 24hrs only -->
                            <span *ngIf="!productGroupContentCard.hasAvailableQuantity && productGroupContentCard['24hrsOnly']"
                                style="font-weight: 500;" class="text-danger"> <span i18n> This item is only available to rent by 24
                                  hour increments. To see
                                  availabilities,
                                  select a pickup and differing drop off date.
                                </span> <span style="color: #414141;" i18n>Example:
                                    4/21/2023 -
                                    4/22/2023 (single day rental)</span></span>
                        </ng-container>
                    </div>

                    <div style="margin-top: 10px;" class="align-items-center justify-content-center">
                        <a *ngIf="productGroupContentCard.hasAvailableQuantity" class="stretched-link"
                            (click)="chosenProductGroupID.emit(productGroupContentCard?.productData?.id)" style="display: hidden;"></a>
                        <button *ngIf="productGroupContentCard.hasAvailableQuantity" type="button" class="btn btn-primary shine" i18n>Book
                            Now</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<!-- NOTIFY ME FORM -->
<!-- <div class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="notifyMeModal" tabindex="-1"
        role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <span>Notify Me | {{selectedProduct?.productName}}</span>
                    </h4>
                    <button type="button" class="btn btn-danger" aria-label="Close" (click)="closeNotifyMeModal()">
                        <i class="fa fa-close"></i>
                    </button>
                </div>

                <div class="modal-body">
                    <app-notify-me-modal [product]="selectedProduct" [startDateString]="startDateString"
                        [endDateString]="endDateString" (submitNotifyMeForm)="closeNotifyMeModal()">
                    </app-notify-me-modal>
                </div>
            </div>
        </div>
    </div> -->
