import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/services/products.service';
import * as moment from 'moment';
import { CurrentUserService } from 'src/app/services/current-user.service';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import Swal from 'sweetalert2';
import { DEFAULT_THEME } from 'src/app/utils/config/themeDefault.config';
@Component({
  selector: 'app-discounts-codes-generator',
  templateUrl: './discounts-codes-generator.component.html',
  styleUrls: ['./discounts-codes-generator.component.scss'],
})
export class DiscountsCodesGeneratorComponent implements OnInit {
  discountInfo: UntypedFormGroup;
  constructor(
    public productService: ProductsService,
    public currentUserService: CurrentUserService,
  ) {
    this.discountInfo = new UntypedFormGroup({
      discount: new UntypedFormControl('', [
        Validators.min(1),
        Validators.required,
      ]),
      discountTitle: new UntypedFormControl('', [Validators.required]),
      expiredDate: new UntypedFormControl('', [Validators.required]),
      discountType: new UntypedFormControl('', [Validators.required]),
      discountScope: new UntypedFormControl('', [Validators.required]),
      selectedProduct: new UntypedFormControl(''),
    });
  }
  get f() {
    return this.discountInfo.controls;
  }
  public submitted: boolean;
  public isEditing = false;
  public redeemed = 0;
  public titleEdit = '';
  //public globalRedeemCount:number
  //public perUserRedeemCount:number

  public products = [];
  public discounts = [];
  public discountsActive = [];
  public discountsInactive  = [];

  public selectedProduct = '';
  public editedDiscountID = '';
  public created = new Date();
  public isActive = true;
  public isvalidProduct = false;
  public isvalidDiscount = false;
  //alerts

  public isDiscountTitleExistAlert = false;
  public isDiscountAlert = false;
  public isSelectedProductAlert = false;
  // public isGlobalRedeemCountAlert = false
  // public isPerUserRedeemCountAlert = false

  public discountsSubscription = null;
  public disabledbutton: boolean;
  public colorTheme: string = DEFAULT_THEME.fontColor;
  public colorText: string = DEFAULT_THEME.value;

  ngOnInit(): void {
    this.getDiscounts();
    this.getProducts();
  }
  validateTitle() {
    if (this.isEditing) {
      this.discounts.forEach((discount) => {
        if (
          discount.discountTitle === this.discountInfo.value.discountTitle &&
          discount.discountTitle != this.titleEdit
        ) {
          this.isDiscountTitleExistAlert = true;
        } else {
          this.isDiscountTitleExistAlert = false;
        }
      });
    } else {
      this.discounts.forEach((discount) => {
        if (discount.discountTitle === this.discountInfo.value.discountTitle) {
          this.isDiscountTitleExistAlert = true;
        } else {
          this.isDiscountTitleExistAlert = false;
        }
      });
    }
  }
  validatePercentage() {
    if (
      this.discountInfo.value.discount == '' ||
      (this.discountInfo.value.discount > 100 &&
        this.discountInfo.value.discountType == 'percentage')
    ) {
      this.isvalidDiscount = false;
    } else if (
      this.discountInfo.value.discount == '' &&
      this.discountInfo.value.discountType == 'fixedAmount'
    ) {
      this.isvalidDiscount = false;
    } else {
      this.isvalidDiscount = true;
    }
  }
  validateForm() {
    if (this.isEditing) {
      const existingRecords = this.discountsActive.filter((record) => {
        return record.id !== this.editedDiscountID;
      });

      // Perform validation with the remaining records
      const isMatch = existingRecords.some((record) => {
        return record.discountTitle === this.discountInfo.value.discountTitle;
      });

      if (isMatch) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'The discount title matches an existing active one!',
        })
      } else {
        this.validatePercentage();
        this.submitted = true;
        if (!this.isDiscountTitleExistAlert) {
          if (this.discountInfo.valid && this.isvalidDiscount) {
            if (this.discountInfo.value.discountScope == 'product') {
              this.discountInfo.value.selectedProduct == ''
                ? (this.isvalidProduct = false)
                : (this.isvalidProduct = true);
              if (this.isvalidProduct == true) {
                this.disabledbutton = true;
                this.submit();
              }
            } else {
              this.disabledbutton = true;
              this.submit();
            }
          }
        }
      }

    } else {
      const isMatch = this.discountsActive.some((record) => {
        return record.discountTitle === this.discountInfo.value.discountTitle;
      });
      if (isMatch) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'The discount title matches an existing active one!',
        })
      } else {
        this.validatePercentage();
        this.submitted = true;
        if (!this.isDiscountTitleExistAlert) {
          if (this.discountInfo.valid && this.isvalidDiscount) {
            if (this.discountInfo.value.discountScope == 'product') {
              this.discountInfo.value.selectedProduct == ''
                ? (this.isvalidProduct = false)
                : (this.isvalidProduct = true);
              if (this.isvalidProduct == true) {
                this.disabledbutton = true;
                this.submit();
              }
            } else {
              this.disabledbutton = true;
              this.submit();
            }
          }
        }
      }
    }
  }

  openDiscountModal(edit: boolean, discount?) {
    this.discountInfo.reset();
    this.reset();
    if (edit) {
      console.log('quetrais', discount);
      this.titleEdit = discount.discountTitle;
      this.isEditing = true;
      this.editedDiscountID = discount.id;
      this.discountInfo.controls['discountTitle'].setValue(
        discount.discountTitle
      );
      this.discountInfo.controls['expiredDate'].setValue(
        discount.expiredDateString
      );
      this.discountInfo.controls['discountType'].setValue(
        discount.discountType
      );
      this.discountInfo.controls['discountScope'].setValue(
        discount.discountScope
      );
      this.discountInfo.controls['discount'].setValue(discount.discount);
      this.discountInfo.controls['selectedProduct'].setValue(
        discount.productID
      );
      this.isActive = discount.isActive;
      //this.globalRedeemCount = discount.globalRedeemCount
      //this.perUserRedeemCount = discount.perUserRedeemCount
      this.created = discount.created;
      this.redeemed = discount.redeemed;
    }
    var today = new Date().toISOString().split('T')[0];
    document.getElementsByName('expiredDate')[0].setAttribute('min', today);
  }
  reset() {
    this.titleEdit = '';
    this.submitted = false;
    this.isEditing = false;
    this.discountInfo.controls['discountTitle'].setValue('');
    this.discountInfo.controls['expiredDate'].setValue('');
    this.discountInfo.controls['discountType'].setValue('');
    this.discountInfo.controls['discountScope'].setValue('');
    this.discountInfo.controls['discount'].setValue('');
    this.discountInfo.controls['selectedProduct'].setValue('');
    this.created = new Date();
    //this.globalRedeemCount = undefined
    //this.perUserRedeemCount = undefined
    this.editedDiscountID = '';
    this.selectedProduct = '';
    this.isActive = true;

    this.isDiscountTitleExistAlert = false;
    this.isDiscountAlert = false;
    this.isSelectedProductAlert = false;
    //this.isGlobalRedeemCountAlert = false
    //this.isPerUserRedeemCountAlert = false
  }
  getDiscounts() {
    if (this.discountsSubscription) {
      this.discountsSubscription.unsubscribe();
    }

    this.discountsSubscription = this.productService
      .getDiscounts()
      .subscribe((discounts) => {
        this.discounts = discounts;

        this.discountsActive = this.discounts.filter((record) => {
          // Current date in milliseconds
          const dateCurrentInMilliseconds = Date.now();
          const dateInSeconds = record.expiredDate.seconds;
          // Convert the date in seconds to milliseconds by multiplying by 1000
          const dateInMilliseconds = dateInSeconds * 1000;


          const dateCurrent = new Date(dateCurrentInMilliseconds);
          const expiredDate = new Date(dateInMilliseconds);

          // Obtain the day, month and year of both dates
          const dateCurrentFormatted = dateCurrent.toISOString().slice(0, 10); // Format YYYY-MM-DD
          const datePreviousFormatted = expiredDate.toISOString().slice(0, 10); // Format YYYY-MM-DD


          return datePreviousFormatted  >=  dateCurrentFormatted && record.isActive === true;
        });

        const discountsInactive = this.discounts.filter((record) => {
          // Current date in milliseconds
          const dateCurrentInMilliseconds = Date.now();
          const dateInSeconds = record.expiredDate.seconds;
          // Convert the date in seconds to milliseconds by multiplying by 1000
          const dateInMilliseconds = dateInSeconds * 1000;


          const dateCurrent = new Date(dateCurrentInMilliseconds);
          const expiredDate = new Date(dateInMilliseconds);

          // Obtain the day, month and year of both dates
          const dateCurrentFormatted = dateCurrent.toISOString().slice(0, 10); // Format YYYY-MM-DD
          const datePreviousFormatted = expiredDate.toISOString().slice(0, 10); // Format YYYY-MM-DD

          return datePreviousFormatted  <  dateCurrentFormatted;
        });
        // Call the method to sort the array by isActive
        this.discountsInactive = this.orderByIsActive(discountsInactive);
      });
  }

  orderByIsActive(arr: any[]): any[] {
    arr.sort((a, b) => {
      // Sort elements with isActive set to true first
      if (a.isActive === b.isActive) {
        return 0;
      } else if (a.isActive) {
        return -1;
      } else {
        return 1;
      }
    });

    return arr;
  }

  getProducts() {
    this.productService.getProductsPromise().then((products) => {
      this.products = products;
      console.log(this.products, 'PRODUCTS');
    });
  }

  discontinueCode() {
    Swal.fire({
      title: 'Discontinue Discount Code',
      html: 'Are you sure you want to discontinue this code? if you do this you will not be able to reactivate it',
      icon: 'info',
      allowOutsideClick: false,
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let update = {
          isActive: false,
        };

        this.productService.updateDiscountCode(this.editedDiscountID, update);
        $('#discountCodeModal').modal('hide');
      }
    });
  }

  test() {
    this.productService.decrementGlobalRedeemCount(this.editedDiscountID);
  }

  async submit() {
    let title = '';
    let html = '';
    //  if(this.globalRedeemCount !== undefined && this.globalRedeemCount > 0 && this.globalRedeemCount >= this.perUserRedeemCount ){
    //    this.isGlobalRedeemCountAlert = false
    //  }else{
    //    this.isGlobalRedeemCountAlert = true
    //  }
    //
    //  if(this.perUserRedeemCount !== undefined && this.perUserRedeemCount > 0  && this.perUserRedeemCount <= this.globalRedeemCount){
    //    this.isPerUserRedeemCountAlert = false
    //  }else{
    //    this.isPerUserRedeemCountAlert = true
    //  }
    this.isEditing === true
      ? ((title = 'Discount Code Updated'),
        (html = 'The discount code has been updated successfully'))
      : ((title = 'Discount Code Saved'),
        (html = 'The discount code has been saved successfully'));
    let obj: any = {
      discountTitle: this.discountInfo.value.discountTitle,
      created: this.created,
      expiredDate: moment(this.discountInfo.value.expiredDate).toDate(),
      expiredDateString: this.discountInfo.value.expiredDate,
      discountType: this.discountInfo.value.discountType,
      discount: this.discountInfo.value.discount,
      discountScope: this.discountInfo.value.discountScope,
      isActive: this.isActive,
      companyID: this.currentUserService.currentUser.companyId,
      redeemed: this.redeemed,
      //  globalRedeemCount: this.globalRedeemCount,
      //  perUserRedeemCount: this.perUserRedeemCount
    };
    console.log(obj);

    Swal.fire({
      title: 'Saving Discount Code',
      html: 'Please wait while we save the discount code',
      icon: 'info',
      allowOutsideClick: false,
      showConfirmButton: false,
      allowEnterKey: false,
      allowEscapeKey: false,
    });
    Swal.showLoading();

    if (this.discountInfo.value.discountScope === 'product') {
      console.log(this.discountInfo.value.selectedProduct);
      await this.productService
        .getProductByID(this.discountInfo.value.selectedProduct)
        .then((product) => {
          obj.productID = this.discountInfo.value.selectedProduct;
          obj.productName = product.productName;
        });
    } else {
    }
    if (this.isEditing === true) {
      // método para actualizar
      this.productService.updateDiscountCode(this.editedDiscountID, obj);
      Swal.fire({
        title: title,
        html: html,
        icon: 'success',
        allowOutsideClick: false,
        showConfirmButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          $('#discountCodeModal').modal('hide');
        }
      });
    } else {
      this.productService.saveDiscountCode(obj);
      Swal.fire({
        title: title,
        html: html,
        icon: 'success',
        allowOutsideClick: false,
        showConfirmButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          $('#discountCodeModal').modal('hide');
        }
      });
    }
  }

  generateCode() {
    let characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    this.discountInfo.controls['discountTitle'].setValue('');
    let charactersLength = characters.length;
    for (let i = 0; i < 8; i++) {
      this.discountInfo.controls['discountTitle'].setValue(
        (this.discountInfo.value.discountTitle += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        ))
      );
    }
  }
}
