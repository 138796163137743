import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartEvent, ChartType } from 'chart.js';
import * as moment from 'moment';
import { BaseChartDirective } from 'ng2-charts';
import { DashboardService } from 'src/app/services/dashboard.service';
import { CurrentUserService } from 'src/app/services/current-user.service';
import { DEFAULT_THEME } from 'src/app/utils/config/themeDefault.config';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public month: any = moment().format('MMMM'); //variable to get the actual month
  public datenow: any = moment().format('LL'); //variable to get the actual date
  public colorsText: string = DEFAULT_THEME.fontColor;
  public colorsBackground: string = DEFAULT_THEME.value;
  constructor(
    protected _dashboardService: DashboardService,
    private _cd: ChangeDetectorRef,
    private Current_users: CurrentUserService,
  ) {}
  public rentals: any[] = []; //Variable to save the documents of rentals
  public rentalsUpcoming: any[] = [];
  public products: any[] = []; //Variable to save the documents of products
  public rentalstotal: number; //Variable to save the total of Rentals
  public rentalsactive: number = 0; //Variable to save the total of Rentals Active right now
  public repairs: number = 0; //Variable to save the total of repairs
  public repairsbyday: any[] = []; //Variable to save the total of repairs by day
  public startMonth: any = moment()
    .clone()
    .startOf('month')
    .format('YYYY-MM-DD'); //Variable to get the first day of the actual month
  public endMonth: any = moment().clone().endOf('month').format('YYYY-MM-DD'); //Variable to get the last day of the actual month
  public datadays: number[] = []; //Variable array to increment the number o rentals per day
  public aux: number = 0;

  ngOnInit(): void {
    this.getRentalsActive(); //Call getRentalsActive
    this.getRentalsToday(); //Call getRentalsToday
    this.getRentalPendingCheckedIn(); //Call getRentalPendingCheckedIn
    this.getProductsRepair(); //Call getProductsRepair
    this.getRentalsList(); //Call getRentalsList
    setTimeout(() => {
      console.log(this.rentals);
    }, 2000);
  }
  getRentalsToday() {
    //Method to work with the rentals
    this._dashboardService.getRentals().subscribe((data) => {
      this.rentals = []; //Empty array of rentals
      this.rentalstotal = data.length; //Total of rentals active
      let ds: any; //Aux variable to save the dayStart
      let de: any; //Aux variable to save the dayEnd
      let dayStart;
      let dayEnd;
      data.forEach((element: any) => {
        //Fot to get all elements
        ds = new Date(element.payload.doc.data()['dayStart'].seconds * 1000); //Get the timestamp in seconds to convert into a date
        de = new Date(element.payload.doc.data()['dayEnd'].seconds * 1000); //Get the timestamp in seconds to convert into a date
        if (
          element.payload.doc.data().isCheckedOut == false &&
          element.payload.doc.data().isComplete == false
        ) {
          //Filter to obtain the rentals
          if (
            moment(ds).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')
          ) {
            if (element.payload.doc.data().rentalType == 'byHour') {
              dayStart = moment(ds).format('MMM DD, YYYY, h:mm a');
              dayEnd = moment(de).format('- h:mm a');
            } else if (element.payload.doc.data().rentalType == 'byDay') {
              if (moment(de).diff(moment(ds), 'days') < 1) {
                dayStart = moment(de).format('MMM DD, yyyy');
                dayEnd = '';
              } else {
                dayStart = moment(ds).format('MMM DD');
                dayEnd = moment(de).format('- MMM DD, yyyy');
              }
            }
            this.rentals.push({
              //Put the elements
              id: element.payload.doc.id, //Obtain the id from the document
              dayStart: dayStart, //Format the date
              dayEnd: dayEnd, //Format the date
              cost: Number(element.payload.doc.data().cost.toFixed(2)), //Obtain from data cost
              phone: element.payload.doc.data().userInfo.phone, //Obtain from data userInfo.phone
              isActive: element.payload.doc.data().isActive, //Obtain from data isActive
              rentalNumber: element.payload.doc.data().rentalNumber, //Obtain from data rentalNumber
              isCancelled: element.payload.doc.data().isCancelled, //Obtain from data isCancelled
              isCheckedIn: element.payload.doc.data().isCheckedIn, //Obtain from data isCheckedIn
              isCheckedOut: element.payload.doc.data().isCheckedOut, //Obtain from data isCheckedOut
              isComplete: element.payload.doc.data().isComplete, //Obtain from data isComplete
              rentalType: element.payload.doc.data().rentalType, //Obtain the type of the rental
              isLate: element.payload.doc.data().isLate, //Obtain from data isLate
            });
          }
        }
      });
    });
  }
  getRentalPendingCheckedIn() {
    //Method to obtain te rentals pending to checked In
    this._dashboardService.getRentals().subscribe((data) => {
      //Obtain all data from service
      this.rentalsUpcoming = []; //Empty array of rentals
      this.rentalstotal = data.length; //Total of rentals active
      let ds: any; //Aux variable to save the dayStart
      let de: any; //Aux variable to save the dayEnd
      let dayStart;
      let dayEnd;
      data.forEach((element: any) => {
        //Fot to get all elements
        ds = new Date(element.payload.doc.data()['dayStart'].seconds * 1000); //Get the timestamp in seconds to convert into a date
        de = new Date(element.payload.doc.data()['dayEnd'].seconds * 1000); //Get the timestamp in seconds to convert into a date
        if (
          element.payload.doc.data().isCheckedIn == false &&
          element.payload.doc.data().isCancelled == false &&
          element.payload.doc.data().isCheckedOut == true
        ) {
          if (
            moment(de).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')
          ) {
            if (element.payload.doc.data().rentalType == 'byHour') {
              dayStart = moment(ds).format('MMM DD, YYYY, h:mm a');
              dayEnd = moment(de).format('- h:mm a');
            } else if (element.payload.doc.data().rentalType == 'byDay') {
              if (moment(de).diff(moment(ds), 'days') < 1) {
                dayStart = moment(de).format('MMM DD, yyyy');
                dayEnd = '';
              } else {
                dayStart = moment(ds).format('MMM DD');
                dayEnd = moment(de).format('- MMM DD, yyyy');
              }
            }
            this.rentalsUpcoming.push({
              //Put the elements
              id: element.payload.doc.id, //Obtain the id from the document
              dayStart: dayStart, //Format the date
              dayEnd: dayEnd, //Format the date
              cost: Number(element.payload.doc.data().cost.toFixed(2)), //Obtain from data cost
              phone: element.payload.doc.data().userInfo.phone, //Obtain from data userInfo.phone
              isActive: element.payload.doc.data().isActive, //Obtain from data isActive
              rentalNumber: element.payload.doc.data().rentalNumber, //Obtain from data rentalNumber
              isCancelled: element.payload.doc.data().isCancelled, //Obtain from data isCancelled
              isCheckedIn: element.payload.doc.data().isCheckedIn, //Obtain from data isCheckedIn
              isCheckedOut: element.payload.doc.data().isCheckedOut, //Obtain from data isCheckedOut
              isComplete: element.payload.doc.data().isComplete, //Obtain from data isComplete
              rentalType: element.payload.doc.data().rentalType, //Obtain the type of the rental
              isLate: element.payload.doc.data().isLate, //Obtain from data isLate
            });
          }
        }
      });
    });
  }
  getRentalsList() {
    //Method to work with the rentals
    this._dashboardService.getRentals().subscribe((data) => {
      this.rentalstotal = data.length; //Total of rentals active
      let ds: any; //Aux variable to save the dayStart
      let de: any; //Aux variable to save the dayEnd
      this.datadays.splice(0, this.datadays.length);
      this.datadays = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0,
      ];
      this.lineChartData.datasets.splice(0, this.lineChartData.datasets.length);
      data.forEach((element: any) => {
        //Fot to get all elements
        ds = new Date(element.payload.doc.data()['dayStart'].seconds * 1000); //Get the timestamp in seconds to convert into a date
        de = new Date(element.payload.doc.data()['dayEnd'].seconds * 1000); //Get the timestamp in seconds to convert into a date
        if (
          moment(ds).format('YYYY-MM-DD') >= this.startMonth &&
          moment(ds).format('YYYY-MM-DD') <= this.endMonth
        ) {
          //Condition to see if the actual rental belongs to the actual month
          for (var i = 0; i <= moment().daysInMonth(); i++) {
            //Take every day of the month
            if (moment(ds).date() == i) {
              //if the actual date is the same as the day in for
              this.datadays[i - 1] = this.datadays[i - 1] + 1; //increment 1 in that posision
            } else {
            }
          }
        }
      }); //end for
      this.lineChartData.datasets.push({
        data: this.datadays, //Array with data
        label: 'Rentals in ' + this.month, //Name of the data
        backgroundColor: 'rgba(66,134,244,0.5)', //Background of the graphic line
        borderColor: 'rgba(66,134,244,1)', //Color of the graphic line
        pointBackgroundColor: 'rgba(66,134,244,1)', ////Color of the point in the graphic line
        pointBorderColor: '#fff', //Border color of the point
        pointHoverBackgroundColor: '#fff', //Color hover in the point
        pointHoverBorderColor: 'rgba(66,134,244,0.8)', //Border color hover of the point
        fill: 'origin',
      });
      this.chart.update(); //Update the chart to get the new data
    });
  }
  getRentalsActive() {
    //Function to obtain the total of Rentals Active
    let ds: any;
    let de: any;
    let varaux: number = 0;
    this._dashboardService.getRentals().subscribe((data) => {
      varaux = 0;
      data.forEach((element: any) => {
        //Fot to get all elements
        ds = new Date(element.payload.doc.data()['dayStart'].seconds * 1000); //Get the timestamp in seconds to convert into a date
        de = new Date(element.payload.doc.data()['dayEnd'].seconds * 1000); //Get the timestamp in seconds to convert into a date
        if (
          element.payload.doc.data().isComplete == false &&
          element.payload.doc.data().isCancelled == false &&
          element.payload.doc.data().isCheckedOut == false &&
          element.payload.doc.data().isCheckedIn == false
        ) {
          if (
            moment(ds).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD') ||
            moment(de).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')
          ) {
            //Condition to see if the actual rental belongs to the actual month
            varaux += 1;
            this.rentalsactive = varaux;
          }
        }
      }); //end for
    });
  }
  getProductsRepair() {
    //Method to work with repairs
    this._dashboardService.getProductsRepairs().subscribe((data) => {
      this.products = []; //Array products in null
      this.repairs = data.length; //Obtain the length of repairs
      data.forEach((element: any) => {
        //For to get all elements
        this.products.push({
          //Put the elements
          id: element.payload.doc.id, //Obtain the id from the document
          productCategory: element.payload.doc.data().productCategory, //Obtain the productCategory from the data
          productNumber: element.payload.doc.data().productNumber, //Obtain the productNumber from the data
          productName: element.payload.doc.data().productName, //Obtain the productName from the data
          price: element.payload.doc.data().price, //Obtain the price from the data
          isActive: element.payload.doc.data().isActive, //Obtain the isActive from the data
          isCleaning: element.payload.doc.data().isCleaning, //Obtain the isCleaning from the data
          isDamaged: element.payload.doc.data().isDamaged, //Obtain the isDamaged from the data
          isTuneUp: element.payload.doc.data().isTuneUp, //Obtain the isTuneUp from the data
          isMaintenance: element.payload.doc.data().isMaintenance, //Obtain the isMaintenance from the data
          isRetired: element.payload.doc.data().isRetired,
        }); //end for
      });
    });
  }

  public lineChartData: ChartConfiguration['data'] = {
    //data for the lineChart
    datasets: [], //array to obtain the data
    labels: [
      'Day 1',
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30,
      'Day 31',
    ], //labels with all days
  };

  public lineChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0.5,
      },
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      x: {},
      'y-axis-0': {
        position: 'left',
      },
    },
    plugins: {
      legend: { display: true },
    },
  };

  public lineChartType: ChartType = 'line';
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
  private static generateNumber(i: number): number {
    return Math.floor(Math.random() * (i < 2 ? 100 : 1000) + 1);
  }

  // events
  public chartClicked({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: {}[];
  }): void {}

  public chartHovered({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: {}[];
  }): void {}

  public hideOne(): void {
    const isHidden = this.chart?.isDatasetHidden(1);
    this.chart?.hideDataset(1, !isHidden);
  }

  public pushOne(): void {
    this.lineChartData.datasets.forEach((x, i) => {
      const num = DashboardComponent.generateNumber(i);
      x.data.push(num);
    });
    this.lineChartData?.labels?.push(
      `Label ${this.lineChartData.labels.length}`
    );

    this.chart?.update();
  }

  public changeColor(): void {
    this.lineChartData.datasets[2].borderColor = 'green';
    this.lineChartData.datasets[2].backgroundColor = `rgba(0, 255, 0, 0.3)`;

    this.chart?.update();
  }

  public changeLabel(): void {
    if (this.lineChartData.labels) {
      this.lineChartData.labels[2] = ['1st Line', '2nd Line'];
    }

    this.chart?.update();
  }
}
