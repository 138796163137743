<div class="card-body scrollable" (scroll)="onScroll($event)">
    <div class="header" class="mb-2">
        <div>
            <div class="row justify-content-center">
                <div class="col-md-6 text-left">
                    <h1 class="mb-2" style="font-size: 32px; cursor: pointer" (click)="openNewCategoryModal(false)"
                        data-bs-toggle="modal" data-bs-target="#newCategoryModal">
                        Categories
                        <button (click)="openNewCategoryModal(false)" type="button" [style.background]="colorBackground"
                            [style.color]="colorText" class="btn" data-bs-toggle="modal"
                            data-bs-target="#newCategoryModal">
                            <i class="fas fa-plus"></i>
                        </button>
                    </h1>
                </div>
                <div class="col-md-6" style="text-align: right !important">
                    <button type="button" [style.background]="colorBackground" [style.color]="colorText" class="btn"
                        (click)="openModalOrderCategories()">
                        <i class="fa fa-arrows"></i> Sort Categories
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="container-xl py-2 row">
            <ng-container *ngFor="let category of categories; let i = index">
                <div class="col-6">
                    <article class="postcard light blue" (click)="openNewCategoryModal(true, category)"
                        data-bs-toggle="modal" data-bs-target="#newCategoryModal"
                        [style.background-image]="'url(' + cleanUrl(category.imageUrl) + ')'">
                        <div class="cardshadow">
                            <div class="description">
                                <div class="textdescription">
                                    <div class="titledes">
                                        <p>{{ category.title }}</p>
                                        <div class="postcard__bar"></div>
                                    </div>
                                    <div class="subtitledes">
                                        <p>{{ category.description }}</p>
                                    </div>
                                </div>
                                <div class="buttondescription">
                                    <button [style.background]="colorBackground" [style.color]="colorText"
                                        class="btn btn-sm">
                                        Browse {{ category.title }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </ng-container>

        </div>
    </div>
</div>

<div class="modal" id="newCategoryModal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    <ng-container *ngIf="isEditing"> Edit Category </ng-container>
                    <ng-container *ngIf="!isEditing"> Add Category </ng-container>
                </h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            <div class="container-fluid modal-body">
                <form class="g-3 needs-validation" [formGroup]="categoryInfo" (ngSubmit)="validateForm()" novalidate>
                    <div class="row mt-2">
                        <div class="col-md-12">
                            <label for="title">Title<b style="color: red">*</b></label>
                            <input type="text" class="form-control form-control-sm" id="title" formControlName="title"
                                [ngClass]="{ 'is-invalid': f.title.errors && submitted }" placeholder="Title"
                                required />
                            <div *ngIf="f.title.errors?.['required'] && submitted" class="invalid-feedback">
                                Please provide a valid Title
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-md-12">
                            <label for="description">Description</label>
                            <textarea class="form-control" cols="30" rows="3" id="description" maxlength="110"
                                formControlName="description"></textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group-sm mb-1 input-group-sm">
                            <div class="col-lg-7">
                                <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                                    [aspectRatio]="96 / 54" [resizeToWidth]="960" format="png"
                                    (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                                    style="padding: 0; max-height: 100%">
                                </image-cropper>
                            </div>
                            <div *ngIf="croppedImage != undefined && croppedImage != ''" class="col-lg-12 mt-4">
                                <img style="width: 100%" [src]="croppedImage" />
                            </div>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col">
                            <button class="btn btn-file" (click)="openImgGallery()" data-bs-target="#imgGallery"
                                type="button" [disabled]="f.title.value === ''"
                                [ngClass]="isButtonAlert ? 'btn-danger' : 'btn-success'"
                                style="width: 150px !important">
                                <i class="fa fa-picture-o" aria-hidden="true"></i> Image Library
                            </button>
                        </div>
                        <!-- <app-file-upload *ngIf="user" style="width: 220px" [storagePath]="'users/' + user.id + '/profile/'" (urlEvent)="getFileURL($event)"></app-file-upload> -->
                    </div>
                    <!-- ImgGallery Modal -->
                    <div class="modal fade" data-bs-backdrop="false" id="imgGallery">
                        <!-- <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document" style="max-width: 90vw;"> -->
                        <div class="modal-dialog modal-dialog-centered" role="document"
                            style="max-width: 90vw; max-height: 90vh">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <!-- Button used to close the modal -->
                                    <div style="position: relative">
                                        <button id="closeImgLibraryBtn" type="button" class="btn btn-danger rowBtn"
                                            data-bs-dismiss="modal">
                                            <i class="fa fa-close"></i>
                                        </button>
                                    </div>

                                    <!-- The image library component -->
                                    <app-image-library [showSelect]="true"
                                        [defaultTabSelection]="'image'"></app-image-library>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-2 mb-2">
                        <div class="col-6 col-lg-3 text-left">
                            <button type="button" class="btn btn-outline-secondary btn-sm mt-1"
                                (click)="deleteCategory()" *ngIf="isEditing">
                                Delete this Category
                            </button>
                        </div>
                        <div class="col-4 col-lg-6 text-center">
                            <button type="submit" id="submitbtn" class="btn btn-success" [disabled]="disabledbutton">
                                <ng-container *ngIf="isEditing"> Update </ng-container>
                                <ng-container *ngIf="!isEditing"> Submit </ng-container>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="categoryCrop" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Please Crop Image</h4>
                <button type="button" class="btn btn-danger rowBtn" data-bs-dismiss="modal">
                    <i class="fa fa-close"></i>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="form-group-sm mb-1 input-group-sm">
                        <div class="col-lg-7">
                            <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                                [aspectRatio]="96 / 54" [resizeToWidth]="960" format="png"
                                (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                                style="padding: 0; max-height: 100%">
                            </image-cropper>
                        </div>
                        <div *ngIf="croppedImage != undefined && croppedImage != ''" class="col-lg-12 mt-4">
                            <img style="width: 100%" id="categoryImage" [src]="croppedImage" />
                        </div>
                    </div>
                </div>
                <div class="row mt-4" style="margin-top: 3.5rem !important">
                    <div class="col">
                        <button type="button" class="btn btn-success float-right" data-bs-dismiss="modal">
                            Finish
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="categoriesOrder" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Sort Categories.</h4>
                <button type="button" class="btn btn-danger rowBtn" data-bs-dismiss="modal">
                    <i class="fa fa-close"></i>
                </button>
            </div>

            <div class="modal-body" style="padding-left: 30px !important; padding-right: 30px">
                <div class="row mb-2">
                    <div class="col-4">
                        <div class="row">
                            <div class="col-3"></div>
                            <div class="col-3">Order</div>
                            <div class="col">Title</div>
                        </div>
                    </div>
                    <div class="col-6">Description</div>
                    <div class="col-2">Option Name</div>
                </div>

                <!--Start  of Content in Drag & Drop-->
                <div class="row" *ngIf="categories.length > 0">
                    <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                        <div class="example-box" *ngFor="let category of categories" cdkDrag>
                            <div class="row dragme mb-2">
                                <div class="col-4">
                                    <div class="row">
                                        <div class="col-3" style="margin-top: 5px !important">
                                            <i class="fa fa-arrows mr-2" style="font-size: 30px; color: #ccc"></i>
                                        </div>
                                        <div class="col-3" style="margin-top: 10px !important">
                                            {{ category.sortOrder + 1 }}
                                        </div>

                                        <div class="col" style="margin-top: 10px !important">
                                            {{ category.title }}
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6" style="margin-top: 10px !important">
                                    {{ category.description }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--Finish of Content in Drag & Drop-->

                <div class="row mt-2">
                    <div class="col-5 text-left mt-1">
                        <div class="alert alert-primary" style="text-align: justify"
                            *ngIf="categories.length > 1 && !isAlertClosed">
                            <i class="fa fa-window-close pull-right fa-2x" aria-hidden="true"
                                style="margin-left: 1.3em !important" (click)="closeAlert()"></i>
                            Click and drag to arrange the categories in the order you would
                            like them to appear on checkout.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
