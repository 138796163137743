<form class="needs-validations" #groupf="ngForm" [formGroup]="affiliateForm" (ngSubmit)="onSubmit()">

  <!-- name -->
  <div class="row">
    <div class="form-group-sm mb-2 input-group-sm">
      <label class="form-check-label" for="pageTitle">Affiliate Name</label>
      <span class="text-danger">*</span> <!-- Required Symbl -->
      <input class="form-control" type="text" name="name" id="name" formControlName="name">
    </div>
    <span class="text-danger mb-2"
      *ngIf="affiliateForm.get('name').errors?.required && affiliateForm?.get('name').touched">
      All affiliates must have a name.
    </span>
  </div>

  <!-- landingURL -->
  <div class="row">
    <div class="form-group-sm mb-2 input-group-sm">
      <label class="form-check-label" for="pageTitle">Landing URL</label>
      <span class="text-danger">*</span> <!-- Required Symbl -->
      <input class="form-control" type="text" name="landingURL" id="landingURL"
        placeholder="Examples: https://www.google.com" formControlName="landingURL">
      <div class="form-text">
        The landingURL should be the route you would like the user to be navigated to upon clicking the
        affiliate
        link.
        Example: https://www.fleetmaid.com/rentals, https://www.google.com, etc
      </div>
    </div>

    <span class="text-danger mb-2" *ngIf="affiliateForm.get('landingURL').errors?.required && affiliateForm?.get('landingURL').touched">
      All affiliates require a landing URL.
    </span>

    <span class="text-danger mb-2" *ngIf="affiliateForm.get('landingURL').errors?.urlInvalid">
      Please provide a valid URL. Example: https://www.fleetmaid.com/rentals, https://www.google.com, etc
    </span>
    <!-- <span class="text-danger" *ngIf="affiliateForm.get('landingURL').errors?.queryParamsPresent">
      The landing URI can not contain symbols such as '?'.https://www.google.com
    </span> -->
  </div>

  <!-- active -->
  <div class="row">
    <div>
      <span>Status</span>
      <span class="text-danger">*</span> <!-- Required Symbl -->
    </div>
    <div class="form-group-sm mb-2 input-group-sm">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" formControlName="active" [value]="true" id="activeTrue">
        <label class="form-check-label" for="activeTrue">Active</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" formControlName="active" [value]="false" id="activeFalse">
        <label class="form-check-label" for="activeFalse">Disabled</label>
      </div>
    </div>
  </div>

  <!-- Buttons -->
  <div class="container-xxl">
    <div class="row justify-content-center mt-2">
      <div class="text-center">
        <button type="submit" class="btn btn-success" [disabled]="affiliateForm.invalid || awaitingDB">
          <span>{{isEditing ? 'Save' : 'Create'}}</span>
          <span *ngIf="awaitingDB" class="spinner-grow spinner-grow-sm ml-1" role="active" aria-hidden="true"></span>
        </button>
      </div>
    </div>
  </div>
</form>