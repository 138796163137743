<!-- Datepicker + Location Form -->
<div>
    <!-- If not inventory page view-->
    <form class="align-items-center" #dateRangeForm>
        <div class="fluid-container" style="padding: 0px;">
            <div class="row">
                <h2 *ngIf="!isMiniCartEdit" style="margin: 0px;">Search:</h2>

                <!-- Location -->
                <div class="col-lg-6 col-md-12 col-sm-12 mt-1">
                    <!-- Location Label -->
                    <ng-container *ngIf="dateRangeConfig?.showFormLabels">
                        <label for="location" class="form-label">Location <b class="text-danger">*</b></label>
                    </ng-container>

                    <select [(ngModel)]="selectedLocationID" [disabled]="cartLockIsApplied" [required]="true"
                        #locationControl="ngModel"
                        [tooltip]="!isMiniCartEdit && cartLockIsApplied ? 'To change to a different location, please clear your cart or checkout' : null"
                        name="location" id="selectedLocation" class="form-select cursorPointer"
                        (ngModelChange)="selectedTimeslot = null; provideParamsOnInputChange(dateRangeInputType.Location)">
                        <option *ngIf="selectedLocationID === null" selected hidden [ngValue]="null">Select
                            location
                        </option>
                        <ng-container *ngFor="let l of locations">
                            <ng-container *ngIf="l.isActive">
                                <option [ngValue]="l.id">{{l.name}}</option>
                            </ng-container>
                        </ng-container>
                    </select>
                </div>

                <!-- Custom Date Range Text: Input -->
                <div class="col-lg-6 col-md-12 col-sm-12 mt-1">

                    <!-- Date range label -->
                    <ng-container *ngIf="dateRangeConfig?.showFormLabels">
                        <label for="dateRange" class="form-label">Date Range <b class="text-danger">*</b></label>
                    </ng-container>

                    <!-- Date Range Values Presented in String Format -->
                    <div style="display: flex; align-items: center">
                        <div class="cursorPointer" (click)="openDayPicker()" style="position: relative; flex-grow: 1"
                            tooltip="To select a different date range, please clear your cart or checkout"
                            placement="bottom" [isDisabled]="cartObj.items.length <= 0 || isMiniCartEdit" [delay]="250" id="dateRangeBootstrapInputDiv">
                            <input id="dateRangeBootstrapInput" class="cursorPointer" type="text" class="form-control"
                                name="dateRange"
                                [ngClass]="{ 'is-invalid': (startDateControl?.invalid && startDateControl?.dirty) || (endDateControl?.invalid && endDateControl?.dirty) }"
                                [disabled]="selectedLocationID === null || cartObj.items.length > 0 && !isMiniCartEdit"
                                readonly>
                            <div style="position: absolute; bottom: 10px; left: 8px;" class="cursorPointer">
                                <ng-container *ngIf="selectedLocationID != null">
                                    <span style="margin-left: 5px; margin-right: 2px;">
                                        {{selectedStartDate |
                                        luxonDisplayPipe : 'DATE_SHORT'}}
                                        <ng-container *ngIf="selectedStartDate && selectedEndDate">
                                            -
                                        </ng-container>
                                        {{selectedEndDate | luxonDisplayPipe :
                                        'DATE_SHORT'}}
                                    </span>
                                </ng-container>
                            </div>
                            <i style="position: absolute; right: 20px; bottom: 13px;" class="fa fa-calendar-o"
                                aria-hidden="true" placeholder="Select a Date Range to search"></i>
                        </div>

                        <ng-container *ngIf="dateRangeConfig?.showAvailabilityOverrideToggle">
                            <ng-container *ngIf="!isAvailabilityOverride">
                                <button (click)="setFormDateInputsAsDirty(); toggleAvailabilityOverride.emit(true);"
                                    tooltip="Standard availability rules are being enforced." [delay]="400" [container]="'body'"
                                    class="ml-1 badge overrideStatusBtn"
                                    style="padding: 1em; border: 1px solid; background-color: #6d8dd3 !important;">
                                    <i class="fas fa-shield-alt" style="font-size: large; pointer-events: none;"></i>
                                </button>
                            </ng-container>

                            <ng-container *ngIf="isAvailabilityOverride">
                                <button (click)="setFormDateInputsAsDirty(); toggleAvailabilityOverride.emit(false);"
                                    tooltip="Availability rules are being overridden. You may see options or times that are normally restricted." [container]="'body'"
                                    class="ml-1 badge bg-warning overrideStatusBtn" [delay]="400"
                                    style="padding: 1em;  border: 1px solid;">
                                    <i class="fa fa-exclamation-triangle"
                                        style="font-size: large; pointer-events: none;"></i>
                                </button>
                            </ng-container>
                        </ng-container>
                    </div>

                    <!-- Angular Material Date Range Component -->
                    <div class="ml-2" style="position: absolute; left: 20%;
                            bottom: -40px; visibility: hidden;">
                        <mat-form-field style="
                                background-color: white;
                                padding: 0.375rem 2.25rem 0.375rem 0.75rem;
                                height: 84%;
                                border: 1px solid #ced4da;
                                transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
                                border-radius: 0.25rem;">
                            <mat-label>Select a date range</mat-label>
                            <mat-date-range-input [disabled]="selectedLocationID === null" [rangePicker]="rangePicker"
                                [min]="dateRangeConfig?.filterByCurrentDay ? timezonesCurrentDate: null"
                                [dateFilter]="isAvailabilityOverride ? null: validateEachCalendarDay">
                                <input matInput matStartDate placeholder="Start date" name="startDate"
                                    [(ngModel)]="selectedStartDate" #startDateControl="ngModel" [required]="true">
                                <input matInput matEndDate placeholder="End date" name="endDate"
                                    [(ngModel)]="selectedEndDate" #endDateControl="ngModel" [required]="true">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matIconSuffix [for]="rangePicker" style="position: absolute;
                                    left: 80%;
                                    /* top: 80%; */
                                    /* top: 40px; */
                                    bottom: 1%;"></mat-datepicker-toggle>
                            <mat-date-range-picker #rangePicker [touchUi]="isMobile" style="position: absolute;">
                                <mat-date-range-picker-actions>
                                    <button mat-button matDateRangePickerCancel>Cancel</button>
                                    <button (click)="provideParamsOnInputChange(dateRangeInputType.DatePicker);"
                                        mat-raised-button color="primary" matDateRangePickerApply>Search</button>
                                </mat-date-range-picker-actions>
                            </mat-date-range-picker>
                        </mat-form-field>
                    </div>
                </div>

                <!-- Timeslot -->
                <ng-container *ngIf="dateRangeConfig && dateRangeConfig?.showAdditionalInputs?.showTimeslots">
                    <!-- Timeslot Input -->
                    <div class="col-lg-6 col-md-12 col-sm-12 mt-1"
                        [ngStyle]="{
                                                'display': (locationControl.invalid || startDateControl.invalid || endDateControl.invalid) ? 'none' : 'block'}">
                        <ng-container *ngIf="dateRangeConfig?.showFormLabels">
                            <label for="selectedTimeslot" class="form-label">Timeslot <b
                                    class="text-danger">*</b></label>
                        </ng-container>
                        <select (ngModelChange)="provideParamsOnInputChange(dateRangeInputType.Timeslot)"
                            [required]="true" [(ngModel)]="selectedTimeslot" #timeslotControl="ngModel"
                            class="form-select" id="selectedTimeslot" name="selectedTimeslot"
                            [disabled]="cartLockIsApplied">
                            <ng-container *ngFor="let group of timeslotsGroupedByType">
                                <ng-container *ngIf="group.timeslotOption.length >= 1">
                                    <optgroup [label]="group.timeslotTypeLabel">
                                        <option *ngFor="let timeslot of group.timeslotOption; let i = index"
                                            [ngValue]="timeslot">
                                            {{timeslot.dayStart | luxonDisplayPipe : 'DATETIME_SHORT' :
                                            locationMap[selectedLocationID].timezone}} - {{timeslot.dayEnd |
                                            luxonDisplayPipe : 'DATETIME_SHORT' :
                                            locationMap[selectedLocationID].timezone}}
                                        </option>
                                    </optgroup>
                                </ng-container>
                            </ng-container>
                        </select>
                    </div>
                </ng-container>

                <!-- Error Message -->
                <ng-container *ngIf="_errorHandlingObject">
                    <app-error-message class="mt-2" [errorHandlingObject]="_errorHandlingObject">
                    </app-error-message>
                </ng-container>
            </div>
        </div>
    </form>

    <!-- Availability Runtime -->
    <ng-container *ngIf="isDev && algoMetadata?.runTime">
        <span>Results returned in: <b>{{algoMetadata?.runTime}} {{algoMetadata?.runTimeUnit}}</b></span>
    </ng-container>
</div>